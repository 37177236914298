// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import Placeholder from '@atlassian/jira-placeholder';
import { ROADMAP_PACKAGE_NAME } from '../../../../constants';
import ListItemCreateSkeleton from './skeleton';
import type ListItemCreateType from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ListItemCreate = lazyAfterPaint<typeof ListItemCreateType>(
	() => import(/* webpackChunkName: "async-standard-roadmap-list-item-create" */ './index'),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	props: JSX.LibraryManagedAttributes<
		typeof ListItemCreateType,
		ComponentProps<typeof ListItemCreateType>
	>,
) => {
	const { isActive } = props;

	return (
		<JSErrorBoundary
			id="async-standard-roadmap-list-item-create"
			packageName={ROADMAP_PACKAGE_NAME}
			fallback="flag"
		>
			<Placeholder
				name="standard-roadmap-list-item-create"
				fallback={<ListItemCreateSkeleton isActive={isActive} />}
			>
				<ListItemCreate {...props} />
			</Placeholder>
		</JSErrorBoundary>
	);
};
