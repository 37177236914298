import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/ignoreElements';
import {
	type JQLFiltersSuccessAction,
	type JQLFiltersReapplyAction,
	JQL_FILTERS_SUCCESS,
	JQL_FILTERS_REAPPLY,
} from '../../state/ui/filters/actions';

export const awaitReapplyJQLFilters = (
	action$: ActionsObservable<JQLFiltersReapplyAction | JQLFiltersSuccessAction>,
): Observable<never> =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	Observable.concat(
		action$.ofType(JQL_FILTERS_REAPPLY).first().ignoreElements(),
		action$.ofType(JQL_FILTERS_SUCCESS).first().ignoreElements(),
	) as Observable<never>;
