import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	exportButton: {
		id: 'roadmap.timeline-table-kit.header.export.export-button',
		defaultMessage: 'Export',
		description: 'Button. Allows a user to export Timeline into a file.',
	},
	exportImagePng: {
		id: 'roadmap.timeline-table-kit.header.export.export-image-png',
		defaultMessage: 'Image (.png)',
		description: 'Button. Allows a user to export Timeline into a image in PNG format.',
	},
	exportSpreadsheetCsv: {
		id: 'roadmap.timeline-table-kit.header.export.export-spreadsheet-csv',
		defaultMessage: 'Spreadsheet (.csv)',
		description: 'Button. Allows a user to export Timeline into a spreadsheet in CSV format.',
	},
	exportSpreadsheetInferredStartDate: {
		id: 'roadmap.timeline-table-kit.header.export.export-spreadsheet-inferred-start-date',
		defaultMessage: 'Inferred start date',
		description: 'Column header for the inferred start date in the spreadsheet export',
	},
	exportSpreadsheetInferredDueDate: {
		id: 'roadmap.timeline-table-kit.header.export.export-spreadsheet-inferred-due-date',
		defaultMessage: 'Inferred due date',
		description: 'Column header for the inferred due date in the spreadsheet export',
	},
});
