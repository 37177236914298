import { defineMessages } from 'react-intl-next';

export default defineMessages({
	expand: {
		id: 'roadmap.timeline-table.main.content.list.list-item.expand',
		defaultMessage: 'Show child issues',
		description: 'Button label. Button expands and collapses a list item on Timelines issue list.',
	},
	collapse: {
		id: 'roadmap.timeline-table.main.content.list.list-item.collapse',
		defaultMessage: 'Hide child issues',
		description: 'Button label. Button expands and collapses a list item on Timelines issue list.',
	},
	createSibling: {
		id: 'roadmap.timeline-table.main.content.list.list-item.create-sibling',
		defaultMessage: 'Create issue',
		description:
			'Button label. Button begins issue creation process inline on Timelines issue list.',
	},
	createChild: {
		id: 'roadmap.timeline-table.main.content.list.list-item.create-child',
		defaultMessage: 'Create child issue',
		description:
			'Button label. Button begins issue creation process inline on Timelines issue list.',
	},
	done: {
		id: 'roadmap.timeline-table.main.content.list.list-item.done',
		defaultMessage: 'Done',
		description: 'Icon label. Icon appears when issue is in the done status category.',
	},
});
