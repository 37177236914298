import React, { memo } from 'react';
import { useEnablements, useIsScrollingY } from '../../../common/context';
import InlineCreateIndicator from './create';
import InlineDragIndicator from './drag';
import { useInlineIndicator } from './use-inline-indicator';

const InlineIndicators = () => {
	const [{ isCreateSiblingEnabled, isDragEnabled }] = useEnablements();
	const [isScrollingY] = useIsScrollingY();
	const inlineIndicatorParams = useInlineIndicator();

	if (isScrollingY) {
		return null;
	}

	return (
		<>
			{isCreateSiblingEnabled && <InlineCreateIndicator {...inlineIndicatorParams} />}
			{isDragEnabled && <InlineDragIndicator {...inlineIndicatorParams} />}
		</>
	);
};

export default memo<{}>(InlineIndicators);
