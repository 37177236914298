const monthMap: Record<string, string> = {
	Jan: '01',
	January: '01',
	Feb: '02',
	February: '02',
	Mar: '03',
	March: '03',
	Apr: '04',
	April: '04',
	May: '05',
	Jun: '06',
	June: '06',
	Jul: '07',
	July: '07',
	Aug: '08',
	August: '08',
	Sep: '09',
	September: '09',
	Oct: '10',
	October: '10',
	Nov: '11',
	November: '11',
	Dec: '12',
	December: '12',
};

const formatDay = (day: string): string => {
	if (day.length === 1) return `0${day}`;
	return day;
};

const formatMonth = (month: string): string => {
	if (monthMap[month]) return monthMap[month];
	if (month.length === 1) return `0${month}`;
	return month;
};

const formatYear = (year: string): number => {
	const yearAsNumber = Number(year);
	if (yearAsNumber < 100) return yearAsNumber + 2000;
	return yearAsNumber;
};

export const convertToISOWithTime = (dateString: string) => {
	const regex =
		/^(\d{1,2})[/\s-]([A-Za-z]+|\d{1,2})[/\s-](\d{2,4})(?:\s+(\d{1,2})(?::(\d{1,2}))?\s?(AM|PM|am|pm)?)?$/;

	const match = dateString.match(regex);

	if (match) {
		const [, day, month, year, hour, minute, period] = match;

		const formattedDay = formatDay(day);
		const formattedMonth = formatMonth(month);
		const formattedYear = formatYear(year);

		// Handle time conversion if present
		let formattedHour = hour ? parseInt(hour, 10) : 0;
		const formattedMinute = minute ? formatDay(minute) : '00'; // Reuse formatDay for padding

		// Adjust hour based on AM/PM
		if (period) {
			const upperPeriod = period.toUpperCase();
			if (upperPeriod === 'PM' && formattedHour !== 12) {
				formattedHour += 12;
			} else if (upperPeriod === 'AM' && formattedHour === 12) {
				formattedHour = 0;
			}
		}

		const timeString = `${String(formattedHour).padStart(2, '0')}:${formattedMinute}`;

		return `${formattedYear}-${formattedMonth}-${formattedDay}T${timeString}:00`;
	}

	return null;
};

export const convertToADF = (value: unknown) => {
	let transformedValue = value;
	if (Array.isArray(value) && value.length > 0) transformedValue = value[0];
	if (typeof transformedValue === 'string' || typeof transformedValue === 'number')
		return {
			type: 'doc',
			version: 1,
			content: [
				{
					type: 'paragraph',
					content: [
						{
							type: 'text',
							text: transformedValue.toString(),
						},
					],
				},
			],
		};
	return null;
};

export const checkIfValidId = (input: unknown) => {
	if ((typeof input === 'string' || typeof input === 'number') && Number(input)) return true;

	return false;
};
