import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { createAri, type Ari } from '@atlassian/jira-platform-ari';
import { removeVersionDetailsCache } from '@atlassian/jira-software-roadmap-services';
import { getCloudId, getActivationId } from '../../state/app/selectors';
import { SET_VERSION, type SetVersionAction as Action } from '../../state/configuration/actions';
import type { State } from '../../state/types';
import type { StateEpic } from '../common/types';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(SET_VERSION).mergeMap(({ payload: { id } }: Action) => {
		const state = store.getState();
		const cloudId = getCloudId(state);
		const activationId = getActivationId(state);

		const versionAri: Ari = createAri({
			resourceOwner: 'jira',
			cloudId,
			resourceType: 'version',
			activationId,
			resourceId: id,
		});

		cloudId && activationId && removeVersionDetailsCache(versionAri);

		return Observable.empty<never>();
	})) as StateEpic;
