import React, { useCallback, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { TodayButton } from '@atlassian/jira-aais-timeline-toolbar';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useTimelineState } from '@atlassian/jira-software-roadmap-timeline-table';
import { timelineScroller } from '@atlassian/jira-software-roadmap-utils/src/utils/timeline-scroller';

const RoadmapTodayButton = () => {
	const [{ timelineOriginPosition }] = useTimelineState();

	const onClickCallback = useCallback(
		(_: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'todayButton');
			timelineScroller.scrollTo({ left: timelineOriginPosition });
		},
		[timelineOriginPosition],
	);

	return <TodayButton onClick={onClickCallback} />;
};

export { RoadmapTodayButton };
