import type { User } from '@atlassian/jira-software-roadmap-model/src/user/index.tsx';
import type { RawGraphQLUser } from './types';

export const transformAssignee = (assignee: RawGraphQLUser): User => ({
	avatarUrls: {
		'16x16': assignee.picture,
		'24x24': assignee.picture,
		'32x32': assignee.picture,
		'48x48': assignee.picture,
	},
	displayName: assignee.name,
});
