import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { async } from 'rxjs/scheduler/async';
import { filterItemsByJQLFilters } from '@atlassian/jira-software-roadmap-services';
import { getSourceARI } from '../../state/app/selectors';
import type { State } from '../../state/types';
import {
	CUSTOM_FILTERS_REQUEST,
	type CustomFiltersRequestAction as Action,
	type CustomFiltersClearAction,
	type JQLFiltersSuccessAction,
	clearCustomFilters,
	jqlFiltersSuccess,
} from '../../state/ui/filters/actions';

const filter = (
	action: Action,
	store: MiddlewareAPI<State>,
): Observable<CustomFiltersClearAction | JQLFiltersSuccessAction> => {
	const state = store.getState();
	const customFilterIds = action.payload;
	if (customFilterIds.length === 0) {
		return Observable.of(clearCustomFilters());
	}

	return filterItemsByJQLFilters(getSourceARI(state), [], customFilterIds).map((filteredIssueIds) =>
		jqlFiltersSuccess(filteredIssueIds),
	);
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(CUSTOM_FILTERS_REQUEST)
		.throttleTime(500, async, {
			leading: true,
			trailing: true,
		})
		.switchMap((action: Action) => filter(action, store));
