import { defineMessages } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default defineMessages({
	today: {
		id: 'aais-timeline-toolbar.today-button.today',
		defaultMessage: 'Today',
		description: "Button label. Scrolls the timeline to today's date",
	},
}) as {
	today: MessageDescriptor;
};
