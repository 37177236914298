import React, { type ComponentPropsWithoutRef } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import Placeholder from '@atlassian/jira-placeholder';
import { ROADMAP_PACKAGE_NAME } from '../../../../../../constants';
import type EditSprintMenu from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const EditSprintMenuLazy = lazyAfterPaint<typeof EditSprintMenu>(
	() => import(/* webpackChunkName: "async-standard-roadmap-edit-sprint-menu" */ './index'),
	{ ssr: false },
);

type Props = JSX.LibraryManagedAttributes<
	typeof EditSprintMenu,
	ComponentPropsWithoutRef<typeof EditSprintMenu>
>;

export const EditSprintMenuAsync = (props: Props) => (
	<JSErrorBoundary
		id="async-standard-roadmap-edit-sprint-menu"
		packageName={ROADMAP_PACKAGE_NAME}
		fallback="flag"
	>
		<Placeholder name="async-standard-roadmap-edit-sprint-menu" fallback={null}>
			<EditSprintMenuLazy {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
