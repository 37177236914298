import { DISABLED } from '@atlassian/jira-software-roadmap-model';
import {
	type SetCanUserInviteAction,
	SET_CAN_USER_INVITE,
	SET_CONFIGURATION,
	type SetConfigurationAction,
	SET_TODAY,
	type SetTodayAction,
	SET_COMPONENTS,
	type SetComponentsAction,
	SET_VERSION,
	type SetVersionAction,
	SET_DERIVE_FIELDS,
	type SetDeriveFieldsHashAction,
	type SetSprintAction,
	SET_SPRINT,
} from './actions';
import type { ConfigurationState } from './types';

type Action =
	| SetConfigurationAction
	| SetTodayAction
	| SetComponentsAction
	| SetVersionAction
	| SetDeriveFieldsHashAction
	| SetSprintAction
	| SetCanUserInviteAction;

// there is no valid initial state.
// this state will be set by the INITIALIZE op, after which it will be valid
// for the rest of the app lifetime. before INITIALIZE, the entire app is invalid
// and must only ever show a spinner or similar
const initialState: ConfigurationState = {
	today: 0,
	rankIssuesSupported: false,
	isCrossProject: false,
	startDateCustomFieldId: '',
	lexoRankCustomFieldId: '',
	colorCustomFieldId: '',
	epicLinkCustomFieldId: '',
	epicNameCustomFieldId: '',
	sprintCustomFieldId: '',
	inwardDependencyDescription: undefined,
	outwardDependencyDescription: undefined,
	dependenciesSupported: false,
	projectConfiguration: {
		id: '',
		key: '',
		name: '',
		permissions: {
			canEditIssues: false,
			canScheduleIssues: false,
			canCreateIssues: false,
			canAdministerProjects: false,
		},
		epicIssueTypeIds: [],
		requiredFields: {},
		issueTypes: {
			hash: {},
		},
		versions: {
			hash: {},
		},
		components: {
			hash: {},
		},
		defaultItemTypeId: undefined,
		isReleasesFeatureEnabled: false,
		isGoalsFeatureEnabled: false,
	},
	boardConfiguration: {
		isUserBoardAdmin: false,
		jql: null,
		isBoardJqlFilteringOutEpics: false,
		isSprintsFeatureEnabled: false,
		isChildIssuePlanningEnabled: false,
		sprints: {
			hash: {},
			sequence: [],
		},
		childIssuePlanningMode: DISABLED,
	},
	hierarchyConfiguration: { levelOneName: '' },
	deriveFields: {},
	fields: [],
	canInviteOthersToProject: false,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ConfigurationState = initialState, action: Action): ConfigurationState => {
	switch (action.type) {
		case SET_CONFIGURATION:
			return action.payload;

		case SET_TODAY:
			return {
				...state,
				today: action.payload,
			};

		case SET_COMPONENTS: {
			const { components } = action.payload;
			const project = state.projectConfiguration;

			return {
				...state,
				projectConfiguration: {
					...project,
					components: {
						hash: components,
					},
				},
			};
		}

		case SET_VERSION: {
			const { projectConfiguration } = state;
			const { hash: versionHash } = projectConfiguration?.versions ?? {};
			const { id, ...version } = action.payload;

			return {
				...state,
				projectConfiguration: {
					...projectConfiguration,
					versions: {
						hash: {
							...versionHash,
							[id]: version,
						},
					},
				},
			};
		}

		case SET_DERIVE_FIELDS: {
			return {
				...state,
				deriveFields: action.payload,
			};
		}

		case SET_SPRINT: {
			const { boardConfiguration } = state;
			const { hash: sprintHash, sequence: sprintSequence } = boardConfiguration?.sprints ?? {};
			const sprint = action.payload;

			return {
				...state,
				boardConfiguration: {
					...boardConfiguration,
					sprints: {
						hash: {
							...sprintHash,
							[sprint.id]: sprint,
						},
						sequence: sprintSequence,
					},
				},
			};
		}

		case SET_CAN_USER_INVITE: {
			return {
				...state,
				canInviteOthersToProject: action.payload.canInviteOthersToProject,
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
