export type GenericInterval<T> = T & {
	startDate: number;
	endDate: number;
};

export type IntervalGroup<T> = {
	startDate: number;
	endDate: number;
	expandedEndDate: number;
	intervals: ReadonlyArray<GenericInterval<T>>;
};

/**
 * This algorithm sorts a list of intervals by interval.end, and then traverses through the sorted list
 * of intervals, and greedily groups together adjacent intervals that are overlapped.
 * The most expensive part of this algorithm is the initial sorting, which should be O(n·log(n)).
 *
 * Takes a list of intervals, where an interval is the time from *interval.start inclusive* to *interval.end exclusive*.
 * Returns a list of interval groups, which is essentially a list of lists.
 */
export const groupOverlappingIntervals = <T,>(
	intervals: GenericInterval<T>[],
): IntervalGroup<T>[] => {
	/* To read the diagram:
	 * - Each unique character represents an interval, where the start is the first character, and the end is the last character.
	 * - Intervals are ordered in the same way they appear in the input list.
	 * - Uppercase characters represent overlapping time, and lowercase characters represents overlapped time.
	 * - Each row, separated by (-), represents an interval grouping.
	 *
	 * |---------------------|
	 * |   A A A             |
	 * |---------------------|
	 * |   B B B B           |
	 * |---------------------|
	 * |               C C   |
	 * |---------------------|
	 * |   D D               |
	 * |---------------------|
	 * | E E E               |
	 * |---------------------|
	 * |   F F               |
	 * |---------------------|
	 */

	// remember original position in the list, because we'll need to sort again at the end

	const intervalsWithIndex = intervals.map((interval, index) => ({ ...interval, index }));

	// First sort, as it will make the grouping logic a lot simpler.
	// Sprint with later end last; earlier sprint start last.
	const sortedIntervals = [...intervalsWithIndex].sort((interval, otherInterval) => {
		const endComparison = interval.endDate - otherInterval.endDate;
		if (endComparison !== 0) {
			return endComparison;
		}
		return otherInterval.startDate - interval.startDate;
	});

	/* |---------------------|
	 * |   D D               |
	 * |---------------------|
	 * |   F F               |
	 * |---------------------|
	 * | E E E               |
	 * |---------------------|
	 * |   A A A             |
	 * |---------------------|
	 * |   B B B B           |
	 * |---------------------|
	 * |               C C   |
	 * |---------------------|
	 */

	let result: IntervalGroup<T>[] = [];

	// Traverse through the list of sorted intervals in _reverse_ order.
	// Group together adjacent intervals that are _completely_ overlapped.

	let i = sortedIntervals.length - 1;
	while (i >= 0) {
		const currentInterval = sortedIntervals[i];
		let groupedIntervals = [currentInterval];
		let j = i - 1;
		while (j >= 0) {
			const nextInterval = sortedIntervals[j];
			// Since intervals are sorted, then the currentInterval.end >= nextInterval.end
			const isNextIntervalCompletelyOverlapped =
				currentInterval.startDate <= nextInterval.startDate;
			if (isNextIntervalCompletelyOverlapped) {
				groupedIntervals = [nextInterval].concat(groupedIntervals);
			} else {
				break;
			}
			j -= 1;
		}
		const groupedIntervalsInOriginalOrdering = groupedIntervals
			.sort((interval, otherInterval) => interval.index - otherInterval.index)
			.map(({ index, ...interval }) => interval);
		result = [
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			{
				startDate: currentInterval.startDate,
				endDate: currentInterval.endDate,
				expandedEndDate: currentInterval.endDate,
				intervals: groupedIntervalsInOriginalOrdering,
			} as IntervalGroup<T>,
		].concat(result);
		i = j;
	}

	/* |---------------------|
	 * |               C C   |
	 * |---------------------|
	 * |   B B B B           |
	 * |   a a a             |
	 * |---------------------|
	 * | E E E               |
	 * |   f f               |
	 * |   d d               |
	 * |---------------------|
	 */

	// We now "collapse" adjacent interval groups that overlap.
	// Later interval groups take precedence over earlier interval groups.
	if (result.length >= 2) {
		for (let k = 0; k < result.length - 1; k += 1) {
			const currentIntervalGroup = result[k];
			const nextIntervalGroup = result[k + 1];
			if (currentIntervalGroup.endDate > nextIntervalGroup.startDate) {
				currentIntervalGroup.endDate = nextIntervalGroup.startDate;
			}
		}
	}

	/* |---------------------|
	 * | E e e               |
	 * |   f f               |
	 * |   d d               |
	 * |---------------------|
	 * |   B B B B           |
	 * |   a a a             |
	 * |---------------------|
	 * |               C C   |
	 * |---------------------|
	 */

	return result;
};
