// eslint-disable-next-line jira/restricted/react-component-props
import type { ComponentProps } from 'react';
import { connect } from '@atlassian/jira-react-redux';
import type { IssueId } from '@atlassian/jira-shared-types';
import { isBaseLevel } from '@atlassian/jira-software-roadmap-model';
import type {
	CreateItemAnchor,
	OnCreateClick,
} from '@atlassian/jira-software-roadmap-timeline-table/src/types';
import {
	getIssueSummary,
	getIssueKeyById,
	getIsIssueResolved,
	getIssueLevel,
} from '../../../../state/entities/issues/selectors';
import { isProgressVisible, getFilteredText } from '../../../../state/selectors/filters';
import { getProgress, getIssueTypeForIssueId } from '../../../../state/selectors/issues';
import {
	getIssueSubtaskProgress,
	DEFAULT_PROGRESS_META_DATA,
} from '../../../../state/selectors/subtasks';
import { getItems, getIsCreateChildEnabled } from '../../../../state/selectors/table';
import type { State } from '../../../../state/types';
import { setCreateItemAnchor } from '../../../../state/ui/table/actions';
import { createGetIsNudgeVisible } from './selectors';
import ListItemContent from './view';

type OwnProps = {
	isHoveredOrFocused: boolean;
	id: IssueId;
};

type DispatchProps = {
	onChildCreateClick: OnCreateClick;
};

type StateProps = Flow.Diff<
	ComponentProps<typeof ListItemContent>,
	DispatchProps & {
		id: IssueId;
		isRichContentEnabled: boolean;
	}
>;

const onChildCreateClick = (anchor: CreateItemAnchor) => setCreateItemAnchor(anchor);

export default connect(
	(state: State, { id }: OwnProps): StateProps => {
		const issueType = getIssueTypeForIssueId(state, id);

		return {
			iconUrl: issueType?.iconUrl,
			iconAltText: issueType?.name,
			title: getIssueSummary(state, id),
			itemKey: getIssueKeyById(state, id),
			progress: isProgressVisible(state) ? getProgress(state, id) : undefined,
			isDone: getIsIssueResolved(state, id),
			highlightText: getFilteredText(state),
			isNudgeEnabled: createGetIsNudgeVisible(id)(
				getIsCreateChildEnabled(state),
				getItems(state)?.[0],
			),
			progressMetadata: isBaseLevel(getIssueLevel(state, id))
				? getIssueSubtaskProgress(state, id)
				: DEFAULT_PROGRESS_META_DATA,
		};
	},
	{
		onChildCreateClick,
	},
	(stateProps: StateProps, dispatchProps: DispatchProps, { isHoveredOrFocused, id }: OwnProps) => ({
		...stateProps,
		...dispatchProps,
		isRichContentEnabled: isHoveredOrFocused,
		id,
	}),
)(ListItemContent);
