import React from 'react';
import { styled } from '@compiled/react';
import IconBase from '@atlaskit/icon/base';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

const getRollupIconGlyph = () => (
	<svg width="24" height="24" viewBox="0 0 24 24">
		<path
			fill="currentColor"
			d="M14.586 11l-2.293-2.293a1 1 0 1 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414L14.586 13H10a1 1 0 0 0-1 1v7a1 1 0 0 1-2 0v-7a3 3 0 0 1 3-3h4.586z"
		/>
	</svg>
);

const RollupIcon = () => {
	const { formatMessage } = useIntl();
	return (
		<Container>
			<IconBase
				glyph={getRollupIconGlyph}
				label={formatMessage(messages.rollUpIconLabel)}
				size="small"
			/>
		</Container>
	);
};

export { RollupIcon };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2.5}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 2}px`,
	marginRight: token('space.025', '2px'),
});
