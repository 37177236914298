import { createSelector } from 'reselect';
import { INWARD_LINK_DIRECTION, OUTWARD_LINK_DIRECTION } from '@atlassian/jira-issue-shared-types';
import type { IssueId } from '@atlassian/jira-shared-types';
import type { EditDependency } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/types';
import {
	getFullIssueTypeHash,
	getIsChildIssuePlanningEnabled,
	getIsSprintsFeatureEnabled,
	getIsSprintsPlanningEnabled,
} from '../../configuration/selectors';
import {
	getIsIssueDueDateRequired,
	getIsIssueStartDateRequired,
	getIssue,
	getIssueIds,
	getIssueKeyById,
	getIssueKeyHash,
	getIssueSummary,
	getIssueSummaryHash,
	getIssueTypeIdHash,
	getIssueLevel,
	getIssueColor,
} from '../../entities/issues/selectors';
import type { State } from '../../types';
import { getSelectedItemIds } from '../../ui/table/selectors';
import {
	getIssueContextMenuSprints,
	getIssueDependees,
	getIssueDependencies,
	getIssueTypeForIssueId,
	getNonBaseLevelIssueIds,
} from '../issues';
import { getCanUserEdit } from '../permission';
import { getIsCurrentlyAssignedToSprints } from '../sprint';
import { getChartDataById } from '../table';
import { getListItemMenuOptionsPure } from './pure';
import { getBulkEditDatesOptionParamsPure } from './pure/bulk-edit-dates';
import { getChangeColorOptionParamsPure } from './pure/change-color';
import { getEditDatesOptionParamsPure } from './pure/edit-dates';
import { getDependencyOptionsPure } from './pure/edit-dependency';
import { getEditSprintOptionParamsPure } from './pure/edit-sprint';
import { getParentOptionsPure } from './pure/reparent';

export const getListItemMenuOptions = createSelector(
	getCanUserEdit,
	getIsSprintsFeatureEnabled,
	getIsChildIssuePlanningEnabled,
	getListItemMenuOptionsPure,
);

export const getEditDatesOptionParams = createSelector(
	getIsChildIssuePlanningEnabled,
	getIsSprintsPlanningEnabled,
	getIsIssueStartDateRequired,
	getIsIssueDueDateRequired,
	getIssue,
	getIssueTypeForIssueId,
	getChartDataById,
	getEditDatesOptionParamsPure,
);

export const getBulkEditDatesOptionParams = createSelector(
	getSelectedItemIds,
	getEditDatesOptionParams,
	getBulkEditDatesOptionParamsPure,
);

export const getChangeColorOptionParams = createSelector(
	getIssueLevel,
	getIssueColor,
	getChangeColorOptionParamsPure,
);

export const getEditSprintOptionParams = createSelector(
	getIssueLevel,
	getIsCurrentlyAssignedToSprints,
	getIssueContextMenuSprints,
	getEditSprintOptionParamsPure,
);

export const getParentOptions = createSelector(
	getNonBaseLevelIssueIds,
	getIssueTypeIdHash,
	getFullIssueTypeHash,
	getIssueKeyHash,
	getIssueSummaryHash,
	getParentOptionsPure,
);

export const getDependencyOptions = createSelector(
	getIssueIds,
	getIssueTypeIdHash,
	getFullIssueTypeHash,
	getIssueKeyHash,
	getIssueSummaryHash,
	getDependencyOptionsPure,
);

export const getDependenciesById = createSelector(
	(state: State, issueId: IssueId): EditDependency[] => {
		const dependencyIds = getIssueDependencies(state, issueId);
		const dependeeIds = getIssueDependees(state, issueId);

		const dependencies: EditDependency[] = [];

		const mapDependencies =
			(link: typeof INWARD_LINK_DIRECTION | typeof OUTWARD_LINK_DIRECTION) =>
			(dependencyId: string) => {
				const dependencyIssueType = getIssueTypeForIssueId(state, dependencyId);
				const dependencyIssueKey = getIssueKeyById(state, dependencyId);

				if (dependencyIssueKey) {
					dependencies.push({
						id: dependencyId,
						issueKey: dependencyIssueKey,
						issueSummary: getIssueSummary(state, dependencyId),
						issueType: dependencyIssueType.name,
						iconUrl: dependencyIssueType.iconUrl,
						link,
					});
				}
			};

		dependencyIds?.forEach(mapDependencies(INWARD_LINK_DIRECTION));
		dependeeIds?.forEach(mapDependencies(OUTWARD_LINK_DIRECTION));

		return dependencies;
	},
	(dependencies) => dependencies,
);
