import type { ClientBounding, ScrollBounding } from '../common/types';

export const DEFAULT_BOUNDING_CLIENT: ClientBounding = {
	width: 0,
	height: 0,
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
};

export const DEFAULT_SCROLL_BOUNDING: ScrollBounding = {
	scrollTop: 0,
	scrollLeft: 0,
	scrollRight: 0,
	scrollBottom: 0,
	scrollHeight: 0,
	scrollWidth: 0,
	isOverflowY: false,
};
