import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	timelineDocumentTitle: {
		id: 'roadmap.standard-roadmap.document-title.timeline-document-title',
		defaultMessage: '{projectName} - Timeline',
		description:
			'HTML document title of the Timeline page. {projectName} refers to the name of the next-gen project the Timeline belongs to.',
	},
});
