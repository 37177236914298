/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { Progress } from '../../common/types/progress';
import ProgressBar from '../../common/ui/progress-bar';
import Summary, { type ProgressMetadata } from './summary';

type Props = {
	isDone: boolean;
	isRichContentEnabled: boolean;
	title: string;
	iconUrl: string;
	iconAltText: string;
	itemKey?: string;
	progress?: Progress;
	highlightText: string;
	progressMetadata?: ProgressMetadata;
};

const RoadmapListItemContent = ({
	title,
	iconUrl,
	iconAltText,
	itemKey,
	progress,
	highlightText,
	isDone,
	isRichContentEnabled,
	progressMetadata,
}: Props) => (
	<>
		<Summary
			title={title}
			itemKey={itemKey}
			iconUrl={iconUrl}
			iconAltText={iconAltText}
			highlightText={highlightText}
			isDone={isDone}
			isActive={isRichContentEnabled}
			progressMetadata={progressMetadata}
		/>
		<>
			{progress && (
				<div css={containerStyles}>
					<ProgressBar isRichContentEnabled={isRichContentEnabled} progress={progress} />
				</div>
			)}
		</>
	</>
);

RoadmapListItemContent.defaultProps = {
	title: '',
	iconUrl: '',
	iconAltText: '',
	highlightText: '',
	isDone: false,
};

export { RoadmapListItemContent };

const containerStyles = css({
	position: 'absolute',
	bottom: token('space.050', '4px'),
	left: token('space.600', '48px'),
	width: '172px',
	height: token('space.050', '4px'),
});
