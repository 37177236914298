// eslint-disable-next-line jira/restricted/moment
import moment from 'moment';
import { ISO_DATE_FORMAT } from '@atlassian/jira-software-roadmap-model/src/datetime/index.tsx';

export const dateToUtcTimestamp = (date: string): number =>
	moment.utc(date, ISO_DATE_FORMAT).valueOf();

export const optionalDateToUtcTimestamp = (date?: string): number | undefined => {
	if (date === undefined) {
		return undefined;
	}

	return dateToUtcTimestamp(date);
};

export const optionalUtcTimestampToDate = (timestamp?: number): string | undefined => {
	if (timestamp === undefined) {
		return undefined;
	}

	return moment.utc(timestamp).format(ISO_DATE_FORMAT);
};
