import { useRef, useEffect } from 'react';
import { useResource } from '@atlassian/jira-router';
import { boardResource as softwareBoardResource } from '@atlassian/jira-router-resources-board-resource';
import { backlogResource as backlogDataResource } from '@atlassian/jira-router-resources-next-gen-backlog/src/services/backlog/index.tsx';
import { nextGenRoadmapResource } from '@atlassian/jira-router-resources-roadmap/src/services/next-gen-roadmap/index.tsx';
import { BOARD, BACKLOG, ROADMAPS, type ResourcesDictionary } from '../../types';

// We only ever want refresh and update in this component
// @ts-expect-error - TS7031 - Binding element 'update' implicitly has an 'any' type. | TS7031 - Binding element 'refresh' implicitly has an 'any' type. | TS7031 - Binding element 'clear' implicitly has an 'any' type.
const buildResource = ({ update, refresh, clear }) => ({
	update,
	refresh,
	clear,
});

/*
 * Simple hook that wraps the commonly used JSW resources in a dictionary and returns them
 */
const useResourcesCacheTracker = (): [ResourcesDictionary] => {
	const boardResource = useResource(softwareBoardResource);
	const backlogResource = useResource(backlogDataResource);
	const roadmapResource = useResource(nextGenRoadmapResource);

	// Store the retrieved objects in an internal state. The reason for doing this is because when our routes
	// change, the objects returned from useResource appear to change as well. We don't want/need that.
	const resourcesByOriginByRef = useRef({});

	useEffect(() => {
		resourcesByOriginByRef.current = {
			[BOARD]: buildResource(boardResource),
			[BACKLOG]: buildResource(backlogResource),
			[ROADMAPS]: buildResource(roadmapResource),
		};
	}, [boardResource, backlogResource, roadmapResource]);

	// @ts-expect-error - TS2322 - Type 'MutableRefObject<{}>' is not assignable to type 'ResourcesDictionary'.
	return [resourcesByOriginByRef];
};

export default useResourcesCacheTracker;
