import type { Observable } from 'rxjs/Observable';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	projectId: string,
	epicTypeName?: string,
	epicTypeDescription?: string,
	promoteIssueTypeId?: string,
): Observable<unknown | undefined> => {
	const options = {
		method: 'POST',
		body: JSON.stringify({
			projectId,
			epicTypeName,
			epicTypeDescription,
			promoteIssueTypeId: Number(promoteIssueTypeId),
		}),
	};

	return fetchJson$('/rest/greenhopper/1.0/roadmap/setup', options);
};
