import type { MouseEvent, KeyboardEvent } from 'react';
import { connect } from '@atlassian/jira-react-redux';
import type { IssueId } from '@atlassian/jira-shared-types';
import type { ListItemMenuOptionProps } from '@atlassian/jira-software-roadmap-timeline-table/src/types';
import { getBulkEditDatesOptionParams } from '../../../../../state/selectors/list-item-menu-option';
import type { State } from '../../../../../state/types';
import BulkEditDatesOption from './view';

type OwnProps = {
	id: IssueId;
	setOpenModal: (event: MouseEvent | KeyboardEvent) => void;
	Option: ListItemMenuOptionProps['Option'];
};

type StateProps = {
	isDateEditable: boolean;
};

const mapStateToProps = (state: State, { id }: OwnProps): StateProps => {
	const { isDateEditable } = getBulkEditDatesOptionParams(state, id);

	return {
		isDateEditable,
	};
};

export default connect(mapStateToProps)(BulkEditDatesOption);
