import {
	createStore,
	createContainer,
	createHook,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import { DEFAULT_LIST_WIDTH } from '../../constants';
import { actions, type Actions } from './actions';
import type { Props, State } from './common/types';
import { getListWidth, getListItemMenuOptions } from './selectors';

const initialState: State = {
	listWidth: DEFAULT_LIST_WIDTH,
	listItemMenuOptions: {
		overrideLabels: {},
		customSections: [],
		customSectionsBulk: [],
	},
};

export const listStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'timeline-table.list',
});

export const useListActions = createActionsHook(listStore);

export const useListWidth = createHook(listStore, {
	selector: getListWidth,
});
export const useListItemMenuOptions = createHook(listStore, {
	selector: getListItemMenuOptions,
});

export const useListState = createHook(listStore);

export const ListProvider = createContainer<State, Actions, Props>(listStore, {
	onInit:
		() =>
		({ setState }, listState: State) => {
			setState({ ...listState });
		},
	onUpdate:
		() =>
		({ setState }, listState: State) => {
			setState({ ...listState });
		},
});

export default ListProvider;
