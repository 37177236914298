import React from 'react';
import {
	RoadmapDateLabels,
	RoadmapBarContent,
} from '@atlassian/jira-software-roadmap-timeline-table-kit';
import type {
	BarContentProps,
	BarContentState,
	BarIconRendererProps,
	BarDependencyHandlerRendererProps,
	BarDependencyHandlerRendererState,
	BarDateLabelRendererProps,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/types';
import BarIcon from './bar-icon';
import DependencyHandler from './dependency-handler';

const renderBarContent = (barContentProps: BarContentProps, barContentState: BarContentState) => {
	const barContentRenderers = {
		renderBarIcon: ({ id }: BarIconRendererProps) => <BarIcon id={id} />,
		renderDependencyHandler: (
			props: BarDependencyHandlerRendererProps,
			state: BarDependencyHandlerRendererState,
		) => <DependencyHandler {...props} {...state} />,
		renderDateLabel: (props: BarDateLabelRendererProps) => <RoadmapDateLabels {...props} />,
	} as const;

	return <RoadmapBarContent {...barContentProps} {...barContentState} {...barContentRenderers} />;
};

export { renderBarContent };
