import { connect } from '@atlassian/jira-react-redux';
import { getLocationUrl } from '../../state/router/selectors';
import type { State } from '../../state/types';
import ErrorFlag from './view';

export default connect(
	(state: State) => ({
		href: getLocationUrl(state),
	}),
	{},
)(ErrorFlag);
