import {
	StatusCategoryIds,
	StatusCategoryTypes,
} from '@atlassian/jira-common-constants/src/status-categories';

export const NO_CATEGORY = StatusCategoryTypes.UNDEFINED;
export const TODO = StatusCategoryTypes.NEW;
export const IN_PROGRESS = StatusCategoryTypes.INDETERMINATE;
export const { DONE } = StatusCategoryTypes;

export const STATUS_CATEGORY_APPEARENCE: Record<string, string> = {
	'2': 'default',
	'3': 'success',
	'4': 'inprogress',
} as const;

export const TODO_STATUS_CATEGORY_ID = StatusCategoryIds[TODO];
