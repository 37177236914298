import memoizeOne from 'memoize-one';

export const getScrollbarSize = (scrollElement: HTMLElement) =>
	scrollElement.offsetWidth - scrollElement.clientWidth;

export const getScrollOffsetRatio = (
	scrollOffset: number,
	scrollSize: number,
	clientSize: number,
): number => scrollOffset / (scrollSize - clientSize);

export const getContentToViewportRatio = (scrollSize: number, clientSize: number) =>
	clientSize / scrollSize;

export const getViewportOffsets = memoizeOne(
	(scrollbarSize: number, listWidth: number, timelineTop: number) => ({
		scrollbar: scrollbarSize,
		timelineLeft: listWidth,
		timelineTop,
	}),
);
