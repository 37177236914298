import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import ScrollFPSMetric from '@atlassian/jira-scroll-fps-metric';
import { useItemsCount } from '../../../context';

type Props = {
	scope: string;
	scrollElement: HTMLElement;
};

const TimelineTableScrollFPSMetric = ({ scope, scrollElement }: Props) => {
	const [itemsCount] = useItemsCount();

	const attributes = {
		isCustomScrollbarPdnd: fg('jsw_roadmaps_timeline_table_custom_scroll_pdnd'),
	};

	return (
		<ScrollFPSMetric
			viewId={scope}
			itemsCount={itemsCount}
			scrollElement={scrollElement}
			attributes={attributes}
		/>
	);
};

export default TimelineTableScrollFPSMetric;
