import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/mergeMap';
import { Observable, type Observable as ObservableType } from 'rxjs/Observable';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	accountId: AccountId,
	propertyKey: string,
	propertyValue: string,
): ObservableType<void> => {
	const options = {
		method: 'PUT',
		body: propertyValue,
	};

	return Observable.fromPromise(
		performPutRequest(
			`/rest/api/2/user/properties/${propertyKey}?accountId=${encodeURIComponent(accountId)}`,
			options,
		),
	).mergeMap(() => Observable.empty<never>());
};
