import {
	type RealtimeEventType,
	ISSUE_CREATED_EVENT,
	ISSUE_DELETED_EVENT,
	ISSUE_TRANSITIONED_EVENT,
	ISSUE_UPDATED_EVENT,
} from '@atlassian/jira-realtime';

export const REALTIME_EVENTS: RealtimeEventType[] = [
	ISSUE_CREATED_EVENT,
	ISSUE_DELETED_EVENT,
	ISSUE_TRANSITIONED_EVENT,
	ISSUE_UPDATED_EVENT,
];
