/** @jsx jsx */
import React, { type PropsWithChildren } from 'react';
import { css, styled, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';

interface Props {
	title: string;
}

const LegendSection = ({ title, children }: PropsWithChildren<Props>) => (
	<Container>
		<span css={TitleStyles}>{title}</span>
		{children}
	</Container>
);

export default LegendSection;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.100', '8px'),
});

const TitleStyles = css({
	color: token('color.text.subtle'),
	font: token('font.heading.xxsmall'),
	lineHeight: token('space.250'),
});
