import { combineEpics } from 'redux-observable';
import { isStateChangeBasedIssueHiddenFlagsEnabled } from '../../feature-flags';
import { dummyEpic } from '../common/dummy';
import type { StateEpic } from '../common/types';
import attemptRank from './attempt-rank';
import attemptRemove from './attempt-remove-issue';
import count from './count';
import create from './create';
import dependencies from './dependencies';
import expand from './expand';
import exportCsv from './export-csv';
import globalIssueCreate from './global-issue-create';
import hiddenFlag from './hidden-flag';
import optimisticUpdate from './optimistic-update';
import rank from './rank';
import realtime from './realtime';
import reload from './reload';
import scheduleIssues from './schedule-issues';
import select from './select';
import update from './update';
import updateAndRank from './update-and-rank';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default combineEpics(
	count,
	create,
	expand,
	globalIssueCreate,
	isStateChangeBasedIssueHiddenFlagsEnabled() ? hiddenFlag : dummyEpic,
	select,
	update,
	updateAndRank,
	reload,
	rank,
	attemptRank,
	dependencies,
	realtime,
	optimisticUpdate,
	scheduleIssues,
	attemptRemove,
	exportCsv,
) as StateEpic;
