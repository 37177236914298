/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { type Color, PURPLE } from '@atlassian/jira-issue-epic-color';
import { dependencyThemes } from '@atlassian/jira-software-roadmap-timeline-table-kit';
import Icon from './icon';

export type APIProps = {
	x0: number;
	y0: number;
	x: number;
	y: number;
	width: number;
	height: number;
};

type Props = APIProps & {
	color: Color;
};

const DependencyLinePreview = ({ x0, y0, x, y, width, height, color }: Props) => {
	const theme = dependencyThemes[color];
	const top = y - 5;
	const left = x - 5;

	return (
		<>
			<Icon color={theme.icon} top={top} left={left} />
			<svg width={width} height={height}>
				<line css={lineStyles} style={{ stroke: theme.line }} x1={x0} y1={y0} x2={left} y2={top} />
			</svg>
		</>
	);
};

DependencyLinePreview.defaultProps = {
	color: PURPLE,
};

export default DependencyLinePreview;

const lineStyles = css({
	strokeWidth: 2,
	strokeLinecap: 'round',
	fill: 'none',
});
