import type { IssueKey } from '@atlassian/jira-shared-types';
import { ISSUE_DETAILS } from '../../model/panel';
import { getIssueKeyToIdHash } from '../../state/entities/issues/selectors';
import type { State } from '../../state/types';
import {
	showPanel,
	hidePanel,
	type ShowPanelAction,
	type HidePanelAction,
} from '../../state/ui/panel/actions';
import { getPanelType } from '../../state/ui/panel/selectors';
import {
	setSelectedItemIds,
	setSelectionAnchors,
	type SetSelectedItemIdsAction,
	type SetSelectionAnchorsAction,
} from '../../state/ui/table/actions';
import { getSelectedItemIds } from '../../state/ui/table/selectors';

type ResetAction = SetSelectedItemIdsAction | SetSelectionAnchorsAction | HidePanelAction;
type SyncAction = ShowPanelAction | ResetAction;

/** This util handles issue selection reset, anchor removal and panel state when a single issue change is detected externally (either from issue view or from url) */
export const getResetActions = (
	shouldResetSelection = true,
	shouldHidePanel = true,
): ResetAction[] => {
	const actions: ResetAction[] = shouldResetSelection
		? [setSelectionAnchors([]), setSelectedItemIds([])]
		: [];
	const panelActions: ResetAction[] = shouldHidePanel ? [hidePanel()] : [];

	return actions.concat(panelActions);
};

/** This util handles issue selection, anchor update and panel state when a single issue change is detected externally (either from issue view or from url) */
export const getSyncActions = (state: State, key: IssueKey | undefined): SyncAction[] => {
	const selectedItemIds = getSelectedItemIds(state);
	const isIssueDetailsShown = getPanelType(state) === ISSUE_DETAILS;
	const id = getIssueKeyToIdHash(state)[key ?? ''] ?? '';

	if (!key) {
		return getResetActions(selectedItemIds.length === 1, isIssueDetailsShown);
	}

	const selectedItemIndex = selectedItemIds.indexOf(id);
	const actions: SyncAction[] = [setSelectionAnchors([id]), setSelectedItemIds([id])];
	const panelActions: SyncAction[] = isIssueDetailsShown ? [] : [showPanel(ISSUE_DETAILS)];

	return id && selectedItemIndex === -1 ? actions.concat(panelActions) : panelActions;
};
