/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import AKSprintIcon from '@atlaskit/icon/glyph/sprint';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type Size = 'small' | 'medium';
type Props = {
	size: Size;
};

const getIconSize = (iconSize: Size) => (iconSize === 'small' ? 16 : 24);

const wrapperStyles = css({
	marginRight: token('space.025', '2px'),
});

const SprintIcon = ({ size }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<div
			css={wrapperStyles}
			style={{
				height: `${getIconSize(size)}px`,
				width: `${getIconSize(size)}px`,
			}}
		>
			{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
			<AKSprintIcon label={formatMessage(messages.sprintLabel)} size={size} />
		</div>
	);
};

SprintIcon.defaultProps = {
	size: 'small',
};

export { SprintIcon };
