import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import Placeholder from '@atlassian/jira-placeholder';
import { ROADMAP_PACKAGE_NAME } from '../../../constants';
import HealthcheckSkeleton from '../skeleton';
import type ResolveHealthcheckType from './index';

const ResolveHealthcheck = lazyForPaint<typeof ResolveHealthcheckType>(
	() => import(/* webpackChunkName: "async-standard-roadmap-unified-healthcheck" */ './index'),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<JSErrorBoundary
		id="async-standard-roadmap-unified-healthcheck"
		packageName={ROADMAP_PACKAGE_NAME}
		fallback="flag"
	>
		<Placeholder
			name="standard-roadmap-unified-healthcheck"
			fallback={<HealthcheckSkeleton imageSize="default" paragraphHeight={65} />}
		>
			<ResolveHealthcheck />
		</Placeholder>
	</JSErrorBoundary>
);
