import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	editDependencies: {
		id: 'roadmap.standard-roadmap.common.table.list-item-menu-option.edit-dependency-option.edit-dependencies',
		defaultMessage: 'Edit dependencies',
		description:
			'Label for Edit dependency option in the meatball menu attached to each issue row in the project timeline.',
	},
});

export default messages;
