import React from 'react';
import Button from '@atlaskit/button/standard-button';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';
import type { Props } from './types';

const TodayButton = ({ onClick, testId }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<Button testId={testId} appearance="subtle" onClick={onClick}>
			{formatMessage(messages.today)}
		</Button>
	);
};

export default TodayButton;
