import { LEFT, RIGHT } from '../../../../../../common/constants';
import { getTargetIntervalId, getGridSize, snapToGrid } from '../../../common/utils';
import type { OnNoDateDrag } from './types';
import { isValidDrag } from './utils';

/* Unlike date dragging, it is possible to dynamically change the drag type.
 * By passing over the drag origin, a user can push out either the left or right position without restarting the drag interaction.
 * E.g. if the drag cursor is right of the where the user began dragging, the left side of the item will be fixed, while the right side resizes.
 * E.g. if the drag cursor is left of the where the user began dragging, the right side of the item will be fixed, while the left side resizes.
 */
export const onNoDateDrag: OnNoDateDrag = (
	{ from, to, start },
	{ activeItem, dragType, previewBaselinePosition },
	{ timelineDuration, timelineWidth, getScrollOffset, simpleIntervals },
) => {
	const gridSize = getGridSize(timelineDuration, timelineWidth);
	const scrollOffset = getScrollOffset(from, to);
	const startPosition = previewBaselinePosition.leftPosition;

	let leftPosition;
	let rightPosition;
	let nextDragType;

	if (dragType === LEFT) {
		const offset = to.x - start.x - scrollOffset;

		rightPosition = timelineWidth - startPosition;
		leftPosition = snapToGrid(startPosition, gridSize, offset);

		nextDragType = leftPosition < startPosition ? LEFT : RIGHT;
	} else {
		const offset = to.x - start.x - scrollOffset;

		leftPosition = startPosition;
		rightPosition = timelineWidth - snapToGrid(startPosition, gridSize, offset);

		nextDragType = timelineWidth - rightPosition < startPosition ? LEFT : RIGHT;
	}

	if (!isValidDrag(leftPosition, rightPosition, gridSize, timelineWidth)) {
		return undefined;
	}

	const targetIntervalId = getTargetIntervalId(simpleIntervals, timelineWidth, leftPosition);

	return {
		itemPositions: { [`${activeItem.id}`]: { leftPosition, rightPosition } },
		targetIntervalId,
		dragType: nextDragType,
	};
};
