/**
 * @generated SignedSource<<3b9141802c93be24cb6175859e1f8c92>>
 * @relayHash dba922c431b7d82ea815adb75d8379d2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID bc6e5f91caa4ea72ba0e0c1b6e9705b161b4143857be506725a8d16a51690270

import type { ConcreteRequest, Query } from 'relay-runtime';
export type roadmapFilterConfigurationQuery$variables = {
  sourceAri: string;
};
export type roadmapFilterConfigurationQuery$data = {
  readonly roadmaps: {
    readonly roadmapFilterConfiguration: {
      readonly customFilters: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }> | null | undefined;
      readonly quickFilters: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly query: string;
      }> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type roadmapFilterConfigurationQuery = {
  response: roadmapFilterConfigurationQuery$data;
  variables: roadmapFilterConfigurationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sourceAri"
  }
],
v1 = {
  "kind": "ScalarField",
  "name": "id"
},
v2 = {
  "kind": "ScalarField",
  "name": "name"
},
v3 = [
  {
    "concreteType": "RoadmapsQuery",
    "kind": "LinkedField",
    "name": "roadmaps",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "sourceARI",
            "variableName": "sourceAri"
          }
        ],
        "concreteType": "RoadmapFilterConfiguration",
        "kind": "LinkedField",
        "name": "roadmapFilterConfiguration",
        "plural": false,
        "selections": [
          {
            "concreteType": "RoadmapQuickFilter",
            "kind": "LinkedField",
            "name": "quickFilters",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "name": "query"
              }
            ]
          },
          {
            "concreteType": "RoadmapCustomFilter",
            "kind": "LinkedField",
            "name": "customFilters",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "roadmapFilterConfigurationQuery",
    "selections": (v3/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "roadmapFilterConfigurationQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "bc6e5f91caa4ea72ba0e0c1b6e9705b161b4143857be506725a8d16a51690270",
    "metadata": {},
    "name": "roadmapFilterConfigurationQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "83a8710482c6bfc95e5dd7f72a0b0f83";

export default node;
