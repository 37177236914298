import 'rxjs/add/operator/map';
import 'rxjs/add/observable/fromPromise';
import type { Observable } from 'rxjs/Observable';
import type { Ari } from '@atlassian/jira-platform-ari';
import filterRoadmapItemsConcreteQuery, {
	type filterRoadmapItemsQuery,
} from '@atlassian/jira-relay/src/__generated__/filterRoadmapItemsQuery.graphql';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { generateFilterRoadmapItemsQueryVariables } from '@atlassian/jira-software-roadmap-data-queries/src/queries/filter-roadmap-items/index.tsx';
import { runQueryWithMetadata } from '@atlassian/jira-software-roadmap-services/src/common/agg/run-query.tsx';
import { FILTER_ROADMAP_ITEMS } from '@atlassian/jira-software-roadmap-utils/src/utils/metrics/common/constants.tsx';
import { queryMetrics } from '../performance-analytics';

const analyticKey = 'jira.frontend.fe.software.roadmap.filter-roadmap-items';
const concurrentMetricDefinition = queryMetrics[FILTER_ROADMAP_ITEMS];

export const filterItemsByJQLFilters = (
	sourceAri: Ari,
	quickFilterIds?: string[],
	customFilterIds?: string[],
): Observable<IssueId[]> =>
	runQueryWithMetadata<filterRoadmapItemsQuery>(
		filterRoadmapItemsConcreteQuery,
		generateFilterRoadmapItemsQueryVariables(
			sourceAri,
			quickFilterIds ?? [],
			customFilterIds ?? [],
		),
		analyticKey,
		concurrentMetricDefinition,
	).map(({ data }) => {
		const issueIds: ReadonlyArray<IssueId> = data.roadmaps?.roadmapFilterItems ?? [];

		return issueIds.slice();
	});
