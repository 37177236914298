import {
	SCRUM_ONBOARDING_TASK,
	useCompletedTaskList,
	useShouldShowOnboardingKanbanTour,
	useShouldShowScrumOnboardingExperience,
} from '@atlassian/jira-onboarding-quickstart-core';
import type { ModalSource } from './types';

export const useCanShowNudgeTour = () => {
	const [completedTaskList] = useCompletedTaskList();
	const [shouldShowScrumOnboardingExperience] = useShouldShowScrumOnboardingExperience();
	const [shouldShowOnboardingKanbanTour] = useShouldShowOnboardingKanbanTour();

	const shouldShowOnboardingScrumTour =
		shouldShowScrumOnboardingExperience === true &&
		// edge case for KURO-2088: After welcome modal failed to load, users complete welcome tour by themselves. Welcome modal should be skipped to load on refreshment.
		!completedTaskList.includes(SCRUM_ONBOARDING_TASK);

	return (source: ModalSource) => {
		switch (source) {
			case 'board':
				return shouldShowOnboardingKanbanTour;
			case 'backlog':
				return shouldShowOnboardingScrumTour;

			case 'timeline':
				// display if eligible for either board/backlog scenarios
				return shouldShowOnboardingKanbanTour || shouldShowOnboardingScrumTour;
			default:
				return false;
		}
	};
};
