import { defineSimpleStorage } from '@atlassian/jira-common-storage-redux/src';
import type { VersionId } from '@atlassian/jira-shared-types';
import { getHighlightedVersions } from '../../../state/selectors/versions';
import { setHighlightedVersions } from '../../../state/settings/actions';
import type { State } from '../../../state/types';

export default defineSimpleStorage<State, VersionId[] | null>(
	() => 'highlightedVersions',
	(highlightedVersions: VersionId[] | null) =>
		highlightedVersions === null ? [] : [setHighlightedVersions(highlightedVersions)],
	(state) => getHighlightedVersions(state),
);
