import type { Optional, Hash } from '@atlassian/jira-shared-types';
import type { HashDiff } from './types';
import { createInitialized, createUnchanged, getHashDiff } from './utils';

type VersionedHashDiffSelector<Entity, State> = (
	arg1: State,
	arg2: Optional<Hash<Entity>>,
) => HashDiff<Entity>;

const createVersionedEntityHashDiffSelector = <Entity, State>(
	hashSelector: (arg1: State) => Hash<Entity>,
): VersionedHashDiffSelector<Entity, State> => {
	const weakHashPairsToDiffs: WeakMap<
		Hash<Entity>,
		WeakMap<Hash<Entity>, HashDiff<Entity>>
	> = new WeakMap();

	const tryGetDiff = (previousHash: Hash<Entity> | undefined, hash: Hash<Entity>) => {
		if (!previousHash) {
			return undefined;
		}
		const previousMap = weakHashPairsToDiffs.get(previousHash);
		if (!previousMap) {
			return undefined;
		}
		return previousMap.get(hash);
	};

	const createDiff = (previousHash: Hash<Entity> | undefined, hash: Hash<Entity>) => {
		if (!previousHash) {
			return createInitialized(hash);
		}
		if (previousHash === hash) {
			return createUnchanged(hash);
		}
		const { added, changed, removed, unchanged } = getHashDiff(previousHash, hash);
		return {
			added,
			changed,
			removed,
			unchanged,
			hash,
		};
	};

	const addDiff = (
		previousHash: Hash<Entity> | undefined,
		hash: Hash<Entity>,
		diff: HashDiff<Entity>,
	) => {
		if (previousHash) {
			if (!weakHashPairsToDiffs.get(previousHash)) {
				weakHashPairsToDiffs.set(previousHash, new WeakMap());
			}
			const map = weakHashPairsToDiffs.get(previousHash);
			if (map && !map.get(hash)) {
				map.set(hash, diff);
			}
		}
	};

	return (state: State, previousHash?: Hash<Entity>): HashDiff<Entity> => {
		const hash = hashSelector(state);

		const memoizedDiff = tryGetDiff(previousHash, hash);
		if (memoizedDiff) {
			return memoizedDiff;
		}

		const diff = createDiff(previousHash, hash);
		addDiff(previousHash, hash, diff);

		return diff;
	};
};

export { createVersionedEntityHashDiffSelector };
export type { VersionedHashDiffSelector, HashDiff };
