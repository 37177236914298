// =========================== //
// SUPPORT DOCUMENTATION LINKS //
// =========================== //

const SUPPORT_DOCUMENTATION_PREFIX = 'https://support.atlassian.com/jira-software-cloud/docs/';

const TROUBLESHOOT_TIMELINES_NEXT_GEN = `${SUPPORT_DOCUMENTATION_PREFIX}troubleshoot-the-timelines-for-team-managed-projects`;
const DEPENDENCIES = `${SUPPORT_DOCUMENTATION_PREFIX}manage-dependencies-between-epics-on-the-timeline`;
const CUSTOMIZE_VIEW = `${SUPPORT_DOCUMENTATION_PREFIX}customize-your-view-on-the-timeline`;

// =============================== //
// NON-ROADMAP DOCUMENTATION LINKS //
// =============================== //

const JIRA_PERMISSIONS_OVERVIEW =
	'https://support.atlassian.com/jira-service-desk-cloud/docs/overview-of-jira-cloud-permissions/';

const CONTACT_SUPPORT =
	'https://support.atlassian.com/contact/#/?inquiry_category=technical_issues';

const JIRA_SOFTWARE_PREMIUM_OVERVIEW =
	'https://www.atlassian.com/software/jira/features/timelines?tab=advanced';

const JIRA_SOFTWARE_PREMIUM_START_TRIAL =
	'https://www.atlassian.com/try/cloud/signup?bundle=jira-software&edition=premium';

const JIRA_WHAT_ARE_ADVANCED_ROADMAPS =
	'https://www.atlassian.com/software/jira/guides/advanced-roadmaps/overview#what-are-advanced-roadmaps';

// =========== //
// OTHER LINKS //
// =========== //

export const docs = {
	TROUBLESHOOT_TIMELINES_NEXT_GEN,
	DEPENDENCIES,
	CUSTOMIZE_VIEW,
	JIRA_PERMISSIONS_OVERVIEW,
	CONTACT_SUPPORT,
	JIRA_SOFTWARE_PREMIUM_OVERVIEW,
	JIRA_SOFTWARE_PREMIUM_START_TRIAL,
	JIRA_WHAT_ARE_ADVANCED_ROADMAPS,
} as const;
