import { fg } from '@atlassian/jira-feature-gating';
import { connect } from '@atlassian/jira-react-redux';
import type {
	OnItemDrop,
	OnItemSelect,
	OnItemExpandChanged,
	OnCreateClick,
} from '@atlassian/jira-software-roadmap-timeline-table/src/types';
import { DragAndDropLongTaskMetric } from '../../metrics';
import { getToday, getSprints } from '../../state/configuration/selectors';
import {
	attemptRankIssue,
	updateAndPersistIssue,
	updateAndRankAndPersistIssue,
} from '../../state/entities/issues/actions';
import { getIsIssueFlaggedHash } from '../../state/entities/issues/selectors';
import { getTimelineMode } from '../../state/selectors/filters';
import { getListItemMenuOptions } from '../../state/selectors/list-item-menu-option';
import {
	getCanUserCreate,
	getCanUserEdit,
	getCanUserViewDetails,
} from '../../state/selectors/permission';
import {
	getItems,
	getItemHeight,
	getIsCreateSiblingEnabled,
	getIsCreateChildEnabled,
	getIsDragEnabled,
	getTableHeaderItems,
	getAriaReadOnly,
} from '../../state/selectors/table';
import { getTimelineDuration, getTimelineOrigin } from '../../state/selectors/timeline';
import { setListWidth } from '../../state/settings/actions';
import { getListWidth } from '../../state/settings/selectors';
import type { State } from '../../state/types';
import {
	setCreateItemAnchor,
	changeExpansionState,
	selectItems,
} from '../../state/ui/table/actions';
import {
	getExpandedItems,
	getSelectedItemIds,
	getCreateItemAnchor,
} from '../../state/ui/table/selectors';
import Table from '../common/table';

const onItemDrop: OnItemDrop = (id, { parentId, rankRequest }, analyticsEvent) => {
	if (parentId !== undefined && rankRequest !== undefined) {
		return updateAndRankAndPersistIssue(
			{ issueId: id, rankRequest, properties: { parentId } },
			analyticsEvent,
		);
	}

	if (rankRequest !== undefined) {
		return attemptRankIssue({ issueId: id, rankRequest }, analyticsEvent);
	}

	return updateAndPersistIssue(id, { parentId }, analyticsEvent);
};

const onItemExpandChanged: OnItemExpandChanged = (id, isExpanded) =>
	changeExpansionState({ id, isExpanded: !isExpanded });

const onCreateClick: OnCreateClick = (anchor) => setCreateItemAnchor(anchor);

const onItemSelect: OnItemSelect = (id, { level, withCmd, withShift }, analyticsEvent) =>
	selectItems({ id, level, withCmd, withShift }, analyticsEvent);

type OwnProps = {
	isServer: boolean;
};

const mapStateToProps = (state: State, ownProps: OwnProps) => {
	const isSelectEnabled = getCanUserViewDetails(state);
	const canUserCreate = getCanUserCreate(state);
	const canUserEdit = getCanUserEdit(state);

	return {
		isHighlightToday: !ownProps.isServer,
		isMeatballsButtonEnabled: canUserEdit || canUserCreate,
		isCreateLastEnabled: canUserCreate,
		isCreateSiblingEnabled: getIsCreateSiblingEnabled(state),
		isCreateChildEnabled: getIsCreateChildEnabled(state),
		isDragEnabled: getIsDragEnabled(state),
		isSelectEnabled,
		isMultiSelectEnabled: fg('project_timeline_multi-select_and_checkboxes')
			? isSelectEnabled
			: isSelectEnabled || undefined,
		hasSprints: getSprints(state).length !== 0,
		items: getItems(state),
		expandedItems: getExpandedItems(state),
		createItemAnchor: getCreateItemAnchor(state),
		selectedItemIds: getSelectedItemIds(state),
		timelineMode: getTimelineMode(state),
		timelineDuration: getTimelineDuration(state),
		timelineOrigin: getTimelineOrigin(state),
		today: getToday(state),
		listWidth: getListWidth(state),
		itemHeight: getItemHeight(state),
		DndLongTaskMetric: DragAndDropLongTaskMetric,
		isVirtualiseEnabled: true,
		headerItems: getTableHeaderItems(state),
		ariaReadOnly: getAriaReadOnly(state),
		flaggedItemsHash: getIsIssueFlaggedHash(state),
		listItemMenuOptions: getListItemMenuOptions(state),
	};
};

export default connect(mapStateToProps, {
	onItemDrop,
	onItemSelect,
	onItemExpandChanged,
	onCreateClick,
	onSetListWidth: setListWidth,
})(Table);
