import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/merge';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import {
	INITIALIZE_NON_CRITICAL_DATA,
	type InitializeNonCriticalDataAction as Action,
} from '../../../../state/app/actions';
import { deriveFieldsRequest } from '../../../../state/configuration/actions';
import type { State } from '../../../../state/types';
import type { StateEpic } from '../../../common/types';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<Action>, _: MiddlewareAPI<State>) =>
	action$
		.ofType(INITIALIZE_NON_CRITICAL_DATA)
		.mergeMap(() => Observable.of(deriveFieldsRequest()))) as StateEpic;
