import React, { useEffect, useMemo, useState, type ReactElement, type ComponentType } from 'react';
import { styled } from '@compiled/react';
import memoizeOne from 'memoize-one';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import type { ChartDragItem } from '../../../common/types';
import { adjustScrollPosition } from '../../../common/utils/adjust-scroll-position';
import type { DragLayerMeta } from './types';

type Props<TDragItem> = {
	type: string;
	DndLongTaskMetric?: ComponentType<{
		isDragging: boolean;
	}>;
	renderPreview: (previewProps: DragLayerMeta<TDragItem>) => ReactElement | null;
};

const getDefaultPreviewProps = memoizeOne(
	<TDragItem,>(): DragLayerMeta<TDragItem> => ({
		item: undefined,
		clientOffset: {
			x: 0,
			y: 0,
		},
		scrollOffset: {
			scrollLeft: 0,
			scrollTop: 0,
		},
	}),
);

const RoadmapChartDragLayer = <TDragItem,>({
	type,
	renderPreview,
	DndLongTaskMetric = () => null,
}: Props<TDragItem>) => {
	const defaultPreviewProps = useMemo(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		() => getDefaultPreviewProps() as DragLayerMeta<TDragItem>,
		[],
	);
	const [isDragging, setIsDragging] = useState(false);
	const [previewProps, setPreviewProps] = useState<DragLayerMeta<TDragItem>>(defaultPreviewProps);

	useEffect(
		() =>
			combine(
				monitorForElements({
					onDrag: ({ source, location }) => {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						const item = source.data as ChartDragItem;
						const {
							current: { input: current },
							initial: { input: initial },
						} = location;

						if (item.type === type) {
							const {
								viewportBoundingRect,
								initialScrollBoundingRect,
								requestViewportScrollBounding,
								setViewportScroll,
							} = item;

							const initialOffset = { x: initial.pageX, y: initial.pageY };
							const clientOffset = { x: current.pageX, y: current.pageY };

							if (initialOffset && clientOffset) {
								const { scrollLeft, scrollTop } = adjustScrollPosition(
									initialOffset,
									clientOffset,
									setViewportScroll,
									initialScrollBoundingRect,
									requestViewportScrollBounding,
									viewportBoundingRect,
								);

								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								const previewItem = source.data as TDragItem;
								setPreviewProps({
									item: previewItem,
									clientOffset,
									scrollOffset: { scrollLeft, scrollTop },
								});
							}
							setIsDragging(true);
						}
					},
					onDrop({ source }) {
						const item = source.data;
						if (item.type === type) {
							setPreviewProps(defaultPreviewProps);
							setIsDragging(false);
						}
					},
				}),
			),
		[type, defaultPreviewProps],
	);

	return (
		<Container>
			{renderPreview(previewProps)}
			<DndLongTaskMetric isDragging={isDragging} />
		</Container>
	);
};

export { RoadmapChartDragLayer };
export type { DragLayerMeta } from './types';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	position: 'absolute',
	left: 0,
	top: 0,
	right: 0,
	bottom: 0,
	overflow: 'hidden',
});
