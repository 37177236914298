import { createSelector } from 'reselect';
import type { CustomFilterType, CustomFilterTypeId } from '@atlassian/jira-software-filters';
import type { CustomFiltersHash } from '@atlassian/jira-software-roadmap-model';
import { getFullCustomFilterHash } from '../ui/filters/selectors';

export const getCustomFiltersBase = (customFiltershash: CustomFiltersHash): CustomFilterType[] =>
	Object.keys(customFiltershash).map((customFilterId: CustomFilterTypeId) => {
		const customFilter = customFiltershash[customFilterId];
		return {
			id: customFilter.id,
			name: customFilter.name,
		};
	});

export const getCustomFilters = createSelector(getFullCustomFilterHash, getCustomFiltersBase);
