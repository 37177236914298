import type { MetadataState } from './types';

export const SET_METADATA = 'state.metadata.SET_METADATA';

export type SetMetadataAction = {
	type: typeof SET_METADATA;
	payload: MetadataState;
};

export const setMetadata = (payload: MetadataState): SetMetadataAction => ({
	type: SET_METADATA,
	payload,
});
