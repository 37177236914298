import React, { Component } from 'react';
import noop from 'lodash/noop';
import ErrorFlag from '@atlassian/jira-flags/src/common/ui/components/error-flag';
import { type IntlShapeV2 as IntlShape, injectIntlV2 as injectIntl } from '@atlassian/jira-intl';
import type { IssueKey } from '@atlassian/jira-shared-types';
import { DEPENDENCY_ADD_ERROR, type DependenciesPermissionType } from '../../model/dependencies';
import { docs } from '../../utils/docs';
import messages from './messages';

export type Props = {
	id: string | number;
	type: DependenciesPermissionType;
	dependeeKey: IssueKey | undefined;
	dependencyKey: IssueKey | undefined;
	onDismissed: (id: string | number) => void;
	intl: IntlShape;
};

// eslint-disable-next-line jira/react/no-class-components
export class DependenciesPermissionFlag extends Component<Props> {
	static defaultProps = {
		type: DEPENDENCY_ADD_ERROR,
		onDismissed: noop,
	};

	onDismissed = () => {
		const { id, onDismissed } = this.props;
		onDismissed(id);
	};

	render() {
		const {
			type,
			dependeeKey,
			dependencyKey,
			intl: { formatMessage },
			...props
		} = this.props;

		const title =
			type === DEPENDENCY_ADD_ERROR
				? formatMessage(messages.titleAdd)
				: formatMessage(messages.titleRemove);

		const descriptionWithIssueKey =
			type === DEPENDENCY_ADD_ERROR
				? formatMessage(messages.descriptionAdd, {
						dependee: dependeeKey,
						dependency: dependencyKey,
					})
				: formatMessage(messages.descriptionRemove, {
						dependee: dependeeKey,
						dependency: dependencyKey,
					});

		return (
			<ErrorFlag
				{...props}
				onDismissed={this.onDismissed}
				id={this.props.id}
				title={title}
				description={
					dependeeKey && dependencyKey
						? descriptionWithIssueKey
						: formatMessage(messages.description)
				}
				actions={[
					{
						content: formatMessage(messages.learnMore),
						href: docs.JIRA_PERMISSIONS_OVERVIEW,
					},
				]}
			/>
		);
	}
}

export default injectIntl(DependenciesPermissionFlag);
