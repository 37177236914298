import type { MiddlewareAPI } from 'redux';
import type { IssueId } from '@atlassian/jira-shared-types';
import {
	type IssueScheduleFields,
	isBaseLevel,
	BASE_LEVEL,
	PARENT_LEVEL,
} from '@atlassian/jira-software-roadmap-model';
import { DUE_DATE_OFFSET, LEFT, RIGHT } from '@atlassian/jira-software-roadmap-timeline-table-kit';
import type { TimelineDuration } from '@atlassian/jira-software-roadmap-timeline-table/src/types';
import { getStartOfDay, getEndOfDay } from '@atlassian/jira-software-roadmap-utils/src/utils/dates';
import type { IssueFieldModification } from '../../../model/issue';
import {
	getIssueStartDate,
	getIssueDueDate,
	getIssueChildrenHash,
} from '../../../state/entities/issues/selectors';
import { getChartDateData } from '../../../state/selectors/table/common/chart-dates';
import { getParentRolledUpDatesHash } from '../../../state/selectors/table/dates';
import { getTimelineDuration, getMinTimelineDateRange } from '../../../state/selectors/timeline';
import type { State } from '../../../state/types';

export const shouldScheduleChildrenIssues = (
	level: number,
	{ startDate, dueDate }: IssueFieldModification,
	{ startDate: prevStartDate, dueDate: prevDueDate }: IssueFieldModification,
	numberOfChildren: number,
	isSprintsPlanning: boolean,
	isChildIssuePlanningEnabled: boolean,
) => {
	/**
	 * When parent bar is dragged (i.e. both start and due dates are updated), then child bars also dragged together.
	 * When parent bar is resized (i.e. only one of start of due dates are updated), then child bars are unaffected.
	 */
	const isParentWithChildren = !isBaseLevel(level) && numberOfChildren > 0;
	const isBothDatesPopulated = startDate !== undefined && dueDate !== undefined;
	const isBothDatesUpdated = startDate !== prevStartDate && dueDate !== prevDueDate;

	return (
		isChildIssuePlanningEnabled &&
		!isSprintsPlanning &&
		isParentWithChildren &&
		isBothDatesPopulated &&
		isBothDatesUpdated
	);
};

export const shouldBulkSchedule = (
	targetItem: IssueId,
	{ startDate, dueDate }: IssueFieldModification,
	{ startDate: prevStartDate, dueDate: prevDueDate }: IssueFieldModification,
	selectedItemIds: IssueId[],
) => {
	const isDraggingOrResizing = startDate !== undefined || dueDate !== undefined;
	const isDateUpdated = startDate !== prevStartDate || dueDate !== prevDueDate;

	return (
		isDraggingOrResizing &&
		isDateUpdated &&
		selectedItemIds.length > 1 &&
		selectedItemIds.includes(targetItem)
	);
};

/**
 * Clamped start and due dates to the beginning and end of the timeline.
 *
 * The new dates will take timeline's start and end into consideration and return the correct dates in edge cases such as:
 *  - It will return the existing date if it attempts to move date towards the direction where the date was already intersected the timeline.
 *  - It will return the start OR end of the timeline (depends on the direction) if it attempts to move date outside the timeline.
 */
export const getSafeScheduleDates = (
	startDate: number | undefined,
	dueDate: number | undefined,
	timelineDuration: TimelineDuration,
	offset: number,
): {
	startDate: number | undefined;
	dueDate: number | undefined;
} => {
	const direction = offset < 0 ? LEFT : RIGHT;

	const { startMilliseconds, endMilliseconds } = timelineDuration;
	let newOffset = offset;

	if (direction === LEFT && startDate !== undefined) {
		const maxLeftOffset = -Math.max(0, startDate - startMilliseconds);
		newOffset = Math.max(maxLeftOffset, offset);
	} else if (direction === RIGHT && dueDate !== undefined) {
		const maxRightOffset = Math.max(0, endMilliseconds - dueDate - DUE_DATE_OFFSET);
		newOffset = Math.min(maxRightOffset, offset);
	}

	const newStartDate = startDate !== undefined ? startDate + newOffset : undefined;
	const newDueDate = dueDate !== undefined ? dueDate + newOffset : undefined;

	return { startDate: newStartDate, dueDate: newDueDate };
};

/**
 * Get issue ids and its properties for bulk-scheduling issues, which handles
 * - multiple bar-dragging (same issue hierarchy)
 * - multiple bar-resizing (same issue hierarchy)
 * - single bar-dragging BUT with child bars dragged along
 * It calculates the dates offset from the interacted issue (target issue) and apply it to all selected issues including hidden child issues.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EMPTY_HASH: Record<string, any> = {};

const getFlippedDates = (
	startDate: number | undefined,
	dueDate: number | undefined,
): [number | undefined, number | undefined] =>
	startDate !== undefined && dueDate !== undefined && startDate > dueDate
		? [dueDate, startDate]
		: [startDate, dueDate];

export const getScheduleFieldsForBulkDatesUpdate = (
	store: MiddlewareAPI<State>,
	targetIssueId: IssueId,
	{ startDate, dueDate }: IssueFieldModification,
	isIssueBaseLevel: boolean,
	selectedItemIds: IssueId[],
): IssueScheduleFields[] => {
	const state = store.getState();
	let prevExplicitStartDate = getIssueStartDate(state, targetIssueId);
	let prevExplicitDueDate = getIssueDueDate(state, targetIssueId);

	// If the dates were flipped previously, flip them back before scheduling
	[prevExplicitStartDate, prevExplicitDueDate] = getFlippedDates(
		prevExplicitStartDate,
		prevExplicitDueDate,
	);

	const { startDate: prevRolledUpStartDate, dueDate: prevRolledUpDueDate } = isIssueBaseLevel
		? EMPTY_HASH // base-level issues won't have rolled-up dates since bulk-scheduling is not applicable to interval derived dates
		: getParentRolledUpDatesHash(state)[targetIssueId] ?? EMPTY_HASH;
	const { startDate: prevInferredStartDate, dueDate: prevInferredDueDate } = getChartDateData(
		prevExplicitStartDate,
		prevExplicitDueDate,
		isIssueBaseLevel ? BASE_LEVEL : PARENT_LEVEL,
		true,
	);
	const scheduleFields: Array<IssueScheduleFields> = [];
	let offset = 0;

	if (startDate !== undefined && prevExplicitStartDate !== undefined) {
		offset = startDate - prevExplicitStartDate;
	} else if (dueDate !== undefined && prevExplicitDueDate !== undefined) {
		offset = dueDate - prevExplicitDueDate;
	} else if (startDate !== undefined && prevRolledUpStartDate !== undefined) {
		offset = startDate - prevRolledUpStartDate;
	} else if (dueDate !== undefined && prevRolledUpDueDate !== undefined) {
		offset = dueDate - prevRolledUpDueDate;
	} else if (startDate !== undefined && prevInferredStartDate !== undefined) {
		offset = startDate - prevInferredStartDate;
	} else if (dueDate !== undefined && prevInferredDueDate !== undefined) {
		offset = dueDate - prevInferredDueDate;
	}

	const timelineDuration = getTimelineDuration(state);
	const issueChildrenHash = getIssueChildrenHash(state);
	const minTimelineDateRange = getMinTimelineDateRange(state);
	const isResizing = startDate === undefined || dueDate === undefined;
	const isBulkScheduling = selectedItemIds.length > 1 && selectedItemIds.includes(targetIssueId);
	const targetItems = isBulkScheduling ? selectedItemIds : [targetIssueId];
	const targetItemsChidren: Array<IssueId> = [];

	// Only consider handling child issues when
	// - the interacted issue is a parent issue AND
	// - the interaction is bar-dragging (both start and due dates are updated)
	if (!isIssueBaseLevel && !isResizing) {
		targetItems.forEach((issueId: IssueId) => {
			const childIds = issueChildrenHash[issueId] || [];

			childIds.length && targetItemsChidren.push(...childIds);
		});
	}

	// For the directly interacted item,
	// - [bar-dragging] use new dates as they are always defined
	// - [bar-dragging] turn placeholder dates (undefined) into set dates
	// - [bar-resizing] use previous dates as fallback (even it's undefined) since the new dates can be undefined for the side that's not resized
	// - [bar-resizing] turn placeholder dates (undefined) into set dates if it's the side that's resized
	scheduleFields.push({
		issueId: targetIssueId,
		startDate: startDate ?? prevExplicitStartDate,
		dueDate: dueDate ?? prevExplicitDueDate,
	});

	// For bulk scheduled items with the same issue hierarchy,
	// - [bar-dragging] use new dates as they are always defined
	// - [bar-dragging] turn placeholder dates (undefined) into set dates (need to extrapolate from roll-up dates and offset)
	// - [bar-resizing] use previous dates as fallback (even it's undefined) since the new dates can be undefined for the side that's not resized
	// - [bar-resizing] turn placeholder dates (undefined) into set dates if it's the side that's resized (need to extrapolate from roll-up dates and offset)
	targetItems.forEach((issueId: IssueId) => {
		if (issueId === targetIssueId) {
			return;
		}

		const isStartDateResized = isResizing && dueDate === undefined;
		const isDueDateResized = isResizing && startDate === undefined;
		let issueExplicitStartDate = getIssueStartDate(state, issueId);
		let issueExplicitDueDate = getIssueDueDate(state, issueId);

		// If the dates of a bulk-scheduled issue were flipped previously, flip them back just like conventional scheduling
		[issueExplicitStartDate, issueExplicitDueDate] = getFlippedDates(
			issueExplicitStartDate,
			issueExplicitDueDate,
		);

		const { startDate: issueRolledUpStartDate, dueDate: issueRolledUpDueDate } = isIssueBaseLevel
			? EMPTY_HASH
			: getParentRolledUpDatesHash(state)[issueId] ?? EMPTY_HASH;
		const { startDate: issueInferredStartDate, dueDate: issueInferredDueDate } = getChartDateData(
			issueExplicitStartDate,
			issueExplicitDueDate,
			isIssueBaseLevel ? BASE_LEVEL : PARENT_LEVEL,
			true,
		);

		// Calculate all possible dates, so placeholder dates (undefined) can be turned into set dates where possible
		const { startDate: newStartDate, dueDate: newDueDate } = getSafeScheduleDates(
			issueExplicitStartDate ?? issueRolledUpStartDate ?? issueInferredStartDate,
			issueExplicitDueDate ?? issueRolledUpDueDate ?? issueInferredDueDate,
			timelineDuration,
			offset,
		);

		// Exclude issues without date changes
		if (issueExplicitStartDate !== newStartDate || issueExplicitDueDate !== newDueDate) {
			// [bar-dragging] Only turn placeholders dates (undefined) into a set date if the date is being scheduled
			let cappedStartDate = isDueDateResized ? issueExplicitStartDate : newStartDate;
			let cappedDueDate = isStartDateResized ? issueExplicitDueDate : newDueDate;

			// [bar-resizing] Cap the dates to form a bar with minimal lengh if the dates are about to be flipped after bulk scheduling
			if (
				isResizing &&
				cappedStartDate !== undefined &&
				cappedDueDate !== undefined &&
				cappedDueDate - cappedStartDate < minTimelineDateRange
			) {
				if (isStartDateResized) {
					cappedStartDate = getEndOfDay(cappedDueDate - minTimelineDateRange);
				} else {
					cappedDueDate = getStartOfDay(cappedStartDate + minTimelineDateRange);
				}
			}

			scheduleFields.push({
				issueId,
				startDate: cappedStartDate,
				dueDate: cappedDueDate,
			});
		}
	});

	// For bulk scheduled items with a sub issue hierarchy,
	// - [bar-dragging] use new dates as they are always defined
	// - [bar-dragging] keep placeholder dates (undefined) as they are
	// - [bar-resizing] invalid cases - child items will never get resized with their parents
	targetItemsChidren.forEach((issueId: IssueId) => {
		const issueExplicitStartDate = getIssueStartDate(state, issueId);
		const issueExplicitDueDate = getIssueDueDate(state, issueId);
		const { startDate: newStartDate, dueDate: newDueDate } = getSafeScheduleDates(
			issueExplicitStartDate,
			issueExplicitDueDate,
			timelineDuration,
			offset,
		);

		// Exclude child issues without date changes
		if (issueExplicitStartDate !== newStartDate || issueExplicitDueDate !== newDueDate) {
			scheduleFields.push({
				issueId,
				startDate: newStartDate,
				dueDate: newDueDate,
			});
		}
	});

	return scheduleFields;
};
