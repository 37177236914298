import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	flagged: {
		id: 'roadmap.timeline-table-kit.list-item-secondary-content.flag-icon.flagged',
		defaultMessage: 'Flagged',
		description: 'Flagged',
	},
});

export default messages;
