import type { DragCache } from './types';

export const THUMB_DRAG_THRESHOLD = 0.1;
export const THUMB_MINIMUM_SIZE = 20;

export const SCROLLBAR_SIZE = 10;
export const SCROLLBAR_OFFSET = 7;

export const DEFAULT_DRAG_CACHE: DragCache = {
	thumbOrigin: 0,
	trackPosition: 0,
	trackSize: 0,
};
