import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	sprintLabel: {
		id: 'roadmap.timeline-table-kit.common.sprint-icon.sprint-label',
		defaultMessage: 'Sprint',
		description: 'Accessibility label for a sprint icon.',
	},
});

export default messages;
