export const BOARD = 'BOARD' as const;
export const BACKLOG = 'BACKLOG' as const;
export const ROADMAPS = 'ROADMAPS' as const;
export const UNKNOWN = 'UNKNOWN' as const;
export type CacheInvalidationType =
	| typeof BOARD
	| typeof BACKLOG
	| typeof ROADMAPS
	| typeof UNKNOWN;
export type Resource = {
	refresh: () => void;
	update: (arg1: () => null) => void;
	clear: () => void;
};
export type ResourcesDictionary = {
	current: Record<CacheInvalidationType, Resource>;
};
