import { connect } from '@atlassian/jira-react-redux';
import {
	LIST_DRAG_AND_DROP,
	CHILD_LIST_DRAG_AND_DROP,
} from '@atlassian/jira-software-roadmap-timeline-table';
import {
	ISSUE_VIEW_RESIZE_DRAG_AND_DROP,
	EXPORT_IMAGE_GENERATED,
	JQL_FILTERS_REAPPLY,
	type InteractionMetricType,
	type MutationMetricType,
} from '@atlassian/jira-software-roadmap-utils/src/utils/metrics';
import {
	getInteractionPerformanceExtraData,
	type InteractionPerformanceExtraData,
} from '../state/selectors/metrics';
import type { State } from '../state/types';
import interactionPerformanceFactory, {
	DragAndDropLongTaskMetric,
	useDragAndDropLongTaskMetric,
} from './utils';

export const interactionPerformance =
	interactionPerformanceFactory<InteractionPerformanceExtraData>();

export {
	LIST_DRAG_AND_DROP,
	CHILD_LIST_DRAG_AND_DROP,
	ISSUE_VIEW_RESIZE_DRAG_AND_DROP,
	DragAndDropLongTaskMetric,
	useDragAndDropLongTaskMetric,
	EXPORT_IMAGE_GENERATED,
	JQL_FILTERS_REAPPLY,
};

export type RequiredStateProps = {
	interactionPerformanceExtraData: InteractionPerformanceExtraData;
};

const CaptureInteractionMetricsExtraData = ({
	interactionPerformanceExtraData,
}: RequiredStateProps) => {
	interactionPerformance.setExtraData(interactionPerformanceExtraData);
	return null;
};

export const InteractionMetricsExtraData = connect(
	(state: State) => ({
		interactionPerformanceExtraData: getInteractionPerformanceExtraData(state),
	}),
	{},
)(CaptureInteractionMetricsExtraData);

export type { InteractionMetricType, MutationMetricType };
