import { connect } from '@atlassian/jira-react-redux';
import { getIssueLevel } from '../../../../../state/entities/issues/selectors';
import type { State } from '../../../../../state/types';
import type { OwnProps, StateProps } from './types';
import ReparentMenuOption from './view';

const mapStateToProps = (state: State, { id }: OwnProps): StateProps => ({
	issueLevel: getIssueLevel(state, id),
});

export default connect(mapStateToProps)(ReparentMenuOption);
