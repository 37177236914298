import { createSelector } from 'reselect';
import { getExpandedItems, getCreateItemAnchor } from '../../ui/table/selectors';
import { getFilteredNonBaseLevelIssueIds, getFilteredChildIssueIdsHash } from '../issues';
import { getItemOffsetPositions } from '../items';
import { getTableHeaderItemsHeight, getItemHeight } from './index';

export const getChartItemTopPositionHash = createSelector(
	getTableHeaderItemsHeight,
	getItemHeight,
	getFilteredNonBaseLevelIssueIds,
	getFilteredChildIssueIdsHash,
	getExpandedItems,
	getCreateItemAnchor,
	getItemOffsetPositions,
);
