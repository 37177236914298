import { ff } from '@atlassian/jira-feature-flagging-using-meta';
import type { roadmapCriticalDataQuery$data } from '@atlassian/jira-relay/src/__generated__/roadmapCriticalDataQuery.graphql';

type Roadmaps = NonNullable<roadmapCriticalDataQuery$data['roadmaps']>;

export type RoadmapForSource = NonNullable<Roadmaps['roadmapForSource']>;
export type RoadmapContent = NonNullable<RoadmapForSource['content']>;

export const generateCriticalDataQueryVariables = (
	sourceAri: string,
	locationAri: string | null = null,
) => ({
	sourceAri,
	locationAri,
	isRoadmapsCriticalDataItemFlaggedEnabled: ff('jsw.timeline.return-flagged-attribute-fe'),
});
