import {
	transformColorToTheme,
	type ColorSchema,
	type Color,
} from '@atlassian/jira-issue-epic-color';
import type { DependencyTheme } from '../../types';

export const dependencyThemes: Record<Color, DependencyTheme> = transformColorToTheme(
	(cs: ColorSchema) => ({
		icon: cs.primary,
		line: cs.line,
		border: cs.border,
		handle: {
			shadow: cs.primary,
			background: cs.primary,
		},
	}),
);
