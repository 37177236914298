import React from 'react';
import type { Color } from '@atlassian/jira-issue-epic-color';
import { useTimelineState } from '@atlassian/jira-software-roadmap-timeline-table';
import type { Placeholder } from '../../common/types';
import { getBaseBarStyles } from '../../common/ui/bar';
import {
	getBarLeftPosition,
	getBarRightPosition,
	getIsBarVisible,
	getBarTheme,
} from '../../common/utils';

type Props = {
	startDate: number | undefined;
	dueDate: number | undefined;
	level: number;
	placeholder?: Placeholder;
	color: Color;
};

type DateContentLiteProps = Props & {
	startDate: number;
	dueDate: number;
};

const DateItemLite = ({ startDate, dueDate, level, placeholder, color }: DateContentLiteProps) => {
	const [{ timelineDuration, timelineWidth }] = useTimelineState();

	const barLeftPosition = getBarLeftPosition(startDate, timelineDuration, timelineWidth);
	const barRightPosition = getBarRightPosition(dueDate, timelineDuration, timelineWidth);
	const isBarVisible = getIsBarVisible(barLeftPosition, barRightPosition, timelineWidth);

	if (!isBarVisible) {
		return null;
	}
	const theme = getBarTheme(color);

	return (
		<div
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values
				...getBaseBarStyles({ level, theme, placeholder }),
				left: `${barLeftPosition}px`,
				right: `${barRightPosition}px`,
			}}
		/>
	);
};

const RoadmapChartItemContentLite = ({ startDate, dueDate, ...restProps }: Props) => {
	if (startDate !== undefined && dueDate !== undefined) {
		return <DateItemLite {...restProps} startDate={startDate} dueDate={dueDate} />;
	}

	return null;
};

export { RoadmapChartItemContentLite };
