import React, { memo } from 'react';
import Badge from '@atlaskit/badge';
import { useIntl } from '@atlassian/jira-intl';
import messages from '../messages';

type DurationBadgeProps = {
	duration: number;
	sign?: string;
};

const DurationBadge = ({ duration, sign }: DurationBadgeProps) => {
	const { formatMessage } = useIntl();

	return duration ? (
		<Badge>{formatMessage(messages.duration, { duration: `${sign ?? ''}${duration}` })}</Badge>
	) : null;
};

export default memo<DurationBadgeProps>(DurationBadge);
