import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'roadmap.standard-roadmap.flags.hidden-dependency-created.title',
		defaultMessage: 'New dependency created',
		description: 'Flag title. Informs a user that a dependency has been created.',
	},
	description: {
		id: 'roadmap.standard-roadmap.flags.hidden-dependency-created.description',
		defaultMessage: 'Your dependency was created, but it is hidden by your views configuration.',
		description: 'Flag description. Informs a user why a newly created dependency is not visible',
	},
	showDependencies: {
		id: 'roadmap.standard-roadmap.flags.hidden-dependency-created.show-dependencies',
		defaultMessage: 'Show dependencies',
		description: 'Show dependencies action caption',
	},
});
