import type { ComponentType } from 'react';
import { connect } from '@atlassian/jira-react-redux';
import { hasMacroConfig } from '../../state/app/selectors';
import {
	hasAdministerProjectPermission,
	getIsUserBoardAdmin,
} from '../../state/configuration/selectors';
import type { State } from '../../state/types';
import Feedback from './feedback/async';
import FilterBar from './filter-bar';
import MoreSettings from './more-settings';
import Share from './share';
import Header from './view';
import ViewSettings from './view-settings';

type StateProps = {
	isMacroView: boolean;
	Share: ComponentType<{}>;
	Filters: ComponentType<{ isMacroView: boolean }>;
	ViewSettings: ComponentType<{}>;
	MoreSettings: ComponentType<{}> | undefined;
	// clean up Feedback prop along with getWillShowNav4()
	Feedback: ComponentType<{}>;
};

export default connect(
	(state: State): StateProps => ({
		isMacroView: hasMacroConfig(state),
		Share,
		Filters: FilterBar,
		ViewSettings,
		// clean up hasAdministerProjectPermission(state) || getIsUserBoardAdmin(state) conditions along with getWillShowNav4()
		MoreSettings:
			hasAdministerProjectPermission(state) || getIsUserBoardAdmin(state)
				? MoreSettings
				: undefined,
		// clean up Feedback prop along with getWillShowNav4()
		Feedback,
	}),
	{},
)(Header);
