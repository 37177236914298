import React, { useState, useEffect } from 'react';
import { ErrorBoundaryFlag as ErrorBoundary } from '@atlassian/jira-error-boundary-flag-renderer';
import { useSpaStateTransition } from '@atlassian/jira-spa-state-controller/src/components/transition-state';

type Props = {
	resource: {
		expiresAt: number | null;
		loading: boolean;
		refresh: () => void;
	} | null;
	refreshOnExpired?: boolean;
};

/**
 * Refresh the resource on mount if it's not already loading or stale, resource from prefetch will have 10s expiry time.
 */
const RefreshResourceOnMount = ({ resource, refreshOnExpired }: Props) => {
	const [{ isInitialRender }] = useSpaStateTransition();

	// Store these values once, we don't want the useEffect proccing more than once after mount
	const [isFullRender] = useState(isInitialRender);
	const [hasCache, setHasCache] = useState(false);
	const [hasRefreshed, setHasRefreshed] = useState(false);

	useEffect(() => {
		if (resource === null || hasRefreshed) {
			return;
		}

		// If we started loading set the cache miss flag to true. React-resource-router procs this
		// on page load
		if (
			resource.loading ||
			(refreshOnExpired && resource.expiresAt && resource.expiresAt > Date.now())
		) {
			setHasCache(true);
			return;
		}

		// If this is not a full render (i.e. a SPA transition)
		// And this is a cache miss (our requested resource never got set to loading true)
		if (!isFullRender && !hasCache) {
			resource.refresh();
			setHasRefreshed(true);
		}
	}, [hasRefreshed, hasCache, isFullRender, resource, refreshOnExpired]);

	return <></>;
};

const RefreshResourceOnMountWithBoundaries = (props: Props) => (
	<ErrorBoundary id="jira-software-resource-invalidator">
		<RefreshResourceOnMount {...props} />
	</ErrorBoundary>
);

export default RefreshResourceOnMountWithBoundaries;
