import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/zip';
import 'rxjs/add/operator/catch';
import { type BatchAction, batchActions } from 'redux-batched-actions';
import isEmpty from 'lodash/isEmpty';
import { Observable } from 'rxjs/Observable';
import type { IssueId, IssueStatusCategoryId } from '@atlassian/jira-shared-types';
import type { StatusCategoryHash, SubtaskHash } from '@atlassian/jira-software-roadmap-model';
import {
	getSubtasksForIssues,
	type SubtasksAndStatusCategories,
} from '@atlassian/jira-software-roadmap-services';
import { getSourceARI } from '../../../state/app/selectors';
import { mergeStatusCategories } from '../../../state/entities/status-categories/actions';
import { getFullStatusCategoriesHash } from '../../../state/entities/status-categories/selectors';
import {
	type ExtendSubtasksAction,
	extendSubtasks,
} from '../../../state/entities/subtasks/actions';
import type { State } from '../../../state/types';

export const reloadSubtaskProgress = (
	store: MiddlewareAPI<State>,
	issueIds: string[],
): Observable<never | BatchAction | ExtendSubtasksAction> => {
	if (issueIds.length === 0) {
		return Observable.empty<never>();
	}

	const state = store.getState();
	const fullStatusCategoriesHash = getFullStatusCategoriesHash(state);

	return getSubtasksForIssues(getSourceARI(state), issueIds)
		.mergeMap(
			({
				subtasks,
				statusCategories,
			}: SubtasksAndStatusCategories): Observable<never | BatchAction | ExtendSubtasksAction> => {
				const subtaskHash: SubtaskHash = {};
				const actions = [];
				issueIds.forEach((id: IssueId) => {
					subtaskHash[id] = [];
				});

				actions.push(extendSubtasks({ ...subtaskHash, ...subtasks }));

				const newStatusCategories: StatusCategoryHash = {};

				Object.keys(statusCategories).forEach((statusCategoryId: IssueStatusCategoryId) => {
					if (fullStatusCategoriesHash[statusCategoryId] === undefined) {
						newStatusCategories[statusCategoryId] = statusCategories[statusCategoryId];
					}
				});

				if (!isEmpty(newStatusCategories)) {
					actions.push(mergeStatusCategories(newStatusCategories));
				}

				return Observable.of(batchActions(actions));
			},
		)
		.catch(() => Observable.empty<never>());
};
