import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noStartDate: {
		id: 'roadmap.timeline-table-kit.common.formatted-date.no-start-date',
		defaultMessage: 'No start date',
		description:
			"Label. Gives context for an item that has no explicit start date. Shown when a user hovers over a bar that represents the item's duration.",
	},
	noDueDate: {
		id: 'roadmap.timeline-table-kit.common.formatted-date.no-due-date',
		defaultMessage: 'No due date',
		description:
			"Label. Gives context for an item that has no explicit due date. Shown when a user hovers over a bar that represents the item's duration.",
	},
});
