import React, { type MouseEvent, useCallback, forwardRef } from 'react';
import Button, { type ButtonProps } from '@atlaskit/button/standard-button';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { N0, N700 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { type AnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

type Props = {
	showLabel: boolean;
};

const LegendButton = forwardRef(({ onClick, showLabel, ...rest }: Props & ButtonProps, _ref) => {
	const { isSelected } = rest;
	const { formatMessage } = useIntl();

	const onClickCallback = useCallback(
		(event: MouseEvent<HTMLElement>, analyticsEvent: AnalyticsEvent) => {
			onClick?.(event, analyticsEvent);
			fireUIAnalytics(analyticsEvent, 'legendButton');
		},
		[onClick],
	);

	return (
		<Tooltip content={formatMessage(messages.legend)} position="top">
			<Button
				iconBefore={
					<InfoIcon
						label={formatMessage(messages.legend)}
						secondaryColor={token('elevation.surface', isSelected ? N700 : N0)}
						size="medium"
					/>
				}
				appearance="subtle"
				onClick={onClickCallback}
				{...rest}
			>
				{showLabel && formatMessage(messages.legend)}
			</Button>
		</Tooltip>
	);
});

export default LegendButton;
