import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import { batchActions } from 'redux-batched-actions';
import { Observable } from 'rxjs/Observable';
import type { Issue } from '@atlassian/jira-software-roadmap-model';
import { getProjectId } from '../../../state/configuration/selectors';
import { addPayloadGlobalIssueCreate } from '../../../state/entities/global-issue-create/actions';
import type { CreateIssueAction } from '../../../state/entities/issues/actions';
import { updateCreationPreferences } from '../../../state/settings/actions';
import type { State } from '../../../state/types';
import { resetCreateItemAnchor } from '../../../state/ui/table/actions';
import { getFieldsForCreateDialogGIC } from './utils/global-create';

/* When an issue to be created has required fields, we defer to the global issue create dialog.
 * Handling the result of this dialog happens elsewhere (see global-issue-create.js), since it is
 * the same handling for when a user would open global issue create manually.
 */
const requiredFieldsCreate = (
	store: MiddlewareAPI<State>,
	{ payload: { level } }: CreateIssueAction,
	optimisticIssue: Issue,
) => {
	const state = store.getState();
	const projectId = getProjectId(state);
	const { issueTypeId } = optimisticIssue;
	return Observable.of(
		batchActions([
			addPayloadGlobalIssueCreate(getFieldsForCreateDialogGIC(state, optimisticIssue)),
			updateCreationPreferences({ itemTypeId: issueTypeId, level, projectId }),
			resetCreateItemAnchor(),
		]),
	);
};

export default requiredFieldsCreate;
