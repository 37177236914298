import { B50, B100, B400, B500, N30, N40, N70, N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { CLOSED, ACTIVE, FUTURE, BORDER_WIDTH } from '../../../../../common/constants';
import type { IntervalState } from '../../../../../common/types';
import type { MarkerColors } from './types';

// ============= //
// === SIZES === //
// ============= //

// Proxy for whether the marker name can fit. Used because CSS ellipsis does not work well in extremely narrow containers.
export const APPROX_WIDTH_OF_SINGLE_CHARACTER_AND_ELLIPSIS = 30;

export const DEFAULT_HORIZONTAL_PADDING = 4;

export const MIN_CONTENT_SIZE = 2 * DEFAULT_HORIZONTAL_PADDING + 2 * BORDER_WIDTH;

// ============== //
// === COLORS === //
// ============== //

export const HIGHLIGHTED_BORDER = `${BORDER_WIDTH}px solid ${token('color.icon.brand', B100)}`;

export const HIGHLIGHTED_COLORS = {
	color: token('color.text.brand', B400),
	backgroundColor: token('color.background.selected', B50),
} as const;

export const MARKER_COLORS_BY_STATUS: Record<IntervalState, MarkerColors> = {
	[ACTIVE]: {
		color: token('color.text.accent.blue', B500),
		backgroundColor: token('color.background.accent.blue.subtler', B50),
	},
	[FUTURE]: {
		color: token('color.text.accent.gray.bolder', N500),
		backgroundColor: token('color.background.accent.gray.subtler', N40),
	},
	[CLOSED]: {
		color: token('color.text.accent.gray', N70),
		backgroundColor: token('color.background.accent.gray.subtlest', N30),
	},
};
