import type { Action } from '@atlassian/react-sweet-state';
import { DEFAULT_STATE } from '../../common/constants';
import type { State, ContainerProps } from '../../common/types';

export type OnLingerEndAction = () => Action<State, ContainerProps>;

export const onLingerEnd: OnLingerEndAction =
	() =>
	({ getState, setState }) => {
		const { dragType } = getState();

		/* The current affordance allows users to continue dragging an item even if
		 * their cursor moves to an adjacent row. Do *not* reset the state in this
		 * circumstance or it will cancel the drag interaction.
		 */
		if (dragType === undefined) {
			setState(DEFAULT_STATE);
		}
	};
