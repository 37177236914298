import { defineMessages } from 'react-intl-next';

const messages = defineMessages({
	meatballsButtonLabel: {
		id: 'roadmap.timeline-table.main.content.list.list-item.meatballs-button.meatball-popup.meatballs-button-label',
		defaultMessage: 'More actions',
		description: 'Label for the more options button in the list item',
	},
	itemsSectionHeader: {
		id: 'roadmap.timeline-table.main.content.list.list-item.meatballs-button.meatball-popup.items-section-header',
		defaultMessage: 'Items',
		description: 'Header for the item section in the list item',
	},
	itemsSelectionCount: {
		id: 'roadmap.timeline-table.main.content.list.list-item.meatballs-button.meatball-popup.items-selection-count',
		defaultMessage: '{count} {count, plural, one {item selected} other {items selected}}',
		description: 'Label to indicate the count of selected items on a meatballs button',
	},
});
export default messages;
