import { createSelector } from 'reselect';
import type { QuickFilterType } from '@atlassian/jira-software-filters';
import type { QuickFiltersHash, QuickFilter } from '@atlassian/jira-software-roadmap-model';
import { getFullQuickFilterHash } from '../ui/filters/selectors';

const getQuickFiltersOrderedBase = (quickFiltersHash: QuickFiltersHash): QuickFilterType[] =>
	Object.values(quickFiltersHash)
		.sort((a, b) => a.position - b.position)
		.map((quickFilter: QuickFilter) => ({
			id: quickFilter.id,
			name: quickFilter.name,
		}));

export const getQuickFilters = createSelector(getFullQuickFilterHash, getQuickFiltersOrderedBase);
