import React, { Component, type ReactNode, type ComponentType, type SyntheticEvent } from 'react';
import noop from 'lodash/noop';
import type {
	RequestBoundingClient,
	RequestScrollBounding,
	ScrollListener,
	SetViewportScroll,
	ViewportContextProps,
} from '../common/types';
import ViewportContext from '../context';
import { MISSING_REF_ERROR_MESSAGE, DEFAULT_BOUNDING_OPTIONS, DEFAULT_OFFSETS } from './constants';
import type { Offsets, WrappedComponent as WrappedComponentProps } from './types';
import { calculateClientBounding, calculateScrollBounding } from './utils';

export type Props = {
	children: ReactNode;
	id: string;
	innerRef: (elem: HTMLElement | null) => void;
	scrollbarSize: number;
	offsets: Offsets;
	onScroll: (({ currentTarget }: SyntheticEvent<HTMLElement, Event>) => void) | undefined;
};

const withViewportProvider = (WrappedComponent: ComponentType<WrappedComponentProps>) => {
	/* eslint-disable jira/react-arrow-function-property-naming */
	// eslint-disable-next-line jira/react/no-class-components
	class WithViewportProvider extends Component<Props, ViewportContextProps> {
		static defaultProps = {
			offsets: DEFAULT_OFFSETS,
			innerRef: noop,
		};

		constructor(props: Props) {
			super(props);

			// It is being used, but only as a whole state object and the linter does not recognize that
			/* eslint-disable react/no-unused-state */
			this.state = {
				requestViewportBoundingClient: this.requestBoundingClient,
				requestViewportScrollBounding: this.requestScrollBounding,
				listenToViewportScroll: this.listenToScroll,
				stopListeningToViewportScroll: this.stopListeningToScroll,
				setViewportScroll: this.setScroll,
			};
			/* eslint-enable react/no-unused-state */
		}

		requestBoundingClient: RequestBoundingClient = ({
			withTimelineOffset,
		} = DEFAULT_BOUNDING_OPTIONS) => {
			if (!this.ref) {
				throw new Error(MISSING_REF_ERROR_MESSAGE);
			}

			return calculateClientBounding({
				element: this.ref,
				offsets: this.props.offsets,
				withTimelineOffset,
			});
		};

		requestScrollBounding: RequestScrollBounding = ({
			withTimelineOffset,
		} = DEFAULT_BOUNDING_OPTIONS) => {
			if (!this.ref) {
				throw new Error(MISSING_REF_ERROR_MESSAGE);
			}

			return calculateScrollBounding({
				element: this.ref,
				offsets: this.props.offsets,
				withTimelineOffset,
			});
		};

		listenToScroll: ScrollListener = (callback) => {
			if (!this.ref) {
				throw new Error(MISSING_REF_ERROR_MESSAGE);
			}

			this.ref.addEventListener('scroll', callback);
		};

		stopListeningToScroll: ScrollListener = (callback) => {
			if (this.ref) {
				this.ref.removeEventListener('scroll', callback);
			}
		};

		setScroll: SetViewportScroll = ({ scrollLeft, scrollTop }) => {
			if (!this.ref) {
				throw new Error(MISSING_REF_ERROR_MESSAGE);
			}

			if (scrollLeft !== undefined) {
				this.ref.scrollLeft = scrollLeft;
			}

			if (scrollTop !== undefined) {
				this.ref.scrollTop = scrollTop;
			}
		};

		onSetRef = (elem: HTMLElement | null) => {
			const { innerRef } = this.props;

			this.ref = elem;
			innerRef(elem);
		};

		ref: HTMLElement | null = null;

		render() {
			const { children, ...props } = this.props;

			return (
				<ViewportContext.Provider value={this.state}>
					<WrappedComponent {...props} innerRef={this.onSetRef}>
						{children}
					</WrappedComponent>
				</ViewportContext.Provider>
			);
		}
	}
	/* eslint-enable jira/react-arrow-function-property-naming */

	return WithViewportProvider;
};

export default withViewportProvider;
