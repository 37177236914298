import type { Modifiable } from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';

type ConversionFunc<T, U> = (arg1: T) => U;

export const toModifiable = <T,>(value: T): Modifiable<T> => ({
	value,
});

export const nullableToModifiable = <T,>(nullable: T | null): Modifiable<T | undefined> => {
	if (nullable === null) {
		return { value: undefined };
	}
	return { value: nullable };
};

export const nullableToModifiableWithConversion = <T, U>(
	nullable: T | null | undefined,
	func: ConversionFunc<T | undefined, U | undefined>,
): Modifiable<U | undefined> => {
	if (nullable === null) {
		return { value: undefined };
	}
	return { value: func(nullable) };
};

export const nullableToOptional = <T,>(nullable: T | null): T | undefined => {
	if (nullable === null) {
		return undefined;
	}
	return nullable;
};

export const nullableToOptionalWithConversion = <T, U>(
	nullable: T | null,
	func: ConversionFunc<T, U>,
): U | undefined => {
	if (nullable === null) {
		return undefined;
	}
	return func(nullable);
};

export const optionalToNullable = <T,>(optional?: T): T | null => {
	if (optional === undefined) {
		return null;
	}
	return optional;
};

export const optionalToNullableWithConversion = <T, U>(
	optional: T | undefined,
	func: ConversionFunc<T, U>,
): U | null => {
	if (optional === undefined) {
		return null;
	}
	return func(optional);
};
