import intersection from 'lodash/intersection';
import union from 'lodash/union';
import type { ComponentId, VersionId, IssueTypeId, IssueId } from '@atlassian/jira-shared-types';
import type { IssueType as FilterIssueType, Label } from '@atlassian/jira-software-filters';
import {
	INCOMPLETE,
	type IssueTypeHash,
	type LevelOneSetting,
	type LevelOneQuerySetting,
	type RoadmapGoalHash,
	levelOneQuerySettingsToPeriodMap,
} from '@atlassian/jira-software-roadmap-model';

export const createGetFilteredLabels = (labels: Label[], uniqueLabels: Label[]): Label[] =>
	intersection(labels, uniqueLabels);

export const createGetFilteredIssueParents = (
	issueParents: IssueId[],
	uniqueIssueParents: IssueId[],
): IssueId[] => intersection(issueParents, uniqueIssueParents);

export const createGetFilteredVersions = (
	versions: VersionId[],
	uniqueVersions: VersionId[],
): VersionId[] => intersection(versions, uniqueVersions);

export const createGetFilteredComponents = (
	components: ComponentId[],
	uniqueComponents: ComponentId[],
): ComponentId[] => intersection(components, uniqueComponents);

export const getChildIssueTypesPure = (
	issueTypesHash: IssueTypeHash,
	epicIssueTypeIds: IssueTypeId[],
): FilterIssueType[] => {
	const issueTypes: FilterIssueType[] = [];
	Object.keys(issueTypesHash).forEach((issueTypeId) => {
		if (!epicIssueTypeIds.includes(issueTypeId)) {
			const issueType = issueTypesHash[issueTypeId];

			issueTypes.push({
				id: issueTypeId,
				name: issueType.name,
				iconUrl: issueType.iconUrl,
			});
		}
	});

	return issueTypes;
};

export const getLevelOneSettingPure = (
	settingFromQuery: LevelOneQuerySetting | undefined,
	settingFromStore: LevelOneSetting,
): LevelOneSetting => {
	if (settingFromQuery !== undefined && settingFromStore !== undefined) {
		const period =
			settingFromQuery === INCOMPLETE
				? settingFromStore.period
				: levelOneQuerySettingsToPeriodMap.get(settingFromQuery) || 12;
		return {
			period,
			showCompleted: settingFromQuery !== INCOMPLETE,
		};
	}

	return settingFromStore;
};

export const createGetFilteredGoals = (goals: string[], uniqueGoals: string[]): string[] =>
	intersection(goals, uniqueGoals);

export const createGetFilteredGoalIssueIds = (
	filteredGoals: string[],
	goals: RoadmapGoalHash,
	childrenHash: Record<string, string[]>,
) => {
	const parentIssues = union(...filteredGoals.map((goalId) => goals[goalId].issueIds));
	return union(parentIssues, ...parentIssues.map((issueId) => childrenHash[issueId] ?? []));
};
