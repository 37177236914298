import { useEffect } from 'react';
import isNil from 'lodash/isNil';
import 'rxjs/add/operator/filter';
import {
	RESOURCES_CACHE_UPDATER_SOURCE,
	RESOURCES_CACHE_UPDATER_SOURCE_KEY,
} from '../../common/types';
import CacheUpdateSubject from '../resources-cache-update-subject';
import useResourcesCache from '../use-resources-cache-tracker';
/*
 * Note this is only really used to handle real-time updates or any other methods of updating the cache
 */
const useResourcesCacheUpdater = () => {
	// Use a local resources cache to track all the useResource instances for each key
	const [resourcesByRef] = useResourcesCache();

	useEffect(() => {
		// We only need to proc this once and then we don't care about any further invalidation events
		const subscription = CacheUpdateSubject.filter((msg) => !isNil(msg.origin)).subscribe((msg) => {
			// Only handle this update if we have the resources in our dictionary
			if (msg.origin in resourcesByRef.current) {
				if (isNil(msg.payload)) {
					resourcesByRef.current[msg.origin].clear();
				} else {
					// @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2345 - Argument of type '(currentData: any) => any' is not assignable to parameter of type '() => null'. | TS7006 - Parameter 'currentData' implicitly has an 'any' type.
					resourcesByRef.current[msg.origin].update((currentData) => ({
						...currentData,
						...msg.payload,
						// Metadata to tell us where this actually came from
						[RESOURCES_CACHE_UPDATER_SOURCE_KEY]: RESOURCES_CACHE_UPDATER_SOURCE,
					}));
				}
			}
		});

		return () => {
			subscription.unsubscribe();
		};
	}, [resourcesByRef]);

	return [CacheUpdateSubject] as const;
};

export default useResourcesCacheUpdater;
