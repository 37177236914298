import type { Action } from '@atlassian/react-sweet-state';
import type { State, ContainerProps } from '../../common/types';

export type OnLingerStartAction = (id: string) => Action<State, ContainerProps>;

export const onLingerStart: OnLingerStartAction =
	(id: string) =>
	({ getState, setState }, { items }: ContainerProps) => {
		const { dragType } = getState();
		const activeItem = items.find((item) => item.id === id);

		/* The current affordance allows users to continue dragging an item even if
		 * their cursor moves to an adjacent row. Do *not* override the active item
		 * in this circumstance or it will cancel the drag interaction.
		 */
		if (activeItem !== undefined && dragType === undefined) {
			setState({ activeItem });
		}
	};
