import { token } from '@atlaskit/tokens';

export const getContainerHeight = ({
	itemHeight,
	depth,
}: {
	itemHeight: number;
	depth: number;
}) => {
	const height = depth > 0 ? token('space.400', '32px') : `${itemHeight}px`;

	return height;
};
