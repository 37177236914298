// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import Placeholder from '@atlassian/jira-placeholder';
import { ROADMAP_PACKAGE_NAME } from '../../../../../../constants';
import type VersionFlyoutType from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const VersionFlyout = lazyAfterPaint<typeof VersionFlyoutType>(
	() => import(/* webpackChunkName: "async-standard-roadmap-version-flyout" */ './index'),
	{ ssr: false },
);

type Props = JSX.LibraryManagedAttributes<
	typeof VersionFlyoutType,
	ComponentProps<typeof VersionFlyoutType>
>;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => (
	<JSErrorBoundary
		id="async-standard-roadmap-version-flyout"
		packageName={ROADMAP_PACKAGE_NAME}
		fallback="flag"
	>
		<Placeholder name="async-standard-roadmap-version-flyout" fallback={null}>
			<VersionFlyout {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
