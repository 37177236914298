import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../common/types';

export type onToggleHeaderModalAction = (isActive: boolean) => Action<State>;

export const onToggleHeaderModal: onToggleHeaderModalAction =
	(isActive: boolean) =>
	({ setState }) => {
		setState({ isHeaderModalActive: isActive });
	};
