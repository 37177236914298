import type { IssueId } from '@atlassian/jira-shared-types';
import type { IdentifiableHash, Sprint } from '@atlassian/jira-software-roadmap-model';

export const getSprintNameForBaseLevelIssue = (
	issueId: IssueId,
	issueSprintsHash: IdentifiableHash<IssueId, Sprint[]>,
): string | undefined => {
	const issueSprints = issueSprintsHash[issueId];
	if (issueSprints.length > 0) {
		const recentSprint = issueSprints[0];
		return recentSprint?.name;
	}

	return undefined;
};
