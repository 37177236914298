/**
 * @generated SignedSource<<426591ebbdeb2a0e3ea13d19bcfb473c>>
 * @relayHash c8c1f6c9567e3fc623ee7998049dba24
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ac083b05f962f8d48e2193ea1422badaa7b089c2b0762e4e158e5e26b3d9238d

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type RoadmapPaletteColor = "BLUE" | "DARK_BLUE" | "DARK_GREEN" | "DARK_GREY" | "DARK_ORANGE" | "DARK_PURPLE" | "DARK_TEAL" | "DARK_YELLOW" | "GREEN" | "GREY" | "ORANGE" | "PURPLE" | "TEAL" | "YELLOW" | "%future added value";
export type RoadmapRankPosition = "AFTER" | "BEFORE" | "%future added value";
export type RoadmapUpdateItemInput = {
  clearFields?: ReadonlyArray<string> | null | undefined;
  color?: RoadmapPaletteColor | null | undefined;
  dueDate?: AGG$Date | null | undefined;
  itemId: string;
  parentId?: string | null | undefined;
  projectId: string;
  rank?: RoadmapItemRankInput | null | undefined;
  sprintId?: string | null | undefined;
  startDate?: AGG$Date | null | undefined;
  summary?: string | null | undefined;
};
export type RoadmapItemRankInput = {
  id: string;
  position: RoadmapRankPosition;
};
export type updateRoadmapItemMutation$variables = {
  input: RoadmapUpdateItemInput;
  sourceARI: string;
};
export type updateRoadmapItemMutation$data = {
  readonly roadmaps: {
    readonly updateRoadmapItem: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly errorType?: string | null | undefined;
          readonly statusCode?: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type updateRoadmapItemMutation = {
  response: updateRoadmapItemMutation$data;
  variables: updateRoadmapItemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceARI"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  },
  {
    "kind": "Variable",
    "name": "sourceARI",
    "variableName": "sourceARI"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "success"
},
v4 = {
  "kind": "ScalarField",
  "name": "message"
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "statusCode"
    },
    {
      "kind": "ScalarField",
      "name": "errorType"
    }
  ],
  "type": "RoadmapMutationErrorExtension"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "updateRoadmapItemMutation",
    "selections": [
      {
        "concreteType": "RoadmapsMutation",
        "kind": "LinkedField",
        "name": "roadmaps",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "RoadmapUpdateItemPayload",
            "kind": "LinkedField",
            "name": "updateRoadmapItem",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateRoadmapItemMutation",
    "selections": [
      {
        "concreteType": "RoadmapsMutation",
        "kind": "LinkedField",
        "name": "roadmaps",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "RoadmapUpdateItemPayload",
            "kind": "LinkedField",
            "name": "updateRoadmapItem",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v5/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "id"
                          }
                        ],
                        "type": "BulkMutationErrorExtension"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ac083b05f962f8d48e2193ea1422badaa7b089c2b0762e4e158e5e26b3d9238d",
    "metadata": {},
    "name": "updateRoadmapItemMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "7ffc6f5477ee6a7f1c9eef09097f8de8";

export default node;
