import React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { Y300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import SuccessFlag from '@atlassian/jira-flags/src/common/ui/components/success-auto-dismiss-flag';
import { useIntl } from '@atlassian/jira-intl';
import { INTERACTED_ITEMS_LIMIT } from '../../constants';
import messages from './messages';

export interface Props {
	id: string | number;
	onDismissed?: (id: number | string, analyticsEvent: UIAnalyticsEvent) => void;
}

const InteractedItemsLimitExceededFlag = ({ id, onDismissed }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<SuccessFlag
			id={id}
			icon={<WarningIcon primaryColor={token('color.icon.warning', Y300)} size="medium" label="" />}
			title={formatMessage(messages.title, { limit: INTERACTED_ITEMS_LIMIT })}
			onDismissed={onDismissed}
		/>
	);
};

export default InteractedItemsLimitExceededFlag;
