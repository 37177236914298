// eslint-disable-next-line jira/restricted/react-component-props
import React, { memo, type ComponentProps } from 'react';
import { lazy } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import Placeholder from '@atlassian/jira-placeholder';
import { ROADMAP_PACKAGE_NAME } from '../../../../../constants';
import type ShareModalType from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ShareModal = lazy<typeof ShareModalType>(
	() => import(/* webpackChunkName: "async-standard-roadmap-share-modal" */ './index'),
);

const AsyncShareModal = (
	props: JSX.LibraryManagedAttributes<typeof ShareModalType, ComponentProps<typeof ShareModalType>>,
) => (
	<JSErrorBoundary
		id="async-standard-roadmap-share-modal"
		packageName={ROADMAP_PACKAGE_NAME}
		fallback="flag"
	>
		<Placeholder name="standard-roadmap-share-modal" fallback={null}>
			<ShareModal {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

export default memo<
	JSX.LibraryManagedAttributes<typeof ShareModalType, ComponentProps<typeof ShareModalType>>
>(AsyncShareModal);
