import { useEffect, useRef, memo, type ComponentType } from 'react';
import {
	fireOperationalAnalyticsDeferred,
	useAnalyticsEvents,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';
import { DEFAULT_SAMPLING_RATE, FRAMES_LIMIT, MAX_FPS } from './constants';

// @Export for testing
export const calculatefps = (frames: number, elapsedMs: number): number =>
	Math.min(Math.floor(1000 * (frames / elapsedMs)), MAX_FPS);

export type Props = {
	/**
	 * view ID
	 */
	viewId: string;
	/**
	 * size of the item
	 */
	itemsCount?: number;
	/**
	 * sampling rate
	 */
	samplingRate?: number;
	/**
	 * scrollable container DOM node
	 */
	scrollElement?: HTMLElement | null;
	/**
	 * Additional attributes in analytic event
	 */
	attributes?: Attributes;
};

/**
 * This component measures scroll performance (frames per second).
 * By default, we only measure every Nth scroll. This is controlled by `samplingRate` prop.
 * When the measurement starts (on Nth scroll), we calculate FPS for the next 20 frames (`FRAMES_LIMIT`) and fire analytics event.
 */
export const ScrollFPSMetric: ComponentType<Props> = memo<Props>(
	({
		viewId,
		itemsCount,
		samplingRate = DEFAULT_SAMPLING_RATE,
		scrollElement,
		attributes,
	}: Props) => {
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const samplingCount = useRef(0);
		const frames = useRef(0);
		const rafId = useRef<number | null>(null);
		const start = useRef(0);

		useEffect(() => {
			const container = scrollElement ?? document;
			const hasScrollCapture = true;

			const tick = () => {
				if (frames.current < FRAMES_LIMIT) {
					frames.current += 1;
					rafId.current = requestAnimationFrame(tick);
				} else {
					const stopTime = performance.now();
					const elapsedMs = stopTime - start.current;
					const fps = calculatefps(frames.current, elapsedMs);
					const avgFrameDuration = Math.floor(elapsedMs / frames.current);
					frames.current = 0;

					const attr: Attributes = {
						...(attributes || {}),
						itemsCount,
						avgFrameDuration,
						fps,
					};

					fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), `${viewId} scrolled`, attr);
				}
			};

			const handleOnScroll = () => {
				samplingCount.current += 1;
				if (samplingCount.current !== samplingRate) {
					return;
				}
				samplingCount.current = 0;

				// start counting frames
				start.current = performance.now();
				tick();
			};

			container.addEventListener('scroll', handleOnScroll, hasScrollCapture);

			return () => {
				container.removeEventListener('scroll', handleOnScroll, hasScrollCapture);
				if (rafId.current) {
					cancelAnimationFrame(rafId.current);
				}
			};
		}, [scrollElement, createAnalyticsEvent, itemsCount, samplingRate, viewId, attributes]);

		return null;
	},
);
export default ScrollFPSMetric;
