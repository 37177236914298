/**
 * @generated SignedSource<<29ef59f9daac427229ed7b906e39ccc2>>
 * @relayHash cde6a02fbb3e988a67f25278696a70bc
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 16dbcd61c253daeede3a24c3baddf1a6ba9b5cc226a5184f833fdc8bbf4c31cf

import type { ConcreteRequest, Query } from 'relay-runtime';
export type versionDetailsQuery$variables = {
  versionAri: string;
};
export type versionDetailsQuery$data = {
  readonly jira: {
    readonly version: {
      readonly __typename: "JiraVersion";
      readonly description: string | null | undefined;
      readonly doneIssues: {
        readonly totalCount: number;
      };
      readonly inProgressIssues: {
        readonly totalCount: number;
      };
      readonly releaseDate: AGG$DateTime | null | undefined;
      readonly startDate: AGG$DateTime | null | undefined;
      readonly todoIssues: {
        readonly totalCount: number;
      };
      readonly versionId: string;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined;
  };
};
export type versionDetailsQuery = {
  response: versionDetailsQuery$data;
  variables: versionDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "versionAri"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "versionAri"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v3 = {
  "kind": "ScalarField",
  "name": "versionId"
},
v4 = {
  "kind": "ScalarField",
  "name": "description"
},
v5 = {
  "kind": "ScalarField",
  "name": "startDate"
},
v6 = {
  "kind": "ScalarField",
  "name": "releaseDate"
},
v7 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": "TODO"
  }
],
v8 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v9 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": "IN_PROGRESS"
  }
],
v10 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": "DONE"
  }
],
v11 = [
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "versionDetailsQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "args": (v1/*: any*/),
              "kind": "LinkedField",
              "name": "version",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": (v3/*: any*/),
                      "action": "THROW",
                      "path": "jira.version.versionId"
                    },
                    (v4/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/),
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": "todoIssues",
                        "args": (v7/*: any*/),
                        "concreteType": "JiraIssueConnection",
                        "kind": "LinkedField",
                        "name": "issues",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "RequiredField",
                            "field": (v8/*: any*/),
                            "action": "THROW",
                            "path": "jira.version.todoIssues.totalCount"
                          }
                        ],
                        "storageKey": "issues(filter:\"TODO\")"
                      },
                      "action": "THROW",
                      "path": "jira.version.todoIssues"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": "inProgressIssues",
                        "args": (v9/*: any*/),
                        "concreteType": "JiraIssueConnection",
                        "kind": "LinkedField",
                        "name": "issues",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "RequiredField",
                            "field": (v8/*: any*/),
                            "action": "THROW",
                            "path": "jira.version.inProgressIssues.totalCount"
                          }
                        ],
                        "storageKey": "issues(filter:\"IN_PROGRESS\")"
                      },
                      "action": "THROW",
                      "path": "jira.version.inProgressIssues"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": "doneIssues",
                        "args": (v10/*: any*/),
                        "concreteType": "JiraIssueConnection",
                        "kind": "LinkedField",
                        "name": "issues",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "RequiredField",
                            "field": (v8/*: any*/),
                            "action": "THROW",
                            "path": "jira.version.doneIssues.totalCount"
                          }
                        ],
                        "storageKey": "issues(filter:\"DONE\")"
                      },
                      "action": "THROW",
                      "path": "jira.version.doneIssues"
                    }
                  ],
                  "type": "JiraVersion"
                }
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "versionDetailsQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "kind": "LinkedField",
            "name": "version",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": "todoIssues",
                    "args": (v7/*: any*/),
                    "concreteType": "JiraIssueConnection",
                    "kind": "LinkedField",
                    "name": "issues",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": "issues(filter:\"TODO\")"
                  },
                  {
                    "alias": "inProgressIssues",
                    "args": (v9/*: any*/),
                    "concreteType": "JiraIssueConnection",
                    "kind": "LinkedField",
                    "name": "issues",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": "issues(filter:\"IN_PROGRESS\")"
                  },
                  {
                    "alias": "doneIssues",
                    "args": (v10/*: any*/),
                    "concreteType": "JiraIssueConnection",
                    "kind": "LinkedField",
                    "name": "issues",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": "issues(filter:\"DONE\")"
                  }
                ],
                "type": "JiraVersion"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "16dbcd61c253daeede3a24c3baddf1a6ba9b5cc226a5184f833fdc8bbf4c31cf",
    "metadata": {},
    "name": "versionDetailsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "5d5e1bd006f223be75c2d70927a59808";

export default node;
