import { combineReducers } from 'redux';
import globalIssueCreate from './global-issue-create/reducer';
import goals from './goals/reducer';
import issues from './issues/reducer';
import statusCategories from './status-categories/reducer';
import subtasks from './subtasks/reducer';
import users from './users/reducer';

export default combineReducers({
	issues,
	statusCategories,
	users,
	globalIssueCreate,
	subtasks,
	goals,
});
