import isEmpty from 'lodash/isEmpty';
import { INTERVAL, LEFT_AND_RIGHT } from '../../../../../../common/constants';
import type { ItemPositions } from '../../../common/types';
import { getTargetIntervalId, getGridSize } from '../../../common/utils';
import type { DateInteractiveChartItem, OnDateDrag } from './types';
import { getDragPosition, getItemsCollection } from './utils';

export const onDateDrag: OnDateDrag = (
	dragPosition,
	{ activeItem, dragType, itemPositions },
	{
		items,
		selectedItemIds,
		timelineDuration,
		timelineWidth,
		isDragAlongEnabled,
		getScrollOffset,
		simpleIntervals,
	},
) => {
	const gridSize = getGridSize(timelineDuration, timelineWidth);
	const scrollOffset = getScrollOffset(dragPosition.from, dragPosition.to);
	const { selectedItems, childItemsHash } = getItemsCollection(items, selectedItemIds);
	const newItemPositions: ItemPositions = {};

	const handleDrag = (targetItem: DateInteractiveChartItem) => {
		const { leftPosition, rightPosition } = getDragPosition(
			dragType,
			targetItem,
			timelineDuration,
			timelineWidth,
			dragPosition,
			gridSize,
			scrollOffset,
		);

		// In case of an invalid drag (e.g. out of bounds) neither positions will be defined
		if (leftPosition === undefined && rightPosition === undefined) {
			return;
		}

		newItemPositions[targetItem.id] = { leftPosition, rightPosition };

		// Depending on the interaction, an item with children can result in updating their positions also
		if (isDragAlongEnabled && dragType === LEFT_AND_RIGHT) {
			const childItems = childItemsHash[targetItem.id] || [];

			// Move the parent item along with its children
			childItems.forEach((childItem: DateInteractiveChartItem) => {
				const { leftPosition: childLeftPosition, rightPosition: childRightPosition } =
					getDragPosition(
						dragType,
						childItem,
						timelineDuration,
						timelineWidth,
						dragPosition,
						gridSize,
						scrollOffset,
					);

				if (childLeftPosition !== undefined || childRightPosition !== undefined) {
					newItemPositions[childItem.id] = {
						leftPosition: childLeftPosition,
						rightPosition: childRightPosition,
					};
				}
			});
		}
	};

	let targetIntervalId;
	const { id, intervalId, startDateType, dueDateType } = activeItem;

	// bulk-scheduling (bar-dragging and bar-resizing) is only available when
	// - there is more than one item selected
	// - the active item is part of the selection
	// - the items selected are schedulable (i.e. their dates are not derived from intervals. Currently only base-level issues in scrum fit)
	if (
		selectedItemIds.length > 1 &&
		selectedItemIds.includes(id) &&
		startDateType !== INTERVAL &&
		dueDateType !== INTERVAL
	) {
		selectedItems.forEach((item: DateInteractiveChartItem) => {
			handleDrag(item);
		});

		if (isEmpty(newItemPositions)) {
			return undefined;
		}
	} else {
		handleDrag(activeItem);

		if (isEmpty(newItemPositions)) {
			return undefined;
		}

		const { leftPosition, rightPosition } = newItemPositions[id];

		if (
			intervalId !== undefined &&
			dragType === LEFT_AND_RIGHT &&
			leftPosition !== undefined &&
			rightPosition !== undefined
		) {
			const midPosition = (timelineWidth - rightPosition - leftPosition) / 2 + leftPosition;

			const unsafeTargetIntervalId = getTargetIntervalId(
				simpleIntervals,
				timelineWidth,
				midPosition,
			);

			// Skip setting to an items existing assigned intervals
			if (unsafeTargetIntervalId !== intervalId) {
				targetIntervalId = unsafeTargetIntervalId;
			}
		}
	}

	return { itemPositions: { ...itemPositions, ...newItemPositions }, targetIntervalId };
};
