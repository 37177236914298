export {
	DATE_FORMAT,
	DATE_FORMAT_MOMENT,
	DUE_DATE_OFFSET,
	INTERVAL,
	ROLLUP,
	EXPLICIT,
} from './date';

export {
	BAR_HEIGHT,
	BASE_BAR_HEIGHT,
	DEFAULT_BORDER_RADIUS,
	PLACEHOLDER_BORDER_RADIUS,
	LEFT_PLACEHOLDER_BORDER_RADIUS,
	RIGHT_PLACEHOLDER_BORDER_RADIUS,
	LEFT_AND_RIGHT_PLACEHOLDER_BORDER_RADIUS,
	MINIMUM_BAR_LENGTH,
	START_DATE_PLACEHOLDER,
	DUE_DATE_PLACEHOLDER,
	START_AND_DUE_DATE_PLACEHOLDER,
} from './chart-item';

export { EXPANDABLE_INDICATOR_WIDTH, BAR_GAP_WIDTH, BORDER_WIDTH } from './chart-header';

export { HEADER_BACKGROUND_COLOR } from './color';

export {
	INCOMING,
	OUTGOING,
	DEPENDENCY_DRAG_ITEM_ID,
	DEPENDENCIES_DROP_TARGET_CLASS_NAME,
} from './dependency';

export { CLOSED, ACTIVE, FUTURE } from './interval';

export {
	CHART_OVERLAY_Z_INDEX,
	DEPENDENCY_LINES_Z_INDEX,
	CHART_BAR_Z_INDEX,
	OVERLAY_FLYOUT_Z_INDEX,
	HIGHLIGHT_LINES_Z_INDEX,
	CHART_BAR_CONTENT_Z_INDEX,
	DEPENDENCIES_HANDLER_Z_INDEX,
	CHART_NAVIGATE_BUTTON_Z_INDEX,
	CHART_PREVIEW_BAR_Z_INDEX,
	CHART_DATES_Z_INDEX,
	CHART_FLYOUT_Z_INDEX,
} from './z-index';

export {
	COMPLETED,
	IN_PROGRESS,
	WARNING,
	MARKER_COLORS,
	MARKER_WIDTH,
	MARKER_BORDER_WIDTH,
	MARKER_WIDTH_WITH_SPACE,
	STATUS_PRIORITY,
} from './key-date';

export { DEPENDENCIES_FLYOUT_TYPE, CONTEXT_MENU_TYPE } from './overlay';

// ============================================== //
// === Catch all - Generic constants go here! === //
// ============================================== //

export const TOP = 'TOP' as const;
export const BOTTOM = 'BOTTOM' as const;
export const LEFT = 'LEFT' as const;
export const RIGHT = 'RIGHT' as const;
export const LEFT_AND_RIGHT = 'LEFT_AND_RIGHT' as const;

export const START_DATE_RELATIVE = 'START_DATE_RELATIVE' as const;
export const DUE_DATE_RELATIVE = 'DUE_DATE_RELATIVE' as const;
export const START_DATE_AND_DUE_DATE_RELATIVE = 'START_DATE_AND_DUE_DATE_RELATIVE' as const;

// TABLE HEADER ID
export const RELEASES_HEADER_ID = 'RELEASES_HEADER_ID';
export const SPRINTS_HEADER_ID = 'SPRINTS_HEADER_ID';

// RENDERERS
export const DEFAULT_RENDERER = () => null;
