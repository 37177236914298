import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { RoadmapListEmptyContent } from '@atlassian/jira-software-roadmap-timeline-table-kit';
import messages from './messages';

type Props = {
	isFilterActive: boolean;
	isLevelOneSettingActive: boolean;
	levelOneName: string;
	canUserCreate: boolean;
	onClearFilter: () => void;
	onClearLevelOneSetting: () => void;
};

const StandardRoadmapListEmptyContent = ({
	levelOneName,
	isFilterActive,
	isLevelOneSettingActive,
	canUserCreate,
	onClearFilter,
	onClearLevelOneSetting,
}: Props) => {
	const { formatMessage } = useIntl();

	if (isFilterActive) {
		return (
			<RoadmapListEmptyContent
				description={formatMessage(messages.explanationFilter)}
				actionLabel={formatMessage(messages.clearFilter)}
				onActionClick={onClearFilter}
			/>
		);
	}

	if (isLevelOneSettingActive) {
		return (
			<RoadmapListEmptyContent
				description={formatMessage(messages.explanationLevelOneSetting, {
					levelOneName,
				})}
				actionLabel={formatMessage(messages.clearLevelOneSetting, { levelOneName })}
				onActionClick={onClearLevelOneSetting}
			/>
		);
	}

	if (!canUserCreate) {
		return (
			<RoadmapListEmptyContent
				title={formatMessage(messages.titleNoAccess)}
				description={formatMessage(messages.explanationNoAccess)}
				illustration="generic-empty-state"
			/>
		);
	}

	return null;
};

export { StandardRoadmapListEmptyContent };
