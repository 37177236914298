import { SHOW_PANEL, HIDE_PANEL, type ShowPanelAction, type HidePanelAction } from './actions';
import type { PanelState as State } from './types';

const defaultState = {
	panelType: undefined,
} as const;

type Action = ShowPanelAction | HidePanelAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = defaultState, action: Action): State => {
	switch (action.type) {
		case SHOW_PANEL:
			return {
				...state,
				panelType: action.payload,
			};

		case HIDE_PANEL:
			return {
				...state,
				panelType: undefined,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
