// eslint-disable-next-line jira/restricted/react-component-props
import type { ComponentProps } from 'react';
import { connect } from '@atlassian/jira-react-redux';
import type { IssueId } from '@atlassian/jira-shared-types';
import { RoadmapListItemContentLite } from '@atlassian/jira-software-roadmap-timeline-table-kit';
import {
	getIssueSummary,
	getIssueKeyById,
	getIsIssueResolved,
} from '../../../../state/entities/issues/selectors';
import { getIssueTypeForIssueId } from '../../../../state/selectors/issues';
import type { State } from '../../../../state/types';

type OwnProps = {
	id: IssueId;
};

type StateProps = ComponentProps<typeof RoadmapListItemContentLite>;

export default connect(
	(state: State, { id }: OwnProps): StateProps => {
		const issueType = getIssueTypeForIssueId(state, id);

		return {
			iconUrl: issueType?.iconUrl,
			title: getIssueSummary(state, id),
			itemKey: getIssueKeyById(state, id),
			isDone: getIsIssueResolved(state, id),
		};
	},
	null,
	(stateProps: StateProps) => ({
		...stateProps,
	}),
)(RoadmapListItemContentLite);
