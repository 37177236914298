import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { IssueTypeId, IssueKey, IssueId } from '@atlassian/jira-shared-types';
import type { BoardId, SprintId } from '@atlassian/jira-software-roadmap-model';
import type { DependenciesPermissionType } from '../../model/dependencies';
import type { IssueFieldModification } from '../../model/issue';
import type { SprintScopeChangeDirection } from './types';

// Error with an unknown cause

export const GENERAL_ERROR = 'state.flags.GENERAL_ERROR';

export type GeneralErrorAction = {
	type: typeof GENERAL_ERROR;
};

export const generalError = (): GeneralErrorAction => ({
	type: GENERAL_ERROR,
});

// Error from AGG

export type AggErrorPayload = {
	description: string | undefined;
	errorType: string | undefined;
};

export const AGG_ERROR = 'state.flags.AGG_ERROR';

export type AggErrorAction = {
	type: typeof AGG_ERROR;
	payload: AggErrorPayload;
};

export const aggError = (
	payload: AggErrorPayload = { description: undefined, errorType: undefined },
): AggErrorAction => ({
	type: AGG_ERROR,
	payload,
});

// Issue not visible after creation or update

export const ISSUE_HIDDEN = 'state.flags.ISSUE_HIDDEN';

export type IssueHiddenPayload = {
	ids: IssueId[];
};

export type IssueHiddenAction = {
	type: typeof ISSUE_HIDDEN;
	payload: IssueHiddenPayload;
};

export const issueHidden = (payload: IssueHiddenPayload): IssueHiddenAction => ({
	type: ISSUE_HIDDEN,
	payload,
});

// Issue not visible after creation due to not matching the roadmap query

export const IRRELEVANT_ISSUES_CREATED = 'state.flags.IRRELEVANT_ISSUES_CREATED';

export type IrrelevantIssuesCreatedPayload = {
	issueKeys: IssueKey[];
};

export type IrrelevantIssuesCreatedAction = {
	type: typeof IRRELEVANT_ISSUES_CREATED;
	payload: IrrelevantIssuesCreatedPayload;
};

export const irrelevantIssuesCreated = (
	payload: IrrelevantIssuesCreatedPayload,
): IrrelevantIssuesCreatedAction => ({
	type: IRRELEVANT_ISSUES_CREATED,
	payload,
});

// Issue disappears due to display range options

export const ISSUE_DISAPPEARS_FROM_DISPLAY_RANGE =
	'state.flags.ISSUE_DISAPPEARS_FROM_DISPLAY_RANGE';
export type IssueDisappearsFromDisplayRangePayload = {
	issueId: IssueId;
};
export type IssueDisappearsFromDisplayRangeAction = {
	type: typeof ISSUE_DISAPPEARS_FROM_DISPLAY_RANGE;
	payload: IssueDisappearsFromDisplayRangePayload;
};

export const issueDisappearsFromDisplayRange = (
	payload: IssueDisappearsFromDisplayRangePayload,
): IssueDisappearsFromDisplayRangeAction => ({
	type: ISSUE_DISAPPEARS_FROM_DISPLAY_RANGE,
	payload,
});

// Issue not created in the order of the roadmap query

export const RANK_MISMATCH_ISSUE_CREATED = 'state.flags.RANK_MISMATCH_ISSUE_CREATED';

export type RankMismatchIssueCreatedPayload = {
	issueTypeId: IssueTypeId;
};

export type RankMismatchIssueCreatedAction = {
	type: typeof RANK_MISMATCH_ISSUE_CREATED;
	payload: RankMismatchIssueCreatedPayload;
};

export const rankMismatchIssueCreated = (
	payload: RankMismatchIssueCreatedPayload,
): RankMismatchIssueCreatedAction => ({
	type: RANK_MISMATCH_ISSUE_CREATED,
	payload,
});

// Issue not ranked due to the order in the roadmap query

export const UNABLE_TO_RANK_ISSUE = 'state.flags.UNABLE_TO_RANK_ISSUE';

export type UnableToRankPayload = {
	boardId: BoardId;
};

export type UnableToRankIssueAction = {
	type: typeof UNABLE_TO_RANK_ISSUE;
	payload: UnableToRankPayload;
};

export const unableToRankIssue = (payload: UnableToRankPayload): UnableToRankIssueAction => ({
	type: UNABLE_TO_RANK_ISSUE,
	payload,
});

// Dependency not visible after creation due to the feature being hidden

export const HIDDEN_DEPENDENCY_CREATED = 'state.flags.HIDDEN_DEPENDENCY_CREATED';

export type HiddenDependencyCreatedAction = {
	type: typeof HIDDEN_DEPENDENCY_CREATED;
};

export const hiddenDependencyCreated = (): HiddenDependencyCreatedAction => ({
	type: HIDDEN_DEPENDENCY_CREATED,
});

// Dependency not created due to the feature being disabled

export const DEPENDENCIES_UNAVAILABLE = 'state.flags.DEPENDENCIES_UNAVAILABLE';

export type DependenciesUnavailableAction = {
	type: typeof DEPENDENCIES_UNAVAILABLE;
};

export const dependenciesUnavailable = (): DependenciesUnavailableAction => ({
	type: DEPENDENCIES_UNAVAILABLE,
});

// Dependency not created due to lack of permissions

export const DEPENDENCIES_PERMISSION = 'state.flags.DEPENDENCIES_PERMISSION';

type DependenciesPermissionPayload = {
	type: DependenciesPermissionType;
	dependeeKey: IssueKey | undefined;
	dependencyKey: IssueKey | undefined;
};

export type DependenciesPermissionAction = {
	type: typeof DEPENDENCIES_PERMISSION;
	payload: DependenciesPermissionPayload;
};

export const dependenciesPermission = (
	payload: DependenciesPermissionPayload,
): DependenciesPermissionAction => ({
	type: DEPENDENCIES_PERMISSION,
	payload,
});

// Issues not updated due to lack of permission
export const UPDATE_ITEM_PERMISSION = 'state.flags.UPDATE_ITEM_PERMISSION';

export type UpdateItemPermissionAction = {
	type: typeof UPDATE_ITEM_PERMISSION;
};

export const updateItemPermission = (): UpdateItemPermissionAction => ({
	type: UPDATE_ITEM_PERMISSION,
});

// Sprint scope change resulting from scheduling to current sprint.

export const SPRINT_SCOPE_CHANGE = 'state.flags.SPRINT_SCOPE_CHANGE';

export type SprintScopeChangePayload = {
	direction: SprintScopeChangeDirection;
	targetSprintId: SprintId | undefined;
	sourceSprintId: SprintId | undefined;
	issueId: IssueId;
	properties: IssueFieldModification;
	sourceEvent: UIAnalyticsEvent;
	transitionId: string;
	hasOverdueReleaseDate: boolean | undefined;
};

export type SprintScopeChangeAction = {
	type: typeof SPRINT_SCOPE_CHANGE;
	payload: SprintScopeChangePayload;
};

export const sprintScopeChanged = (payload: SprintScopeChangePayload): SprintScopeChangeAction => ({
	type: SPRINT_SCOPE_CHANGE,
	payload,
});

// Interacted items limit exceeded due to issue drag-along or bulk interactions

export const INTERACTED_ITEMS_LIMIT_EXCEEDED = 'state.flags.INTERACTED_ITEMS_LIMIT_EXCEEDED';

export type InteractedItemsLimitExceededPayload = {
	transitionId: string;
	issueId: IssueId;
	hasOverdueReleaseDate: boolean | undefined;
	properties: IssueFieldModification;
	sourceEvent: UIAnalyticsEvent;
};

export type InteractedItemsLimitExceededAction = {
	type: typeof INTERACTED_ITEMS_LIMIT_EXCEEDED;
	payload: InteractedItemsLimitExceededPayload | undefined;
};

export const interactedItemsLimitExceeded = (
	payload?: InteractedItemsLimitExceededPayload,
): InteractedItemsLimitExceededAction => ({
	type: INTERACTED_ITEMS_LIMIT_EXCEEDED,
	payload,
});

// Dependency not created due to the creation of a dependency cycle

export const DEPENDENCY_CYCLE_CREATION = 'state.flags.DEPENDENCY_CYCLE_CREATION';

export type DependencyCycleCreationPayload = {
	dependee?: IssueKey;
	dependency?: IssueKey;
};

export type DependencyCycleCreationAction = {
	type: typeof DEPENDENCY_CYCLE_CREATION;
	payload: DependencyCycleCreationPayload;
};

export const dependencyCycleCreation = (
	payload: DependencyCycleCreationPayload,
): DependencyCycleCreationAction => ({
	type: DEPENDENCY_CYCLE_CREATION,
	payload,
});

// Dependency is duplicated

export const DEPENDENCY_DUPLICATION = 'state.flags.DEPENDENCY_DUPLICATION';

export type DependencyDuplicationPayload = {
	dependee?: IssueKey;
	dependency?: IssueKey;
};

export type DependencyDuplicationAction = {
	type: typeof DEPENDENCY_DUPLICATION;
	payload: DependencyDuplicationPayload;
};

export const dependencyDuplication = (
	payload: DependencyDuplicationPayload,
): DependencyDuplicationAction => ({
	type: DEPENDENCY_DUPLICATION,
	payload,
});
