import { combineEpics } from 'redux-observable';
import { isStateChangeBasedIssueHiddenFlagsEnabled } from '../../../feature-flags';
import { dummyEpic } from '../../common/dummy';
import type { StateEpic } from '../../common/types';
import realtimeEvents from './realtime-events';
import realtimeReapplyJQL from './realtime-reapply-jql';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default combineEpics(
	realtimeEvents,
	isStateChangeBasedIssueHiddenFlagsEnabled() ? realtimeReapplyJQL : dummyEpic,
) as StateEpic;
