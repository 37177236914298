import { TOP } from '../../constants';
import type { DragHandleType } from '../../types';

const FIXED_OFFSET_X = 15;
const FIXED_OFFSET_Y = 8;

// The handles are portalled out into a fixed position. We want that position to be slightly outside the bar.
export const getFixedHandlePosition = (type: DragHandleType, barClientRect: ClientRect) => {
	const { top, left, width, height } = barClientRect;

	if (type === TOP) {
		return { top: top - FIXED_OFFSET_Y, left: left + FIXED_OFFSET_X };
	}
	return { top: top + height + FIXED_OFFSET_Y, left: left + width - FIXED_OFFSET_X };
};

// For the drag and drop preview calculations, we also want to know its position relative to the timeline.
export const getRelativeHandlePosition = (
	type: DragHandleType,
	timelineWidth: number,
	barLeftPosition: number,
	barRightPosition: number,
) => (type === TOP ? barLeftPosition : timelineWidth - barRightPosition);
