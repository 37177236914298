import React, { Component, type ReactNode } from 'react';
import flow from 'lodash/flow';
import noop from 'lodash/noop';
import type AnalyticsWebClient from '@atlassiansox/analytics-web-client';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type Client from '@atlaskit/pubsub';
import type { PubSubClient } from '@atlaskit/pubsub';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { PUBSUB_DEFAULT_HOST, PUBSUB_PRODUCT_JIRA } from '../../common/constants/pubsub';
import { Provider } from '../../common/utils/context.utils';
import { withHost } from './with-host';
import { withRuntimeProvider } from './with-runtime-provider';

const createPubSubClient = (host: string, analyticsClient?: AnalyticsWebClient) =>
	import(/* webpackChunkName: "@atlaskit-jira_pubsub" */ '@atlaskit/pubsub').then((module) => {
		const Client = module.default;

		return new Client({
			product: PUBSUB_PRODUCT_JIRA,
			url: `${host}`,
			apsProtocol: {
				enabled: true,
			},
			analyticsClient,
		});
	});

export const joinChannel = (
	appId: string,
	client: Client,
	channels: string[],
	onHandler: (result?: PubSubClient) => void,
) =>
	client
		.join(channels)
		.then(onHandler) // eslint-disable-next-line @typescript-eslint/no-explicit-any
		.catch((error: any) => {
			// Ideally anonymous users shouldn't get there.
			// Ignoring 401 status codes until it is fixed on the app level.
			if (error && error.code === 401) {
				return;
			}
			log.safeErrorWithoutCustomerData(
				'realtime.channel.join.failure',
				`Failed to join channel @ ${appId}`,
				error,
			);
		});

export const leaveChannel = (
	appId: string,
	client: Client,
	channels: string[],
	onHandler: (result?: PubSubClient) => void,
) =>
	client
		.leave(channels)
		.then(onHandler) // eslint-disable-next-line @typescript-eslint/no-explicit-any
		.catch((error: any) => {
			// Ideally anonymous users shouldn't get there.
			// Ignoring 401 status codes until it is fixed on the app level.
			if (error && error.code === 401) {
				return;
			}
			log.safeErrorWithoutCustomerData(
				'realtime.channel.leave.failure',
				`Failed to left channel @ ${appId}`,
				error,
			);
		});

type DefaultProps = {
	host: string;
	onJoin: (channels: string[], arg2: UIAnalyticsEvent) => void;
	onLeave: (channels: string[], arg2: UIAnalyticsEvent) => void;
};

type Props = DefaultProps & {
	appId: string;
	channels: string[];
	children: ReactNode;
};

type State = {
	client: Client | null;
};

// eslint-disable-next-line jira/react/no-class-components
class RealtimeProviderView extends Component<Props, State> {
	static defaultProps: DefaultProps = {
		host: PUBSUB_DEFAULT_HOST,
		onJoin: noop,
		onLeave: noop,
	};

	state = { client: null };

	componentDidMount() {
		const { appId, host, channels } = this.props;

		this.isComponentMounted = true;

		getAnalyticsWebClientPromise()
			.then((analyticsClient) => createPubSubClient(host, analyticsClient.getInstance()))
			.then((pubSubClient) =>
				joinChannel(appId, pubSubClient, channels, this.onJoinChannel).then(() => {
					if (this.isComponentMounted) {
						this.setState({ client: pubSubClient });
					}
				}),
			);
	}

	componentDidUpdate({ channels: prevChannels }: Props) {
		const { appId, channels: newChannels } = this.props;
		const { client } = this.state;

		if (client && newChannels !== prevChannels) {
			const removedChannels = prevChannels.filter((channel) => !newChannels.includes(channel));
			leaveChannel(appId, client, removedChannels, this.onLeaveChannel);

			joinChannel(appId, client, newChannels, this.onJoinChannel);
		}
	}

	componentWillUnmount() {
		const { appId, channels } = this.props;
		const { client } = this.state;

		this.isComponentMounted = false;

		if (client) {
			leaveChannel(appId, client, channels, this.onLeaveChannel);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onJoinChannel = (result: any) => {
		const { onJoin } = this.props;
		// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
		onJoin(Array.from(result.currentChannels));
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onLeaveChannel = (result: any) => {
		const { onLeave } = this.props;
		// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
		onLeave(Array.from(result.currentChannels));
	};

	isComponentMounted = false;

	render() {
		return (
			<Provider
				value={{
					client: this.state.client,
				}}
			>
				{this.props.children}
			</Provider>
		);
	}
}

const withAnalytics = AnalyticsEventToProps('realtimeProvider', {
	onJoin: 'joinedChannel',
	onLeave: 'leftChannel',
});

export const RealtimeProvider = flow(
	withAnalytics,
	withHost,
	withRuntimeProvider,
)(RealtimeProviderView);

export default RealtimeProvider;
