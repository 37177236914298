import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { getBoardId } from '../../state/app/selectors';
import { rankIssuesSupported } from '../../state/configuration/selectors';
import {
	type AttemptRankIssueAction,
	type RankIssueAction,
	ATTEMPT_RANK_ISSUE,
	rankIssue,
} from '../../state/entities/issues/actions';
import { unableToRankIssue, type UnableToRankIssueAction } from '../../state/flags/actions';
import type { State } from '../../state/types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<AttemptRankIssueAction>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(ATTEMPT_RANK_ISSUE)
		.mergeMap(
			(action: AttemptRankIssueAction): Observable<UnableToRankIssueAction | RankIssueAction> => {
				const state = store.getState();
				if (rankIssuesSupported(state)) {
					return Observable.of(rankIssue(action.payload, action.meta.analyticsEvent));
				}
				return Observable.of(unableToRankIssue({ boardId: getBoardId(state) }));
			},
		);
