import type { Instruction } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import type { ItemId } from '../../../../../common/types';
import { isBaseLevel } from '../../../../../common/utils/hierarchy-enriched-items';

type Item = {
	id: ItemId;
	parentId: ItemId | undefined;
	level: number;
	depth: number;
};

export const UPDATE_RANK = 'UPDATE_RANK' as const;
export const UPDATE_PARENT = 'UPDATE_PARENT' as const;
export const UPDATE_RANK_AND_PARENT = 'UPDATE_RANK_AND_PARENT' as const;
export const SUBTASK_HIERARCHY_LEVEL = -1;
export const INDENT_PER_LEVEL = 25;

export type UpdateType = typeof UPDATE_RANK | typeof UPDATE_PARENT | typeof UPDATE_RANK_AND_PARENT;
export type BlockedInstructions = Instruction['type'][];

export const getUpdateType = (draggedItem: Item, dropTarget: Item): UpdateType | undefined => {
	if (draggedItem.level === dropTarget.level) {
		if (isBaseLevel(draggedItem.level) && dropTarget.parentId !== draggedItem.parentId) {
			// Update parent and rank if drop child to another expanded epic
			return UPDATE_RANK_AND_PARENT;
		}
		// Update rank if drop issue in the same level
		return UPDATE_RANK;
	}
	if (
		isBaseLevel(draggedItem.level) &&
		!isBaseLevel(dropTarget.level) &&
		dropTarget.id !== draggedItem.parentId
	) {
		// Update parentId when drop child issue to epic directly
		return UPDATE_PARENT;
	}

	return undefined;
};

export const getIsDragChildOverParent = (itemLevel: number, overItemLevel: number): boolean =>
	isBaseLevel(itemLevel) && !isBaseLevel(overItemLevel);

export const getIsDragParentOverChild = (itemLevel: number, overItemLevel: number): boolean =>
	!isBaseLevel(itemLevel) && isBaseLevel(overItemLevel);

export const getTreeItemIsDragChildOverParent = (
	draggedItemLevel: number,
	dropTargetLevel: number,
): boolean => dropTargetLevel - draggedItemLevel === 1;

export const getIsDragIssueWithinOneLevel = (
	draggedItemLevel: number,
	dropTargetLevel: number,
): boolean => dropTargetLevel - draggedItemLevel === 0 || dropTargetLevel - draggedItemLevel === 1;

export const getIsDragIssueOnSameLevel = (
	draggedItemLevel: number,
	dropTargetLevel: number,
): boolean => dropTargetLevel === draggedItemLevel;

export const getIsDragOneLevelBelow = (
	draggedItemLevel: number,
	dropTargetLevel: number,
): boolean => dropTargetLevel - draggedItemLevel === 1;

export const getBlockedInstructions = ({
	dropTarget,
	draggedItem,
}: {
	dropTarget: Item;
	draggedItem: Item;
}): BlockedInstructions => {
	const { level: dropTargetLevel, parentId: dropTargetParentId } = dropTarget;
	const { level: draggedItemLevel } = draggedItem;

	const isOnSameLevel = getIsDragIssueOnSameLevel(draggedItemLevel, dropTargetLevel);
	const isWithinOneLevel = getIsDragIssueWithinOneLevel(draggedItemLevel, dropTargetLevel);
	const isOneLevelBelow = getIsDragOneLevelBelow(draggedItemLevel, dropTargetLevel);
	const isSubTask = draggedItemLevel === SUBTASK_HIERARCHY_LEVEL;
	// Should not reparent when drop on same level
	if (isOnSameLevel) {
		return ['make-child'];
	}

	// When dropTargetLevel -  draggedItemLevel > 1 or dropTargetLevel < draggedItemLevel
	if (!isWithinOneLevel) {
		if (dropTargetParentId || isSubTask) {
			// Should block all instructions when dropTarget has parent
			return ['make-child', 'reorder-below', 'reorder-above'];
		}
		// Should allow re-rank when dropTarget is root level
		return ['make-child'];
	}
	// When drag item to one level below
	if (isOneLevelBelow) {
		if (isSubTask) {
			// Should allow reparent when drop one level below
			return ['reorder-below', 'reorder-above'];
		}
		if (dropTargetParentId) {
			// Should allow re-parent when dropTarget has parent
			return ['reorder-below', 'reorder-above'];
		}
	}

	return [];
};
