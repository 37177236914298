import React, { useCallback, useRef } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import type { IssueId } from '@atlassian/jira-shared-types';
import { RoadmapBarIcon } from '@atlassian/jira-software-roadmap-timeline-table-kit';
import type { Position } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/types';

type Props = {
	hasImplicitDependency: boolean;
	level: number;
	dependencies: IssueId[];
	dependees: IssueId[];
	onClickIcon: (
		fromIds: IssueId[],
		toIds: IssueId[],
		position: Position,
		triggerRef?: React.RefObject<HTMLElement>,
	) => void;
};

const BarIcon = ({ level, dependencies, dependees, hasImplicitDependency, onClickIcon }: Props) => {
	const triggerRef = useRef<HTMLDivElement>(null);

	const onClick = useCallback(
		(position: Position) => {
			if (fg('jsw_roadmaps_timeline-dependency-flyout-refocus')) {
				onClickIcon(dependencies, dependees, position, triggerRef);
			} else {
				onClickIcon(dependencies, dependees, position);
			}
		},
		[dependencies, dependees, onClickIcon],
	);

	if (dependencies.length === 0 && dependees.length === 0 && !hasImplicitDependency) {
		return null;
	}

	return (
		<RoadmapBarIcon
			level={level}
			onClick={onClick}
			{...(fg('jsw_roadmaps_timeline-dependency-flyout-refocus') ? { ref: triggerRef } : {})}
		/>
	);
};

export default BarIcon;
