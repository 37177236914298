/* The browser can attempt to schedule more than one animation frame on the same tick of the event loop.
 * In this case, we should cancel the already scheduled one to avoid redundant work.
 * The createRaf utility encapsulates this behaviour.
 */
export const createRaf = () => {
	let frameId: number | null = null;

	return (callback: () => void): number => {
		if (frameId) {
			cancelAnimationFrame(frameId);
		}

		frameId = requestAnimationFrame(callback);

		return frameId;
	};
};
