import { createSelector } from 'reselect';
import type { AccountId } from '@atlassian/jira-shared-types';
import type { Person } from '@atlassian/jira-software-filters';
import { createSelectorWithDefaultEqual } from '../../../../../common/utils/reselect';
import { getLoggedInAccountId } from '../../../../app/selectors';
import { getIssueIds, getIssueAssigneeHash } from '../../../../entities/issues/selectors';
import { getFullUserHash } from '../../../../entities/users/selectors';

export const getSortedAssigneeIds = createSelector(
	getLoggedInAccountId,
	getIssueIds,
	getIssueAssigneeHash,
	(loggedInUserId, issueIds, assigneeHash) => {
		const assigneeSet = new Set<AccountId>([loggedInUserId]);

		// reverse mutates an original array, so it should be cloned first
		Array.from(issueIds)
			.reverse()
			.forEach((issueId) => {
				const assignee = assigneeHash[issueId];
				if (assignee) {
					assigneeSet.add(assignee);
				}
			});

		return Array.from(assigneeSet.values());
	},
);

export const getAssignees = createSelectorWithDefaultEqual(
	getSortedAssigneeIds,
	getFullUserHash,
	(assigneeIds, usersHash): Person[] => {
		const assignees: Person[] = [];

		assigneeIds.forEach((assigneeId) => {
			const user = usersHash[assigneeId];
			if (assigneeId && user) {
				assignees.push({
					id: assigneeId,
					displayName: user.displayName,
					avatarUrl: user.avatarUrls['48x48'],
				});
			}
		});

		return assignees;
	},
);
