/**
 * @generated SignedSource<<aaf856764ab7f6d3002ddce971058f8f>>
 * @relayHash 800f7f5d14028fe401831f044c0b82ed
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 33a869b2348eba3530cb6f638df8e5e0d286115c291912fded9f10e48df8e18a

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type RoadmapResolveHealthcheckInput = {
  actionId: string;
  addLevelOneIssueType?: RoadmapAddLevelOneIssueTypeHealthcheckResolution | null | undefined;
};
export type RoadmapAddLevelOneIssueTypeHealthcheckResolution = {
  create?: RoadmapCreateLevelOneIssueType | null | undefined;
  promote?: RoadmapPromoteLevelOneIssueType | null | undefined;
};
export type RoadmapCreateLevelOneIssueType = {
  epicTypeDescription: string;
  epicTypeName: string;
};
export type RoadmapPromoteLevelOneIssueType = {
  promoteItemTypeId: string;
};
export type resolveHealthcheckMutation$variables = {
  input: RoadmapResolveHealthcheckInput;
  sourceARI: string;
};
export type resolveHealthcheckMutation$data = {
  readonly roadmaps: {
    readonly resolveRoadmapHealthcheck: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly errorType?: string | null | undefined;
          readonly statusCode?: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type resolveHealthcheckMutation = {
  response: resolveHealthcheckMutation$data;
  variables: resolveHealthcheckMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceARI"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  },
  {
    "kind": "Variable",
    "name": "sourceARI",
    "variableName": "sourceARI"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "success"
},
v4 = {
  "kind": "ScalarField",
  "name": "message"
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "statusCode"
    },
    {
      "kind": "ScalarField",
      "name": "errorType"
    }
  ],
  "type": "RoadmapMutationErrorExtension"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "resolveHealthcheckMutation",
    "selections": [
      {
        "concreteType": "RoadmapsMutation",
        "kind": "LinkedField",
        "name": "roadmaps",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "RoadmapResolveHealthcheckPayload",
            "kind": "LinkedField",
            "name": "resolveRoadmapHealthcheck",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "resolveHealthcheckMutation",
    "selections": [
      {
        "concreteType": "RoadmapsMutation",
        "kind": "LinkedField",
        "name": "roadmaps",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "RoadmapResolveHealthcheckPayload",
            "kind": "LinkedField",
            "name": "resolveRoadmapHealthcheck",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v5/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "id"
                          }
                        ],
                        "type": "BulkMutationErrorExtension"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "33a869b2348eba3530cb6f638df8e5e0d286115c291912fded9f10e48df8e18a",
    "metadata": {},
    "name": "resolveHealthcheckMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "7937429ceae92519546484200a3f3d14";

export default node;
