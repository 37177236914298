import { createContext, useContext } from 'react';

export interface PortalScopeProps {
	portalContainerElement: Element | null;
	setPortalContainerElement: (portalContainer: Element | null) => void;
}

const PortalScopeContext = createContext<PortalScopeProps>({
	setPortalContainerElement: () => undefined,
	portalContainerElement: null,
});

const usePortalScope = () => useContext(PortalScopeContext);

export { PortalScopeContext, usePortalScope };
