/**
 * @generated SignedSource<<d01bf4d7d86d5870e98ee63e1ddbd473>>
 * @relayHash 76fa5e70860be87b0aea6f6c5d2ab7f9
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 27d267d70916c4190d559166d5b624c365e56a06e45c7cd51a1a29372491ea76

import type { ConcreteRequest, Query } from 'relay-runtime';
export type filterRoadmapItemsQuery$variables = {
  customFilterIds?: ReadonlyArray<string> | null | undefined;
  quickFilterIds?: ReadonlyArray<string> | null | undefined;
  sourceAri: string;
};
export type filterRoadmapItemsQuery$data = {
  readonly roadmaps: {
    readonly roadmapFilterItems: ReadonlyArray<string>;
  } | null | undefined;
};
export type filterRoadmapItemsQuery = {
  response: filterRoadmapItemsQuery$data;
  variables: filterRoadmapItemsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customFilterIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "quickFilterIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceAri"
},
v3 = [
  {
    "concreteType": "RoadmapsQuery",
    "kind": "LinkedField",
    "name": "roadmaps",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "customFilterIds",
            "variableName": "customFilterIds"
          },
          {
            "kind": "Variable",
            "name": "quickFilterIds",
            "variableName": "quickFilterIds"
          },
          {
            "kind": "Variable",
            "name": "sourceARI",
            "variableName": "sourceAri"
          }
        ],
        "kind": "ScalarField",
        "name": "roadmapFilterItems"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "filterRoadmapItemsQuery",
    "selections": (v3/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "filterRoadmapItemsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "27d267d70916c4190d559166d5b624c365e56a06e45c7cd51a1a29372491ea76",
    "metadata": {},
    "name": "filterRoadmapItemsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "19827cdd137051897ba5e27b5db7eeae";

export default node;
