import type { RealtimeEvent } from '@atlassian/jira-realtime';

export const REALTIME_DISPATCH_EVENT = 'state.realtime.REALTIME_DISPATCH_EVENT' as const;

export type RealtimeDispatchEventAction = {
	type: typeof REALTIME_DISPATCH_EVENT;
	payload: RealtimeEvent;
};

export const dispatchRealtimeEvent = (event: RealtimeEvent): RealtimeDispatchEventAction => ({
	type: REALTIME_DISPATCH_EVENT,
	payload: event,
});
