import { connect } from '@atlassian/jira-react-redux';
import { RoadmapTimelineMode } from '@atlassian/jira-software-roadmap-timeline-table-kit';
import type { TimelineMode } from '@atlassian/jira-software-roadmap-timeline-table/src/types';
import { setTimelineMode } from '../../../../../../state/ui/filters/actions';

const onClick = (timelineMode: TimelineMode) => setTimelineMode(timelineMode);

export default connect(null, {
	onClick,
})(RoadmapTimelineMode);
