import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import { batchActions } from 'redux-batched-actions';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { State } from '../../state/types';
import {
	CLEAR_FILTER_AND_SETTING,
	type ClearFilterAndSettingAction as Action,
} from '../../state/ui/filters/actions';
import { clearLevelOneSetting } from '../../state/ui/views/actions';
import type { StateEpic } from '../common/types';
import updateQuery from '../common/update-query';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
	{ router: { push } },
) =>
	action$.ofType(CLEAR_FILTER_AND_SETTING).mergeMap(() =>
		Observable.of(
			batchActions([
				clearLevelOneSetting(),
				updateQuery(push, store.getState(), (query) => ({
					...query,
					statuses: undefined,
					assignee: undefined,
					issueType: undefined,
					text: undefined,
					label: undefined,
					issueParent: undefined,
					epic: undefined,
					version: undefined,
					component: undefined,
					quickFilter: undefined,
					customFilter: undefined,
				})),
			]),
		),
	)) as StateEpic;
