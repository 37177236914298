import { defineMessages } from 'react-intl-next';

export default defineMessages({
	defaultLabel: {
		id: 'roadmap.timeline-table.common.context.side-effect-marshal.default-label',
		defaultMessage: 'Timeline',
		description:
			"Default label for assistive technology. Describes in short the purpose of the Timeline's table component",
	},
});
