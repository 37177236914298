import React, { forwardRef, type Ref } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import Lozenge from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { ff } from '@atlassian/jira-feature-flagging-using-meta';
import { FlagIcon } from './flag-icon';
import type { ItemStatus, ItemAssignee } from './types';

type Props = {
	status: ItemStatus | undefined;
	assignee: ItemAssignee | undefined;
	flagged: boolean;
};

const TooltipTag = forwardRef<HTMLElement>((props, ref) => (
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	<AvatarContainer ref={ref as Ref<HTMLDivElement>} data-export-assignee {...props} />
));

const RoadmapListItemSecondaryContent = ({ status, assignee, flagged }: Props) => {
	if (ff('jsw.timeline.return-flagged-attribute-fe')) {
		if (!status && !assignee && !flagged) {
			return null;
		}
	} else if (!status && !assignee) {
		return null;
	}

	return (
		<Container>
			{ff('jsw.timeline.return-flagged-attribute-fe') && flagged && <FlagIcon />}
			{status && (
				<Lozenge
					appearance={status.appearance}
					maxWidth={85}
					testId="roadmap.timeline-table-kit.ui.list-item-secondary-content.export-status"
				>
					{status.name}
				</Lozenge>
			)}
			{assignee && (
				<Tooltip content={assignee.name} tag={TooltipTag}>
					<Avatar
						testId="roadmap.timeline-table-kit.ui.list-item-secondary-content.export-assignee" // NOTE: The testId is used by ADS Avatar component to generate testId for avatar name which is used by the image export in src/packages/software/roadmap/image-export/src/export/common/utils/convert-rasterisable-table.tsx
						appearance="circle"
						size="xsmall"
						src={assignee.avatarUrl}
						name={assignee.name}
						borderColor="transparent"
					/>
				</Tooltip>
			)}
		</Container>
	);
};

export { RoadmapListItemSecondaryContent };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'inline-flex',
	alignItems: 'center',
	marginLeft: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarContainer = styled.div({
	marginBottom: token('space.negative.025', '-2px'),
	marginLeft: token('space.100', '8px'),
});
