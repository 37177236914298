import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl';
import type { IssueId, IssueStatusCategoryId } from '@atlassian/jira-shared-types';
import type {
	Status,
	Hash,
	IdentifiableHash,
	Sprint,
} from '@atlassian/jira-software-roadmap-model';
import {
	START_AND_DUE_DATE_PLACEHOLDER,
	START_DATE_PLACEHOLDER,
	DUE_DATE_PLACEHOLDER,
} from '@atlassian/jira-software-roadmap-timeline-table-kit';
import {
	START_DATE_LATER_DUE_DATE_MESSAGE_ID,
	END_DATE_LATER_RELEASE_DATE_MESSAGE_ID,
	PARENT_STARTS_AFTER_CHILD_MESSAGE_ID,
	PARENT_ENDS_BEFORE_CHILD_MESSAGE_ID,
	PARENT_STARTS_AFTER_CHILD_AND_ENDS_BEFORE_CHILD_MESSAGE_ID,
	SPRINT_ENDS_BEFORE_CHILD_MESSAGE_ID,
} from '../../../../../constants';
import { getParentLevelDates, getBaseLevelDates } from '../../common';
import type { RolledUpDates, BoundaryDates } from '../../dates';
import warningMessages from '../messages';
import { isDateAfter, BOTH, START, DUE, getErrorForDatesOutsideBoundary } from './utils';

/**
 * Checks if any child issue is outside range of parent issue.
 */

export const getChildIssueDateOutsideParentIssuesWarning = (
	startDate?: number,
	dueDate?: number,
	rolledUpBaseLevelStartDate?: number,
	rolledUpBaseLevelDueDate?: number,
	rolledUpLatestStartDate?: number,
	rolledUpEarliestDueDate?: number,
): MessageDescriptor | undefined => {
	const rolledUpStartDate =
		rolledUpLatestStartDate !== undefined &&
		dueDate !== undefined &&
		rolledUpLatestStartDate > dueDate
			? rolledUpLatestStartDate
			: rolledUpBaseLevelStartDate;

	const rolledUpDueDate =
		rolledUpEarliestDueDate !== undefined &&
		startDate !== undefined &&
		rolledUpEarliestDueDate < startDate
			? rolledUpEarliestDueDate
			: rolledUpBaseLevelDueDate;

	const warningMapKey = getErrorForDatesOutsideBoundary(
		rolledUpStartDate,
		rolledUpDueDate,
		startDate,
		dueDate,
	);

	const warningMessageIdMap = new Map([
		[BOTH, PARENT_STARTS_AFTER_CHILD_AND_ENDS_BEFORE_CHILD_MESSAGE_ID],
		[START, PARENT_STARTS_AFTER_CHILD_MESSAGE_ID],
		[DUE, PARENT_ENDS_BEFORE_CHILD_MESSAGE_ID],
	]);

	const warningMessageId = warningMapKey && warningMessageIdMap.get(warningMapKey);

	return warningMessageId !== undefined ? warningMessages[warningMessageId] : undefined;
};

export const createGetIssueDateWarningsHash = (
	doneStatusCategoryId: IssueStatusCategoryId | undefined,
	issueStatusHash: Hash<Status | undefined>,
	issueStartDateHash: Hash<number | undefined>,
	issueDueDateHash: Hash<number | undefined>,
	parentIdHash: Hash<IssueId | undefined>,
	parentRolledUpDatesHash: Hash<RolledUpDates>,
	boundaryDates: IdentifiableHash<IssueId, BoundaryDates>,
	isSprintsPlanning: boolean,
	isChildIssuePlanningEnabled: boolean,
	issueEdgeReleaseDatesVersionHash: Hash<[number | undefined, number | undefined]>,
	issueSprintIdsHash: IdentifiableHash<IssueId, Sprint[]>,
	isReleasesFeatureEnabled: boolean,
): Hash<MessageDescriptor[]> => {
	const result: Record<IssueId, MessageDescriptor[]> = {};

	Object.keys(issueStartDateHash).forEach((issueId: IssueId) => {
		result[issueId] = [];

		if (
			doneStatusCategoryId &&
			issueStatusHash[issueId]?.statusCategoryId === doneStatusCategoryId
		) {
			return;
		}

		const explicitStartDate = issueStartDateHash[issueId];
		const explicitDueDate = issueDueDateHash[issueId];
		const sprints = issueSprintIdsHash[issueId] || [];
		const isParentIssue = parentIdHash[issueId] === undefined;

		let startDate;
		let dueDate;
		let placeholder;
		let childExceedsRolledUpParent;

		if (isParentIssue) {
			const rolledUpDates = parentRolledUpDatesHash[issueId] || {};
			const {
				startDate: parentLevelStartDate,
				dueDate: parentLevelDueDate,
				placeholder: parentLevelPlaceholder,
				childExceedsRolledUpParent: parentChildExceedsRolledUpParent,
			} = getParentLevelDates(
				explicitStartDate,
				explicitDueDate,
				rolledUpDates,
				isChildIssuePlanningEnabled,
			);

			startDate = parentLevelStartDate;
			dueDate = parentLevelDueDate;
			placeholder = parentLevelPlaceholder;
			childExceedsRolledUpParent = parentChildExceedsRolledUpParent;
		} else {
			const {
				startDate: baseLevelStartDate,
				dueDate: baseLevelDueDate,
				placeholder: baseLevelPlaceholder,
			} = getBaseLevelDates(
				explicitStartDate,
				explicitDueDate,
				sprints,
				isSprintsPlanning,
				isChildIssuePlanningEnabled,
			);

			startDate = baseLevelStartDate;
			dueDate = baseLevelDueDate;
			placeholder = baseLevelPlaceholder;
		}

		// start date after due date warning
		if (isDateAfter(explicitStartDate, explicitDueDate) || childExceedsRolledUpParent) {
			result[issueId].push(warningMessages[START_DATE_LATER_DUE_DATE_MESSAGE_ID]);
		}

		// child outside of parent warning
		const shouldShowChildWarning = isParentIssue && isChildIssuePlanningEnabled;

		if (shouldShowChildWarning) {
			let parentDateError;

			const isStartDatePlaceholder =
				placeholder === START_DATE_PLACEHOLDER || placeholder === START_AND_DUE_DATE_PLACEHOLDER;

			const isDueDatePlaceholder =
				placeholder === DUE_DATE_PLACEHOLDER || placeholder === START_AND_DUE_DATE_PLACEHOLDER;

			const dateRangeBoundary = boundaryDates[issueId];

			const latestStartDate = parentRolledUpDatesHash[issueId]?.latestStartDate;
			const earliestDueDate = parentRolledUpDatesHash[issueId]?.earliestDueDate;

			if (dateRangeBoundary) {
				parentDateError = getChildIssueDateOutsideParentIssuesWarning(
					!isStartDatePlaceholder ? startDate : undefined,
					!isDueDatePlaceholder ? dueDate : undefined,
					dateRangeBoundary.startDateBoundary,
					dateRangeBoundary.dueDateBoundary,
					latestStartDate,
					earliestDueDate,
				);
			}

			if (parentDateError !== undefined) {
				result[issueId].push(parentDateError);
			}
		}

		// child outside of sprint warning (check end date only as the issue may be carried over from previous sprints)
		const shouldShowChildSprintWarning =
			!isParentIssue && isChildIssuePlanningEnabled && sprints.length > 0;

		if (shouldShowChildSprintWarning) {
			const { endDate: maxSprintDate } = sprints[0];

			const shouldShowWarning =
				maxSprintDate !== undefined &&
				dueDate !== undefined &&
				(!placeholder || placeholder === START_DATE_PLACEHOLDER) &&
				isDateAfter(dueDate, maxSprintDate);

			if (shouldShowWarning) {
				const sprintWarningMessage = warningMessages[SPRINT_ENDS_BEFORE_CHILD_MESSAGE_ID];

				result[issueId].push(sprintWarningMessage);
			}
		}

		// end date after release date warning
		if (isReleasesFeatureEnabled) {
			const [minReleaseDate, maxReleaseDate] = issueEdgeReleaseDatesVersionHash[issueId];
			const shouldShowWarning = isSprintsPlanning
				? minReleaseDate !== undefined &&
					startDate !== undefined &&
					!placeholder &&
					isDateAfter(startDate, minReleaseDate)
				: maxReleaseDate !== undefined &&
					dueDate !== undefined &&
					!placeholder &&
					isDateAfter(dueDate, maxReleaseDate);

			if (shouldShowWarning) {
				const releasesWarningMessage = warningMessages[END_DATE_LATER_RELEASE_DATE_MESSAGE_ID];

				result[issueId].push(releasesWarningMessage);
			}
		}
	});

	return result;
};
