import forEach from 'lodash/forEach';
import { ff } from '@atlassian/jira-feature-flagging-using-meta';
import type {
	IssueId,
	AccountId,
	IssueStatusCategoryId,
	IssueTypeId,
} from '@atlassian/jira-shared-types';
import { UNASSIGNED_USER_ID } from '@atlassian/jira-software-filters';
import {
	BASE_LEVEL,
	PARENT_LEVEL,
	type IssueType,
	type IssueTypeHash,
	type Hash,
	type Filter,
	type Status,
	type CreationPreferences,
} from '@atlassian/jira-software-roadmap-model';
import type {
	ItemType,
	ItemTypes,
	ItemStatus,
	ItemAssignee,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/types';
import { STATUS_CATEGORY_APPEARENCE } from '../../../../model/status';

/* When specific filters are applied, a newly created issue can be pre-populated with the value of those filters.
 * To provide a hint to the user, we display a count of the number of effective filters.
 */
export const getFilteredCreateCountPure = ({
	assignees,
	labels,
	versions,
	components,
}: Filter): number => {
	const assigneesWithoutUnassigned = assignees.filter(
		(assignee) => assignee !== UNASSIGNED_USER_ID,
	);

	// Any effective filter contributes at most once towards the count
	const filteredCreateCount =
		Math.min(assigneesWithoutUnassigned.length, 1) +
		Math.min(labels.length, 1) +
		Math.min(versions.length, 1) +
		Math.min(components.length, 1);

	return filteredCreateCount;
};

export const getItemTypesByLevelPure = (
	issueTypeHash: IssueTypeHash,
	epicIssueTypeIds: IssueTypeId[],
	creationPreferences: CreationPreferences,
	defaultItemTypeId?: IssueTypeId,
): ItemTypes => {
	const itemTypes: ItemTypes = { [BASE_LEVEL]: [], [PARENT_LEVEL]: [] };

	// Construct a lookup of item types per level
	forEach(issueTypeHash, (issueType: IssueType, issueTypeId: IssueTypeId) => {
		const level = epicIssueTypeIds.includes(issueTypeId) ? 1 : 0;
		itemTypes[level].push({
			id: issueTypeId,
			iconUrl: issueType.iconUrl,
			name: issueType.name,
		});
	});

	// Sort the item types by alphabetical ascending, with the preferred item type to the top
	forEach(itemTypes, (itemTypeForLevel: ItemType[], level: string) => {
		itemTypeForLevel.sort((itemType1: ItemType, itemType2: ItemType) => {
			const creationPreferenceForLevel = creationPreferences.itemTypes[Number(level)];
			const itemTypeName1 = itemType1.name.toUpperCase();
			const itemTypeName2 = itemType2.name.toUpperCase();

			// Check by creation preference
			if (creationPreferenceForLevel) {
				if (creationPreferenceForLevel.itemTypeId === itemType1.id) {
					return -1;
				}
				if (creationPreferenceForLevel.itemTypeId === itemType2.id) {
					return 1;
				}
			}

			// if no preference, then check by default item type
			if (defaultItemTypeId) {
				if (defaultItemTypeId === itemType1.id) {
					return -1;
				}
				if (defaultItemTypeId === itemType2.id) {
					return 1;
				}
			}

			// If no preference and default item type, then check by name
			if (itemTypeName1 < itemTypeName2) {
				return -1;
			}
			if (itemTypeName1 > itemTypeName2) {
				return 1;
			}

			return 0;
		});
	});

	return itemTypes;
};

export const getSecondaryFieldsHashPure = (
	issueIds: IssueId[],
	parentIdHash: Hash<IssueId | undefined>,
	issueStatusHash: Hash<Status | undefined>,
	issueAssigneeIdHash: Hash<AccountId | undefined>,
	userAvatarUrlHash: Hash<string>,
	userDisplayNameHash: Hash<string>,
	doneStatusCategoryId: IssueStatusCategoryId | undefined,
	issueFlaggedHash: Hash<boolean>,
): Hash<{
	status: ItemStatus;
	assignee: ItemAssignee;
	flagged: boolean;
}> => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const issueSecondaryFieldHash: Record<string, any> = {};

	issueIds.forEach((id) => {
		// Raw fields associated with the issue
		const issueStatus = issueStatusHash[id];
		const issueAssigneeId = issueAssigneeIdHash[id];
		const flagged = issueFlaggedHash[id];

		// Fields as to be displayed. Different for parent and child issues.
		let status;
		let assignee;
		let mapItem;

		if (issueStatus) {
			const { name, statusCategoryId } = issueStatus;
			const appearance = STATUS_CATEGORY_APPEARENCE[statusCategoryId];
			status = { name, appearance };
		}

		if (issueAssigneeId) {
			const name = userDisplayNameHash[issueAssigneeId];
			const avatarUrl = userAvatarUrlHash[issueAssigneeId];
			assignee = { name, avatarUrl };
		}

		if (parentIdHash[id]) {
			mapItem = { status, assignee };
		} else if (issueStatus?.statusCategoryId === doneStatusCategoryId) {
			mapItem = { status };
		}

		issueSecondaryFieldHash[`${id}`] = {
			...mapItem,
			flagged: ff('jsw.timeline.return-flagged-attribute-fe') && !!flagged,
		};
	});

	return issueSecondaryFieldHash;
};
