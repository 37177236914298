import React, { type ComponentType, type ReactElement } from 'react';
import noop from 'lodash/noop';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl';
import type { Color } from '@atlassian/jira-issue-epic-color';
import { EXPLICIT, DEFAULT_RENDERER } from '../../common/constants';
import type {
	DateType,
	Placeholder,
	Interval,
	OnChartItemClick,
	OnChartItemUpdate,
	Position,
} from '../../common/types';
import { DateContent, type BarContentProps, type BarContentState } from './date-content';
import { IntervalDropZones } from './interval-drop-zones';
import { NoDateContent } from './no-date-content';

type Props = {
	isHovered: boolean;
	isReadOnly: boolean;
	id: string;
	startDate?: number;
	dueDate?: number;
	level: number;
	startDateType: DateType;
	dueDateType: DateType;
	placeholder?: Placeholder;
	color: Color;
	warnings: MessageDescriptor[];
	intervals: ReadonlyArray<Interval>;
	renderBarContent: (props: BarContentProps, state: BarContentState) => ReactElement | null;
	DndLongTaskMetric: ComponentType<{
		isDragging: boolean;
		level: number;
	}>;
	onLeftClick: OnChartItemClick;
	onRightClick?: (id: string, position: Position) => void;
	onUpdate: OnChartItemUpdate;
};

const RoadmapChartItemContent = ({
	id,
	startDate,
	dueDate,
	color,
	level,
	startDateType,
	dueDateType,
	placeholder,
	DndLongTaskMetric,
	warnings,
	intervals,
	isHovered,
	isReadOnly,
	onLeftClick,
	onRightClick,
	onUpdate,
	renderBarContent,
}: Props) => {
	if (startDate !== undefined && dueDate !== undefined) {
		return (
			<>
				{!isReadOnly && intervals.length > 0 && <IntervalDropZones id={id} intervals={intervals} />}
				<DateContent
					id={id}
					color={color}
					level={level}
					startDate={startDate}
					dueDate={dueDate}
					startDateType={startDateType}
					dueDateType={dueDateType}
					warnings={warnings}
					placeholder={placeholder}
					isHovered={isHovered}
					isReadOnly={isReadOnly}
					onLeftClick={onLeftClick}
					onRightClick={onRightClick}
					onUpdate={onUpdate}
					renderBarContent={renderBarContent}
					DndLongTaskMetric={DndLongTaskMetric}
				/>
			</>
		);
	}

	return !isReadOnly ? (
		<>
			<NoDateContent
				id={id}
				level={level}
				color={color}
				startDateType={startDateType}
				dueDateType={dueDateType}
				isHovered={isHovered}
				onUpdate={onUpdate}
			>
				{intervals.length > 0 && (
					<IntervalDropZones id={id} intervals={intervals} onClick={onUpdate} />
				)}
			</NoDateContent>
		</>
	) : null;
};

RoadmapChartItemContent.defaultProps = {
	startDateType: EXPLICIT,
	dueDateType: EXPLICIT,
	DndLongTaskMetric: () => null,
	warnings: [],
	intervals: [],
	isReadOnly: false,
	onLeftClick: noop,
	renderBarContent: DEFAULT_RENDERER,
};

export { RoadmapChartItemContent };
