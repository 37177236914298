import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import { batchActions } from 'redux-batched-actions';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type {
	resolveHealthcheckMutation$data,
	RoadmapAddLevelOneIssueTypeHealthcheckResolution,
} from '@atlassian/jira-relay/src/__generated__/resolveHealthcheckMutation.graphql';
import type { HealthcheckResolutionSelection } from '@atlassian/jira-software-roadmap-model';
import {
	type CriticalData,
	getCriticalDataObservable,
	resolveHealthcheckFromServer,
} from '@atlassian/jira-software-roadmap-services';
import { getSourceARI } from '../../state/app/selectors';
import { aggError } from '../../state/flags/actions';
import { getQuery } from '../../state/router/selectors';
import type { State } from '../../state/types';
import {
	RESOLVE_HEALTHCHECK,
	type ResolveHealthcheckAction,
	setResolving,
} from '../../state/ui/healthcheck/actions';
import { onHandleAggErrors } from '../common/handle-agg-errors';
import { getCriticalDataActions } from '../common/initialize';
import type { StateEpic } from '../common/types';

const transformLevelOneSelection = (
	levelOneIssueTypeSelection?: HealthcheckResolutionSelection,
): RoadmapAddLevelOneIssueTypeHealthcheckResolution | undefined => {
	if (levelOneIssueTypeSelection) {
		if (levelOneIssueTypeSelection.issueTypeToPromote) {
			return {
				promote: {
					promoteItemTypeId: String(levelOneIssueTypeSelection.issueTypeToPromote),
				},
			};
		}
		if (
			levelOneIssueTypeSelection.epicName !== undefined &&
			levelOneIssueTypeSelection.epicDescription !== undefined
		) {
			return {
				create: {
					epicTypeName: levelOneIssueTypeSelection.epicName,
					epicTypeDescription: levelOneIssueTypeSelection.epicDescription,
				},
			};
		}
	}
	return undefined;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((
	action$: ActionsObservable<ResolveHealthcheckAction>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(RESOLVE_HEALTHCHECK).mergeMap((action: ResolveHealthcheckAction) => {
		const state = store.getState();
		const sourceARI = getSourceARI(state);
		const resolutionActionId = action.payload.actionId;
		const addLevelOneIssueType: RoadmapAddLevelOneIssueTypeHealthcheckResolution | undefined =
			transformLevelOneSelection(action.payload.levelOneIssueTypeSelection);

		const resolveHealthcheckObservable = resolveHealthcheckFromServer(
			sourceARI,
			resolutionActionId,
			addLevelOneIssueType,
		)
			.mergeMap((response: resolveHealthcheckMutation$data) => {
				const resolveRoadmapHealthcheckData = response.roadmaps?.resolveRoadmapHealthcheck;

				const errors = resolveRoadmapHealthcheckData?.errors;
				const success = resolveRoadmapHealthcheckData?.success;

				if (success === false && errors?.length) {
					return onHandleAggErrors(!!resolveRoadmapHealthcheckData, errors).map((aggErrors) =>
						batchActions([setResolving(false), aggErrors]),
					);
				}

				fireTrackAnalytics(
					action.meta.analyticsEvent,
					'healthcheck resolved',
					'roadmapHealthcheck',
					{
						projectType: 'software',
						resolutionActionId,
					},
				);

				action.payload.refreshNavigationData();

				const query = getQuery(state);

				return getCriticalDataObservable(getSourceARI(state), query, undefined).mergeMap(
					(criticalData: CriticalData) =>
						Observable.of(
							batchActions([...getCriticalDataActions(criticalData), setResolving(false)]),
						),
				);
			})
			.catch(() => Observable.from([aggError(), setResolving(false)]));

		return Observable.concat(Observable.of(setResolving(true)), resolveHealthcheckObservable);
	})) as StateEpic;
