import type {
	Label,
	CustomFilterId as CustomFilterTypeId,
} from '@atlassian/jira-filters/src/common/types.tsx';
import type {
	ComponentId,
	IssueTypeId,
	AccountId,
	VersionId,
	IssueStatusCategoryId,
	IssueId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { QuickFilterId } from '../quick-filter';

export type Filter = {
	statuses: IssueStatusCategoryId[];
	assignees: AccountId[];
	issueTypes: IssueTypeId[];
	text: string;
	labels: Label[];
	issueParents: IssueId[];
	versions: VersionId[];
	components: ComponentId[];
	quickFilters: QuickFilterId[];
	customFilters: CustomFilterTypeId[];
	goals: string[];
};

// Level one display option filter on View settings panel
export const INCOMPLETE = 'INCOMPLETE' as const;
export const COMPLETE1M = 'COMPLETE1M' as const;
export const COMPLETE3M = 'COMPLETE3M' as const;
export const COMPLETE6M = 'COMPLETE6M' as const;
export const COMPLETE9M = 'COMPLETE9M' as const;
export const COMPLETE12M = 'COMPLETE12M' as const;

export type LevelOneQuerySetting =
	| typeof INCOMPLETE
	| typeof COMPLETE1M
	| typeof COMPLETE3M
	| typeof COMPLETE6M
	| typeof COMPLETE9M
	| typeof COMPLETE12M;

export type LevelOneSetting = {
	showCompleted: boolean;
	period: number;
};

export const periodToLevelOneQuerySettingsMap: Map<number, LevelOneQuerySetting> = new Map([
	[12, COMPLETE12M],
	[9, COMPLETE9M],
	[6, COMPLETE6M],
	[3, COMPLETE3M],
	[1, COMPLETE1M],
]);

export const levelOneQuerySettingsToPeriodMap: Map<LevelOneQuerySetting, number> = new Map([
	[COMPLETE12M, 12],
	[COMPLETE9M, 9],
	[COMPLETE6M, 6],
	[COMPLETE3M, 3],
	[COMPLETE1M, 1],
]);

export const DEFAULT_LEVEL_ONE_VIEW_SETTINGS = { showCompleted: true, period: 12 } as const;
