import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-legacy
import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context';
import { toBaseUrl, toIssueKey, toCloudId } from '@atlassian/jira-shared-types';
import type { DataProvider, DataProviderConfig, DataProviderMetrics } from '../model/data-provider';
import type { IssueServiceResult } from '../model/errors';
import type { Issue, IssueRemoteData } from '../model/issue';
import {
	fetchIssueRemoteData$,
	fetchIssueRemoteDataWithWhiteboards$,
} from '../source/issue-express';

const DEFAULT_EXPIRY_THRESHOLD_SECONDS = 60 * 60;
const DEFAULT_MAX_SIZE = 100;

const getTenantBaseUrl = () => {
	try {
		return toBaseUrl(getTenantContext_DEPRECATED_DO_NOT_USE().baseUrl);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return toBaseUrl('');
	}
};

const createConfig = (config: Partial<DataProviderConfig> = {}): DataProviderConfig => {
	const defaultConfig: DataProviderConfig = {
		expiryThresholdSeconds: DEFAULT_EXPIRY_THRESHOLD_SECONDS,
		maxSize: DEFAULT_MAX_SIZE,
		// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
		baseUrl: Object.prototype.hasOwnProperty.call(config, 'baseUrl')
			? config.baseUrl
			: getTenantBaseUrl(),
		ssrCachedIssue: null,
	};
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	return Object.assign({} as any, defaultConfig, config);
};

// exported just for tests
export const createSupressedDataProvider = (
	options: Partial<DataProviderConfig> = {},
): DataProvider => {
	const config = createConfig(options);
	const baseUrl = toBaseUrl(config.baseUrl);
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const clearCache = (): void => {};
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const insertSerializedIssue = () => {};

	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const loadIssues = () => {};

	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const loadIssuesById = () => {};

	// @ts-expect-error - TS2355 - A function whose declared type is neither 'void' nor 'any' must return a value.
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const getIssue = (): Issue | undefined => {};

	const loadIssue$ = (): Observable<IssueServiceResult> => Observable.empty<never>();

	const getIssue$ = (): Observable<IssueServiceResult> => Observable.empty<never>();

	const refreshIssue$ = (): Observable<IssueServiceResult> => loadIssue$();

	const getIssueRemoteData$ = (
		issueKey: string,
		cloudId: string,
		isIssueViewWhiteboardsStandaloneEnabled: boolean,
	): Observable<IssueRemoteData> =>
		isIssueViewWhiteboardsStandaloneEnabled
			? fetchIssueRemoteDataWithWhiteboards$(toIssueKey(issueKey), toCloudId(cloudId))
			: fetchIssueRemoteData$(baseUrl, toIssueKey(issueKey));

	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const updateIssue = (): void => {};

	const getMetrics = (): DataProviderMetrics => ({
		cacheSize: 0,
		cacheStaleCount: 0,
		cacheAvailableCapacity: 0,
		cacheHit: false,
		staleCacheHit: false,
	});

	return {
		clearCache,
		loadIssues,
		loadIssuesById,
		getIssue,
		getIssue$,
		getIssueRemoteData$,
		refreshIssue$,
		updateIssue,
		getMetrics,
		insertSerializedIssue,
	};
};

// store it as a singleton
let dataProvider: DataProvider;
export const getDataProvider = () => {
	if (dataProvider == null) {
		dataProvider = createSupressedDataProvider();
	}
	return dataProvider;
};
