/** @jsx jsx */
import React, { type ReactElement, type ComponentType } from 'react';
import { css, jsx } from '@compiled/react';
import { useHeaderState, zIndex } from '@atlassian/jira-software-roadmap-timeline-table';
import {
	RoadmapChartDragLayer,
	RoadmapChartOverlay,
	DEPENDENCY_DRAG_ITEM_ID,
} from '@atlassian/jira-software-roadmap-timeline-table-kit';
import type { DragLayerMeta } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/types';
import DependencyLinePreview from './dependency-line-preview';
import ChartOverlayButton from './overlay-button';
import type { DependencyDragItem } from './types';
import { getPreviewProps } from './utils';

type Props = {
	isEditEnabled: boolean;
	DndLongTaskMetric: ComponentType<{
		isDragging: boolean;
	}>;
};

const renderOverlayButton = () => <ChartOverlayButton />;

const ChartOverlay = ({ isEditEnabled, DndLongTaskMetric }: Props) => {
	const [{ headerHeight }] = useHeaderState();
	const renderPreview = (props: DragLayerMeta<DependencyDragItem>): ReactElement | null => {
		const previewProps = getPreviewProps(props);

		return previewProps ? <DependencyLinePreview {...previewProps} /> : null;
	};

	const renderChartDragLayer = () =>
		isEditEnabled ? (
			<RoadmapChartDragLayer
				type={DEPENDENCY_DRAG_ITEM_ID}
				renderPreview={renderPreview}
				DndLongTaskMetric={DndLongTaskMetric}
			/>
		) : null;

	return (
		<div css={dragLayerContainerStyles} style={{ top: headerHeight }}>
			<RoadmapChartOverlay
				renderChartDragLayer={renderChartDragLayer}
				renderOverlayButton={renderOverlayButton}
			/>
		</div>
	);
};

export default ChartOverlay;

const dragLayerContainerStyles = css({
	position: 'absolute',
	right: 0,
	bottom: 0,
	left: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.DRAGGABLE,
});
