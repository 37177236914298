import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { ROADMAP_CREATE_EPIC_CHILD_ISSUE_BUTTON_NUDGE_ID } from '@atlassian/jira-onboarding-core/src/constants';
import { OnboardingNudgeErrorBoundary } from '@atlassian/jira-onboarding-quickstart-core/src/common/ui/onboarding-nudge-error-boundary';
import type { QuickstartNudgeWrapperBaseProps } from '@atlassian/jira-onboarding-quickstart-core/src/common/ui/quickstart-nudge/types';
import Placeholder from '@atlassian/jira-placeholder';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const RoadmapCreateEpicChildIssueButtonLazy = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "async-software-quickstart-roadmap-create-child-issue-nudge" */ './index'
		).then((exportedModule) => exportedModule.RoadmapCreateEpicChildIssueButtonWrapperNudge),
	{
		ssr: false,
	},
);
export const RoadmapCreateEpicChildIssueButtonWrapperNudgeAsync = ({
	children,
}: QuickstartNudgeWrapperBaseProps) => (
	<OnboardingNudgeErrorBoundary
		id={ROADMAP_CREATE_EPIC_CHILD_ISSUE_BUTTON_NUDGE_ID}
		fallback={children}
		attributes={{
			nudgeId: ROADMAP_CREATE_EPIC_CHILD_ISSUE_BUTTON_NUDGE_ID,
		}}
	>
		<Placeholder
			name="software-quickstart-roadmap-create-child-issue-nudge"
			fallback={<>{children}</>}
		>
			<RoadmapCreateEpicChildIssueButtonLazy
				nudgeId={ROADMAP_CREATE_EPIC_CHILD_ISSUE_BUTTON_NUDGE_ID}
			>
				{children}
			</RoadmapCreateEpicChildIssueButtonLazy>
		</Placeholder>
	</OnboardingNudgeErrorBoundary>
);
