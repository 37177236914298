import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { createAri } from '@atlassian/jira-platform-ari';
import { getProjectIssueTypes } from '@atlassian/jira-software-roadmap-services';
import { aggError } from '../../state/flags/actions';
import {
	type RequestIssueTypesAction,
	REQUEST_ISSUE_TYPES,
	extendIssueTypes,
} from '../../state/ui/healthcheck/actions';
import type { StateEpic } from '../common/types';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<RequestIssueTypesAction>) =>
	action$.ofType(REQUEST_ISSUE_TYPES).mergeMap((action: RequestIssueTypesAction) => {
		const { cloudId, projectId } = action.payload;

		return getProjectIssueTypes(
			createAri({
				resourceOwner: 'jira',
				cloudId,
				resourceType: 'project',
				resourceId: String(projectId),
			}),
		)
			.map((issueTypes) => extendIssueTypes(issueTypes))
			.catch(() => Observable.of(aggError()));
	})) as StateEpic;
