import memoizeOne from 'memoize-one';
// eslint-disable-next-line jira/restricted/moment
import moment from 'moment';
import {
	DATE_FORMAT,
	MILLISECONDS_PER_DAY,
} from '@atlassian/jira-software-roadmap-model/src/datetime/index.tsx';

const HALF_DAY_IN_MS = MILLISECONDS_PER_DAY / 2;

export const getToday = memoizeOne((): number => {
	const now = new Date();
	const date = moment.utc().startOf('day');

	date.set({
		year: now.getFullYear(),
		month: now.getMonth(),
		date: now.getDate(),
	});

	return date.valueOf();
});

export const getStartOfDay = (date: number) => moment.utc(date).startOf('day').valueOf();

export const getClosestStartOfDay = (date: number) => {
	const newValue = getStartOfDay(date);

	if (date - newValue > HALF_DAY_IN_MS) {
		return newValue + HALF_DAY_IN_MS * 2;
	}

	return newValue;
};

export const getEndOfDay = (date: number): number => moment.utc(date).endOf('day').valueOf();

export const getClosestEndOfDay = (date: number): number => {
	const newValue = getEndOfDay(date);

	if (newValue - date > HALF_DAY_IN_MS) {
		return newValue - HALF_DAY_IN_MS * 2;
	}

	return newValue;
};

export const getClosestDay = (date: number): number =>
	Math.round(date / MILLISECONDS_PER_DAY) * MILLISECONDS_PER_DAY;

/**
 * Returns formatted string representation of an Epoch time.
 * @param timestamp Unix epoch time
 * @param format Optional format. Default is YYYY/MM/DD.
 */
export const getDateFromTimestamp = (timestamp: number, format: string = DATE_FORMAT): string =>
	moment.utc(timestamp).format(format);
