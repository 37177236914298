import { onDrag } from './drag';
import { onDragEnd } from './drag-end';
import { onDragStart } from './drag-start';
import { onLinger } from './linger';
import { onLingerEnd } from './linger-end';
import { onLingerStart } from './linger-start';
import { onPreview } from './preview';
import { onToggleHeaderModal } from './toggle-header-modal';
import type { Actions } from './types';

export const actions: Actions = {
	onDrag,
	onDragEnd,
	onDragStart,
	onLinger,
	onLingerEnd,
	onLingerStart,
	onPreview,
	onToggleHeaderModal,
};

export type { Actions };
