import { useViewport } from '@atlassian/jira-software-roadmap-timeline-table';
import { LEFT_AND_RIGHT } from '../../../constants';
import type { BarDragType, Placeholder, DateType } from '../../../types';
import type { Placement } from '../../formatted-date';
import { calculateDateVisibilities, getDatePlacement } from './utils';

type Props = {
	isDurationVisible: boolean;
	dragType: BarDragType | undefined;
	placeholder: Placeholder;
	startDateType: DateType;
	dueDateType: DateType;
	getBarBoundingClientRect: () => ClientRect | undefined;
};

export const useDatePlacement = ({
	dragType,
	placeholder,
	startDateType,
	dueDateType,
	isDurationVisible,
	getBarBoundingClientRect,
}: Props): Placement => {
	const { requestViewportBoundingClient } = useViewport();

	const rect = getBarBoundingClientRect();

	if (!rect) {
		return LEFT_AND_RIGHT;
	}

	const { isStartDateVisible, isDueDateVisible } = calculateDateVisibilities(
		rect,
		requestViewportBoundingClient(),
		placeholder,
		startDateType,
		dueDateType,
		isDurationVisible,
	);

	return getDatePlacement(dragType, isStartDateVisible, isDueDateVisible);
};
