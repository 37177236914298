import type { Location } from '@atlassian/jira-router';

/**
 * Router location change action
 */
export const ROUTER_LOCATION_CHANGE = 'ROUTER_LOCATION_CHANGE' as const;

export type RouterLocationChangePayload = Location;

export type RouterLocationChangeAction = {
	type: typeof ROUTER_LOCATION_CHANGE;
	payload: RouterLocationChangePayload;
};

export const routerLocationChange = (
	payload: RouterLocationChangePayload,
): RouterLocationChangeAction => ({
	type: ROUTER_LOCATION_CHANGE,
	payload,
});
