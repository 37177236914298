import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/map';
import type { ActionsObservable } from 'redux-observable';
import type { State } from '../../state/types';
import {
	SET_ISSUE_TYPE_FILTER,
	type SetIssueTypeFilterAction as Action,
} from '../../state/ui/filters/actions';
import type { StateEpic } from '../common/types';
import updateQuery from '../common/update-query';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
	{ router: { push } },
) =>
	action$.ofType(SET_ISSUE_TYPE_FILTER).map((action: Action) =>
		updateQuery(push, store.getState(), (query) => ({
			...query,
			issueType: action.payload.length > 0 ? action.payload : undefined,
		})),
	)) as StateEpic;
