import { defineMessages } from 'react-intl-next';

export default defineMessages({
	label: {
		id: 'roadmap.timeline-table.main.static-overlay.inline-indicators.create.label',
		defaultMessage: 'What needs to be done?',
		description:
			'Icon label. Appears when the epics list is too long. Activates epic creation input at the bottom of the list',
	},
});
