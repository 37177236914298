import React, { type ReactElement } from 'react';
import {
	type ApplicationLicenseState,
	type ApplicationLicenseStates,
	LICENSE_STATE_UNLICENSED,
} from '@atlassian/jira-shared-types/src/license-state.tsx';
import type { TenantContext } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

export type LicenseType = 'software' | 'servicedesk';
export type Props = {
	type: LicenseType;
	children: (arg1: { licenseState: ApplicationLicenseState }) => ReactElement;
};

const appLicenseMapper: Record<LicenseType, keyof ApplicationLicenseStates> = {
	software: 'software',
	servicedesk: 'serviceDesk',
};

export const checkLicenseState = (
	tenantContext: TenantContext,
	type: LicenseType,
): ApplicationLicenseState => {
	if (tenantContext.licenseStates) {
		return tenantContext.licenseStates[appLicenseMapper[type]];
	}

	return LICENSE_STATE_UNLICENSED;
};

export const LicenseStateCheck = ({ children, type }: Props) => (
	<TenantContextSubscriber>
		{({ tenantContext }) => {
			const licenseState = checkLicenseState(tenantContext, type);
			return children({ licenseState });
		}}
	</TenantContextSubscriber>
);
