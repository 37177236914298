import React, {
	useState,
	memo,
	useCallback,
	useMemo,
	useRef,
	useEffect,
	type KeyboardEvent,
	type MouseEvent,
	type DragEvent,
} from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { listItemMenu } from '../../../../../common/constants';
import {
	useEnablements,
	useListItemMenuOptions,
	useIsCreateTriggerActive,
} from '../../../../../common/context';
import type { ListItemOption, ListItemSection } from '../../../../../common/types';
import type { SetCloseMenuCallback } from '../../../../../common/types/item';
import { useOnClickOutside } from '../use-on-click-outside';
import MeatballPopup from './meatball-popup';
import type { MeatballsButtonProps } from './types';

const MeatBallsButton = ({
	isTriggerVisible = true,
	isMenuOpen,
	id,
	level,
	depth,
	parentId,
	isCreateChildEnabled,
	isCreateSiblingEnabled,
	renderListItemMenuOption,
	renderListItemModal,
	onStopHoverOrFocus,
	onInlineCreateClicked,
	onDrop,
	setIsMenuOpen,
	cellRef,
}: MeatballsButtonProps) => {
	const [activeOptionId, setActiveOptionId] = useState('');
	const [{ customSections }] = useListItemMenuOptions();
	const [{ isDragEnabled }] = useEnablements();
	const [isActiveMeatballTrigger] = useIsCreateTriggerActive({ id, type: 'MEATBALLS' });

	const returnFocus = useRef<boolean>(false);
	const ref = useRef<HTMLButtonElement>(null);
	const menuRef = useRef<HTMLDivElement>(null);

	const closeMenu = useCallback(() => {
		setIsMenuOpen(false);
	}, [setIsMenuOpen]);

	// Manages the refocusing of the meatballs trigger when the menu is closed
	useEffect(() => {
		if (!isMenuOpen && returnFocus.current) {
			returnFocus.current = false;
			// Timeout is added here to ensure refocusing works correctly in cypress tests.
			setTimeout(() => {
				ref.current?.focus();
			}, 0);
		}
	}, [isMenuOpen]);

	useOnClickOutside({
		cellRef,
		menuRef,
		triggerRef: ref,
		closeMenu,
		isMenuOpen,
		onStopHoverOrFocus,
	});

	const [customItemsSections, customOtherSections] = useMemo(() => {
		const itemsSections: ListItemSection[] = [];
		const otherSections: ListItemSection[] = [];

		customSections.forEach((section) => {
			if (section.id === listItemMenu.ITEMS_SECTION) {
				itemsSections.push(section);
			} else {
				otherSections.push(section);
			}
		});

		return [itemsSections, otherSections];
	}, [customSections]);

	const setCloseMenu: SetCloseMenuCallback = useCallback(
		(event, { shouldReturnFocus } = { shouldReturnFocus: true }) => {
			const isMouseClick = event?.detail && event.detail > 0;
			const shouldStopFocus = isMouseClick || shouldReturnFocus === false;

			returnFocus.current = !shouldStopFocus;
			setIsMenuOpen(false);

			if (shouldStopFocus) {
				onStopHoverOrFocus();
			}
		},
		[onStopHoverOrFocus, setIsMenuOpen],
	);

	const onMenuToggle = useCallback(() => {
		setIsMenuOpen((currentState) => !currentState);
	}, [setIsMenuOpen]);

	const setOpenModal = useCallback(
		(event: MouseEvent | KeyboardEvent, optionId: string) => {
			setCloseMenu(event);
			setActiveOptionId(optionId);
		},
		[setActiveOptionId, setCloseMenu],
	);

	const setCloseModal = useCallback(() => {
		setActiveOptionId('');
	}, []);

	const onStopPropagation = useCallback((event: MouseEvent | KeyboardEvent) => {
		event.stopPropagation();
	}, []);

	const onStopDragging = useCallback((event: DragEvent) => {
		event.preventDefault();
		event.stopPropagation();
	}, []);

	const prebuiltOptions = useMemo(() => {
		const options = [];
		if (isCreateChildEnabled || isCreateSiblingEnabled) {
			options.push({ id: listItemMenu.CREATE_ITEM });
		}
		if (fg('jsw_roadmap_timeline_meatball_move_menu')) {
			if (isDragEnabled) {
				options.push({ id: listItemMenu.MOVE_ITEM });
			}
		}
		return options;
	}, [isCreateChildEnabled, isCreateSiblingEnabled, isDragEnabled]);

	const issuesSectionOptions = useMemo(() => {
		const issuesSectionCustomOptions: ListItemOption[] = customItemsSections.reduce(
			(acc: ListItemOption[], { options }) => {
				acc.push(...options);
				return acc;
			},
			[],
		);
		return [...prebuiltOptions, ...issuesSectionCustomOptions];
	}, [customItemsSections, prebuiltOptions]);

	if (issuesSectionOptions.length > 0 || customSections.length > 0) {
		// NOTE: Neither wrappers below are interactive (and should be invisible to assistive technology)
		// They are event suppressors to stop any interactions seeping into the gridcell which will have unexpected behavior like triggering the issue view panel
		return (
			<>
				{/* eslint-disable-next-line @atlaskit/design-system/no-direct-use-of-web-platform-drag-and-drop */}
				<div role="presentation" onClick={onStopPropagation} draggable onDragStart={onStopDragging}>
					{isTriggerVisible || isActiveMeatballTrigger ? (
						<MeatballPopup
							id={id}
							isOpen={isMenuOpen}
							onMenuToggle={onMenuToggle}
							setCloseMenu={setCloseMenu}
							setOpenModal={setOpenModal}
							issuesSectionOptions={issuesSectionOptions}
							customOtherSections={customOtherSections}
							parentId={parentId}
							level={level}
							depth={depth}
							isCreateChildEnabled={isCreateChildEnabled}
							isCreateSiblingEnabled={isCreateSiblingEnabled}
							onInlineCreateClicked={onInlineCreateClicked}
							renderListItemMenuOption={renderListItemMenuOption}
							onDrop={onDrop}
							ref={ref}
							menuRef={menuRef}
						/>
					) : null}
				</div>
				<div role="presentation" onClick={onStopPropagation} onKeyUp={onStopPropagation}>
					{renderListItemModal?.({ id, optionId: activeOptionId, setCloseModal, triggerRef: ref })}
				</div>
			</>
		);
	}

	return null;
};

export default memo<MeatballsButtonProps>(MeatBallsButton);
