import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/empty';
import type { MiddlewareAPI } from 'redux';
import type { ActionsObservable } from 'redux-observable';
import { saveAs } from 'file-saver';
// eslint-disable-next-line jira/restricted/moment
import moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { log } from '@atlassian/jira-common-util-logging';
import { ff } from '@atlassian/jira-feature-flagging';
import type { IssueId } from '@atlassian/jira-shared-types';
import { onGenerateCsv } from '@atlassian/jira-software-roadmap-image-export';
import type { Issue } from '@atlassian/jira-software-roadmap-model';
import {
	getFullIssueTypeHash,
	getStartDateCustomFieldId,
	getSprintCustomFieldId,
	getColorCustomFieldId,
	getFieldsNamesHash,
	getIsSprintsFeatureEnabled,
	getProjectName,
} from '../../../state/configuration/selectors';
import { getFullIssueHash } from '../../../state/entities/issues/selectors';
import { getUserDisplayNameHash } from '../../../state/entities/users/selectors';
import { generalError } from '../../../state/flags/actions';
import { getFilteredIssueIds } from '../../../state/selectors/issues';
import { getSanitisedIssueSprintsHash } from '../../../state/selectors/sprint';
import { getChartDataHash } from '../../../state/selectors/table';
import type { State } from '../../../state/types';
import { type ExportCsvAction as Action, EXPORT_CSV } from '../../../state/ui/share/actions';
import type { StateEpic } from '../../common/types';
import { getColumnTransformers, escapeValue } from './columns';
import type { GenerateCsvType, ColumnTransformerContext, ColumnTransformers } from './types';

const BASE_LEVEL_PARENT = '[NO_PARENT]';

const generatePartialCsv = (
	columnTransformers: ColumnTransformers,
	context: ColumnTransformerContext,
	parentId: string,
) => {
	const { chartDataHash, issuesByParent } = context;
	let csv = '';

	(issuesByParent[parentId] ?? []).forEach(({ issueId, issue }) => {
		const chartData = chartDataHash[issueId];
		csv += `${columnTransformers
			.map((column) =>
				column.transform(
					{
						issueId,
						issue,
						chartData,
					},
					context,
				),
			)
			.join(',')}\r\n`;

		csv += generatePartialCsv(columnTransformers, context, issueId);
	});

	return csv;
};

export const generateHeaders = (
	columnTransformers: ColumnTransformers,
	context: ColumnTransformerContext,
) =>
	`${columnTransformers
		.map((column) => escapeValue(context.issueFields[column.fieldId ?? ''] ?? column.fieldId))
		.join(',')}\r\n`;

export const generateCsv = (
	columnTransformers: ColumnTransformers,
	context: ColumnTransformerContext,
) => {
	let csv = '';

	csv += generateHeaders(columnTransformers, context);

	csv += generatePartialCsv(columnTransformers, context, BASE_LEVEL_PARENT);

	const safeFileName = context.projectName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
	const dateNow = moment().format('YYYY-MM-DD_hh.mma');

	const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
	saveAs(blob, `${safeFileName}_${dateNow}.csv`);
};

export const generate = ({
	issueIds,
	issuesHash,
	userDisplayNameHash,
	issueTypesHash,
	startDateCustomFieldId,
	colorCustomFieldId,
	sprintCustomFieldId,
	issueFields,
	issueSprintsHash,
	isSprintsFeatureEnabled,
	chartDataHash,
	projectName,
}: GenerateCsvType) => {
	if (ff('timeline-export-as-spreadsheet_1mdsg')) {
		onGenerateCsv({
			issueIds,
			issuesHash,
			userDisplayNameHash,
			issueTypesHash,
			startDateCustomFieldId,
			colorCustomFieldId,
			sprintCustomFieldId,
			issueFields,
			issueSprintsHash,
			isSprintsFeatureEnabled,
			chartDataHash,
			projectName,
		});
	} else {
		if (issueIds.length === 0) {
			return Observable.empty<never>();
		}

		const issuesByParent: Record<string, { issueId: IssueId; issue: Issue }[]> = {
			BASE_LEVEL_PARENT: [],
		};

		issueIds.forEach((issueId) => {
			const issue = issuesHash[issueId];
			const parentId = issue.parentId.value ?? BASE_LEVEL_PARENT;
			const issueList = issuesByParent[parentId] ?? [];
			issueList.push({ issueId, issue });
			issuesByParent[parentId] = issueList;
		});

		const customFields = {
			startDateCustomFieldId,
			colorCustomFieldId,
			sprintCustomFieldId,
			isSprintsFeatureEnabled,
		};
		const columnTransformers = getColumnTransformers(customFields);

		const transformationContext: ColumnTransformerContext = {
			issueIds,
			issuesByParent,
			issuesHash,
			userDisplayNameHash,
			issueTypesHash,
			issueFields,
			issueSprintsHash,
			customFields,
			chartDataHash,
			projectName,
		};

		generateCsv(columnTransformers, transformationContext);
	}
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(EXPORT_CSV)
		.mergeMap(({ payload: { inferredDueDateHeader, inferredStartDateHeader } }) => {
			try {
				const state = store.getState();
				const issueIds = getFilteredIssueIds(state);
				const issuesHash = getFullIssueHash(state);
				const userDisplayNameHash = getUserDisplayNameHash(state);
				const issueTypesHash = getFullIssueTypeHash(state);
				const startDateCustomFieldId = getStartDateCustomFieldId(state);
				const colorCustomFieldId = getColorCustomFieldId(state);
				const sprintCustomFieldId = getSprintCustomFieldId(state);
				const issueFields = {
					inferredDueDate: inferredDueDateHeader,
					inferredStartDate: inferredStartDateHeader,
					...getFieldsNamesHash(state),
				};
				const issueSprintsHash = getSanitisedIssueSprintsHash(state);
				const isSprintsFeatureEnabled = getIsSprintsFeatureEnabled(state);
				const chartDataHash = getChartDataHash(state);
				const projectName = getProjectName(state);

				generate({
					issueIds,
					issuesHash,
					userDisplayNameHash,
					issueTypesHash,
					startDateCustomFieldId,
					colorCustomFieldId,
					sprintCustomFieldId,
					issueFields,
					issueSprintsHash,
					isSprintsFeatureEnabled,
					chartDataHash,
					projectName,
				});
			} catch {
				log.safeErrorWithoutCustomerData(
					'jsw.timeline.export-csv.failure',
					'Failed to generate timeline CSV',
				);
				return Observable.of(generalError());
			}

			return Observable.empty<never>();
		})) as StateEpic;
