import { connect } from '@atlassian/jira-react-redux';
import { getRoadmapLoadedAnalyticsData } from '../../state/selectors/analytics';
import type { State } from '../../state/types';
import MountAnalytic from './view';

export default connect(
	(state: State) => ({
		attributes: getRoadmapLoadedAnalyticsData(state),
	}),
	{},
)(MountAnalytic);
