import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	editSprint: {
		id: 'roadmap.standard-roadmap.common.table.list-item-menu-option.edit-sprint-option.edit-sprint',
		defaultMessage: 'Edit sprint',
		description: 'Label for edit sprint option',
	},
});

export default messages;
