import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';

export const fireInteractedItemsLimitExceededAnalytics = (analyticsEvent: UIAnalyticsEvent) => {
	const { actionSubject, action } = analyticsEvent.payload;
	const clonedEvent = analyticsEvent.clone();

	clonedEvent &&
		fireTrackAnalytics(clonedEvent, 'interactedItemsLimit exceeded', {
			trigger: `${actionSubject} ${action}`,
		});
};
