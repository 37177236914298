import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/merge';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import {
	INITIALIZE_NON_CRITICAL_DATA,
	type InitializeNonCriticalDataAction as Action,
} from '../../../../state/app/actions';
import {
	shouldUseQuickFilters,
	getSourceARI,
	shouldUseCustomFilters,
} from '../../../../state/app/selectors';
import type { State } from '../../../../state/types';
import type { StateEpic } from '../../../common/types';
import { getInitializeFilterConfigurationAction } from './filter-configuration';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(INITIALIZE_NON_CRITICAL_DATA).mergeMap(() => {
		const state = store.getState();

		let filterConfigAction = Observable.empty();
		const sourceAri = getSourceARI(state);
		const useQuickFilters = shouldUseQuickFilters(state);
		const useCustomFilters = shouldUseCustomFilters(state);

		if (sourceAri !== undefined) {
			filterConfigAction = getInitializeFilterConfigurationAction(
				sourceAri,
				useQuickFilters,
				useCustomFilters,
			);
		}

		return filterConfigAction;
	})) as StateEpic;
