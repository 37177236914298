// eslint-disable-next-line jira/restricted/react-component-props
import type { ComponentType, ComponentProps } from 'react';
import { connect } from '@atlassian/jira-react-redux';
import { getSourceARI } from '../../../state/app/selectors';
import { getRoadmapAri } from '../../../state/selectors/share';
import type { State } from '../../../state/types';
import { exportCsv } from '../../../state/ui/share/actions';
import ShareModalWithAnalytics from './share-modal-with-analytics';
import type ShareModal from './share-modal-with-analytics/share-modal/async';
import Share from './view';

type StateProps = {
	ShareModalWithAnalytics: ComponentType<ComponentProps<typeof ShareModal>>;
	roadmapAri: string;
};

export default connect(
	(state: State): StateProps => ({
		ShareModalWithAnalytics,
		roadmapAri: getSourceARI(state) || getRoadmapAri(state),
	}),
	{
		onExportCsv: exportCsv,
	},
)(Share);
