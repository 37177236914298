import React, { useCallback } from 'react';
import { useTimelineState } from '@atlassian/jira-software-roadmap-timeline-table';
import { RoadmapKeyDates } from '@atlassian/jira-software-roadmap-timeline-table-kit';
import type { KeyDate } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/types';
import RoadmapVersionFlyoutAsync from './flyout/async';

export type Props = {
	getVersionsForKeyDate: (today: number) => ReadonlyArray<KeyDate>;
	highlightedKeyDates: string[];
};

const ReleasesHeader = ({ getVersionsForKeyDate, highlightedKeyDates }: Props) => {
	const [{ today }] = useTimelineState();
	const keyDates = getVersionsForKeyDate(today);

	const renderPopupContent = useCallback(
		(selectedKeyDates: ReadonlyArray<KeyDate>) => (
			<RoadmapVersionFlyoutAsync keyDates={selectedKeyDates} />
		),
		[],
	);

	return (
		<RoadmapKeyDates
			keyDates={keyDates}
			highlightedKeyDates={highlightedKeyDates}
			renderPopupContent={renderPopupContent}
		/>
	);
};

export default ReleasesHeader;
