import { useRef, useCallback, useEffect } from 'react';
import type { ItemId } from '../../../../types';
import { useFocusMarshal } from '../index';

/* This hook is fairly simple, but integral in our grid navigation functionality.
 * It registers grid cells to build up an understanding of the grids coordinate system.
 *
 * The "origin cell" is the entry point for the grid. If more than one origin cell is
 * provided, only the first will be considered.
 */
export const useGridCell = (rowId: ItemId, column: number, isOriginCell = false) => {
	const element = useRef<HTMLElement | null>(null);
	const { trackCell, untrackCell, initialiseRovingCell } = useFocusMarshal();

	// Ref callback is more robust for consumers than ref object
	const setCellRef = useCallback((ref: HTMLElement | null) => {
		element.current = ref;
	}, []);

	/* Lazily evaluating the cell co-ordinates helps prevent a series of edge-cases.
	 * Even for the same cell, their coordinates are not static, and depend on user interactions.
	 */
	const getData = useCallback(
		() => ({
			rowId,
			column,
		}),
		[rowId, column],
	);

	useEffect(() => {
		if (!element.current) return;

		/* useEffect's own cleanup will be called after element.current is already null
		 * This closure provides us access to a valid reference to update the Map
		 */
		const setup = (safeElement: HTMLElement) => {
			trackCell(safeElement, getData);
			isOriginCell && initialiseRovingCell(safeElement);
			return () => untrackCell(safeElement);
		};

		const cleanup = setup(element.current);

		return () => {
			cleanup();
		};
	}, [rowId, column, isOriginCell, getData, trackCell, untrackCell, initialiseRovingCell]);

	return setCellRef;
};
