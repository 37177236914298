import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/map';
import type { ActionsObservable } from 'redux-observable';
import type { State } from '../../state/types';
import { CLEAR_FILTER, type ClearFilterAction as Action } from '../../state/ui/filters/actions';
import type { StateEpic } from '../common/types';
import updateQuery from '../common/update-query';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
	{ router: { push } },
) =>
	action$.ofType(CLEAR_FILTER).map(() =>
		updateQuery(push, store.getState(), (query) => ({
			...query,
			statuses: undefined,
			assignee: undefined,
			issueType: undefined,
			text: undefined,
			label: undefined,
			issueParent: undefined,
			version: undefined,
			component: undefined,
			quickFilter: undefined,
			customFilter: undefined,
			goal: undefined,
		})),
	)) as StateEpic;
