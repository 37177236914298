import type { CriticalData } from '@atlassian/jira-software-roadmap-services';

export const UPDATE_RESOURCE_CACHE = 'state.metadata.UPDATE_RESOURCE_CACHE';

export type UpdateResourceCacheAction = {
	type: typeof UPDATE_RESOURCE_CACHE;
	payload: CriticalData;
};

export const updateResourceCache = (payload: CriticalData): UpdateResourceCacheAction => ({
	type: UPDATE_RESOURCE_CACHE,
	payload,
});
