/**
 * @generated SignedSource<<072b24458f8d9f4f3a915f15cac39ffe>>
 * @relayHash 6f4122f3aceda8ab3b15c7f0855270fa
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 936c169a1a3da60677b586f7416035e96cc19f2558c71ff347037e2d661f574f

import type { ConcreteRequest, Query } from 'relay-runtime';
export type projectIssueTypesQuery$variables = {
  projectAri: string;
};
export type projectIssueTypesQuery$data = {
  readonly jira: {
    readonly jiraProject: {
      readonly issueTypes: {
        readonly edges: ReadonlyArray<{
          readonly cursor: string;
          readonly node: {
            readonly avatar: {
              readonly medium: string | null | undefined;
            };
            readonly description: string | null | undefined;
            readonly id: string;
            readonly issueTypeId: string;
            readonly name: string;
          };
        } | null | undefined>;
      };
    };
  };
};
export type projectIssueTypesQuery = {
  response: projectIssueTypesQuery$data;
  variables: projectIssueTypesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectAri"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectAri"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "id"
},
v3 = {
  "kind": "ScalarField",
  "name": "name"
},
v4 = {
  "kind": "ScalarField",
  "name": "description"
},
v5 = {
  "kind": "ScalarField",
  "name": "issueTypeId"
},
v6 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "medium"
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "name": "cursor"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "projectIssueTypesQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v1/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProject",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "JiraIssueTypeConnection",
                      "kind": "LinkedField",
                      "name": "issueTypes",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "JiraIssueTypeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "JiraIssueType",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    (v2/*: any*/),
                                    (v3/*: any*/),
                                    (v4/*: any*/),
                                    {
                                      "kind": "RequiredField",
                                      "field": (v5/*: any*/),
                                      "action": "THROW",
                                      "path": "jira.jiraProject.issueTypes.edges.node.issueTypeId"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v6/*: any*/),
                                      "action": "THROW",
                                      "path": "jira.jiraProject.issueTypes.edges.node.avatar"
                                    }
                                  ]
                                },
                                "action": "THROW",
                                "path": "jira.jiraProject.issueTypes.edges.node"
                              },
                              (v7/*: any*/)
                            ]
                          },
                          "action": "THROW",
                          "path": "jira.jiraProject.issueTypes.edges"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "jira.jiraProject.issueTypes"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.jiraProject"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "projectIssueTypesQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProject",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueTypeConnection",
                "kind": "LinkedField",
                "name": "issueTypes",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssueType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ]
                      },
                      (v7/*: any*/)
                    ]
                  }
                ]
              },
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "936c169a1a3da60677b586f7416035e96cc19f2558c71ff347037e2d661f574f",
    "metadata": {},
    "name": "projectIssueTypesQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "e1bde9923437b56592abdd2237c64176";

export default node;
