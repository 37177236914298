import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/map';
import type { Ari } from '@atlassian/jira-platform-ari';
import {
	type DeriveFields,
	getDeriveFields,
	transformDerivedFieldsHash,
} from '@atlassian/jira-software-roadmap-services';
import { setDeriveFieldsHash } from '../../../state/configuration/actions';

export const getInitializeDeriveFieldsAction = (
	sourceAri: Ari,
	jqlContexts: string[],
	customFilterIds: string[],
) =>
	jqlContexts.length === 0 && customFilterIds.length === 0
		? Observable.empty<never>()
		: getDeriveFields(sourceAri, jqlContexts, customFilterIds).map((deriveFields: DeriveFields) => {
				const deriveFieldsHash = transformDerivedFieldsHash(deriveFields);
				return setDeriveFieldsHash(deriveFieldsHash);
			});
