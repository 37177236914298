import { ROUTER_LOCATION_CHANGE, type RouterLocationChangeAction } from './actions';
import type { RouterState } from './types';

type Action = RouterLocationChangeAction;

const initialState: RouterState = {
	location: {
		search: '',
		pathname: '',
		hash: '',
	},
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: RouterState = initialState, action: Action): RouterState => {
	switch (action.type) {
		case ROUTER_LOCATION_CHANGE:
			return {
				location: {
					...state.location,
					...action.payload,
				},
			};

		default:
			return state;
	}
};
