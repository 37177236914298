import React from 'react';
import DurationBadge from './duration-badge';
import { getDaysInBetween } from './utils';

type Props = {
	fromDate: number;
	toDate: number;
	isSigned: boolean;
	isToDateExcluded: boolean;
};

const Duration = ({ fromDate, toDate, isSigned, isToDateExcluded }: Props) => {
	const sign = toDate < fromDate ? '-' : '+';
	const duration = getDaysInBetween(fromDate, toDate, isToDateExcluded);

	return duration ? <DurationBadge duration={duration} sign={isSigned ? sign : undefined} /> : null;
};

Duration.defaultProps = {
	isSigned: false,
	isToDateExcluded: false,
};

export { Duration };
