/**
 * @generated SignedSource<<e02ac3a151e2932b2331b39a1a93577d>>
 * @relayHash ed01c651c385a1754424ab87363cbba9
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID cd0e4c566bd1af6d8a8352be7b22bfc085c63b835d01e5adfd2ca9f0007cb006

import type { ConcreteRequest, Query } from 'relay-runtime';
export type TownsquareGoalStateValue = "archived" | "at_risk" | "cancelled" | "done" | "off_track" | "on_track" | "paused" | "pending" | "%future added value";
export type roadmapGoalsQuery$variables = {
  sourceAri: string;
};
export type roadmapGoalsQuery$data = {
  readonly roadmaps: {
    readonly roadmapAriGoals: {
      readonly goals: ReadonlyArray<{
        readonly ari: string;
        readonly goal: {
          readonly id: string;
          readonly key: string;
          readonly name: string;
          readonly state: {
            readonly label: string;
            readonly score: number | null | undefined;
            readonly value: TownsquareGoalStateValue;
          };
          readonly uuid: string;
        } | null | undefined;
        readonly issueIds: ReadonlyArray<AGG$Long>;
      }>;
    } | null | undefined;
  } | null | undefined;
};
export type roadmapGoalsQuery = {
  response: roadmapGoalsQuery$data;
  variables: roadmapGoalsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sourceAri"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "sourceARI",
    "variableName": "sourceAri"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "ari"
},
v3 = {
  "kind": "ScalarField",
  "name": "issueIds"
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "uuid"
},
v6 = {
  "kind": "ScalarField",
  "name": "key"
},
v7 = {
  "kind": "ScalarField",
  "name": "name"
},
v8 = {
  "kind": "ScalarField",
  "name": "label"
},
v9 = {
  "kind": "ScalarField",
  "name": "score"
},
v10 = {
  "kind": "ScalarField",
  "name": "value"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "roadmapGoalsQuery",
    "selections": [
      {
        "concreteType": "RoadmapsQuery",
        "kind": "LinkedField",
        "name": "roadmaps",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "RoadmapAriGoals",
            "kind": "LinkedField",
            "name": "roadmapAriGoals",
            "plural": false,
            "selections": [
              {
                "kind": "RequiredField",
                "field": {
                  "concreteType": "RoadmapAriGoalDetails",
                  "kind": "LinkedField",
                  "name": "goals",
                  "plural": true,
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/),
                    {
                      "concreteType": "TownsquareGoal",
                      "kind": "LinkedField",
                      "name": "goal",
                      "plural": false,
                      "selections": [
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v6/*: any*/),
                        (v7/*: any*/),
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "TownsquareGoalState",
                            "kind": "LinkedField",
                            "name": "state",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v8/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapAriGoals.goals.goal.state.label"
                              },
                              (v9/*: any*/),
                              {
                                "kind": "RequiredField",
                                "field": (v10/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapAriGoals.goals.goal.state.value"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "roadmaps.roadmapAriGoals.goals.goal.state"
                        }
                      ]
                    }
                  ]
                },
                "action": "THROW",
                "path": "roadmaps.roadmapAriGoals.goals"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "roadmapGoalsQuery",
    "selections": [
      {
        "concreteType": "RoadmapsQuery",
        "kind": "LinkedField",
        "name": "roadmaps",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "RoadmapAriGoals",
            "kind": "LinkedField",
            "name": "roadmapAriGoals",
            "plural": false,
            "selections": [
              {
                "concreteType": "RoadmapAriGoalDetails",
                "kind": "LinkedField",
                "name": "goals",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "concreteType": "TownsquareGoal",
                    "kind": "LinkedField",
                    "name": "goal",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "concreteType": "TownsquareGoalState",
                        "kind": "LinkedField",
                        "name": "state",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "cd0e4c566bd1af6d8a8352be7b22bfc085c63b835d01e5adfd2ca9f0007cb006",
    "metadata": {},
    "name": "roadmapGoalsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "2792b908e31158794969cc76ffa0f0f8";

export default node;
