import { useEffect, useRef, useCallback } from 'react';
import noop from 'lodash/noop';
// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridge } from '@atlassian/jira-common-bridge/src';
import type { GlobalIssueCreateProps } from '../../../model/issue';
import type { JiraGlobalIssueCreateCloseCallback } from './types';

export type Props = {
	isMacroView: boolean;
	onJiraGlobalIssueCreateClosed: (payload: GlobalIssueCreateProps[]) => void;
};

const JiraGlobalIssueListener = ({ isMacroView, onJiraGlobalIssueCreateClosed }: Props) => {
	const subscriptionHandle = useRef<() => void>(noop);
	const globalIssueCreateEventHandler = useCallback<JiraGlobalIssueCreateCloseCallback>(
		(event, issues) => {
			const globalIssues: GlobalIssueCreateProps[] = (issues || []).reduce(
				(acc: GlobalIssueCreateProps[], issue) => {
					if (issue.createdIssueDetails) {
						acc.push({
							issueId: issue.createdIssueDetails.id,
							issueKey: issue.createdIssueDetails.key,
							projectId: issue.createdIssueDetails.fields.project.id,
							issueTypeId: issue.createdIssueDetails.fields.issuetype.id,
						});
					}
					return acc;
				},
				[],
			);
			onJiraGlobalIssueCreateClosed(globalIssues);
		},
		[onJiraGlobalIssueCreateClosed],
	);
	useEffect(() => {
		if (!isMacroView) {
			// window.require is not available on initial script load.
			// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/wrm/no-load-bridge
			loadBridge<any>({
				name: 'jira/util/events',
				wrmKeys: 'wrc!jira.webresources:jira-events',
			}).then((Events) => {
				if (Events?.bind) {
					Events.bind('QuickCreateIssue.sessionComplete', globalIssueCreateEventHandler);

					subscriptionHandle.current = () => {
						Events.unbind('QuickCreateIssue.sessionComplete', globalIssueCreateEventHandler);
					};
				}
			});
		}
		return () => {
			subscriptionHandle.current();
			subscriptionHandle.current = noop;
		};
	}, [isMacroView, globalIssueCreateEventHandler]);
	return null;
};

export default JiraGlobalIssueListener;
