import { fg } from '@atlassian/jira-feature-gating';
import { BEFORE, INSIDE } from '../../../common/constants';
import type { CreateItemAnchor, ItemId } from '../../../common/types';
import { isRoot } from '../../../common/utils/hierarchy-enriched-items';

type CreateCheckParams = {
	id: ItemId;
	createItemAnchor: CreateItemAnchor;
	isInTransition: boolean;
};

type SiblingCreateCheckParams = CreateCheckParams & {
	isCreateChildEnabled: boolean;
	isCreateSiblingEnabled: boolean;
	index: number;
	depth: number;
};

type ChildCreateCheckParams = CreateCheckParams & {
	isCreateChildEnabled: boolean;
	canCreateChildOverride: boolean;
};

// Remove on FG cleanup jsw_roadmaps_timeline_table_meatballs_menu_a11y
const isItemAlreadyInCreate = (id: ItemId, createItemAnchor: CreateItemAnchor) =>
	createItemAnchor &&
	(createItemAnchor.position === BEFORE || createItemAnchor.position === INSIDE) &&
	(createItemAnchor.beforeId === id || createItemAnchor.parentId === id);

// Remove on FG cleanup jsw_roadmaps_timeline_table_meatballs_menu_a11y
const canItemCreate = ({ id, createItemAnchor, isInTransition }: CreateCheckParams) =>
	!isInTransition && !isItemAlreadyInCreate(id, createItemAnchor);

export const canItemCreateSibling = ({
	index,
	isCreateChildEnabled,
	isCreateSiblingEnabled,
	depth,
	...createCheckParams
}: SiblingCreateCheckParams) =>
	isCreateSiblingEnabled &&
	// !isInTransition is checked within canItemCreate, set this check to just !isInTransition on FG cleanup
	// The button must be rendered while the item is in create in order to refocus it once ICC is cancelled
	(canItemCreate(createCheckParams) ||
		(!createCheckParams.isInTransition && fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y'))) &&
	((isRoot(depth) && index !== 0) || (!isRoot(depth) && isCreateChildEnabled));

export const canItemCreateChild = ({
	isCreateChildEnabled,
	canCreateChildOverride,
	...createCheckParams
}: ChildCreateCheckParams) =>
	isCreateChildEnabled &&
	canCreateChildOverride &&
	// !isInTransition is checked within canItemCreate, set this check to just !isInTransition on FG cleanup
	// The button must be rendered while the item is in create in order to refocus it once ICC is cancelled
	(canItemCreate(createCheckParams) ||
		(!createCheckParams.isInTransition && fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y')));
