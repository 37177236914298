/**
 * @generated SignedSource<<a4cdb38644fc652a84fbc59caf7af6c2>>
 * @relayHash 8984ea3b0bd52a4a879c387014bb9c8b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4f71597ff5dc1e918db86f2c0ed3f92c5c95a21bda21e558fe23d5b71ced2c4b

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type RoadmapScheduleItemInput = {
  dueDate?: AGG$Date | null | undefined;
  itemId: string;
  startDate?: AGG$Date | null | undefined;
};
export type scheduleRoadmapItemsMutation$variables = {
  scheduleRequests: ReadonlyArray<RoadmapScheduleItemInput | null | undefined>;
  sourceARI: string;
};
export type scheduleRoadmapItemsMutation$data = {
  readonly roadmaps: {
    readonly scheduleRoadmapItems: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly errorType?: string | null | undefined;
          readonly statusCode?: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type scheduleRoadmapItemsMutation = {
  response: scheduleRoadmapItemsMutation$data;
  variables: scheduleRoadmapItemsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scheduleRequests"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceARI"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "scheduleRequests",
        "variableName": "scheduleRequests"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  },
  {
    "kind": "Variable",
    "name": "sourceARI",
    "variableName": "sourceARI"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "success"
},
v4 = {
  "kind": "ScalarField",
  "name": "message"
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "statusCode"
    },
    {
      "kind": "ScalarField",
      "name": "errorType"
    }
  ],
  "type": "RoadmapMutationErrorExtension"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "scheduleRoadmapItemsMutation",
    "selections": [
      {
        "concreteType": "RoadmapsMutation",
        "kind": "LinkedField",
        "name": "roadmaps",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "RoadmapScheduleItemsPayload",
            "kind": "LinkedField",
            "name": "scheduleRoadmapItems",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "scheduleRoadmapItemsMutation",
    "selections": [
      {
        "concreteType": "RoadmapsMutation",
        "kind": "LinkedField",
        "name": "roadmaps",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "RoadmapScheduleItemsPayload",
            "kind": "LinkedField",
            "name": "scheduleRoadmapItems",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v5/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "id"
                          }
                        ],
                        "type": "BulkMutationErrorExtension"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "4f71597ff5dc1e918db86f2c0ed3f92c5c95a21bda21e558fe23d5b71ced2c4b",
    "metadata": {},
    "name": "scheduleRoadmapItemsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "0dd026eff03519d70944542fa22dbd32";

export default node;
