import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	editDates: {
		id: 'roadmap.standard-roadmap.common.table.list-item-menu-option.edit-dates-option.edit-dates',
		defaultMessage: 'Edit dates',
		description: 'Label for edit dates option',
	},
});

export default messages;
