/** @jsx jsx */
import React, {
	useRef,
	useState,
	useEffect,
	useCallback,
	useLayoutEffect,
	type SyntheticEvent,
	type Ref,
} from 'react';
import { css, jsx } from '@compiled/react';
import noop from 'lodash/noop';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { TIMELINE_ID } from '../../common/constants';
import {
	useTimelineState,
	useHeaderState,
	useListWidth,
	useTimelineViewportActions,
} from '../../common/context';
import {
	type Props as ViewportProviderProps,
	withViewportProvider,
} from '../../common/context/viewport';
import type { WrappedComponent as WrappedComponentProps } from '../../common/context/viewport/provider/types';
import { createRaf } from '../../common/utils';
import { useResizeObserver } from '../../common/utils/use-resize-observer';
import { useDragScroller } from './drag-scroller';
import ThumbTracks from './thumb-tracks';
import LegacyThumbTracks from './thumb-tracks/index-old';
import type { ImperativeRef } from './thumb-tracks/types';
import type { Children } from './types';
import {
	getScrollbarSize,
	getScrollOffsetRatio,
	getContentToViewportRatio,
	getViewportOffsets,
} from './utils';

/* ===========================================================================
 * === @atlaskit/pragmatic-drag-and-drop === //
 *
 * Once the jsw_roadmaps_timeline_table_custom_scroll_pdnd feature gate is
 * removed, this comment and the following feature gated ThumbTrackComponent
 * can be removed. The legacy-thumb-tracks component module can also be
 * deleted in its entirety.
 *
 * TODO(FG-REMOVE): jsw_roadmaps_timeline_table_custom_scroll_pdnd
 *
 * https://switcheroo.atlassian.com/ui/gates/49cac7b3-a5f8-45d9-9533-08d52c4f3345
 *
 * =========================================================================== */
const ThumbTrackComponent = componentWithFG(
	'jsw_roadmaps_timeline_table_custom_scroll_pdnd',
	ThumbTracks,
	LegacyThumbTracks,
);

const containerStyles = css({
	position: 'absolute',
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
	overflow: 'hidden',
	borderRadius: `${token('border.radius', '3px')} 0 0 0`,
});

const hiddenScrollbarPanelStyles = css({
	display: 'flex',
	position: 'absolute',
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
	overflow: 'scroll',
});

const HiddenScrollbarPanel = ({
	scrollbarSize,
	innerRef,
	children,
	...rest
}: ViewportProviderProps) => (
	<div
		ref={innerRef}
		css={hiddenScrollbarPanelStyles}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		style={
			scrollbarSize > 0
				? {
						right: `-${scrollbarSize}px`,
						bottom: `-${scrollbarSize}px`,
					}
				: undefined
		}
		{...rest}
	>
		{children}
	</div>
);

const requestAnimationFrame = createRaf();

const Viewport = withViewportProvider(
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	HiddenScrollbarPanel as React.ComponentType<WrappedComponentProps>,
);

type Props = {
	isHideScrollbar: boolean;
	children: Children;
};

/*
 * !! Changes here should be made with extreme caution !!
 * This component exists to create scrollbars that have a browser/OS agnostic look and feel, while still respecting user settings.
 * The context this component provides is used by dozens of leaf nodes and is accessible to external consumers. Additionally, performance
 * optimisations here are nuanced, which if regressed can easily cause bottlenecks to our virtual scrolling and TTI.
 * ---
 * Some points of interest:
 * - We rely heavily on accessing the DOM directly, skipping as much work as possible in the React lifecycle.
 * - In the local system (we cannot guarantee this globally), we look to minimise layout thrashing by batching DOM reads and writes.
 * - We translate measurements between two coordinate systems to decouple the scrollbar ref from our custom element refs (see ./thumb-tracks).
 */
const CustomScrollbars = ({ isHideScrollbar, children }: Props) => {
	const [{ timelineOriginPosition }] = useTimelineState();
	const [{ headerHeight }] = useHeaderState();
	const [listWidth] = useListWidth();
	const [scrollbarSize, setScrollbarSize] = useState<number>(-1);

	const viewportRef = useRef<HTMLElement | null>(null);
	const thumbTracksRef = useRef<ImperativeRef | null>(null);

	const lastScrollY = useRef(0);
	const lastScrollX = useRef(0);

	const frameId = useRef<number>();
	const [setupDragScroller, teardownDragScroller] = useDragScroller(headerHeight);
	const { onResize } = useTimelineViewportActions();

	// ====================== //
	// === EVENT HANDLING === //
	// ====================== //

	const onSetViewportRef = (elem: HTMLElement | null) => {
		viewportRef.current = elem;
		setupDragScroller(elem);
	};

	// Set the scroll left on the real scrollbar based on a position from clicking/dragging the track
	const onSetScrollLeft = useCallback((relativePosition: number) => {
		if (!viewportRef.current || !thumbTracksRef.current) {
			throw new Error('An element required to set the scroll left position was null');
		}

		const { scrollWidth } = viewportRef.current;
		viewportRef.current.scrollLeft = relativePosition * scrollWidth;
	}, []);

	// Set the scroll top on the real scrollbar based on a position from from clicking/dragging the track
	const onSetScrollTop = useCallback((relativePosition: number) => {
		if (!viewportRef.current || !thumbTracksRef.current) {
			throw new Error('An element required to set the scroll top position was null');
		}

		const { scrollHeight } = viewportRef.current;
		viewportRef.current.scrollTop = relativePosition * scrollHeight;
	}, []);

	/* Update the custom thumb positions to reflect the "real" ones that we've hidden.
	 * To minimise the number of updates, we check whether the scroll position has changed
	 * before mutating the DOM, e.g. don't change vertical position when only scrolling horizontally.
	 */
	const onScroll = ({ currentTarget }: SyntheticEvent<HTMLElement>) => {
		frameId.current = requestAnimationFrame(() => {
			// DOM reads //
			const { scrollLeft, scrollTop, scrollWidth, scrollHeight, clientWidth, clientHeight } =
				currentTarget;

			let horizontalScrollRatio = -1;
			let verticalScrollRatio = -1;

			if (lastScrollX.current !== scrollLeft) {
				lastScrollX.current = scrollLeft;
				horizontalScrollRatio = getScrollOffsetRatio(scrollLeft, scrollWidth, clientWidth);
			}

			if (lastScrollY.current !== scrollTop) {
				lastScrollY.current = scrollTop;
				verticalScrollRatio = getScrollOffsetRatio(scrollTop, scrollHeight, clientHeight);
			}

			// DOM reads then writes //
			thumbTracksRef.current?.setThumbPositions({
				horizontalScrollRatio,
				verticalScrollRatio,
			});
		});
	};

	/* This should be called in response to anything that would affect the dimensions of the viewport
	 * or its content. As a result, the relevant custom properties are updated, e.g. thumb position and size.
	 */
	const updateScrollbars = useCallback(() => {
		if (!viewportRef.current || !thumbTracksRef.current) {
			throw new Error('An element required to set the custom scroll parameters was null');
		}

		// DOM reads //
		const { scrollWidth, scrollHeight, clientWidth, clientHeight, scrollLeft, scrollTop } =
			viewportRef.current;
		const { left } = viewportRef.current.getBoundingClientRect();
		onResize({ left, clientWidth });

		const horizontalScrollRatio = getScrollOffsetRatio(scrollLeft, scrollWidth, clientWidth);
		const verticalScrollRatio = getScrollOffsetRatio(scrollTop, scrollHeight, clientHeight);

		const horizontalContentRatio = getContentToViewportRatio(scrollWidth, clientWidth);
		const verticalContentRatio = getContentToViewportRatio(scrollHeight, clientHeight);

		// DOM reads then writes //
		thumbTracksRef.current?.update(
			{ horizontalScrollRatio, verticalScrollRatio },
			{ horizontalContentRatio, verticalContentRatio },
		);
	}, [onResize]);

	// If a scrollbar is not measured, we don't need to handle updating the custom parameters.
	const onContentResize = scrollbarSize > 0 ? updateScrollbars : noop;

	// =============== //
	// === EFFECTS === //
	// =============== //

	/* Step 1: Initialize the fundamental custom scrollbar properties.
	 *
	 * Some OS settings hide scrollbars or display them as overlays. In these cases the
	 * scrollbar size will be measured as 0, and we will fallback to using the browser built-ins.
	 * Regardless, given how often these settings change, we only ever set the scrollbar size once.
	 */
	useLayoutEffect(() => {
		if (!viewportRef.current) {
			throw new Error('An element required to initialise the custom scrollbars was null');
		}

		const initialScrollbarSize = getScrollbarSize(viewportRef.current);

		// Browsers will convert scrollLeft to an integer, and we want to compare these two values later
		const integerTimelineDefaultPosition = Math.round(timelineOriginPosition);
		viewportRef.current.scrollLeft = integerTimelineDefaultPosition;
		lastScrollX.current = integerTimelineDefaultPosition;

		setScrollbarSize(initialScrollbarSize);
	}, [timelineOriginPosition]);

	/* Step 2: Construct the resize observer *after* initialization so DOM is stable.
	 *
	 * This handles updating the scrollbar properties whenever the *viewport* changes size,
	 * e.g. resizing the window or collapsing a sidebar. As we have no direct way to observe
	 * content changes, this will be handled by a callback (see the render).
	 */
	useResizeObserver(viewportRef.current, onContentResize);

	// Step 3: Don't forget to cleanup!!
	useEffect(
		() => () => {
			frameId.current && cancelAnimationFrame(frameId.current);
			teardownDragScroller(null);
		},
		[teardownDragScroller],
	);

	// ============== //
	// === RENDER === //
	// ============== //

	return (
		<div css={containerStyles}>
			<Viewport
				id={TIMELINE_ID}
				data-testid={TIMELINE_ID}
				innerRef={onSetViewportRef}
				scrollbarSize={scrollbarSize}
				offsets={getViewportOffsets(scrollbarSize, listWidth, headerHeight)}
				onScroll={scrollbarSize > 0 ? onScroll : undefined}
			>
				{children({
					scrollElement: viewportRef.current,
					onContentResize,
				})}
			</Viewport>
			{scrollbarSize > 0 && (
				<ThumbTrackComponent
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					ref={thumbTracksRef as Ref<ImperativeRef>}
					listWidth={listWidth}
					timelineTop={headerHeight}
					isHideScrollbar={isHideScrollbar}
					onSetScrollLeft={onSetScrollLeft}
					onSetScrollTop={onSetScrollTop}
				/>
			)}
		</div>
	);
};

export default CustomScrollbars;
