/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fontSize } from '@atlassian/jira-common-styles/src';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

const containerStylesCompiledStyles = css({
	display: 'flex',
	flexDirection: 'row',
	overflow: 'hidden',
	alignItems: 'center',
	textOverflow: 'ellipsis',
	width: '100%',
	padding: `${token('space.050', '4px')} 0px`,
});

const iconStylesCompiledStyles = css({
	width: token('space.200', '16px'),
	height: token('space.200', '16px'),
	borderRadius: '3px',
	flex: '0 0 auto',
});

const keyStylesCompiledStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${fontSize}px`,
	outline: 'none',
	margin: `0 0 0 ${token('space.100', '8px')}`,
	whiteSpace: 'nowrap',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&, &:hover, &:active, &:focus': {
		color: token('color.text.subtlest', N300),
		textDecoration: 'none',
	},
});

const doneStylesCompiledStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&, &:hover, &:active, &:focus': {
		textDecoration: 'line-through',
	},
});

const titleStylesCompiledStyles = css({
	margin: `0 0 0 ${token('space.100', '8px')}`,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	flexGrow: 1,
});

type SummaryType = 'div' | 'p';

type AnchorType = {
	isDone: boolean;
	testId?: string;
	children?: React.ReactNode;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

type SpanType = {
	isDone: boolean;
	testId?: string;
	children?: React.ReactNode;
} & React.HTMLAttributes<HTMLSpanElement>;

// these styles are reusable, so created in a shared component
export const SummaryContainer = ({ children }: { children?: React.ReactNode }) => (
	<div css={[containerStylesCompiledStyles]}>{children}</div>
);
// these styles are reusable, so created in a shared component
export const SummaryIcon = ({ alt, src }: { alt: string; src: string }) => {
	const { formatMessage } = useIntl();
	return (
		<Tooltip content={alt}>
			{(tooltipProps) => (
				<img
					src={src}
					alt={alt}
					css={iconStylesCompiledStyles}
					aria-label={formatMessage(messages.iconLabel, { issueType: alt })}
					data-testid="roadmap.timeline-table-kit.common.ui.summary.icon"
					{...tooltipProps}
				/>
			)}
		</Tooltip>
	);
};

// https://github.com/facebook/react/issues/1881 (replaces previous implementation of !important not working in React)
export const SummaryKeyLink = ({ children, isDone, testId, ...rest }: AnchorType) => (
	<a
		css={[keyStylesCompiledStyles, isDone && doneStylesCompiledStyles]}
		data-testid={testId}
		{...rest}
	>
		{children}
	</a>
);

// https://github.com/facebook/react/issues/1881 (replaces previous implementation of !important not working in React)
export const SummaryKeyLabel = ({ children, isDone, testId, ...rest }: SpanType) => (
	<span
		css={[keyStylesCompiledStyles, isDone && doneStylesCompiledStyles]}
		data-testid={testId}
		{...rest}
	>
		{children}
	</span>
);

// these styles are reusable, so created in a shared component (currently used to render as `div` and `a` elements in the codebase)
export const SummaryTitle = ({
	type,
	testId,
	children,
}: {
	type: SummaryType;
	testId?: string;
	children?: React.ReactNode;
}) => {
	switch (type) {
		case 'div':
			return (
				<div css={titleStylesCompiledStyles} data-testid={testId}>
					{children}
				</div>
			);
		case 'p':
		default:
			return (
				<p css={titleStylesCompiledStyles} data-testid={testId}>
					{children}
				</p>
			);
	}
};
