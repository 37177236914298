/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { Flex, xcss } from '@atlaskit/primitives';
import { N50 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';

const TooltipIntervalLegacy = ({ children }: { children: React.ReactNode }) => (
	<div
		// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css={css({
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
			fontSize: '11px',
			whiteSpace: 'nowrap',
		})}
	>
		{children}
	</div>
);

const TooltipIntervalPrimitives = ({ children }: { children: React.ReactNode }) => (
	<Flex direction="row" alignItems="center" xcss={tooltipStyles}>
		{children}
	</Flex>
);
export const TooltipInterval = componentWithFG(
	'replace-typography-css-with-tokens-for-chronos',
	TooltipIntervalPrimitives,
	TooltipIntervalLegacy,
);

export const ContentName = ({ children }: { children: React.ReactNode }) => (
	<div
		// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css={css({
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		})}
	>
		{children}
	</div>
);

export const ContentDateLabel = ({ children }: { children: React.ReactNode }) => (
	<div
		// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css={css({
			marginLeft: token('space.050', '4px'),
			color: token('color.text.inverse', N50),
		})}
	>
		{children}
	</div>
);

const tooltipStyles = xcss({
	whiteSpace: 'nowrap',
});
