import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sprintLabel: {
		id: 'roadmap.timeline-table-kit.chart-header-item.intervals.marker.utils.sprint-label',
		defaultMessage: '{name} (from {startDate} to {endDate})',
		description:
			'Aria label text for each sprint, where {name} is the sprint name, {startDate} is the start date of the sprint, and {endDate} is the end date of the sprint',
	},
});
