import type { IssueTypeId } from '@atlassian/jira-shared-types';
import type {
	Healthcheck,
	HealthcheckIssueType,
	IdentifiableHash,
} from '@atlassian/jira-software-roadmap-model';
import type { State } from '../../types';

export const getIssueCounts = (state: State): IdentifiableHash<IssueTypeId, number> =>
	state.ui.healthcheck.issueTypeCounts;
export const getHealthcheckIssueTypes = (
	state: State,
): IdentifiableHash<IssueTypeId, HealthcheckIssueType> | undefined =>
	state.ui.healthcheck.issueTypes;
export const isResolving = (state: State): boolean => state.ui.healthcheck.isResolving;

export const getHealthcheck = (state: State): Healthcheck | undefined =>
	state.ui.healthcheck.current;
export const getHasHealthcheck = (state: State): boolean =>
	state.ui.healthcheck.current !== undefined;
