import React, { useMemo } from 'react';
import { COMMAND_PALETTE_REGISTRY_IDS } from '@atlassian/jira-command-palette-registry/src/common/constants/registry/index.tsx';
import { RegisterCommands } from '@atlassian/jira-command-palette-registry/src/ui/register-commands/index.tsx';
import {
	type Command,
	CommandActionType,
	type CommandAction,
} from '@atlassian/jira-command-palette/src/common/types/commands/index.tsx';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import type { CommandShortcutsProps } from './types';

/**
 * CommandShortcuts is a wrapper of the Shortcuts component to
 * be able to add shortcut actions to the command palette
 */
export const CommandShortcuts = (props: CommandShortcutsProps) => {
	const { keyMap } = props;

	const mappedCommands = useMemo(() => {
		const commands: Command[] = [];

		Object.entries(keyMap).forEach(([key, mapItem]) => {
			if (!mapItem.registerInCommandPalette) return;

			const { primaryAction, ...command } = mapItem.registerInCommandPalette;

			let shortcutPrimaryAction: CommandAction | undefined;

			if (
				primaryAction?.type === CommandActionType.COMMAND_LIST ||
				primaryAction?.type === CommandActionType.PERFORM
			) {
				shortcutPrimaryAction = primaryAction;
			} else if (mapItem.callback) {
				shortcutPrimaryAction = {
					type: CommandActionType.PERFORM,
					perform: () => mapItem.callback(),
				};
			}

			/**
			 * loadChildren function has the preference over the other actions
			 * followed by perform and then, if none set, the KeyMap callback
			 */
			commands.push({
				id: `command-shortcut-${key}`,
				shortcut: key,
				...command,
				primaryAction: shortcutPrimaryAction,
			});
		});

		return commands;
	}, [keyMap]);

	/**
	 * As mappedCommands is an object, it changes at every render.
	 * To memoise the commands properly in the command palette
	 * the ids are stringified and add as a dependency of the effect
	 */
	const shortcutIds = useMemo(
		() => mappedCommands.map(({ id }) => id).toString(),
		[mappedCommands],
	);

	return (
		<>
			<Shortcuts {...props} />
			{!!mappedCommands.length && (
				<RegisterCommands
					registrationId={COMMAND_PALETTE_REGISTRY_IDS.SHORTCUTS}
					commands={mappedCommands}
					deps={[shortcutIds]}
				/>
			)}
		</>
	);
};
