import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	share: {
		id: 'roadmap.standard-roadmap.header.share.share',
		defaultMessage: 'Share',
		description:
			'Dropdown label. Allows a user to share their view of the timeline with other people',
	},
	export: {
		id: 'roadmap.standard-roadmap.header.share.export',
		defaultMessage: 'Export image',
		description: 'Dropdown item. Allows a user to export Timeline into a file.',
	},
	exportButton: {
		id: 'roadmap.standard-roadmap.header.share.export-button',
		defaultMessage: 'Export',
		description: 'Button. Allows a user to export Timeline into a file.',
	},
	exportImagePng: {
		id: 'roadmap.standard-roadmap.header.share.export-image-png',
		defaultMessage: 'Image (.png)',
		description: 'Button. Allows a user to export Timeline into a image in PNG format.',
	},
	exportSpreadsheetCsv: {
		id: 'roadmap.standard-roadmap.header.share.export-spreadsheet-csv',
		defaultMessage: 'Spreadsheet (.csv)',
		description: 'Button. Allows a user to export Timeline into a spreadsheet in CSV format.',
	},
	exportButtonIcon: {
		id: 'roadmap.standard-roadmap.header.share.export-button-icon',
		defaultMessage: 'Export icon',
		description: 'Button icon. Allows a user to export Timeline into a file.',
	},
	timeline: {
		id: 'roadmap.standard-roadmap.header.share.timeline',
		defaultMessage: 'Timeline',
		description:
			'Share summary. Title of the timeline to be shared that will be sent in notifications',
	},
	exportSpreadsheetInferredStartDate: {
		id: 'roadmap.standard-roadmap.header.share.export-spreadsheet-inferred-start-date',
		defaultMessage: 'Inferred start date',
		description: 'Column header for the inferred start date in the spreadsheet export',
	},
	exportSpreadsheetInferredDueDate: {
		id: 'roadmap.standard-roadmap.header.share.export-spreadsheet-inferred-due-date',
		defaultMessage: 'Inferred due date',
		description: 'Column header for the inferred due date in the spreadsheet export',
	},
});
