// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps, type ComponentType } from 'react';
import { ContextualAnalyticsData, MODAL } from '@atlassian/jira-product-analytics-bridge';
import type { RoadmapImageExportMetrics } from '../../../../state/selectors/analytics';
import type ShareModal from './share-modal/async';

export type StateProps = {
	isDependenciesVisible: boolean;
	isProgressVisible: boolean;
	isWarningsVisible: boolean;
	isReleasesVisible: boolean;
	imageExportMetrics: RoadmapImageExportMetrics;
	ShareModal: ComponentType<ComponentProps<typeof ShareModal>>;
};

type Props = ComponentProps<typeof ShareModal> & StateProps;

const ShareModalWithAnalytics = ({
	isDependenciesVisible,
	isProgressVisible,
	isWarningsVisible,
	isReleasesVisible,
	imageExportMetrics,
	ShareModal,
	...props
}: Props) => (
	<ContextualAnalyticsData
		sourceName="share"
		sourceType={MODAL}
		attributes={{
			isDependenciesVisible,
			isProgressVisible,
			isWarningsVisible,
			isReleasesVisible,
			...imageExportMetrics,
		}}
	>
		<ShareModal {...props} />
	</ContextualAnalyticsData>
);

export default ShareModalWithAnalytics;
