import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createChild: {
		id: 'roadmap.standard-roadmap.common.table.list-item-content.create-child',
		defaultMessage: 'Create child issue',
		description:
			'Button label. Button begins issue creation process inline on Timelines issue list.',
	},
});
