import React from 'react';
import Icon from '@atlaskit/icon';
import { Y400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

interface Props {
	label: string;
	color?: string;
}

const getIconContents = () => (
	<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
		<polygon points="10,6  18,6  18,14" fill="currentColor" />
	</svg>
);

const ChangeIndicatorIcon = ({ label, color = token('color.icon.warning', Y400) }: Props) => (
	<Icon glyph={getIconContents} size="medium" label={label} primaryColor={color} />
);

export default ChangeIndicatorIcon;
