import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import {
	TODO_COLOR_HIGHER_CONTRAST,
	IN_PROGRESS_COLOR_HIGHER_CONTRAST,
	DONE_COLOR_HIGHER_CONTRAST,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/ui/progress-bar/constants.tsx';
import type { VersionDetailsResponse } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-header-item/version-flyout/content/types.tsx';
import { optionalDateToUtcTimestamp } from '../common/date';
import { nullableToOptional } from '../common/field';
import messages from './messages';
import type { GraphQLVersionDetailsResponse } from './types';

export const transformVersionDetails = (
	version: GraphQLVersionDetailsResponse,
	{ formatMessage }: IntlShape,
): VersionDetailsResponse => {
	if (version.__typename === 'JiraVersion') {
		const {
			versionId: id,
			description,
			startDate,
			releaseDate,
			todoIssues,
			inProgressIssues,
			doneIssues,
		} = version;

		const progress = [
			{
				id: `version-${id}-in-progress`,
				name: formatMessage(messages.inProgress),
				count: inProgressIssues.totalCount,
				color: IN_PROGRESS_COLOR_HIGHER_CONTRAST,
			},
			{
				id: `version-${id}-done`,
				name: formatMessage(messages.done),
				count: doneIssues.totalCount,
				color: DONE_COLOR_HIGHER_CONTRAST,
			},
			{
				id: `version-${id}-todo`,
				name: formatMessage(messages.todo),
				count: todoIssues.totalCount,
				color: TODO_COLOR_HIGHER_CONTRAST,
			},
		];

		return {
			id,
			description: description || '',
			startDate: optionalDateToUtcTimestamp(nullableToOptional(startDate)),
			releaseDate: optionalDateToUtcTimestamp(nullableToOptional(releaseDate)),
			progress,
		};
	}

	throw new Error(`Invalid version type: ${version.__typename}`);
};
