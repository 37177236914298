import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import type { IssueStatusCategoryId } from '@atlassian/jira-shared-types';
import type { StatusCategoryHash } from '@atlassian/jira-software-roadmap-model';
import { TODO, IN_PROGRESS, DONE } from '../../../../../model/status';
import { getFullStatusCategoriesHash } from '../../../../entities/status-categories/selectors';

const STATUS_KEYS_ORDER_HASH = {
	[`${TODO}`]: 1,
	[`${IN_PROGRESS}`]: 2,
	[`${DONE}`]: 3,
} as const;

export const getStatusCategories = createSelector(
	getFullStatusCategoriesHash,
	(statusCategoriesHash: StatusCategoryHash) => {
		const statusCategoryIds = Object.keys(statusCategoriesHash).filter(
			(id: IssueStatusCategoryId) => statusCategoriesHash[id].key in STATUS_KEYS_ORDER_HASH,
		);
		const orderedStatusCategoryIds = sortBy(
			statusCategoryIds,
			(id: IssueStatusCategoryId) => STATUS_KEYS_ORDER_HASH[statusCategoriesHash[id].key],
		);
		return orderedStatusCategoryIds.map((id: IssueStatusCategoryId) => ({
			id,
			name: statusCategoriesHash[id].name,
		}));
	},
);
