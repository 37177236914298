/**
 * @generated SignedSource<<5bc62a992ea19135d71697e9e7337015>>
 * @relayHash e768406b8e14cc741a9cab3c8f11b795
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID eac03d37c54edb765ed82f90ebd26f88b6be8aec5a8d90e4095bccaefef13888

import type { ConcreteRequest, Query } from 'relay-runtime';
export type getSubtasksQuery$variables = {
  itemIds: ReadonlyArray<string>;
  sourceAri: string;
};
export type getSubtasksQuery$data = {
  readonly roadmaps: {
    readonly roadmapSubtasksByIds: {
      readonly statusCategories: ReadonlyArray<{
        readonly id: string;
        readonly key: string;
        readonly name: string;
      }>;
      readonly subtasks: ReadonlyArray<{
        readonly id: string;
        readonly key: string;
        readonly parentId: string;
        readonly statusCategoryId: string;
      }>;
    } | null | undefined;
  } | null | undefined;
};
export type getSubtasksQuery = {
  response: getSubtasksQuery$data;
  variables: getSubtasksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceAri"
},
v2 = {
  "kind": "ScalarField",
  "name": "id"
},
v3 = {
  "kind": "ScalarField",
  "name": "key"
},
v4 = [
  {
    "concreteType": "RoadmapsQuery",
    "kind": "LinkedField",
    "name": "roadmaps",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "itemIds",
            "variableName": "itemIds"
          },
          {
            "kind": "Variable",
            "name": "sourceARI",
            "variableName": "sourceAri"
          }
        ],
        "concreteType": "RoadmapSubtasksWithStatusCategories",
        "kind": "LinkedField",
        "name": "roadmapSubtasksByIds",
        "plural": false,
        "selections": [
          {
            "concreteType": "RoadmapSubtasks",
            "kind": "LinkedField",
            "name": "subtasks",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "name": "parentId"
              },
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "name": "statusCategoryId"
              }
            ]
          },
          {
            "concreteType": "RoadmapStatusCategory",
            "kind": "LinkedField",
            "name": "statusCategories",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "name": "name"
              },
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "getSubtasksQuery",
    "selections": (v4/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "getSubtasksQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "id": "eac03d37c54edb765ed82f90ebd26f88b6be8aec5a8d90e4095bccaefef13888",
    "metadata": {},
    "name": "getSubtasksQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "86a1203541c65452598c6eb81a645287";

export default node;
