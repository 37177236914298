import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'roadmap.standard-roadmap.flags.rank-disabled.title',
		defaultMessage: 'Ranking is disabled',
		description: 'Flag title. Informs a user that ranking is disabled.',
	},
	description: {
		id: 'roadmap.standard-roadmap.flags.rank-disabled.description',
		defaultMessage:
			'Ranking is disabled, as the Filter Query for this board is not ordered by ascending Rank.',
		description: 'Flag description. Informs a user why ranking is disabled',
	},
	contactAdmin: {
		id: 'roadmap.standard-roadmap.flags.rank-disabled.contact-admin',
		defaultMessage: 'Contact your admin to adjust the board filter.',
		description:
			'Flag description. Informs an end user to contact their admin as a means to resolve the warning.',
	},
	showJqlFilterForBoard: {
		id: 'roadmap.standard-roadmap.flags.rank-disabled.show-jql-filter-for-board',
		defaultMessage: 'Please check your filter configuration',
		description: 'Filter configuration caption',
	},
});
