import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { Color } from '@atlassian/jira-issue-epic-color';
import { connect } from '@atlassian/jira-react-redux';
import { RoadmapDependencyHandlers } from '@atlassian/jira-software-roadmap-timeline-table-kit';
import type { Placeholder } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/types';
import { createDependency } from '../../../../../../state/entities/issues/actions';
import { getChartDataHash } from '../../../../../../state/selectors/table';
import type { State } from '../../../../../../state/types';

type OwnProps = {
	isHovered: boolean;
	leftPosition: number;
	rightPosition: number;
	getBarBoundingClientRect: () => ClientRect | undefined;
	id: string;
	placeholder: Placeholder;
	onBarMouseLeave: () => void;
};

type StateProps = {
	color: Color;
};

const onCreateDependency = (from: string, to: string, analyticEvent: UIAnalyticsEvent) =>
	createDependency({ dependency: to, dependee: from }, analyticEvent);

export default connect(
	(state: State, { id }: OwnProps): StateProps => {
		const chartData = getChartDataHash(state)[id];
		return {
			color: chartData.color,
		};
	},
	{
		onCreateDependency,
	},
)(RoadmapDependencyHandlers);
