import { createSelector } from 'reselect';
import forEach from 'lodash/forEach';
import { defineSimpleStorage } from '@atlassian/jira-common-storage-redux/src';
import type { IssueTypeHash, CreationPreferences } from '@atlassian/jira-software-roadmap-model';
import { getFullIssueTypeHash } from '../../../state/configuration/selectors';
import { setCreationPreferences } from '../../../state/settings/actions';
import { getCreationPreferences } from '../../../state/settings/selectors';
import type { State } from '../../../state/types';

const STORAGE_KEY = 'creationPreferences';

// Make sure to exclude any types that may have been deleted from the project
const transformCreationPreferences = (
	creationPreferences: CreationPreferences,
	issueTypes: IssueTypeHash,
): CreationPreferences => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const itemTypes: Record<string, any> = {};
	const safeCreationPreferences: CreationPreferences = {
		itemTypes,
	};

	forEach(creationPreferences.itemTypes, (preference, level) => {
		// Can we still find the stored issueType in the retrieved issue types
		if (issueTypes[preference.itemTypeId]) {
			itemTypes[level] = preference;
		}
	});
	safeCreationPreferences.itemTypes = itemTypes;

	return safeCreationPreferences;
};

const mappedPreference = createSelector(
	getCreationPreferences,
	getFullIssueTypeHash,
	transformCreationPreferences,
);

export default defineSimpleStorage<State, CreationPreferences | null>(
	() => STORAGE_KEY,
	(creationPreferences: CreationPreferences | null) =>
		creationPreferences === null ? [] : [setCreationPreferences(creationPreferences)],
	(state: State) => mappedPreference(state),
);
