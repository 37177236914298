import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { batchActions } from 'redux-batched-actions';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { removeRoadmapDependencyMutation$data } from '@atlassian/jira-relay/src/__generated__/removeRoadmapDependencyMutation.graphql';
import { removeDependencyOnServer } from '@atlassian/jira-software-roadmap-services';
import { isStateChangeBasedIssueHiddenFlagsEnabled } from '../../../feature-flags';
import { DEPENDENCY_DELETE_ERROR } from '../../../model/dependencies';
import { getSourceARI } from '../../../state/app/selectors';
import {
	type RemoveDependencyAction,
	REMOVE_DEPENDENCY,
	deleteDependency,
	addDependency,
} from '../../../state/entities/issues/actions';
import {
	getIssueKeyHash,
	getIssueKeyById,
	getIssueLevel,
} from '../../../state/entities/issues/selectors';
import { aggError, dependenciesPermission } from '../../../state/flags/actions';
import type { State } from '../../../state/types';
import { reapplyJQLFilters } from '../../../state/ui/filters/actions';
import { isPermissionError } from '../../common/get-error';
import { onHandleAggErrors } from '../../common/handle-agg-errors';
import type { StateEpic } from '../../common/types';
import refreshIssuePage from '../refresh-issue-page';
import { getSafeDependencyInput } from './common';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<RemoveDependencyAction>, store: MiddlewareAPI<State>) =>
	action$.ofType(REMOVE_DEPENDENCY).mergeMap((action: RemoveDependencyAction) => {
		const {
			payload,
			meta: { analyticsEvent },
		} = action;
		const state = store.getState();
		const inputObservable = getSafeDependencyInput(state, action.payload);
		const sourceARI = getSourceARI(state);

		const longRunning = inputObservable.mergeMap((input) => {
			const { dependee, dependency } = input;

			const removeDependencyOnBackendPromise = removeDependencyOnServer({
				sourceARI,
				input: { dependee, dependency },
			}).mergeMap((data?: removeRoadmapDependencyMutation$data) => {
				const removeRoadmapDependencyData = data?.roadmaps?.removeRoadmapDependency;
				const errors = removeRoadmapDependencyData?.errors;
				const success = removeRoadmapDependencyData?.success;

				if (success === false && errors?.length) {
					if (isPermissionError(errors)) {
						// permissions error
						const actions = [
							addDependency(payload),
							dependenciesPermission({
								type: DEPENDENCY_DELETE_ERROR,
								dependeeKey: getIssueKeyHash(state)[payload.dependee],
								dependencyKey: getIssueKeyHash(state)[payload.dependency],
							}),
						];

						return Observable.of(batchActions(actions));
					}
					return onHandleAggErrors(!!removeRoadmapDependencyData, errors).map((aggErrorAction) =>
						batchActions([addDependency(payload), aggErrorAction]),
					);
				}

				refreshIssuePage(state, getIssueKeyById(state, payload.dependee));
				refreshIssuePage(state, getIssueKeyById(state, payload.dependency));

				fireTrackAnalytics(analyticsEvent, 'issueDependency deleted', {
					dependeeLevel: getIssueLevel(state, payload.dependee),
					dependencyLevel: getIssueLevel(state, payload.dependency),
				});

				return Observable.empty<never>();
			});

			return removeDependencyOnBackendPromise.catch(() => {
				const actions = [addDependency(payload), aggError()];
				return Observable.of(batchActions(actions));
			});
		});

		return Observable.concat(
			Observable.of(deleteDependency(payload)),
			longRunning,
			isStateChangeBasedIssueHiddenFlagsEnabled()
				? Observable.empty()
				: Observable.of(reapplyJQLFilters()),
		);
	})) as StateEpic;
