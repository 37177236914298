export type GraphQLStatusCategory = {
	readonly id: string;
	readonly key: string;
	readonly name: string;
};

export const transformStatusCategories = (statusCategories: GraphQLStatusCategory[]) => {
	const getTransformedStatusCategories = () => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const result: Record<string, any> = {};
		statusCategories.forEach((statusCategory) => {
			result[statusCategory.id] = {
				key: statusCategory.key,
				name: statusCategory.name,
			};
		});
		return result;
	};

	return getTransformedStatusCategories();
};
