import { connect } from '@atlassian/jira-react-redux';
import { hasMacroConfig } from '../../../../../state/app/selectors';
import type { State } from '../../../../../state/types';
import { OverlayButton } from './view';

export default connect(
	(state: State) => ({
		isMacroView: hasMacroConfig(state),
	}),
	{},
)(OverlayButton);
