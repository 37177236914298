import React from 'react';
import { FormattedMessage } from '@atlassian/jira-intl';
import type { DescriptionProps } from '../common/types';
import messages from './messages';

const BoardDescription = ({ issueCount, issueUrls }: DescriptionProps) => {
	const messageDescriptor =
		issueCount === 1 ? messages.description : messages.descriptionMultipleIssues;

	return <FormattedMessage {...messageDescriptor} values={{ issueUrls }} />;
};

export default BoardDescription;
