import { metrics } from '@atlassian/browser-metrics';

export const roadmapPageLoad = metrics.pageLoad({
	key: 'roadmap',
	ssr: {
		doneAsFmp: true,
	},
	slo: {
		fmp: {
			initial: { threshold: 1000 },
			transition: { threshold: 1000 },
		},
		tti: {
			initial: { threshold: 4000 },
			transition: { threshold: 4000 },
		},
	},
});
