import { N30, B50, N50, B300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import type { ClassName } from './types';

// =============== //
// === UTILS === //
// =============== //

// Can be used to transform the hex values from @atlaskit/theme into rgb/rgba
export const hexToRgb = (hexColor: string, alpha = 1): string => {
	const parsed = hexColor.match(/[a-f0-9]{2}/gi);
	if (!parsed || (parsed && parsed.length !== 3) || hexColor.indexOf('#') < 0) {
		return hexColor;
	}
	const rgb = parsed.map((hex) => parseInt(hex, 16));

	if (alpha < 1 && alpha >= 0) {
		return `rgba(${rgb.concat(alpha).join(',')})`;
	}
	return `rgb(${rgb.join()})`;
};

// =============== //
// === GETTERS === //
// =============== //

export const getClassName = (className: ClassName | ClassName[]) => ({
	className: Array.isArray(className) ? className.join(' ') : className,
});

export const getIndexBySelector = (scope: string, cssRules: CSSRuleList, target: string) =>
	Array.from(cssRules || []).findIndex(
		(cssRule) => 'selectorText' in cssRule && cssRule.selectorText === `.${scope} ${target}`,
	);

export const getIndexByRule = (cssRules: CSSRuleList, target: string) =>
	Array.from(cssRules || []).findIndex(({ cssText }) =>
		toRule(cssText).endsWith(`{${toRule(target)}}`),
	);

// ==================== //
// === TRANSFORMERS === //
// ==================== //

export const toSelector = (className: ClassName): string => `.${className}`;

export const toSelectors = (targetClassNames: ClassName[], scope: string): string[] =>
	targetClassNames.map((className: ClassName) => `.${scope} .${className}`);

/* As a step of CSS rule matching, standardise CSS rules by:
 * - converting untokenised color from hex to RGB (RGB is what's returned from native cssText)
 * - removing space and new lines (tokenised color variable may contain extra space in their CSS rules)
 */
export const toRule = (style: string): string =>
	style.replace(/#\w+/g, (hexColor: string) => hexToRgb(hexColor)).replace(/[\r\n\s]/g, '');

// =============== //
// === CLASSES === //
// =============== //

const row = (id: string): ClassName => `row-${id}`;
const rowChild = (id: string): ClassName => `row-child-${id}`;

const inlineCreate: ClassName = 'inline-create-button';
const inlineCreateIcon: ClassName = 'inline-create-button-icon';

const dragIndicator: ClassName = 'inline-drag-indicator';
const dragIndicatorTop: ClassName = 'inline-drag-indicator-top';

const itemsContainer: ClassName = 'items-container';
const createChild: ClassName = 'create-child-button';

export const classNames = {
	row,
	rowChild,
	inlineCreate,
	inlineCreateIcon,
	dragIndicator,
	dragIndicatorTop,
	itemsContainer,
	createChild,
};

// =============== //
// === STYLES ==== //
// =============== //

const itemHover = `
    background-color: ${token('color.background.neutral.subtle.hovered', N30)} !important;
`;

const itemSelected = `
    background-color: ${token('color.background.selected', B50)} !important;
`;

const itemDragging = `
    opacity: 0;
`;

const itemsContainerDragging = `
    background: ${token('elevation.surface.pressed', N50)} !important;
`;

const itemsContainerScrolling = `
    pointer-events: none;
`;

const itemCanBeCombined = `
    background-color: ${token('color.background.selected', N30)} !important;
`;

const selectedItemCanBeCombined = `
    background-color: ${token('color.background.selected.hovered', N30)} !important;
`;

const getDragIndicator = (top: number, left?: number) => `
    display: block;
    position: fixed;
    right: 0;
    transform: ${left ? `translate(${left}px, ${top}px)` : `translateY(${top}px)`};
    pointer-events: none;
`;

const getInlineCreateButton = (top: number) => `
    display: block;
    position: fixed;
    right: 0;
    transform: translateY(${top}px);
    pointer-events: none;
`;

const getInlineCreateIcon = () => `
	outline: 2px solid ${token('color.border.focused', B300)};
	outline-offset: 2px;
`;

export const styles = {
	itemHover,
	itemSelected,
	itemDragging,
	itemsContainerDragging,
	itemsContainerScrolling,
	itemCanBeCombined,
	selectedItemCanBeCombined,
	getDragIndicator,
	getInlineCreateButton,
	getInlineCreateIcon,
};

// ========================= //
// === CONSUMER CLASSES ==== //
// ========================= //

export const getDragIndicatorClassName = () => getClassName([dragIndicator, dragIndicatorTop]);

export const getInlineCreateButtonClassName = () => getClassName(inlineCreate);

export const getInlineCreateIconClassName = () => getClassName(inlineCreateIcon);

export const getItemsBackgroundClassName = () => getClassName(itemsContainer);

export const getCreateChildButtonClassName = () => getClassName(createChild);

export const getRowClassName = (id: string, parentId?: string) => {
	const classes = parentId !== undefined ? [row(id), rowChild(parentId)] : row(id);
	return getClassName(classes);
};
