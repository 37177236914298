import { combineStorage } from '@atlassian/jira-common-storage-redux/src';
import type { State } from '../../../state/types';
import dependenciesView from './dependencies-view';
import levelOneSettingView from './level-one-setting-view';
import progressView from './progress-view';
import releasesView from './releases-view';
import timelineMode from './timeline-mode';
import warningsView from './warnings-view';

export default combineStorage<State>(
	timelineMode,
	dependenciesView,
	progressView,
	warningsView,
	releasesView,
	levelOneSettingView,
);
