import { connect } from '@atlassian/jira-react-redux';
import {
	areDependenciesSupported,
	getIsChildIssuePlanningEnabled,
} from '../../../../../state/configuration/selectors';
import { getIssueLevel } from '../../../../../state/entities/issues/selectors';
import type { State } from '../../../../../state/types';
import type { OwnProps, StateProps } from './types';
import EditDependencyOption from './view';

const mapStateToProps = (state: State, { id }: OwnProps): StateProps => ({
	areDependenciesSupported: areDependenciesSupported(state),
	isChildIssuePlanningEnabled: getIsChildIssuePlanningEnabled(state),
	level: getIssueLevel(state, id),
});

export default connect(mapStateToProps)(EditDependencyOption);
