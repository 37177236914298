import type { FetchError } from '@atlassian/jira-fetch';
import type { Issue } from './issue';

export class NoDataError extends Error {}

export type IssueError = FetchError | NoDataError;

export type IssueServiceResult = {
	issue?: Issue;
	error?: IssueError;
};
