import React from 'react';
import { MARKER_COLORS, WARNING } from '../../../../common/constants';

const FailedIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		fill="none"
		viewBox="0 0 16 16"
		/**
		 * AK inline-dialog's handleClickOutside does not recognise svg elements
		 * https://bitbucket.org/atlassian/atlassian-frontend/src/master/packages/design-system/inline-dialog/src/InlineDialog/index.tsx#lines-60
		 */

		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		style={{ pointerEvents: 'none' }}
	>
		<circle r="6" cx="8" cy="8" fill="currentColor" />
		<path
			fill={MARKER_COLORS[WARNING]}
			fillRule="evenodd"
			d="M.768 9.768a2.5 2.5 0 010-3.536L6.232.768a2.5 2.5 0 013.536 0l5.464 5.464a2.5 2.5 0 010 3.536l-5.464 5.464a2.5 2.5 0 01-3.536 0L.768 9.768zM7 4a1 1 0 112 0v4a1 1 0 01-2 0V4zm1 8a1 1 0 100-2 1 1 0 000 2z"
			clipRule="evenodd"
		/>
	</svg>
);

export { FailedIcon };
