import React from 'react';
import {
	ContextualAnalyticsData,
	SCREEN,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import UnifiedHealthcheck from './unified-healthcheck/async';
import Healthcheck from './view';

const HealthcheckWithContext = () => (
	<ContextualAnalyticsData sourceName="healthcheck" sourceType={SCREEN}>
		<Healthcheck Indicator={UnifiedHealthcheck} />
		<FireScreenAnalytics />
	</ContextualAnalyticsData>
);

export default HealthcheckWithContext;
