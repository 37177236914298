import type { ActionsObservable } from 'redux-observable';
import { tap } from 'rxjs/operators/tap';
import { ROADMAPS } from '@atlassian/jira-software-resource-invalidator/src/common/types';
import CacheUpdateSubject from '@atlassian/jira-software-resource-invalidator/src/controllers/resources-cache-update-subject';
import {
	UPDATE_RESOURCE_CACHE,
	type UpdateResourceCacheAction,
} from '../../state/resource-cache/actions';
import type { StateEpic } from '../common/types';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<UpdateResourceCacheAction>) =>
	action$.ofType(UPDATE_RESOURCE_CACHE).pipe(
		tap(({ payload }) => {
			CacheUpdateSubject.next({
				origin: ROADMAPS,
				payload: { roadmapData: payload },
			});
		}),
	)) as StateEpic;
