import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	hiddenByFilterWarning: {
		id: 'roadmap.standard-roadmap.state.selectors.dependencies.hidden-by-filter-warning',
		defaultMessage: 'Hidden by filter',
		description:
			'Warning message text. Appears when a dependency issue is hidden by any issue filter.',
	},
	hiddenByViewsConfigurationWarning: {
		id: 'roadmap.standard-roadmap.state.selectors.dependencies.hidden-by-views-configuration-warning',
		defaultMessage: 'Hidden by "Views" dropdown',
		description:
			'Warning message text. Appears when a dependency issue is hidden by views configuration.',
	},
});
