import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

type Props = {
	symbol: ReactNode;
	label: string;
};

const LegendItem = ({ symbol, label }: Props) => (
	<Container>
		<Symbol>{symbol}</Symbol>
		{label}
	</Container>
);

export default LegendItem;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.span({
	display: 'flex',
	alignItems: 'center',
	color: token('color.text.subtle', N300),
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Symbol = styled.span({
	display: 'inline-flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: gridSize * 3,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: gridSize * 2.5,
	alignItems: 'center',
	justifyContent: 'center',
});
