import React from 'react';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';
import RedirectIfProjectArchived from '@atlassian/jira-redirect-archived-project/src/ui/index.tsx';

export const ArchivedProjectRedirect = () => {
	const [{ data, error }] = useContainerContext();
	// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'.
	const projectData = (data && data.project) || {};
	const { key: projectKey } = projectData;

	if (error || !data) {
		return null;
	}

	return <RedirectIfProjectArchived projectKey={projectKey || null} />;
};
