import type { ProjectKey } from '@atlassian/jira-shared-types';
import type { BoardId } from '@atlassian/jira-software-roadmap-model';

export const getRoadmapSettingsUrl = (
	isCMP: boolean,
	projectKey: ProjectKey,
	boardId: BoardId,
): string => {
	const cmpRoadmapConfigUrl = `/jira/software/c/projects/${projectKey}/boards/${boardId}?config=timelineConfig`;
	const tmpRoadmapConfigUrl = `/jira/software/projects/${projectKey}/settings/boards/${boardId}/timeline`;

	return isCMP ? cmpRoadmapConfigUrl : tmpRoadmapConfigUrl;
};
