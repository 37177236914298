/**
 * @generated SignedSource<<4bbe94915c9a58784e4d72bfe5e79419>>
 * @relayHash dce5379cadea1d78150457c03377ca0b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 90c1860875ba5b16004b821f7b613eaa617bf47cc6b4cdff8568a42a6c8d5729

import type { ConcreteRequest, Query } from 'relay-runtime';
export type RoadmapPaletteColor = "BLUE" | "DARK_BLUE" | "DARK_GREEN" | "DARK_GREY" | "DARK_ORANGE" | "DARK_PURPLE" | "DARK_TEAL" | "DARK_YELLOW" | "GREEN" | "GREY" | "ORANGE" | "PURPLE" | "TEAL" | "YELLOW" | "%future added value";
export type getItemsByIdsQuery$variables = {
  ids: ReadonlyArray<string>;
  isRoadmapsCriticalDataItemFlaggedEnabled: boolean;
  sourceAri: string;
};
export type getItemsByIdsQuery$data = {
  readonly roadmaps: {
    readonly roadmapItemByIds: ReadonlyArray<{
      readonly assignee: {
        readonly accountId: string;
        readonly name: string;
        readonly picture: AGG$URL;
      } | null | undefined;
      readonly color: RoadmapPaletteColor;
      readonly componentIds: ReadonlyArray<string>;
      readonly dependencies: ReadonlyArray<string>;
      readonly dueDateRFC3339: AGG$Date | null | undefined;
      readonly flagged?: boolean;
      readonly id: string;
      readonly itemTypeId: AGG$Long;
      readonly key: string;
      readonly labels: ReadonlyArray<string>;
      readonly parentId: string | null | undefined;
      readonly rank: string;
      readonly resolved: boolean;
      readonly sprintIds: ReadonlyArray<string>;
      readonly startDateRFC3339: AGG$Date | null | undefined;
      readonly status: {
        readonly id: string;
        readonly name: string;
        readonly statusCategory: {
          readonly id: string;
        };
      } | null | undefined;
      readonly summary: string;
      readonly versionIds: ReadonlyArray<string>;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type getItemsByIdsQuery = {
  response: getItemsByIdsQuery$data;
  variables: getItemsByIdsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ids"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isRoadmapsCriticalDataItemFlaggedEnabled"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceAri"
},
v3 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  },
  {
    "kind": "Variable",
    "name": "sourceARI",
    "variableName": "sourceAri"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "key"
},
v6 = {
  "kind": "ScalarField",
  "name": "parentId"
},
v7 = {
  "kind": "ScalarField",
  "name": "summary"
},
v8 = {
  "kind": "ScalarField",
  "name": "startDateRFC3339"
},
v9 = {
  "kind": "ScalarField",
  "name": "dueDateRFC3339"
},
v10 = {
  "kind": "ScalarField",
  "name": "color"
},
v11 = {
  "kind": "ScalarField",
  "name": "dependencies"
},
v12 = {
  "kind": "ScalarField",
  "name": "itemTypeId"
},
v13 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v14 = {
  "kind": "ScalarField",
  "name": "name"
},
v15 = {
  "kind": "ScalarField",
  "name": "picture"
},
v16 = {
  "kind": "ScalarField",
  "name": "rank"
},
v17 = {
  "kind": "ScalarField",
  "name": "labels"
},
v18 = {
  "kind": "ScalarField",
  "name": "sprintIds"
},
v19 = {
  "kind": "ScalarField",
  "name": "versionIds"
},
v20 = {
  "kind": "ScalarField",
  "name": "componentIds"
},
v21 = {
  "kind": "ScalarField",
  "name": "resolved"
},
v22 = {
  "kind": "ScalarField",
  "name": "flagged"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "getItemsByIdsQuery",
    "selections": [
      {
        "concreteType": "RoadmapsQuery",
        "kind": "LinkedField",
        "name": "roadmaps",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "RoadmapItem",
            "kind": "LinkedField",
            "name": "roadmapItemByIds",
            "plural": true,
            "selections": [
              {
                "kind": "RequiredField",
                "field": (v4/*: any*/),
                "action": "THROW",
                "path": "roadmaps.roadmapItemByIds.id"
              },
              {
                "kind": "RequiredField",
                "field": (v5/*: any*/),
                "action": "THROW",
                "path": "roadmaps.roadmapItemByIds.key"
              },
              (v6/*: any*/),
              {
                "kind": "RequiredField",
                "field": (v7/*: any*/),
                "action": "THROW",
                "path": "roadmaps.roadmapItemByIds.summary"
              },
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "kind": "RequiredField",
                "field": (v10/*: any*/),
                "action": "THROW",
                "path": "roadmaps.roadmapItemByIds.color"
              },
              {
                "kind": "RequiredField",
                "field": (v11/*: any*/),
                "action": "THROW",
                "path": "roadmaps.roadmapItemByIds.dependencies"
              },
              {
                "kind": "RequiredField",
                "field": (v12/*: any*/),
                "action": "THROW",
                "path": "roadmaps.roadmapItemByIds.itemTypeId"
              },
              {
                "kind": "LinkedField",
                "name": "assignee",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v13/*: any*/),
                    "action": "THROW",
                    "path": "roadmaps.roadmapItemByIds.assignee.accountId"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v14/*: any*/),
                    "action": "THROW",
                    "path": "roadmaps.roadmapItemByIds.assignee.name"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v15/*: any*/),
                    "action": "THROW",
                    "path": "roadmaps.roadmapItemByIds.assignee.picture"
                  }
                ]
              },
              {
                "concreteType": "RoadmapItemStatus",
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v4/*: any*/),
                    "action": "THROW",
                    "path": "roadmaps.roadmapItemByIds.status.id"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v14/*: any*/),
                    "action": "THROW",
                    "path": "roadmaps.roadmapItemByIds.status.name"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "RoadmapItemStatusCategory",
                      "kind": "LinkedField",
                      "name": "statusCategory",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v4/*: any*/),
                          "action": "THROW",
                          "path": "roadmaps.roadmapItemByIds.status.statusCategory.id"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "roadmaps.roadmapItemByIds.status.statusCategory"
                  }
                ]
              },
              {
                "kind": "RequiredField",
                "field": (v16/*: any*/),
                "action": "THROW",
                "path": "roadmaps.roadmapItemByIds.rank"
              },
              {
                "kind": "RequiredField",
                "field": (v17/*: any*/),
                "action": "THROW",
                "path": "roadmaps.roadmapItemByIds.labels"
              },
              {
                "kind": "RequiredField",
                "field": (v18/*: any*/),
                "action": "THROW",
                "path": "roadmaps.roadmapItemByIds.sprintIds"
              },
              {
                "kind": "RequiredField",
                "field": (v19/*: any*/),
                "action": "THROW",
                "path": "roadmaps.roadmapItemByIds.versionIds"
              },
              {
                "kind": "RequiredField",
                "field": (v20/*: any*/),
                "action": "THROW",
                "path": "roadmaps.roadmapItemByIds.componentIds"
              },
              {
                "kind": "RequiredField",
                "field": (v21/*: any*/),
                "action": "THROW",
                "path": "roadmaps.roadmapItemByIds.resolved"
              },
              {
                "condition": "isRoadmapsCriticalDataItemFlaggedEnabled",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v22/*: any*/),
                    "action": "THROW",
                    "path": "roadmaps.roadmapItemByIds.flagged"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "getItemsByIdsQuery",
    "selections": [
      {
        "concreteType": "RoadmapsQuery",
        "kind": "LinkedField",
        "name": "roadmaps",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "RoadmapItem",
            "kind": "LinkedField",
            "name": "roadmapItemByIds",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "kind": "LinkedField",
                "name": "assignee",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "__typename"
                  },
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v4/*: any*/)
                ]
              },
              {
                "concreteType": "RoadmapItemStatus",
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v14/*: any*/),
                  {
                    "concreteType": "RoadmapItemStatusCategory",
                    "kind": "LinkedField",
                    "name": "statusCategory",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ]
                  }
                ]
              },
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              {
                "condition": "isRoadmapsCriticalDataItemFlaggedEnabled",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  (v22/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "90c1860875ba5b16004b821f7b613eaa617bf47cc6b4cdff8568a42a6c8d5729",
    "metadata": {},
    "name": "getItemsByIdsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "591daa91640ce28204a62d6e310a6911";

export default node;
