import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import memoizeOne from 'memoize-one';
import type { IssueId, VersionId } from '@atlassian/jira-shared-types';
import { UNASSIGNED_VERSION_ID, type Version } from '@atlassian/jira-software-filters';
import {
	type Hash,
	type IdentifiableHash,
	type VersionsHash,
	RELEASED,
	UNRELEASED,
} from '@atlassian/jira-software-roadmap-model';
import {
	COMPLETED,
	IN_PROGRESS,
	WARNING,
} from '@atlassian/jira-software-roadmap-timeline-table-kit';
import type { KeyDate } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/types';

export const createGetSanitisedIssueVersionIdsHash = (
	issueVersionIdsHash: Hash<VersionId[]>,
	allVersionsHash: VersionsHash,
): Hash<VersionId[]> => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: Record<string, any> = {};

	Object.keys(issueVersionIdsHash).forEach((issueId: IssueId) => {
		const versionIds = issueVersionIdsHash[issueId];
		result[issueId] = versionIds.filter((versionId: VersionId) =>
			Boolean(allVersionsHash[versionId]),
		);
	});

	return result;
};

export const createGetUniqueVersions = (
	issueVersionsHash: IdentifiableHash<IssueId, VersionId[]>,
): VersionId[] => {
	const allVersions: VersionId[] = flatten(
		Object.keys(issueVersionsHash).map((key) => {
			const issueVersions: VersionId[] = issueVersionsHash[key] || [];
			return issueVersions;
		}),
	);
	const uniqueVersions = uniq(allVersions);

	return uniqueVersions.length ? [UNASSIGNED_VERSION_ID, ...uniqueVersions] : uniqueVersions;
};

export const createGetVersionsForFilter = (
	uniqueVersions: VersionId[],
	versionsHash: VersionsHash,
): Version[] => {
	const versions: Version[] = [];

	uniqueVersions.forEach((versionId: VersionId) => {
		const version = versionsHash[versionId];

		// NOTE: Leave unassigned version case out as it's not a real version
		if (version) {
			versions.push({
				id: versionId,
				name: version.name,
			});
		}
	});

	return versions.sort((versionA, versionB) =>
		versionA.name.toLowerCase() < versionB.name.toLowerCase() ? -1 : 1,
	);
};

export const createGetVersionsForKeyDates = (versionsHash: VersionsHash) =>
	memoizeOne((today: number): ReadonlyArray<KeyDate> => {
		const keyDates: KeyDate[] = [];

		const STATUS_MAP = {
			[UNRELEASED]: IN_PROGRESS,
			[RELEASED]: COMPLETED,
		};

		Object.keys(versionsHash).forEach((versionId: VersionId) => {
			const version = versionsHash[versionId];

			if (version.releaseDate !== undefined) {
				keyDates.push({
					id: versionId,
					name: version.name,
					date: version.releaseDate,
					status:
						version.status === UNRELEASED && version.releaseDate < today
							? WARNING
							: STATUS_MAP[version.status],
				});
			}
		});

		return keyDates;
	});
