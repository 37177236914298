import { connect } from '@atlassian/jira-react-redux';
import { getContainerName } from '../../state/app/selectors';
import type { State } from '../../state/types';
import DocumentTitle from './view';

export default connect(
	(state: State) => ({
		projectName: getContainerName(state),
	}),
	{},
)(DocumentTitle);
