import { N30, B300, G300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export const TODO_COLOR = token('color.background.neutral', N30);
export const TODO_COLOR_HIGHER_CONTRAST = token('color.border', N30);

export const IN_PROGRESS_COLOR = token('color.text.information', B300);
export const IN_PROGRESS_COLOR_HIGHER_CONTRAST = token('color.border.information', B300);

export const DONE_COLOR = token('color.background.success.bold', G300);
export const DONE_COLOR_HIGHER_CONTRAST = token('color.border.success', G300);
