/** @jsx jsx */
import React, { memo, useCallback, type MouseEvent } from 'react';
import { css, jsx } from '@compiled/react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import { N40, N500, N50, B50, B400, B100 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import { INSIDE } from '../../../../../common/constants';
import { getCreateChildButtonClassName } from '../../../../../common/context/side-effect-marshal';
import type { ItemId, OnCreateClick } from '../../../../../common/types';
import { fireUIAnalyticsEvent } from '../../../../../common/utils/analytics';

const buttonStyles = css({
	padding: 0,
	margin: 0,
	border: 'none',
	cursor: 'pointer',
	width: token('space.300', '24px'),
	height: token('space.300', '24px'),
	borderRadius: token('border.radius', '3px'),
	verticalAlign: 'top',
	backgroundColor: token('color.background.neutral', N40),
	color: token('color.text.subtle', N500),
	outline: 'none',
	'&:focus': {
		outline: 'none',
	},
	'&:focus-visible': {
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused', B100)}`,
	},
	'&:hover, &:focus': {
		backgroundColor: token('color.background.neutral.hovered', N50),
	},
	'&:active': {
		backgroundColor: token('color.background.selected.pressed', B50),
		color: token('color.icon.selected', B400),
	},
});

type Props = {
	parentId: ItemId;
	label: string;
	onInlineCreateClicked: OnCreateClick;
};

const CreateChildButton = ({ parentId, label, onInlineCreateClicked }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClick = useCallback(
		(event: MouseEvent<HTMLButtonElement>) => {
			// Do not consider clicking this as also clicking on the list item itself
			event.stopPropagation();

			traceUFOPress('timeline-add-item-clicked', event.timeStamp);

			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'childIssueCreateButton',
				attributes: {
					position: INSIDE,
				},
			});

			fireUIAnalyticsEvent(analyticsEvent);

			onInlineCreateClicked({ parentId, position: INSIDE });
		},
		[createAnalyticsEvent, parentId, onInlineCreateClicked],
	);

	return (
		<Tooltip content={label}>
			{fg('jsw_roadmaps_timeline-issue-creation-ally') ? (
				(tooltipProps) => (
					<button
						{...tooltipProps}
						css={buttonStyles}
						data-testid={`roadmap.timeline-table.components.list-item.create-child-button.container-${parentId}`}
						aria-label={label}
						onClick={onClick}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={getCreateChildButtonClassName().className}
					>
						<EditorAddIcon label="" size="medium" />
					</button>
				)
			) : (
				<button
					css={buttonStyles}
					data-testid={`roadmap.timeline-table.components.list-item.create-child-button.container-${parentId}`}
					aria-label={label}
					onClick={onClick}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={getCreateChildButtonClassName().className}
				>
					<EditorAddIcon label="" size="medium" />
				</button>
			)}
		</Tooltip>
	);
};

export default memo<Props>(CreateChildButton);
