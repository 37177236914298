import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	rollUpIconLabel: {
		id: 'roadmap.timeline-table-kit.common.rollup-icon.roll-up-icon-label',
		defaultMessage: 'Roll-up',
		description: 'Accessibility label for a roll-up icon.',
	},
});

export default messages;
