import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import type { IssueId } from '@atlassian/jira-shared-types';
import type { RankRequest } from '@atlassian/jira-software-roadmap-model';
import type { GraphqlRankBeforeRequest } from '@atlassian/jira-software-roadmap-services';
import { getSafeId } from '../../../state/entities/issues/selectors';
import type { State } from '../../../state/types';

export const getSafeRankRequest = (state: State, request: RankRequest): Observable<RankRequest> => {
	if ('rankAfterId' in request) {
		return getSafeId(state, request.rankAfterId).map((safeIssueId) => ({
			rankAfterId: safeIssueId,
		}));
	}
	return getSafeId(state, request.rankBeforeId).map((safeIssueId) => ({
		rankBeforeId: safeIssueId,
	}));
};

export const getSafeRankBeforeRequest = (
	state: State,
	beforeId?: IssueId,
): Observable<GraphqlRankBeforeRequest | null> => {
	if (beforeId !== undefined) {
		return getSafeId(state, beforeId).map((safeIssueId) =>
			safeIssueId !== undefined
				? {
						beforeId: safeIssueId,
					}
				: null,
		);
	}
	return Observable.of(null);
};
