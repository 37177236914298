import { connect } from '@atlassian/jira-react-redux';
import {
	isDependenciesVisibleInQuery,
	isProgressVisibleInQuery,
	isWarningsVisibleInQuery,
	isReleasesVisibleInQuery,
} from '../../../../state/router/selectors';
import { getRoadmapImageExportMetrics } from '../../../../state/selectors/analytics';
import type { State } from '../../../../state/types';
import ShareModal from './share-modal/async';
import ShareModalWithAnalytics, { type StateProps } from './view';

export default connect(
	(state: State): StateProps => ({
		ShareModal,
		isDependenciesVisible: isDependenciesVisibleInQuery(state),
		isProgressVisible: isProgressVisibleInQuery(state),
		isWarningsVisible: isWarningsVisibleInQuery(state),
		isReleasesVisible: isReleasesVisibleInQuery(state),
		imageExportMetrics: getRoadmapImageExportMetrics(state),
	}),
	{},
)(ShareModalWithAnalytics);
