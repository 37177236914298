import type { Action } from 'redux';
import { type BatchActionType, BATCH } from 'redux-batched-actions';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/ignoreElements';

export type BatchAction<T extends Action> = {
	type: BatchActionType;
	payload: T[];
};

export type BatchActionsObservable<T extends Action> = ActionsObservable<T | BatchAction<T>>;

export const isBatched = <T extends Action>(action: T | BatchAction<T>): action is BatchAction<T> =>
	action.type === BATCH;

type GroupedAction<A extends Action> =
	A extends Action<infer T>
		? Omit<A, 'type' | 'payload'> & {
				type: T;
				payload: A[];
				grouped: true;
			}
		: never;

const group = <T,>(values: T[], extractKey: (value: T) => string): Record<string, T[]> =>
	values.reduce<Record<string, T[]>>((acc, curr) => {
		const key = extractKey(curr);
		if (acc[key] === undefined) acc[key] = [];
		acc[key].push(curr);
		return acc;
	}, {});

const batchActionToGroupedActions = <T extends Action>(
	action: BatchAction<T> | T,
): GroupedAction<T>[] => {
	const actions = isBatched(action) ? action.payload : [action];
	const groupedActions = group(actions, ({ type }) => type);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return Object.entries(groupedActions).map(([key, value]) => ({
		type: key,
		payload: value,
		grouped: true,
	})) as GroupedAction<T>[];
};

type ProcessObservableInput<T extends Action> = {
	change: [string[], string[]];
	action: T | BatchAction<T>;
};

type ProcessObservableOutput<T extends Action> = {
	change: [string[], string[]];
	action: GroupedAction<T>;
};

export const groupBatchedActionsObservable = <T extends Action>(
	action$: Observable<ProcessObservableInput<T>>,
): Observable<ProcessObservableOutput<T>> =>
	action$.mergeMap(({ change, action }) =>
		Observable.from(
			batchActionToGroupedActions(action).map((groupedAction) => ({
				change,
				action: groupedAction,
			})),
		),
	);
