import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	filterPlaceholder: {
		id: 'roadmap.standard-roadmap.header.filter-bar.filter-placeholder',
		defaultMessage: 'Search timeline',
		description:
			'The placeholder of the input textbox used to enter text to filter issues by in the timeline view',
	},
});
