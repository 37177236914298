import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	weeks: {
		id: 'roadmap.timeline-table-kit.chart-overlay.timeline-mode.weeks',
		defaultMessage: 'Weeks',
		description: 'Button caption. Sets a users timeline to be viewed in week intervals',
	},
	months: {
		id: 'roadmap.timeline-table-kit.chart-overlay.timeline-mode.months',
		defaultMessage: 'Months',
		description: 'Button caption. Sets a users timeline to be viewed in month intervals',
	},
	quarters: {
		id: 'roadmap.timeline-table-kit.chart-overlay.timeline-mode.quarters',
		defaultMessage: 'Quarters',
		description:
			'Button caption. Sets a users timeline to be viewed in quarter of a year intervals',
	},
});
