import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { createSelector } from 'reselect';
import forEach from 'lodash/forEach';
import invertBy from 'lodash/invertBy';
import noop from 'lodash/noop';
import { Observable } from 'rxjs/Observable';
import { type Color, PURPLE } from '@atlassian/jira-issue-epic-color';
import type {
	ComponentId,
	IssueTypeId,
	AccountId,
	VersionId,
	IssueId,
	IssueKey,
	IssueStatusCategoryId,
} from '@atlassian/jira-shared-types';
import type { Label } from '@atlassian/jira-software-filters';
import {
	BASE_LEVEL,
	PARENT_LEVEL,
	type Status,
	type SprintId,
	type Hash,
	type IdentifiableHash,
	type SortedIssueHash,
	type Issue,
} from '@atlassian/jira-software-roadmap-model';
import {
	createVersionedEntityHashDiffSelector,
	createPropertyHashSelector,
} from '../../../common/utils/reselect';
import type { IssueHash } from '../../../model/issue';
import { getRequiredFieldsByIssueTypeId, getEpicIssueTypeIds } from '../../configuration/selectors';
import { getSubtaskParentHash } from '../../selectors/subtasks';
import type { State, PropertyHashSelector } from '../../types';
import type { ResolveTransitionIdFunction, TransitionHandler } from './types';

const get = (state: State): SortedIssueHash => state.entities.issues;

export const getFullIssueHash = (state: State): IssueHash => get(state).hash;

export const getIssue = (state: State, issueId: IssueId): Issue | undefined =>
	getFullIssueHash(state)[issueId];

export const isIssueExists = (state: State, issueId: IssueId): boolean =>
	Boolean(getIssue(state, issueId)) || Boolean(getSubtaskParentHash(state)[issueId]);

export const getIssueIds = (state: State): IssueId[] => get(state).sequence;

// ============== //
// === HASHES === //
// ============== //

const entityUpdateSelector = createVersionedEntityHashDiffSelector<Issue, State>(getFullIssueHash);

export const getIssueKeyHash: PropertyHashSelector<IssueKey | undefined> =
	createPropertyHashSelector(entityUpdateSelector, (issue) => issue.key);

export const getIssueSummaryHash: PropertyHashSelector<string> = createPropertyHashSelector(
	entityUpdateSelector,
	(issue) => issue.summary.value,
);

export const getIssueSummary = (state: State, issueId: IssueId) =>
	getIssueSummaryHash(state)[issueId];

export const getIssueLabelsHash: PropertyHashSelector<Label[]> = createPropertyHashSelector(
	entityUpdateSelector,
	(issue) => issue.labels,
);

export const getIssueLabels = (state: State, issueId: IssueId): Label[] =>
	getIssueLabelsHash(state)[issueId];

// Do not use this, use "getSanitisedIssueVersionIdsHash" instead
export const getIssueVersionIdsHash: PropertyHashSelector<VersionId[]> = createPropertyHashSelector(
	entityUpdateSelector,
	(issue) => issue.versionIds,
);

// Do not use this, use "getSanitisedIssueComponentIdsHash" instead
export const getIssueComponentIdsHash: PropertyHashSelector<ComponentId[]> =
	createPropertyHashSelector(entityUpdateSelector, (issue) => issue.componentIds);

// Do not use this, use "getSanitisedIssueSprintsHash" instead
export const getIssueSprintIdsHash: PropertyHashSelector<SprintId[]> = createPropertyHashSelector(
	entityUpdateSelector,
	(issue) => issue.sprintIds.value,
);

export const getIssueStartDateHash: PropertyHashSelector<number | undefined> =
	createPropertyHashSelector(entityUpdateSelector, (issue) => issue.startDate.value);

export const getIssueStartDate = (state: State, issueId: IssueId): number | undefined =>
	getIssueStartDateHash(state)[issueId];

export const getIssueDueDateHash: PropertyHashSelector<number | undefined> =
	createPropertyHashSelector(entityUpdateSelector, (issue) => issue.dueDate.value);

export const getIssueDueDate = (state: State, issueId: IssueId): number | undefined =>
	getIssueDueDateHash(state)[issueId];

export const getIssueStatusHash: PropertyHashSelector<Status | undefined> =
	createPropertyHashSelector(entityUpdateSelector, (issue) => issue.status);

export const getIssueStatusCategoryIdHash: PropertyHashSelector<IssueStatusCategoryId | undefined> =
	createPropertyHashSelector(entityUpdateSelector, (issue) =>
		issue.status ? issue.status.statusCategoryId : undefined,
	);

export const getIssueColorHash: PropertyHashSelector<Color> = createPropertyHashSelector(
	entityUpdateSelector,
	(issue) => issue?.color.value || PURPLE,
);

export const getIssueColor = (state: State, issueId: IssueId): Color =>
	getIssueColorHash(state)[issueId];

export const getIssueDependenciesHash: PropertyHashSelector<IssueId[]> = createPropertyHashSelector(
	entityUpdateSelector,
	(issue) => issue.dependencies,
);

export const hasDependencies = (state: State, issueId: IssueId): boolean => {
	const dependencies = getIssueDependenciesHash(state)[issueId];

	return Boolean(dependencies && dependencies.length > 0);
};

export const getIssueDependeesHash = createSelector(
	getIssueDependenciesHash,
	(dependenciesHash: Hash<IssueId[]>) => {
		const result: Hash<IssueId[]> = {};

		forEach(dependenciesHash, (dependencies, issueId) => {
			dependencies.forEach((dependencyId) => {
				if (!result[dependencyId]) {
					result[dependencyId] = [];
				}

				result[dependencyId].push(issueId);
			});
		});

		return result;
	},
);

export const getIssueCreationTransitionHash: PropertyHashSelector<boolean | undefined> =
	createPropertyHashSelector(entityUpdateSelector, (issue) => issue.inCreation);

export const getIssueTypeIdHash: PropertyHashSelector<IssueTypeId> = createPropertyHashSelector(
	entityUpdateSelector,
	(issue) => issue.issueTypeId,
);

export const getIssueParentIdHash: PropertyHashSelector<IssueId | undefined> =
	createPropertyHashSelector(entityUpdateSelector, (issue) => issue.parentId.value);

export const getIssueParentIds = createSelector(getIssueParentIdHash, (issueParentIdHash) =>
	Object.keys(issueParentIdHash).filter((issueId) => issueParentIdHash[issueId] === undefined),
);

export const getIssueChildrenHash = createSelector(getIssueParentIdHash, invertBy);

export const getIssueAssigneeHash: PropertyHashSelector<AccountId | undefined> =
	createPropertyHashSelector(entityUpdateSelector, (issue) => issue.assignee);

export const getIssueRankHash: PropertyHashSelector<string | undefined> =
	createPropertyHashSelector(entityUpdateSelector, (issue) => issue.rank);

export const getIssueTypeIdForIssueId = (state: State, issueId: IssueId): IssueTypeId =>
	getIssueTypeIdHash(state)[issueId];

export const getIssueRank = (state: State, issueId: IssueId): string | undefined =>
	getIssueRankHash(state)[issueId];

export const getIssueKeyToIdHash = createSelector(
	getIssueKeyHash,
	(keyHash: Hash<IssueKey | undefined>): IdentifiableHash<IssueKey, IssueId> => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const result: Record<string, any> = {};

		Object.keys(keyHash).forEach((id: string) => {
			const issueKey = keyHash[id];
			if (issueKey !== undefined && typeof issueKey === 'string') {
				result[issueKey] = id;
			}
		});
		return result;
	},
);

export const getIssueKeyById = (state: State, issueId: IssueId): IssueKey | undefined =>
	getIssueKeyHash(state)[issueId];

export const isIssueInTransition = (state: State, issueId: IssueId): boolean =>
	getIssueCreationTransitionHash(state)[issueId] === true;

// ================== //
// === TRANSITION === //
// ================== //

const getTransitionHandlers: PropertyHashSelector<TransitionHandler | undefined> =
	createPropertyHashSelector(
		entityUpdateSelector,
		(issue: Issue, id: string): TransitionHandler => {
			if (issue.inCreation) {
				const resolve = {
					func: noop,
				};

				const promise = new Promise((resolution: ResolveTransitionIdFunction) => {
					resolve.func = resolution;
				});

				return {
					observable: Observable.fromPromise(promise),
					resolve: resolve.func,
				};
			}
			return {
				observable: Observable.of(id),
				resolve: noop,
			};
		},
	);

const getSafeTransitionId = (state: State, id: IssueId): TransitionHandler | undefined =>
	getTransitionHandlers(state)[id];

export const getSafeId = (state: State, id: IssueId): Observable<IssueId> => {
	const transitionId = getSafeTransitionId(state, id);

	if (transitionId === undefined) {
		return Observable.of({}).mergeMap(() => {
			throw Error(`no safe id can be retrieved for id ${id}`);
		});
	}

	return transitionId.observable;
};

export const getSafeIds = (state: State, ids: IssueId[]): Observable<IssueId[]> =>
	Observable.forkJoin(ids.map((id: IssueId) => getSafeId(state, id))).map((safeIds) => safeIds);

export const getSafeIdResolve = (state: State, id: IssueId): ResolveTransitionIdFunction => {
	const transitionId = getSafeTransitionId(state, id);

	if (transitionId === undefined) {
		return () => {
			throw Error(`cannot resolve id ${id}`);
		};
	}

	return transitionId.resolve;
};

export const getIssueLevel = (state: State, issueId: IssueId): number => {
	if (getEpicIssueTypeIds(state).includes(getIssueTypeIdForIssueId(state, issueId))) {
		return PARENT_LEVEL;
	}
	return getIssueParentIdHash(state)[issueId] === undefined ? PARENT_LEVEL : BASE_LEVEL;
};

export const getIsIssueStartDateRequired = (state: State, issueId: IssueId): boolean =>
	getRequiredFieldsByIssueTypeId(state, getIssueTypeIdForIssueId(state, issueId)).includes(
		'startdate',
	);

export const getIsIssueDueDateRequired = (state: State, issueId: IssueId): boolean =>
	getRequiredFieldsByIssueTypeId(state, getIssueTypeIdForIssueId(state, issueId)).includes(
		'duedate',
	);

export const getIsIssueResolvedHash = createPropertyHashSelector(
	entityUpdateSelector,
	(issue) => issue.resolved,
);

export const getIsIssueResolved = (state: State, issueId: IssueId): boolean =>
	getIsIssueResolvedHash(state)[issueId];

export const getIsIssueFlaggedHash = createPropertyHashSelector(
	entityUpdateSelector,
	(issue) => issue.flagged,
);
