import get from 'lodash/get';
import last from 'lodash/last';
import type { AggErrors } from '../types';
import {
	statusCodesNotImpactingReliability,
	statusCodesToSkipForSentry,
	errorMessagesNotImpactingReliability,
	errorMessagesToSkipForSentry,
} from './constants';

export const GRAPHQL_ERROR = 'graphql' as const;
export const UNKNOWN_ERROR = 'unknown' as const;
export const NETWORK_ERROR = 'network' as const;
export const MISSING_DATA_ERROR = 'missingData' as const;
export const INVALID_PAYLOAD_ERROR = 'invalidPayload' as const;

export type AGG_ERROR_KIND =
	| typeof GRAPHQL_ERROR
	| typeof UNKNOWN_ERROR
	| typeof NETWORK_ERROR
	| typeof INVALID_PAYLOAD_ERROR
	| typeof MISSING_DATA_ERROR;

const errorSanitizationRegex = /\bNetwork\serror:|GraphQL\serror:/;

export class AggError extends Error {
	errors: AggErrors;

	errorType: AGG_ERROR_KIND;

	traceIds: string[];

	traceId: string | undefined;

	statusCodes: number[];

	userMessages: string[];

	affectsReliability = true;

	skipSentry = true;

	getCause(): Error | undefined {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		return (this as any).cause;
	}

	constructor(
		message: string,
		errors: AggErrors,
		traceIds: string[],
		errorType: AGG_ERROR_KIND,
		cause?: Error,
	) {
		// using the now widely support error cause mechanism https://github.com/tc39/proposal-error-cause. This is also supported by sentry.
		super(message, { cause });
		this.errors = errors;
		this.traceIds = traceIds;
		this.errorType = errorType;

		this.traceId = last(this.traceIds);
		this.statusCodes = errors
			.map((graphQLError) => graphQLError?.extensions?.statusCode)
			.filter((x): x is number => x != null);
		this.userMessages = errors
			.map(
				(graphQLError) =>
					get(graphQLError, 'extensions.userMessage', undefined) ||
					graphQLError?.message ||
					undefined,
			)
			.filter((x): x is string => x != null);
		this.computeAffectsReliability();
		this.skipSentry =
			!this.affectsReliability ||
			this.statusCodes.some((statusCode) => statusCodesToSkipForSentry.includes(statusCode)) ||
			this.userMessages.some((msg) =>
				errorMessagesToSkipForSentry.includes(msg.replace(errorSanitizationRegex, '').trim()),
			);
	}

	private computeAffectsReliability() {
		const causeTrimmedMessage =
			this.getCause()?.message?.replace(errorSanitizationRegex, '').trim() || '';

		this.affectsReliability =
			this.affectsReliability &&
			!this.statusCodes.some((code) => statusCodesNotImpactingReliability.includes(Number(code))) &&
			!errorMessagesNotImpactingReliability.includes(causeTrimmedMessage) &&
			!this.userMessages.some((message) =>
				errorMessagesNotImpactingReliability.includes(
					message.replace(errorSanitizationRegex, '').trim(),
				),
			);
	}
}

export type AggErrorInstance = InstanceType<typeof AggError>;
