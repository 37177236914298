import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	duration: {
		id: 'roadmap.timeline-table-kit.common.date-labels.duration.duration',
		defaultMessage: '{duration}d',
		description:
			'The duration in days between the given "from" and "to" dates. This duration is displayed inside a pill. ' +
			'The "d" is short for "days" and should only be 1 character long.',
	},
});
