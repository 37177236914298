import join from 'lodash/join';
import qs from 'query-string';
import type {
	ComponentId,
	IssueTypeId,
	AccountId,
	VersionId,
	IssueStatusCategoryId,
	IssueId,
} from '@atlassian/jira-shared-types';
import type { Label, CustomFilterTypeId } from '@atlassian/jira-software-filters';
import {
	type Query,
	type QuickFilterId,
	type RoadmapType,
	NEXTGEN,
} from '@atlassian/jira-software-roadmap-model';
import { getSanitizedQuery } from '@atlassian/jira-software-roadmap-services';

const stringifyValuesList = <TValue extends string>(
	value?: TValue | TValue[],
): string | undefined => {
	if (Array.isArray(value)) {
		return value.length > 0 ? join(value, ',') : undefined;
	}

	return value || undefined;
};

export const convertQueryToString = (query: Query): string => {
	const modOverride = {
		statuses: stringifyValuesList<IssueStatusCategoryId>(query.statuses),
		issueType: stringifyValuesList<IssueTypeId>(query.issueType),
		assignee: stringifyValuesList<AccountId>(query.assignee),
		label: stringifyValuesList<Label>(query.label),
		issueParent: stringifyValuesList<IssueId>(query.issueParent),
		shared: query.shared ? null : undefined,
		version: stringifyValuesList<VersionId>(query.version),
		component: stringifyValuesList<ComponentId>(query.component),
		quickFilter: stringifyValuesList<QuickFilterId>(query.quickFilter),
		customFilter: stringifyValuesList<CustomFilterTypeId>(query.customFilter),
		highlightedVersions: stringifyValuesList<VersionId>(query.highlightedVersions),
		goal: stringifyValuesList<string>(query.goal),
	};

	const mod = { ...query, ...modOverride };
	return qs.stringify(mod);
};

export const convertStringToQuery = (query: string, roadmapType: RoadmapType = NEXTGEN): Query => {
	const parsed = qs.parse(query);

	return getSanitizedQuery(parsed, roadmapType);
};
