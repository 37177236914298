import { createSelector, createStructuredSelector } from 'reselect';
import type { IssueId } from '@atlassian/jira-shared-types';
import type {
	OverlayItem,
	ImplicitDependencyItem,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/types';
import {
	getIsSprintsPlanningEnabled,
	getIsChildIssuePlanningEnabled,
	getFullIssueTypeHash,
	getEpicIssueTypeIds,
} from '../../configuration/selectors';
import {
	getIssueDependenciesHash,
	getIssueDependeesHash,
	getIssueParentIdHash,
	getIssueSummaryHash,
	getIssueTypeIdHash,
	getIssueDueDateHash,
	getIssueStartDateHash,
	getIssueKeyHash,
	getIssueIds,
} from '../../entities/issues/selectors';
import type { State } from '../../types';
import { getTooltipParams, getExpandedItems } from '../../ui/table/selectors';
import { isDependenciesVisible } from '../filters';
import {
	getFilteredIssueIds,
	getFilteredIssueIdsHash,
	getFilteredIssueDependeesHash,
	getFilteredIssueDependenciesHash,
} from '../issues';
import { getSanitisedIssueSprintsHash } from '../sprint';
import { getChartDataHash, getItemHeight } from '../table';
import { getChartItemTopPositionHash } from '../table/chart';
import { getParentRolledUpDatesHash } from '../table/dates';
import {
	getIssueAttributesHashPure,
	getDependenciesTooltipParamsPure,
	getImplicitDependencyItemsHashPure,
	getSelectedDependencyPure,
	getDependenciesItemsPure,
} from './pure';

const EMPTY_DEPENDENCY: Array<never> = [];

type IssueAttributeHashes = {
	issueSummaryHash: ReturnType<typeof getIssueSummaryHash>;
	issueTypeIdHash: ReturnType<typeof getIssueTypeIdHash>;
	issueDueDateHash: ReturnType<typeof getIssueDueDateHash>;
	issueStartDateHash: ReturnType<typeof getIssueStartDateHash>;
	issueKeyHash: ReturnType<typeof getIssueKeyHash>;
	issueParentIdHash: ReturnType<typeof getIssueParentIdHash>;
};

const getIssueAttributeHashes = createStructuredSelector<State, IssueAttributeHashes>({
	issueSummaryHash: getIssueSummaryHash,
	issueTypeIdHash: getIssueTypeIdHash,
	issueDueDateHash: getIssueDueDateHash,
	issueStartDateHash: getIssueStartDateHash,
	issueKeyHash: getIssueKeyHash,
	issueParentIdHash: getIssueParentIdHash,
});

export const getIssueAttributesHash = createSelector(
	getIssueIds,
	getFilteredIssueIdsHash,
	getIssueAttributeHashes,
	getFullIssueTypeHash,
	getSanitisedIssueSprintsHash,
	getIsSprintsPlanningEnabled,
	getIsChildIssuePlanningEnabled,
	getParentRolledUpDatesHash,
	isDependenciesVisible,
	(
		issueIds,
		filteredIssueIdHash,
		{
			issueSummaryHash,
			issueTypeIdHash,
			issueDueDateHash,
			issueStartDateHash,
			issueKeyHash,
			issueParentIdHash,
		},
		fullIssueTypes,
		issueSprintsHash,
		isSprintsPlanning,
		isChildIssuePlanningEnabled,
		parentRolledUpDatesHash,
		isDependenciesVisibleValue,
	): ReturnType<typeof getIssueAttributesHashPure> =>
		getIssueAttributesHashPure(
			issueIds,
			filteredIssueIdHash,
			issueSummaryHash,
			issueTypeIdHash,
			fullIssueTypes,
			issueDueDateHash,
			issueStartDateHash,
			issueKeyHash,
			issueParentIdHash,
			issueSprintsHash,
			isSprintsPlanning,
			isChildIssuePlanningEnabled,
			parentRolledUpDatesHash,
			isDependenciesVisibleValue,
		),
);

export const getIssueAttributes = (state: State, issueId: IssueId): OverlayItem =>
	getIssueAttributesHash(state)[issueId];

export const getDependenciesTooltipParams = createSelector(
	getTooltipParams,
	getIssueDependenciesHash,
	getIssueDependeesHash,
	getDependenciesTooltipParamsPure,
);

export const getSelectedDependency = createSelector(
	getTooltipParams,
	getIssueDependeesHash,
	getSelectedDependencyPure,
);

export const getImplicitDependencyItemsHash = createSelector(
	getFilteredIssueIds,
	getIssueParentIdHash,
	getExpandedItems,
	getFilteredIssueDependenciesHash,
	getFilteredIssueDependeesHash,
	getIssueAttributesHash,
	getIsChildIssuePlanningEnabled,
	getImplicitDependencyItemsHashPure,
);

export const getImplicitDependencyItemsForIssue = (
	state: State,
	issueId: IssueId,
): ImplicitDependencyItem[] => getImplicitDependencyItemsHash(state)[issueId] || EMPTY_DEPENDENCY;

export const getDependenciesItems = createSelector(
	getChartItemTopPositionHash,
	getChartDataHash,
	getIssueDependenciesHash,
	getEpicIssueTypeIds,
	getIssueTypeIdHash,
	getItemHeight,
	getDependenciesItemsPure,
);

export const getDependencyItem = (state: State, issueId: IssueId): OverlayItem =>
	getIssueAttributes(state, issueId);

export const getDependencyItems = (state: State, issueIds: IssueId[]): OverlayItem[] =>
	issueIds.flatMap((issueId) => getDependencyItem(state, issueId) || EMPTY_DEPENDENCY);
