import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	gridCellAriaLabel: {
		id: 'roadmap.timeline-table-kit.chart-item-content.date-content.bar.grid-cell-aria-label',
		defaultMessage: 'Start date {startDate} until due date {dueDate} with color {color}',
		description:
			'Aria label for grid cell where {startDate} is epic start date, {dueDate} is epic due date and {color} is the color of the grid cell',
	},
	warningsCount: {
		id: 'roadmap.timeline-table-kit.chart-item-content.date-content.bar.warnings-count',
		defaultMessage: '{count, plural, one {# warning} other {# warnings}}',
		description: 'Text for gridcell where {count} is number of warnings',
	},
});
