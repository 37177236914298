import React, { type ReactNode } from 'react';
import Heading from '@atlaskit/heading';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

export const DEFAULT_CONTENT_WIDTH = 500;
export const DEFAULT_IMAGE_WIDTH_OLD = 221;
export const DEFAULT_IMAGE_WIDTH = 235;
export const DEFAULT_IMAGE_HEIGHT = 138;

export const LARGE_IMAGE_WIDTH = 344;
export const LARGE_IMAGE_HEIGHT = 167;

interface ComponentWithChildren {
	children: ReactNode;
}

export const Content: React.FC<ComponentWithChildren> = ({ children }) => (
	<Flex direction="column" justifyContent="start" alignItems="center" xcss={ContentStyles}>
		{children}
	</Flex>
);

export const Messages: React.FC<ComponentWithChildren> = ({ children }) => (
	<Box xcss={MessagesStyles}>{children}</Box>
);

export const Title: React.FC<ComponentWithChildren> = ({ children }) => (
	<Heading size="large">
		<Box xcss={TitleComponentStyle}>{children}</Box>
	</Heading>
);

const ContentStyles = xcss({
	width: `${DEFAULT_CONTENT_WIDTH}px`,
});

const MessagesStyles = xcss({
	font: token('font.body'),
	textAlign: 'center',
	marginTop: 'space.250',
	marginBottom: 'space.250',
});

const TitleComponentStyle = xcss({
	marginTop: 'space.500',
	textAlign: 'center',
});
