import type { Color } from '@atlassian/jira-issue-epic-color';
import { connect } from '@atlassian/jira-react-redux';
import type { IssueId } from '@atlassian/jira-shared-types';
import { getIssueParentColor } from '../../../../../state/selectors/issues';
import type { State } from '../../../../../state/types';
import DependencyLinePreview, { type APIProps } from './view';

type StateProps = {
	color: Color;
};
type Props = APIProps & {
	id: IssueId;
};
type OwnProps = Props;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mergeProps = (stateProps: StateProps, _: any, { id, ...ownProps }: OwnProps) => ({
	...stateProps,
	...ownProps,
});

export default connect(
	(state: State, ownProps: OwnProps): StateProps => ({
		color: getIssueParentColor(state, ownProps.id),
	}),
	null,
	mergeProps,
)(DependencyLinePreview);

export type { Props as APIProps };
