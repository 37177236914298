import React, { useCallback, useRef, useMemo } from 'react';
import Button from '@atlaskit/button/standard-button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import FeedbackIcon from '@atlaskit/icon/glyph/feedback';
import MoreIcon from '@atlaskit/icon/glyph/more';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import {
	EntryKeys,
	ENTRYPOINT_ID_JIRA_PLATFORM,
	useFeedbackCollectorActions,
} from '@atlassian/jira-feedback-collector';
import { useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { setReturnToProjectUrl } from '@atlassian/jira-navigation-apps-sidebar-project-utils';
import normaliseUrl from '@atlassian/jira-platform-url-normalisation';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ResponsiveButton } from '@atlassian/jira-responsive-button';
import { useRouterActions } from '@atlassian/jira-router';
import type { LicensedProducts, ProjectKey } from '@atlassian/jira-shared-types';
import type { BoardId } from '@atlassian/jira-software-roadmap-model';
import {
	useAppEditions,
	useIsAdmin,
	useTenantContext,
} from '@atlassian/jira-tenant-context-controller';
import { getRoadmapSettingsUrl } from '../../../utils';
import messages from './messages';

type Props = {
	isCMP: boolean;
	boardId: BoardId;
	projectKey: ProjectKey;
	cloudId: string;
	boardType: string;
	canConfigureTimeline: boolean;
};

const licensedProductsToString: (arg1: LicensedProducts) => string = (products) =>
	Object.keys(products)
		.map((productKey) => (products[productKey] ? productKey : ''))
		.join(', ');

const MoreSettings = ({
	isCMP,
	boardId,
	projectKey,
	cloudId,
	boardType,
	canConfigureTimeline,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { push } = useRouterActions();
	const isAdmin = useIsAdmin();
	const { licensedProducts } = useTenantContext();
	const { software: softwareEdition } = useAppEditions();

	const { openFeedbackCollector } = useFeedbackCollectorActions();

	const configureRoadmapAnalyticsEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'configureRoadmapButtonClicked',
	});

	const navigateToRoadmapSettingsPage = () => {
		fireUIAnalytics(configureRoadmapAnalyticsEvent);
		if (!isCMP) setReturnToProjectUrl();
		const url = getRoadmapSettingsUrl(isCMP, projectKey, boardId);
		push(url);
	};

	const feedbackButtonRef = useRef<HTMLButtonElement>(null);

	const additionalFeedbackEntries = useMemo(
		() => [
			{
				id: EntryKeys.OPT_OUT,
				value: 'No',
			},
			{
				id: EntryKeys.FEEDBACK_COLLECTOR_LOCATION,
				value: 'jsw.roadmap',
			},
			{
				id: EntryKeys.CLOUD_ID,
				value: cloudId,
			},
			{
				id: EntryKeys.IS_SIMPLIFIED_PROJECT,
				value: !isCMP.toString(),
			},
			{
				id: EntryKeys.BOARD_TYPE,
				value: boardType,
			},

			{
				id: EntryKeys.LOCATION,
				value: normaliseUrl(window?.location?.href ?? ''),
			},
			{
				id: EntryKeys.REFERER,
				value: document.referrer ? normaliseUrl(document.referrer) : '',
			},
			{
				id: EntryKeys.USER_AGENT,
				value: navigator.userAgent,
			},
			{
				id: EntryKeys.SCREEN_RESOLUTION,
				value: `${window.screen.width} x ${window.screen.height}`,
			},
			{
				id: EntryKeys.IS_ADMIN,
				value: isAdmin.toString(),
			},
			{
				id: EntryKeys.SUPPORTED_PROJECT_TYPES,
				value: licensedProductsToString(licensedProducts),
			},
			{
				id: EntryKeys.PROJECT_TYPE,
				value: 'software',
			},
			{
				id: EntryKeys.EDITION,
				value: softwareEdition,
			},
		],
		[boardType, cloudId, isCMP, isAdmin, licensedProducts, softwareEdition],
	);

	const onOpenFeedbackCollector = useCallback(() => {
		openFeedbackCollector({
			entrypointId: ENTRYPOINT_ID_JIRA_PLATFORM,
			feedbackTitle: formatMessage(messages.feedbackCollectorTitle),
			feedbackTitleDetails: formatMessage(messages.feedbackCollectorDescription),
			triggerRef: feedbackButtonRef,
			feedbackContext: additionalFeedbackEntries,
		});
	}, [openFeedbackCollector, formatMessage, additionalFeedbackEntries]);

	return (
		<DropdownMenu
			testId="roadmap.standard-roadmap.header.more-settings"
			// eslint-disable-next-line jira/react-no-inline-function-prop
			trigger={({ triggerRef, ...triggerProps }) =>
				getWillShowNav4() ? (
					<ResponsiveButton
						label={formatMessage(messages.moreSettingsMenuLabel)}
						// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
						icon={MoreIcon}
						ref={triggerRef}
						{...triggerProps}
					/>
				) : (
					<Button
						{...triggerProps}
						ref={triggerRef}
						appearance="subtle"
						iconAfter={<MoreIcon label="" />}
					/>
				)
			}
			placement="bottom-end"
		>
			<DropdownItemGroup>
				{canConfigureTimeline && (
					<DropdownItem
						onClick={navigateToRoadmapSettingsPage}
						elemBefore={<SettingsIcon label="" />}
						testId="roadmap.standard-roadmap.header.more-settings.configure-timeline"
					>
						{formatMessage(messages.moreSettingsButtonLabel)}
					</DropdownItem>
				)}
				{getWillShowNav4() && (
					<DropdownItem
						elemBefore={<FeedbackIcon label="" />}
						onClick={onOpenFeedbackCollector}
						ref={feedbackButtonRef}
						testId="roadmap.standard-roadmap.header.more-settings.feedback"
					>
						{formatMessage(messages.feedbackButtonLabel)}
					</DropdownItem>
				)}
			</DropdownItemGroup>
		</DropdownMenu>
	);
};

export default MoreSettings;
