import memoizeOne from 'memoize-one';
import type { IssueId } from '@atlassian/jira-shared-types';
import {
	CLOSED,
	type IdentifiableHash,
	type Sprint,
	type SprintId,
} from '@atlassian/jira-software-roadmap-model';
import { DUE_DATE_OFFSET } from '@atlassian/jira-software-roadmap-timeline-table-kit';
import type { Interval } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/types';
import type { TimelineDuration } from '@atlassian/jira-software-roadmap-timeline-table/src/types';
import type { Sprint as SprintInterval } from '../../../../model/sprint/types';
import { getFirstOpenSprint } from '../../../../utils';
import { groupOverlappingIntervals } from '../utils';

const withAdjustedSprintDates = (sprints: Sprint[]): Sprint[] =>
	sprints.map((sprint) => ({
		id: sprint.id,
		name: sprint.name,
		state: sprint.state,
		startDate: Math.min(sprint.startDate, sprint.endDate),
		endDate: Math.max(sprint.startDate, sprint.endDate),
	}));

export const getSprintsHash = (sprints: Sprint[]): IdentifiableHash<SprintId, Sprint> =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	withAdjustedSprintDates(sprints).reduce<Record<string, any>>((acc, sprint) => {
		acc[`${sprint.id}`] = sprint;
		return acc;
	}, {});

export const getSprintIntervals = (sprints: Sprint[]): ReadonlyArray<Interval> =>
	sprints.map(({ id, name, state, startDate, endDate }: Sprint) => ({
		id: String(id),
		name,
		state,
		startDate,
		endDate,
	}));

// Deprecated - ROAD-4848
export const createGetSanitizedSprints = (
	sprints: Sprint[],
): ((timelineDuration: TimelineDuration) => Sprint[]) =>
	memoizeOne((timelineDuration) =>
		sprints
			.map((sprint) => ({
				id: sprint.id,
				name: sprint.name,
				state: sprint.state,
				startDate: Math.min(sprint.startDate, sprint.endDate),
				endDate: Math.max(sprint.startDate, sprint.endDate) + DUE_DATE_OFFSET,
			}))
			.filter(
				(sprint) =>
					(sprint.startDate >= timelineDuration.startMilliseconds &&
						sprint.startDate <= timelineDuration.endMilliseconds) ||
					(sprint.endDate <= timelineDuration.endMilliseconds &&
						sprint.endDate >= timelineDuration.startMilliseconds) ||
					(sprint.startDate <= timelineDuration.startMilliseconds &&
						sprint.endDate >= timelineDuration.endMilliseconds),
			),
	);

const asSprintIntervalType = (sprint: Sprint): SprintInterval => ({
	id: sprint.id,
	name: sprint.name,
	state: sprint.state,
	start: sprint.startDate,
	end: sprint.endDate,
});

export const createGetIntersectingSprintId =
	(
		timelineDuration: TimelineDuration,
		getSanitizedSprints: (timelineDuration: TimelineDuration) => Sprint[],
	): ((startDate: number, currentSprintId: SprintId | undefined) => SprintId | undefined) =>
	(startDate, currentSprintId) => {
		const sprintGroups = groupOverlappingIntervals(
			getSanitizedSprints(timelineDuration)
				.filter((sprint) => sprint.id !== currentSprintId)
				.map(asSprintIntervalType),
		);
		// Filter out groups that don't overlap with start date and closed sprints
		const filteredSprintGroup = sprintGroups.find(
			(sprintGroup) => sprintGroup.start <= startDate && sprintGroup.end > startDate,
		);
		return filteredSprintGroup ? getFirstOpenSprint(filteredSprintGroup.intervals) : undefined;
	};

export const getSprintName =
	(sprintsHash: IdentifiableHash<SprintId, Sprint>) => (sprintId?: SprintId) =>
		sprintId !== undefined ? sprintsHash[sprintId]?.name : undefined;

export const getSprintState =
	(sprintsHash: IdentifiableHash<SprintId, Sprint>) => (sprintId?: SprintId) =>
		sprintId !== undefined ? sprintsHash[sprintId]?.state : undefined;

export const createGetMostRecentSprintId = (
	issueIdToSprintIds: IdentifiableHash<IssueId, SprintId[]>,
): ((id: IssueId) => SprintId | undefined) =>
	memoizeOne((id) => {
		const sprintIds = issueIdToSprintIds[id] || [];
		return sprintIds[0];
	});

export const getSanitisedIssueSprintsHash = (
	issueSprintIdsHash: IdentifiableHash<IssueId, SprintId[]>,
	allSprintsHash: IdentifiableHash<SprintId, Sprint>,
): IdentifiableHash<IssueId, Sprint[]> => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: Record<string, any> = {};

	Object.keys(issueSprintIdsHash).forEach((issueId: IssueId) => {
		const sprintIds = issueSprintIdsHash[issueId];

		const filteredSprints = sprintIds
			.filter((sprintId: SprintId) => allSprintsHash[sprintId] !== undefined)
			.map((sprintId: SprintId) => allSprintsHash[sprintId]);

		result[issueId] = filteredSprints;
	});

	return result;
};

export const getCurrentlyAssignedSprints =
	(issueSprintsHash: IdentifiableHash<IssueId, Sprint[]>) =>
	(issueId: IssueId): Sprint[] =>
		(issueSprintsHash[issueId] ?? []).filter((sprint: Sprint) => sprint.state !== CLOSED);
