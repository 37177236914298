import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { OVERSCAN_COUNT } from '../common/constants';
import type { State, ContainerProps } from '../common/types';
import { getVisibleItemsFromIndexes } from '../common/utils';

export const DEFAULT_VALUE: State = {
	overscan: OVERSCAN_COUNT,
	requiredItems: [],
	overscanRange: { start: 0, end: 0 },
};

export const generateVirtualMeta =
	(startIndex?: number, endIndex?: number) =>
	(
		{ setState }: StoreActionApi<State>,
		{ createItemIndex, itemHeights, items }: ContainerProps,
	) => {
		if (startIndex !== undefined && endIndex !== undefined) {
			const virtualMeta = getVisibleItemsFromIndexes({
				createItemIndex,
				itemHeights,
				startIndex,
				endIndex,
				items,
			});
			setState(virtualMeta);
		} else {
			setState(DEFAULT_VALUE);
		}
	};
