import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/map';
import type { Ari } from '@atlassian/jira-platform-ari';
import { getFilterConfiguration } from '@atlassian/jira-software-roadmap-services';
import { setFilterConfiguration } from '../../../../state/ui/filters/actions';

const getConfig = (sourceAri: Ari) =>
	getFilterConfiguration(sourceAri).map((filterConfiguration) =>
		setFilterConfiguration(filterConfiguration),
	);

export const getInitializeFilterConfigurationAction = (
	sourceAri: Ari,
	useQuickFilters: boolean,
	useCustomFilters: boolean,
) => (useQuickFilters || useCustomFilters ? getConfig(sourceAri) : Observable.empty<never>());
