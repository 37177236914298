import React from 'react';
import Icon from '@atlaskit/icon';
import { N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

interface Props {
	label: string;
}

const getIconContents = () => (
	<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
		<path
			d="m14.586 9-2.293-2.293a1 1 0 1 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414L14.586 11H10a1 1 0 0 0-1 1v7a1 1 0 1 1-2 0v-7a3 3 0 0 1 3-3h4.586Z"
			fill="currentColor"
		/>
	</svg>
);

const RollupIcon = ({ label }: Props) => (
	<Icon
		glyph={getIconContents}
		size="medium"
		label={label}
		primaryColor={token('color.text.subtle', N300)}
	/>
);

export default RollupIcon;
