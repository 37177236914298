import type { Color } from '@atlassian/jira-issue-epic-color';
import { isParentLevel } from '@atlassian/jira-software-roadmap-model';
import type { ChangeColorOptionParams } from '../../../../model/list-item-menu-option';

export const getChangeColorOptionParamsPure = (
	level: number,
	color: Color,
): ChangeColorOptionParams => ({
	isColorEditable: isParentLevel(level),
	color,
});
