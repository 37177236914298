/** @jsx jsx */
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { css, jsx } from '@compiled/react';
import invariant from 'tiny-invariant';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import {
	draggable,
	type ElementEventBasePayload,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { disableNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview';
import { preventUnhandled } from '@atlaskit/pragmatic-drag-and-drop/prevent-unhandled';
import type { DragLocationHistory } from '@atlaskit/pragmatic-drag-and-drop/types';
import { B200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import {
	MINIMUM_LIST_WIDTH,
	LIST_RESIZE_DRAG_AND_DROP,
	zIndex,
} from '../../../../../common/constants';
import type {
	OnSetListWidth as OnResize,
	DndLongTaskMetricComponent,
} from '../../../../../common/types';
import { fireUIAnalyticsEvent } from '../../../../../common/utils/analytics';
import { useMaxWidth } from '../use-max-width';

const getProposedWidth = ({
	initialWidth,
	maxWidth,
	location,
}: {
	initialWidth: number;
	maxWidth: number;
	location: DragLocationHistory;
}): number => {
	const diffX = location.current.input.clientX - location.initial.input.clientX;
	const proposedWidth = initialWidth + diffX;

	// ensure we don't go below the min or above the max allowed widths
	return Math.min(Math.max(MINIMUM_LIST_WIDTH, proposedWidth), maxWidth);
};

type Props = {
	width: number;
	DndLongTaskMetric: DndLongTaskMetricComponent | undefined;
	onResize: OnResize;
	onResizeEnd: OnResize;
};

const Resizer = ({ width, DndLongTaskMetric, onResize, onResizeEnd }: Props) => {
	const [isDragging, setIsDragging] = useState(false);
	const dividerRef = useRef<HTMLDivElement | null>(null);
	const initialWidth = useRef<number>(width);
	const maxWidth = useMaxWidth();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onDragStart = useCallback(() => {
		initialWidth.current = width;
		setIsDragging(true);
	}, [width]);

	const onDrag = useCallback(
		({ location }: ElementEventBasePayload) => {
			onResize(getProposedWidth({ initialWidth: initialWidth.current, maxWidth, location }));
		},
		[onResize, maxWidth],
	);

	const onDrop = useCallback(
		({ location }: ElementEventBasePayload) => {
			preventUnhandled.stop();
			setIsDragging(false);

			const analyticsEvent = createAnalyticsEvent({
				action: 'resized',
				actionSubject: 'list',
				actionSubjectId: 'resizedList',
				attributes: {
					didExpand: location.current.input.clientX > location.initial.input.clientX,
				},
			});

			fireUIAnalyticsEvent(analyticsEvent);

			onResizeEnd(initialWidth.current);
		},
		[createAnalyticsEvent, onResizeEnd],
	);

	// The following effect makes sure the width of the list never exceeds the
	// width of the viewport causing users to not have access to the chart
	// component.
	useEffect(() => {
		width > maxWidth && onResize(maxWidth);
	}, [width, maxWidth, onResize]);

	useEffect(() => {
		const divider = dividerRef.current;
		invariant(divider);

		return draggable({
			element: divider,
			onGenerateDragPreview: ({ nativeSetDragImage }) => {
				disableNativeDragPreview({ nativeSetDragImage });
				preventUnhandled.start();
			},
			onDragStart,
			onDrag,
			onDrop,
		});
	}, [onDragStart, onDrag, onDrop]);

	return (
		<>
			<div
				css={[resizeHandlerStyles, isDragging ? draggingStyles : staticStyles]}
				ref={dividerRef}
			/>
			{DndLongTaskMetric !== undefined && (
				<DndLongTaskMetric isDragging={isDragging} metricType={LIST_RESIZE_DRAG_AND_DROP} />
			)}
		</>
	);
};

const resizeHandlerStyles = css({
	position: 'absolute',
	top: 0,
	right: token('space.negative.025', '-2px'),
	width: token('space.100', '8px'),
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.LIST_RESIZER,
	transition: 'opacity 0.1s ease',
	pointerEvents: 'all',
	cursor: 'ew-resize',
	'&::after': {
		position: 'absolute',
		display: 'block',
		content: '',
		top: 0,
		right: token('space.025', '2px'),
		width: token('space.025', '2px'),
		height: '100%',
		background: token('color.border.selected', B200),
		pointerEvents: 'none',
	},
});

const staticStyles = css({
	opacity: 0,
	'&:hover': {
		opacity: 1,
	},
});

const draggingStyles = css({
	opacity: 1,
});

export default Resizer;
