import flatten from 'lodash/flatten';
import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';
import type {
	ComponentId,
	IssueTypeId,
	IssueId,
	AccountId,
	VersionId,
} from '@atlassian/jira-shared-types';
import { UNASSIGNED_USER_ID, type Label } from '@atlassian/jira-software-filters';
import {
	LABELS,
	VERSION_IDS,
	ASSIGNEE,
	ISSUE_TYPE,
	COMPONENT_IDS,
	type IdentifiableHash,
	type ComponentsHash,
	type FieldName,
} from '@atlassian/jira-software-roadmap-model';
import {
	convertIssueViewFieldValue,
	convertIssueViewFieldValueToConfiguration,
	type AssigneeFieldValueType,
	type VersionsFieldValueType,
	type LabelsFieldValueType,
	type IssueTypeFieldValueType,
	type ComponentsFieldValueType,
} from '../../../model/issue-view';
import { getLoggedInAccountId } from '../../../state/app/selectors';
import { type SetComponentsAction, setComponents } from '../../../state/configuration/actions';
import {
	getFullComponentHash,
	getFullIssueTypeHash,
	getProjectId,
} from '../../../state/configuration/selectors';
import {
	getIssueLabelsHash,
	getIssueVersionIdsHash,
	getIssueTypeIdHash,
	getIssueAssigneeHash,
	getIssueComponentIdsHash,
} from '../../../state/entities/issues/selectors';
import {
	getFilteredLabels,
	getFilteredVersions,
	getFilteredAssignees,
	getFilteredIssueTypes,
	getFilteredComponents,
} from '../../../state/selectors/filters';
import type { State } from '../../../state/types';
import {
	setLabelFilter,
	setVersionFilter,
	setAssigneeFilter,
	setIssueTypeFilter,
	type SetLabelFilterAction,
	type SetVersionFilterAction,
	type SetAssigneeFilterAction,
	type SetIssueTypeFilterAction,
	setComponentFilter,
	type SetComponentFilterAction,
} from '../../../state/ui/filters/actions';

type FieldValuesType =
	| IssueTypeFieldValueType
	| AssigneeFieldValueType
	| VersionsFieldValueType
	| ComponentsFieldValueType
	| LabelsFieldValueType;

const getValidFilter = (
	filteredOptions: IssueTypeId[] | Label[] | VersionId[] | ComponentId[] | AccountId[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	issueOptions: IdentifiableHash<IssueId, any>,
	fieldValue: FieldValuesType,
	issueId: IssueId,
	fieldName: FieldName,
): string[] | undefined => {
	const issueOptionsHash = { ...issueOptions };
	issueOptionsHash[issueId] = convertIssueViewFieldValue(fieldName, fieldValue);

	const validOptions = uniq(
		flatten(Object.keys(issueOptionsHash).map((key) => issueOptionsHash[key])),
	);
	const intersectingOptions = intersection(filteredOptions, validOptions);

	// check whether the filter and router filter doesn't match
	if (intersectingOptions.length !== filteredOptions.length) {
		return intersectingOptions;
	}

	return undefined;
};

export const createSetUpdatedFilter = (
	state: State,
	issueId: IssueId,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fieldValue: any,
	fieldName: FieldName,
):
	| SetLabelFilterAction
	| SetVersionFilterAction
	| SetComponentFilterAction
	| SetAssigneeFilterAction
	| SetIssueTypeFilterAction
	| undefined => {
	if (fieldName === LABELS) {
		const filteredLabels = getFilteredLabels(state);
		const issueLabels = getIssueLabelsHash(state);
		const newValue: LabelsFieldValueType = fieldValue;
		const validFilter = getValidFilter(filteredLabels, issueLabels, newValue, issueId, fieldName);
		if (validFilter) {
			return setLabelFilter(validFilter);
		}
	}

	if (fieldName === VERSION_IDS) {
		const filteredVersions = getFilteredVersions(state);
		const issueVersions = getIssueVersionIdsHash(state);
		const newValue: VersionsFieldValueType = fieldValue;
		const validFilter = getValidFilter(
			filteredVersions,
			issueVersions,
			newValue,
			issueId,
			fieldName,
		);
		if (validFilter) {
			return setVersionFilter(validFilter);
		}
	}
	if (fieldName === COMPONENT_IDS) {
		const filteredComponents = getFilteredComponents(state);
		const issueComponents = getIssueComponentIdsHash(state);
		const newValue: ComponentsFieldValueType = fieldValue;
		const validFilter = getValidFilter(
			filteredComponents,
			issueComponents,
			newValue,
			issueId,
			fieldName,
		);
		if (validFilter) {
			return setComponentFilter(validFilter);
		}
	}

	if (fieldName === ASSIGNEE) {
		const filteredAssignees = getFilteredAssignees(state);
		const issueAssignees = getIssueAssigneeHash(state);
		const newValue: AssigneeFieldValueType = fieldValue;
		const loggedInAccountId = getLoggedInAccountId(state);

		// logged in user is never removed from the assignee dropdown
		const issueAssigneeWithLoggedInUser = {
			...issueAssignees,
			'-1': loggedInAccountId,
		};

		const validFilter = getValidFilter(
			filteredAssignees.filter(
				(filteredOption) => filteredOption !== UNASSIGNED_USER_ID, // filters out unassigned assignee
			),
			issueAssigneeWithLoggedInUser,
			newValue,
			issueId,
			fieldName,
		);

		const unassignedFilter = filteredAssignees.includes(UNASSIGNED_USER_ID)
			? [UNASSIGNED_USER_ID]
			: [];

		if (validFilter && !(filteredAssignees.length === 1 && unassignedFilter.length)) {
			return setAssigneeFilter([...validFilter, ...unassignedFilter]);
		}
	}

	if (fieldName === ISSUE_TYPE) {
		const filteredIssueTypes = getFilteredIssueTypes(state);
		const issueIssueTypes = getIssueTypeIdHash(state);
		const issueTypes = getFullIssueTypeHash(state);
		let issueTypeId;
		Object.keys(issueTypes).forEach((id: string) => {
			if (issueTypes[id].name === fieldValue) {
				issueTypeId = id;
			}
		});

		if (issueTypeId) {
			const validFilter = getValidFilter(
				filteredIssueTypes,
				issueIssueTypes,
				issueTypeId,
				issueId,
				fieldName,
			);

			if (validFilter) {
				return setIssueTypeFilter(validFilter);
			}
		}
	}

	return undefined;
};

export const createSetUpdatedConfiguration = (
	state: State,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fieldValue: any,
	fieldName: FieldName,
): SetComponentsAction | undefined => {
	if (fieldName === COMPONENT_IDS) {
		const projectId = getProjectId(state);

		const existingComponents: ComponentsHash = getFullComponentHash(state);
		const issueComponents: ComponentsHash | undefined = convertIssueViewFieldValueToConfiguration(
			fieldName,
			fieldValue,
		);

		if (issueComponents === undefined) return undefined;

		const hasNewComponents = Object.keys(issueComponents).some(
			(key) => !(key in existingComponents),
		);

		if (hasNewComponents) {
			const components: ComponentsHash = Object.assign(existingComponents, issueComponents);
			return setComponents({ projectId, components });
		}
	}

	return undefined;
};
