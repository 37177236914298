import { useCallback, type KeyboardEvent, type FocusEvent } from 'react';
import { useFocusMarshal } from '../index';

/* This hook is fairly simple, but integral in our grid navigation functionality.
 * It delegates events at the root of the grid and maps them to the relevant marshal action.
 */
export const useGridContainer = () => {
	const { focusCell, blurCell, navigateCell } = useFocusMarshal();

	const onFocus = useCallback(
		(event: FocusEvent) => {
			if (event.target instanceof HTMLElement) {
				focusCell(event.target);
			}
		},
		[focusCell],
	);

	const onBlur = useCallback(
		(event: FocusEvent) => {
			if (event.target instanceof HTMLElement) {
				blurCell(event.target);
			}
		},
		[blurCell],
	);

	// Keyboard interactions from https://www.w3.org/WAI/ARIA/apg/patterns/grid
	const onKeyDown = useCallback(
		(event: KeyboardEvent) => {
			const { key, ctrlKey } = event;

			switch (key) {
				case 'ArrowLeft':
				case 'ArrowRight':
				case 'ArrowUp':
				case 'ArrowDown':
				case 'PageUp':
				case 'PageDown':
				case 'Home':
				case 'End': {
					event.preventDefault();
					navigateCell(key, { ctrlKey });
					break;
				}
				default:
					break;
			}
		},
		[navigateCell],
	);

	return { onKeyDown, onFocus, onBlur };
};
