import { log } from '@atlassian/jira-common-util-logging';
import { ValidationError } from '@atlassian/jira-fetch';
import { NoDataError } from '../../model/errors';
import type {
	ConfluenceWhiteboardsGraphqlResponse,
	GraphqlResponse,
	ViewIssueGraphqlResponse,
	ViewMultipleIssueGraphqlResponse,
} from './types';

export const getGraphQlData = <
	T extends
		| ViewIssueGraphqlResponse
		| ViewMultipleIssueGraphqlResponse
		| ConfluenceWhiteboardsGraphqlResponse,
>(
	logLocation: string,
	graphQlResponse: GraphqlResponse<T>,
) => {
	if (graphQlResponse.errors) {
		const firstError = graphQlResponse.errors[0];
		const validationError = new ValidationError(firstError.message);
		log.safeErrorWithoutCustomerData(
			logLocation,
			'getGraphQlData: Error present in GraphQL response',
			validationError,
		);
		throw validationError;
	}
	return graphQlResponse.data;
};

const emptyResponseText = 'Response is either not present or has no data.';

/**
 * Check GraphQL Response and throw if empty `data` attribute or empty body all together
 */
export const checkEmptyResponse = <
	T extends ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse,
>(
	response: GraphqlResponse<T>,
): {
	data: T;
	errors: unknown;
} => {
	if (!response || !Object.prototype.hasOwnProperty.call(response, 'data')) {
		throw new NoDataError(emptyResponseText);
	}
	if (response.data === null) {
		throw new NoDataError(emptyResponseText);
	}
	return response;
};
