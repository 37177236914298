import React from 'react';
import { MARKER_COLORS, COMPLETED } from '../../../../common/constants';

const CompletedIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		fill="none"
		viewBox="0 0 16 16"
		/**
		 * AK inline-dialog's handleClickOutside does not recognise svg elements
		 * https://bitbucket.org/atlassian/atlassian-frontend/src/master/packages/design-system/inline-dialog/src/InlineDialog/index.tsx#lines-60
		 */

		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		style={{ pointerEvents: 'none' }}
	>
		<circle r="7" cx="8" cy="8" fill="currentColor" />
		<path
			fill={MARKER_COLORS[COMPLETED]}
			fillRule="evenodd"
			d="M8 16A8 8 0 108 0a8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
			clipRule="evenodd"
		/>
	</svg>
);

export { CompletedIcon };
