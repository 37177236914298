/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { getDateFromTimestamp } from '@atlassian/jira-software-roadmap-utils/src/utils/dates';
import { LEFT, CHART_DATES_Z_INDEX, EXPLICIT, RIGHT, LEFT_AND_RIGHT } from '../../constants';
import type { DateType, Placeholder } from '../../types';
import { isStartDatePlaceholder, isDueDatePlaceholder } from '../../utils';
import { Duration } from '../date-labels/duration';
import { DATE_ICON_LOOKUP, DATE_CONTAINER_LOOKUP } from './constants';
import messages from './messages';
import type { Placement } from './types';

type Props = {
	isDurationVisible?: boolean;
	startDate?: number;
	dueDate?: number;
	startDateType?: DateType;
	dueDateType?: DateType;
	placeholder?: Placeholder;
	placement?: Placement;
	renderDuration?: () => ReactNode;
};

/* Combines timestamps into a human readable label that changes based upon the date type (see props).
 * - If a date is not provided it will *not* be rendered.
 * - By providing a placement, the dates will be rendered absolute to either side of their nearest relative ancestor.
 */
const FormattedDate = ({
	startDate,
	dueDate,
	startDateType = EXPLICIT,
	dueDateType = EXPLICIT,
	placeholder,
	isDurationVisible = false,
	placement,
	renderDuration,
}: Props) => {
	const { formatMessage } = useIntl();

	const startDatePlaceholderMessage = isStartDatePlaceholder(placeholder)
		? formatMessage(messages.noStartDate)
		: undefined;

	const dueDatePlaceholderMessage = isDueDatePlaceholder(placeholder)
		? formatMessage(messages.noDueDate)
		: undefined;

	// === RENDER === //

	const renderDurationLabel = (shouldRender = true) => {
		const isDurationValid = startDate !== undefined || dueDate !== undefined;

		return isDurationVisible && isDurationValid && shouldRender ? (
			<>
				{' '}
				{renderDuration ? (
					renderDuration()
				) : (
					<Duration fromDate={startDate ?? 0} toDate={dueDate ?? 0} />
				)}
			</>
		) : null;
	};

	const renderDate = (dateType: DateType, date?: number, placeholderMessage?: string) => {
		if (date === undefined) {
			return null;
		}

		const DateContainer = DATE_CONTAINER_LOOKUP[dateType];
		const DateIcon = DATE_ICON_LOOKUP[dateType];

		return (
			<>
				<DateIcon />
				{placeholderMessage ?? <DateContainer>{getDateFromTimestamp(date)}</DateContainer>}
			</>
		);
	};

	if (placement === LEFT_AND_RIGHT) {
		return (
			<>
				<small
					css={[outerContainerStyles, defaultPlacementStyles, placemenetLeftStyles]}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className="notranslate"
				>
					{renderDate(startDateType, startDate, startDatePlaceholderMessage)}
					{renderDurationLabel(startDate !== undefined && dueDate === undefined)}
				</small>
				<small
					css={[outerContainerStyles, defaultPlacementStyles, placemenetRightStyles]}
					// eslint-disable-next-line  @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className="notranslate"
				>
					{renderDate(dueDateType, dueDate, dueDatePlaceholderMessage)}
					{renderDurationLabel(dueDate !== undefined)}
				</small>
			</>
		);
	}

	return (
		<small
			css={[
				outerContainerStyles,
				placement && defaultPlacementStyles,

				placement === LEFT && placemenetLeftStyles,

				placement === RIGHT && placemenetRightStyles,
			]}
			data-testid="roadmap.timeline-table-kit.common.ui.formatted-date.formatted-date"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className="notranslate"
		>
			{renderDate(startDateType, startDate, startDatePlaceholderMessage)}
			{startDate !== undefined && dueDate !== undefined && ' - '}
			{renderDate(dueDateType, dueDate, dueDatePlaceholderMessage)}
			{renderDurationLabel()}
		</small>
	);
};

export { FormattedDate };

const placemenetLeftStyles = css({
	right: '100%',
	paddingRight: token('space.100', '8px'),
});

const placemenetRightStyles = css({
	left: '100%',
	paddingLeft: token('space.100', '8px'),
});

const defaultPlacementStyles = css({
	position: 'absolute',
});

const outerContainerStyles = css({
	display: 'flex',
	alignItems: 'center',
	marginTop: 0,
	textTransform: 'uppercase',
	whiteSpace: 'pre',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: CHART_DATES_Z_INDEX,
	// span selector applies lowercase styles to the duration badge
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		textTransform: 'lowercase',
	},
});
