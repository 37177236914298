export const dependenciesOverlayHiddenStyles = `
    opacity: 0;
`;

export const dependencyLinesHiddenStyles = `
    pointer-events: none;
`;

export const dependenciesDropTargetStyles = `
    pointer-events: all;
`;
