import React from 'react';
import type { PanelType } from '../../model/panel';
import Panel from './panel';

type Props = {
	panelType: PanelType;
};

const TableOverlay = ({ panelType }: Props) => <Panel panelType={panelType} />;

export default TableOverlay;
