import { defineMessages } from 'react-intl-next';

const messages = defineMessages({
	january: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.january',
		defaultMessage: 'Jan',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of January',
	},
	february: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.february',
		defaultMessage: 'Feb',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of February',
	},
	march: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.march',
		defaultMessage: 'Mar',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of March',
	},
	april: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.april',
		defaultMessage: 'Apr',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of April',
	},
	may: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.may',
		defaultMessage: 'May',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of May',
	},
	june: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.june',
		defaultMessage: 'Jun',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of June',
	},
	july: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.july',
		defaultMessage: 'Jul',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of July',
	},
	august: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.august',
		defaultMessage: 'Aug',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of August',
	},
	september: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.september',
		defaultMessage: 'Sep',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of September',
	},
	october: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.october',
		defaultMessage: 'Oct',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of October',
	},
	november: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.november',
		defaultMessage: 'Nov',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of November',
	},
	december: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.december',
		defaultMessage: 'Dec',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of December',
	},
	monday: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.monday',
		defaultMessage: 'M',
		description:
			'Column sub-heading text. High fidelity visual guide for a users relative position timeline. Proper noun. Abbreviation for the day of Monday',
	},
	tuesday: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.tuesday',
		defaultMessage: 'T',
		description:
			'Column sub-heading text. High fidelity visual guide for a users relative position timeline. Proper noun. Abbreviation for the day of Tuesday',
	},
	wednesday: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.wednesday',
		defaultMessage: 'W',
		description:
			'Column sub-heading text. High fidelity visual guide for a users relative position timeline. Proper noun. Abbreviation for the day of Wednesday',
	},
	thursday: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.thursday',
		defaultMessage: 'T',
		description:
			'Column sub-heading text. High fidelity visual guide for a users relative position timeline. Proper noun. Abbreviation for the day of Thursday',
	},
	friday: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.friday',
		defaultMessage: 'F',
		description:
			'Column sub-heading text. High fidelity visual guide for a users relative position timeline. Proper noun. Abbreviation for the day of Friday',
	},
	saturday: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.saturday',
		defaultMessage: 'S',
		description:
			'Column sub-heading text. High fidelity visual guide for a users relative position timeline. Proper noun. Abbreviation for the day of Saturday',
	},
	sunday: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.sunday',
		defaultMessage: 'S',
		description:
			'Column sub-heading text. High fidelity visual guide for a users relative position timeline. Proper noun. Abbreviation for the day of Sunday',
	},
});

const inWeekOrder = [
	messages.monday,
	messages.tuesday,
	messages.wednesday,
	messages.thursday,
	messages.friday,
	messages.saturday,
	messages.sunday,
];

const inMonthOrder = [
	messages.january,
	messages.february,
	messages.march,
	messages.april,
	messages.may,
	messages.june,
	messages.july,
	messages.august,
	messages.september,
	messages.october,
	messages.november,
	messages.december,
];

const inQuarterOrder = [
	{ startMonth: messages.january, endMonth: messages.march },
	{ startMonth: messages.april, endMonth: messages.june },
	{ startMonth: messages.july, endMonth: messages.september },
	{ startMonth: messages.october, endMonth: messages.december },
];

export default messages;
export { inWeekOrder, inMonthOrder, inQuarterOrder };
