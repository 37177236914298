import type { AttachmentsResponse } from '@atlassian/jira-issue-attachments-base';
import type {
	Field,
	MyPreferences,
	RemoteLinks,
	ActivitySortOrderType,
} from '@atlassian/jira-issue-shared-types';
import type { CommentsData, Issue, IssueWorkflow } from '../model';

class IssueBuilder {
	key: string;

	id: string | undefined;

	fields: Map<string, Field>;

	meta:
		| {
				newIssueViewLockedIn: boolean;
				dataSource: string;
				fetchTime: Date;
		  }
		| undefined;

	remoteLinks: RemoteLinks | undefined;

	myPreferences: MyPreferences | undefined;

	commentsData: CommentsData | undefined;

	attachmentsData: AttachmentsResponse | undefined;

	workflow: IssueWorkflow | undefined;

	// @ts-expect-error - TS2564 - Property 'activitySortOrder' has no initializer and is not definitely assigned in the constructor.
	activitySortOrder: ActivitySortOrderType;

	constructor({ key }: { key: string }) {
		this.key = key;
		this.fields = new Map();
	}

	withMeta(meta: {
		newIssueViewLockedIn: boolean;
		dataSource: string;
		fetchTime: Date;
		// go/jfe-eslint
	}): IssueBuilder {
		this.meta = meta;
		return this;
	}

	// go/jfe-eslint
	withId(id: string): IssueBuilder {
		this.id = id;
		return this;
	}

	// go/jfe-eslint
	withFields(fields: (Field | undefined)[]): IssueBuilder {
		fields.forEach(this.withField, this);
		return this;
	}

	// go/jfe-eslint
	withField(field?: Field): IssueBuilder {
		if (field) {
			this.fields.set(String(field.key), field);
		}
		return this;
	}

	// go/jfe-eslint
	withRemoteLinks(remoteLinks: RemoteLinks): IssueBuilder {
		this.remoteLinks = remoteLinks;
		return this;
	}

	// go/jfe-eslint
	withPreferences(myPreferences: MyPreferences): IssueBuilder {
		this.myPreferences = myPreferences;
		return this;
	}

	// go/jfe-eslint
	withComments(commentsData: CommentsData): IssueBuilder {
		this.commentsData = commentsData;
		return this;
	}

	// go/jfe-eslint
	withWorkflow(workflow: IssueWorkflow): IssueBuilder {
		this.workflow = workflow;
		return this;
	}

	// go/jfe-eslint
	withActivitySortOrder(activitySortOrder: ActivitySortOrderType): IssueBuilder {
		this.activitySortOrder = activitySortOrder;
		return this;
	}

	build(): Issue {
		const meta =
			this.meta !== null && this.meta !== undefined
				? this.meta
				: { dataSource: 'unknown', newIssueViewLockedIn: false, fetchTime: new Date() };

		const remoteLinks =
			this.remoteLinks !== null && this.remoteLinks !== undefined
				? this.remoteLinks
				: {
						confluencePages: {
							linkedPages: {
								pageCount: 0,
							},
							mentionedPages: {
								pageCount: 0,
							},
						},
						confluenceWhiteboards: {
							linkedWhiteboards: {
								whiteboardCount: 0,
							},
						},
						webLinks: {
							linkCount: 0,
						},
						remoteLinkedIssues: [],
					};
		const myPreferences =
			this.myPreferences !== null && this.myPreferences !== undefined ? this.myPreferences : {};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let users: Record<string, any> = {};
		let comments = {
			totalCount: 0,
			startIndex: 0,
			comments: {},
		};
		if (this.commentsData !== null && this.commentsData !== undefined) {
			comments = {
				totalCount: this.commentsData.totalCount,
				// @ts-expect-error - TS2322 - Type 'number | undefined' is not assignable to type 'number'.
				startIndex: this.commentsData.startIndex,
				comments: this.commentsData.comments,
			};
			users = this.commentsData.users;
		}

		const workflow =
			this.workflow !== null && this.workflow !== undefined ? this.workflow : undefined;

		const activitySortOrder = this.activitySortOrder;

		const issue = {
			key: this.key,
			fields: this.fields,
			meta,
			remoteLinks,
			myPreferences,
			comments,
			users,
			workflow,
			activitySortOrder,
			...(this.id !== null && this.id !== undefined ? { id: this.id } : undefined),
		};

		return issue;
	}
}

export const createIssue = ({ key }: { key: string }): IssueBuilder => new IssueBuilder({ key });
