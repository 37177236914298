import React from 'react';
import { N300, N60 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

type BarType = 'noStartDate' | 'noEndDate' | 'rollupDates';

interface Props {
	type: BarType;
}

const getIssueBarContents = (type: BarType) => {
	const color = token('color.background.accent.gray.bolder', N300);

	switch (type) {
		case 'noStartDate':
			return (
				<svg width="53" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<defs>
						<linearGradient id="noStartDate" gradientUnits="userSpaceOnUse">
							<stop stopColor={color} stopOpacity="0" />
							<stop offset=".75" stopColor={color} />
						</linearGradient>
					</defs>
					<rect width="53" height="16" rx="3" fill="url(#noStartDate)" />
				</svg>
			);
		case 'noEndDate':
			return (
				<svg width="53" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<defs>
						<linearGradient id="noEndDate" gradientUnits="userSpaceOnUse">
							<stop offset="0.25" stopColor={color} />
							<stop offset="1" stopColor={color} stopOpacity="0" />
						</linearGradient>
					</defs>
					<rect width="53" height="16" rx="3" fill="url(#noEndDate)" />
				</svg>
			);
		case 'rollupDates':
			return (
				<svg width="53" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<defs>
						<pattern
							id="rollupDates"
							patternUnits="userSpaceOnUse"
							width="12"
							height="12"
							x="6"
							y="0"
							patternTransform="rotate(27.5)"
						>
							<line
								x1="3"
								y1="0"
								x2="3"
								y2="12"
								opacity="0.5"
								stroke={token('color.background.accent.gray.subtler', N60)}
								strokeWidth="6"
							/>
						</pattern>
					</defs>
					<rect width="53" height="16" rx="3" fill={color} />
					<rect width="53" height="16" rx="3" fill="url(#rollupDates)" />
				</svg>
			);
		default:
			return (
				<svg width="53" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect width="53" height="16" rx="3" fill={color} />
				</svg>
			);
	}
};

const IssueBarIcon = ({ type }: Props) => getIssueBarContents(type);

export default IssueBarIcon;
