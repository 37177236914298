import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'roadmap.standard-roadmap.flags.dependencies-unavailable.title',
		defaultMessage: "Sorry we couldn't create a dependency",
		description: 'Flag title. Informs a user that a dependency cannot be created.',
	},
	description: {
		id: 'roadmap.standard-roadmap.flags.dependencies-unavailable.description',
		defaultMessage:
			'Check your issue link Jira setting or contact your Jira administrator for help.',
		description: 'Flag description. Informs a user why a dependency cannot be created.',
	},
	learnMore: {
		id: 'roadmap.standard-roadmap.flags.dependencies-unavailable.learn-more',
		defaultMessage: 'Learn more',
		description: 'Learn more link title',
	},
});
