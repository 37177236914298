import type { PrimitiveType, FormatXMLElementFn } from 'intl-messageformat';
import type { MessageDescriptor, IntlShape } from 'react-intl-next';

export const renderOverrideLabel = (
	formatMessage: IntlShape['formatMessage'],
	descriptor?: MessageDescriptor,
	values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
) => {
	if (descriptor) {
		return formatMessage(descriptor, values);
	}
};
