import type {
	InteractiveChartItem,
	ItemPositions,
	PreviewBaselinePosition,
} from '../../common/types';

/* When beginning drag, a no date item should already have a visible preview, and thus pre-existing positions.
 * We use these positions as the baseline for any upcoming drag interactions.
 */
export const getPreviewBaselinePosition = (
	activeItem: InteractiveChartItem,
	itemPositions: ItemPositions,
): PreviewBaselinePosition | undefined => {
	let previewBaselinePosition;
	const { id, startDate, dueDate } = activeItem;

	if (startDate === undefined && dueDate === undefined && itemPositions[id]) {
		const { leftPosition, rightPosition } = itemPositions[id];

		if (leftPosition !== undefined && rightPosition !== undefined) {
			previewBaselinePosition = { leftPosition, rightPosition };
		}
	}

	return previewBaselinePosition;
};
