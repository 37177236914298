// eslint-disable-next-line jira/wrm/no-load-bridge
import jiraBridge from '@atlassian/jira-common-bridge/src/jira-bridge';
import { getSelectedIssueKey } from '../../state/router/selectors';
import type { State } from '../../state/types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State, issueKey?: string) => {
	if (issueKey !== undefined && getSelectedIssueKey(state) === issueKey) {
		// eslint-disable-next-line jira/wrm/no-load-bridge
		jiraBridge.refreshIssuePage(issueKey);
	}
};
