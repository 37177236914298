import type { KeyboardEvent, SyntheticEvent } from 'react';

export const isEnterOrSpaceKey = (event: KeyboardEvent<HTMLElement>) =>
	event.keyCode === 13 || event.keyCode === 32;

export const isEscapeKey = (event: KeyboardEvent<HTMLElement>) =>
	event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27;

export const isBubbledEvent = (event: SyntheticEvent<HTMLElement>) =>
	event.currentTarget !== event.target;

export const isLeftMousePressed = (e: MouseEvent): boolean => {
	const event = e || window.event;
	if ('buttons' in event) {
		return event.buttons === 1;
	}
	if (event) {
		const { button } = event;
		return button === 1;
	}
	return false;
};
