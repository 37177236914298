import React, { Component, Fragment, type ComponentType } from 'react';
import noop from 'lodash/noop';
import Anchor from '@atlaskit/primitives/anchor';
import { fg } from '@atlassian/jira-feature-gating';
import SuccessFlag from '@atlassian/jira-flags/src/common/ui/components/success-auto-dismiss-flag';
import { type IntlShapeV2 as IntlShape, injectIntlV2 as injectIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	MODAL,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types';
import type { DescriptionProps } from './common/types';
import messages from './messages';

type Props = {
	issueKeys: IssueKey[];
	id: string | number;
	Description: ComponentType<DescriptionProps>;
	onDismissed: (id: number | string) => void;
	intl: IntlShape;
};

// eslint-disable-next-line jira/react/no-class-components
class IrrelevantItemCreatedFlag extends Component<Props> {
	static defaultProps = {
		onDismissed: noop,
	};

	onDismissed = () => {
		const { id, onDismissed } = this.props;
		onDismissed(id);
	};

	renderIssueUrls() {
		const { issueKeys } = this.props;

		return (
			<>
				{issueKeys.map((issueKey, index) => (
					<Fragment key={issueKey}>
						{fg('jira-software-roadmap-standard-link-in-text-a11y') ? (
							<Anchor href={`/browse/${issueKey}`}>{issueKey}</Anchor>
						) : (
							<a href={`/browse/${issueKey}`}>{issueKey}</a>
						)}
						{index < issueKeys.length - 1 && ', '}
					</Fragment>
				))}
			</>
		);
	}

	render() {
		const {
			id,
			issueKeys,
			Description,
			intl: { formatMessage },
		} = this.props;

		const issueCount = issueKeys.length;
		const issueUrls = this.renderIssueUrls();

		const title =
			issueCount === 1
				? formatMessage(messages.title)
				: formatMessage(messages.titleMultipleIssues, { issueCount });

		return (
			<ContextualAnalyticsData sourceName="irrelevantItemCreated" sourceType={MODAL}>
				<SuccessFlag
					id={id}
					onDismissed={this.onDismissed}
					title={title}
					description={<Description issueCount={issueCount} issueUrls={issueUrls} />}
				/>
				<FireScreenAnalytics />
			</ContextualAnalyticsData>
		);
	}
}

export default injectIntl(IrrelevantItemCreatedFlag);
