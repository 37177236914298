import { connect } from '@atlassian/jira-react-redux';
import type { PanelType } from '../../model/panel';
import type { State } from '../../state/types';
import { getPanelType } from '../../state/ui/panel/selectors';
import TableOverlay from './view';

type StateProps = {
	panelType: PanelType;
};

export default connect(
	(state: State): StateProps => ({
		panelType: getPanelType(state),
	}),
	{},
)(TableOverlay);
