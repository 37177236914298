import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

type Props = {
	letter: string;
};

const LetterIcon = ({ letter }: Props) => (
	<Lozenge>
		<Box xcss={TextStyles} as="span">
			{letter}
		</Box>
	</Lozenge>
);

export const DateFieldIcon = () => <LetterIcon letter="D" />;
export const SprintDateIcon = () => <LetterIcon letter="S" />;
export const ReleaseDateIcon = () => <LetterIcon letter="R" />;

export default LetterIcon;

const TextStyles = xcss({
	font: token('font.heading.xsmall'),
	lineHeight: token('space.200'),
	fontWeight: token('font.weight.bold'),
});
