import React, { useMemo, type MouseEvent } from 'react';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import HighlightText from '@atlassian/jira-software-highlight-text';
import {
	SummaryContainer as Container,
	SummaryIcon,
	SummaryTitle,
	SummaryKeyLink,
} from '../../../common/ui/summary';
import messages from './messages';
import ProgressIndicator, { type ProgressMetadata } from './progress-indicator';

type Props = {
	isDone: boolean;
	title: string;
	iconUrl: string;
	itemKey?: string;
	iconAltText: string;
	highlightText: string;
	progressMetadata: ProgressMetadata;
	isActive: boolean;
};

const DEFAULT_PROGRESS_META = {
	completed: 0,
	total: 0,
};

export const onClick = (event: MouseEvent<HTMLAnchorElement>) => {
	const withCmd = event.ctrlKey || event.metaKey;
	// We do not want to navigate away when clicking unless there is explicit intent
	// In most cases, a click should open up the item details
	withCmd ? event.stopPropagation() : event.preventDefault();
};

const onPress = (e: React.KeyboardEvent<HTMLAnchorElement>, link: string) => {
	if (e.key === 'Enter') {
		window.open(link, '_blank');
	}
};

const Summary = ({
	title,
	iconUrl,
	isDone,
	itemKey,
	iconAltText,
	highlightText,
	isActive,
	progressMetadata,
}: Props) => {
	const highlight = useMemo(() => [highlightText], [highlightText]);
	const { formatMessage } = useIntl();

	const itemKeyEl =
		highlightText !== '' && itemKey !== undefined ? (
			<HighlightText
				data-testid="roadmap.timeline-table-kit.ui.list-item-content.summary.item-el"
				text={itemKey}
				highlight={highlight}
			/>
		) : (
			itemKey
		);

	const titleEl =
		highlightText !== '' ? (
			<HighlightText
				data-testid="roadmap.timeline-table-kit.ui.list-item-content.summary.title-el"
				text={title}
				highlight={highlight}
			/>
		) : (
			title
		);

	const renderSummaryTitle = () => (
		<SummaryTitle type="p" testId="roadmap.timeline-table-kit.ui.list-item-content.summary.title">
			{titleEl}
		</SummaryTitle>
	);

	return (
		<>
			<Container>
				<SummaryIcon src={iconUrl} alt={iconAltText} />
				{itemKey && (
					<SummaryKeyLink
						href={`/browse/${itemKey}`}
						isDone={isDone}
						testId="roadmap.timeline-table-kit.ui.list-item-content.summary.key"
						onClick={onClick}
						aria-label={formatMessage(messages.issueKeyLabel, { itemKey })}
						onKeyDown={(e) => onPress(e, `/browse/${itemKey}`)}
					>
						{itemKeyEl}
					</SummaryKeyLink>
				)}
				{title.length > 16 ? (
					<Tooltip position="bottom-start" content={titleEl}>
						{renderSummaryTitle()}
					</Tooltip>
				) : (
					renderSummaryTitle()
				)}
			</Container>
			{progressMetadata.total > 0 && (
				<ProgressIndicator {...progressMetadata} isActive={isActive} />
			)}
		</>
	);
};

Summary.defaultProps = {
	isDone: false,
	iconAltText: '',
	highlightText: '',
	progressMetadata: DEFAULT_PROGRESS_META,
};

export default Summary;
export type { ProgressMetadata };
