import { useEffect, useMemo } from 'react';
import { UNSAFE_noExposureExp, expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { sessionStorageProvider } from '@atlassian/jira-onboarding-core';
import {
	CAN_ENROLL_IN_SELECT_INITIAL_VIEW,
	SHOULD_SHOW_WELCOME_ONBOARDING_MODAL,
} from '@atlassian/jira-onboarding-core/src/constants';
import type { ModalSource } from '../../../common/types';
import { useCanShowNudgeTour } from '../../../common/use-can-show-nudge-tour';

const isInWelcomeModalVariationCohort = () => {
	const [expConfig] = UNSAFE_noExposureExp('jsw_welcome_modal_m2');

	return expConfig.get('cohort', 'not-enrolled') === 'variation';
};

export const isInSelectInitialViewVariationCohort = () => {
	const value = expVal('jfind-select-initial-view', 'cohort', 'not-enrolled');
	return value === 'variation';
};

export const useShouldShowModalBasedOnSessionStorage = () => {
	const shouldShowModalBasedOnSessionStorage = useMemo(() => {
		if (!isInWelcomeModalVariationCohort()) {
			return false;
		}

		const sessionStorageVal = sessionStorageProvider.get(SHOULD_SHOW_WELCOME_ONBOARDING_MODAL);

		if (sessionStorageVal === undefined) {
			return false;
		}

		if (!fg('fix_welcome_modal_in_strict_mode_')) {
			sessionStorageProvider.remove(SHOULD_SHOW_WELCOME_ONBOARDING_MODAL);
		}

		return true;
	}, []);

	useEffect(() => {
		// Remove the session storage value to prevent the modal showing again
		if (fg('fix_welcome_modal_in_strict_mode_')) {
			sessionStorageProvider.remove(SHOULD_SHOW_WELCOME_ONBOARDING_MODAL);
		}
	}, []);

	return shouldShowModalBasedOnSessionStorage;
};

export const useShouldShowWelcomeModal = (source: ModalSource) => {
	const canShowNudgeTour = useCanShowNudgeTour();
	const sessionStorageVal = useMemo(
		() => sessionStorageProvider.get(SHOULD_SHOW_WELCOME_ONBOARDING_MODAL),
		[],
	);
	if (sessionStorageVal === undefined) {
		return false;
	}

	return canShowNudgeTour(source);
};

export const useShouldShowSelectInitialViewVariation = (source: ModalSource) => {
	const shouldShowModal = useShouldShowWelcomeModal(source);

	const canEnrollInSelectInitialViewBasedOnSessionStorage = useMemo(() => {
		if (!shouldShowModal) {
			return false;
		}

		const sessionStorageVal = sessionStorageProvider.get(CAN_ENROLL_IN_SELECT_INITIAL_VIEW);

		if (sessionStorageVal === undefined) {
			return false;
		}

		if (!isInSelectInitialViewVariationCohort()) {
			return false;
		}

		return true;
	}, [shouldShowModal]);

	useEffect(() => {
		sessionStorageProvider.remove(CAN_ENROLL_IN_SELECT_INITIAL_VIEW);
	}, []);

	return canEnrollInSelectInitialViewBasedOnSessionStorage;
};
