import { useRef, useMemo, useCallback } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { fireOperationalAnalyticsEvent } from '../../../utils/analytics';
import { VALID_INITIAL_STATES, VALID_TRANSITIONS, DEFAULT_COUNTS, ROW_STATES } from './constants';
import type { RowState, InteractionStates, PreviouslySelected, HookValue } from './types';

/* Due to being side-effect driven, the table marshal's have historically been difficult to debug and reason about.
 * While not a silver bullet, this hook attempts to express the marshal's operations as a finite state machine.
 * This addresses our historical problems in a few ways:
 * 1. Each row is represented by single state at any one time, as opposed to series of booleans
 * 2. Developers can inspect the state during local development, and production logs will generate for invalid states
 * 3. The state transitions can be easily extended and tested
 */
export const useRowState = (): HookValue => {
	const rowState = useRef<RowState>({});
	const stateCount = useRef<typeof DEFAULT_COUNTS>({ ...DEFAULT_COUNTS });
	const previouslySelected = useRef<PreviouslySelected>({});
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// ================ //
	// === ANALYTICS === //
	// ================ //

	const fireInvalidRowTransitionEvent = useCallback(
		(message: string) => {
			const event = createAnalyticsEvent({
				action: 'invalidRowTransition',
				actionSubject: 'timelineTable.sideEffectMarshall',
				attributes: { message },
			});

			fireOperationalAnalyticsEvent(event);
		},
		[createAnalyticsEvent],
	);

	const fireEmptyRowEntryEvent = useCallback(() => {
		const event = createAnalyticsEvent({
			action: 'emptyRowEntry',
			actionSubject: 'timelineTable.sideEffectMarshall',
		});

		fireOperationalAnalyticsEvent(event);
	}, [createAnalyticsEvent]);

	// ================ //
	// === METADATA === //
	// ================ //

	// Doing this for each `set` and `clear` operation stops this from becoming an O(N) problem
	const updateStateCount = useCallback(
		(currentState: InteractionStates, nextState?: InteractionStates) => {
			if (currentState) stateCount.current[currentState] -= 1;
			if (nextState) stateCount.current[nextState] += 1;
		},
		[],
	);

	const isAnyRowDragging = useCallback(
		() =>
			stateCount.current[ROW_STATES.DRAGGED] > 0 ||
			stateCount.current[ROW_STATES.DRAGGED_OVER] > 0 ||
			stateCount.current[ROW_STATES.DRAGGED_OUT] > 0,
		[],
	);

	// Helps with interactions that rely on restoring the selection state
	const updatePreviouslySelected = useCallback(
		(id: string, currentState: InteractionStates, nextState?: InteractionStates) => {
			if (nextState && currentState === ROW_STATES.SELECTED) {
				previouslySelected.current[id] = true;
			}
			if (!nextState || nextState === ROW_STATES.SELECTED) {
				delete previouslySelected.current[id];
			}
		},
		[],
	);

	const wasRowSelected = useCallback((id: string) => previouslySelected.current[id] ?? false, []);

	// ================= //
	// === GET / SET === //
	// ================= //

	const get = useCallback((id: string) => rowState.current[id], []);

	const set = useCallback(
		(id: string, nextState: InteractionStates) => {
			const currentState = get(id);

			if (
				(!currentState && VALID_INITIAL_STATES[nextState]) ||
				(currentState && VALID_TRANSITIONS[currentState][nextState])
			) {
				updateStateCount(currentState, nextState);
				updatePreviouslySelected(id, currentState, nextState);
				rowState.current[id] = nextState;
			} else if (currentState !== nextState) {
				fireInvalidRowTransitionEvent(`Invalid transition from ${currentState} to ${nextState}`);
			}
		},
		[get, updateStateCount, updatePreviouslySelected, fireInvalidRowTransitionEvent],
	);

	const clear = useCallback(
		(id: string) => {
			const currentState = get(id);

			if (currentState) {
				updateStateCount(currentState);
				updatePreviouslySelected(id, currentState);
				delete rowState.current[id];
			} else {
				// This is a warning as it could be a sign of a bug in the table marshal
				fireEmptyRowEntryEvent();
			}
		},
		[get, updateStateCount, updatePreviouslySelected, fireEmptyRowEntryEvent],
	);

	return useMemo(
		() => ({ get, set, clear, isAnyRowDragging, wasRowSelected }),
		[get, set, clear, isAnyRowDragging, wasRowSelected],
	);
};

export { ROW_STATES };
