import memoizeOne from 'memoize-one';
// eslint-disable-next-line jira/restricted/moment
import moment from 'moment';
import type { TimelineDuration } from '@atlassian/jira-software-roadmap-timeline-table/src/types';
import type { SimpleInterval } from './types';

// Only 1dp is required for any browser to render an absolute pixel position.
// Rounding it to 1dp avoids slight differences when the pixel position is calculated slightly differently.
export const to1DP = (value: number): number => Math.round((value + Number.EPSILON) * 10) / 10;

export const getTargetIntervalId = (
	simpleIntervals: ReadonlyArray<SimpleInterval>,
	timelineWidth: number,
	posX: number,
): string | undefined => {
	const overlappingIntervals = simpleIntervals.filter(({ leftPosition, rightPosition }) => {
		const isItemStartEqualToOrGreaterThanIntervalStart = to1DP(posX) >= to1DP(leftPosition);
		const isItemStartLessThanIntervalEnd = to1DP(posX) < to1DP(timelineWidth - rightPosition);
		return isItemStartEqualToOrGreaterThanIntervalStart && isItemStartLessThanIntervalEnd;
	});

	return overlappingIntervals.length > 0 ? overlappingIntervals[0].targetId : undefined;
};

// The size of the dragging "grid" is the pixel equivalent of one day
export const getGridSize = memoizeOne(
	({ endMilliseconds, startMilliseconds }: TimelineDuration, timelineWidth: number) => {
		const numberOfDaysInTimeline = moment(endMilliseconds).diff(startMilliseconds, 'days') + 1;
		return timelineWidth / numberOfDaysInTimeline;
	},
);

// When dragging, we want to snap to a grid, rather than allowing arbitrary position
export const snapToGrid = (position: number, gridSize: number, offset = 0) => {
	const roundedOffset = Math.round(offset / gridSize) * gridSize;
	return position + roundedOffset;
};
