import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/merge';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { getGoals } from '@atlassian/jira-software-roadmap-services';
import {
	INITIALIZE_NON_CRITICAL_DATA,
	type InitializeNonCriticalDataAction as Action,
} from '../../../../state/app/actions';
import { getSourceARI } from '../../../../state/app/selectors';
import { getIsGoalsFeatureEnabled } from '../../../../state/configuration/selectors';
import { setGoals } from '../../../../state/entities/goals/actions';
import { getIsGoalsLoaded } from '../../../../state/entities/goals/selectors';
import type { State } from '../../../../state/types';
import type { StateEpic } from '../../../common/types';

const getInitializeGoalsAction = (sourceAri: string) => getGoals(sourceAri).map(setGoals);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(INITIALIZE_NON_CRITICAL_DATA).mergeMap(() => {
		const state = store.getState();

		const sourceAri = getSourceARI(state);

		const isGoalsFeatureEnabled = getIsGoalsFeatureEnabled(state);
		const isGoalsLoaded = getIsGoalsLoaded(state);

		if (isGoalsFeatureEnabled && !isGoalsLoaded) {
			return getInitializeGoalsAction(sourceAri);
		}
		return Observable.empty();
	})) as StateEpic;
