import type { ItemId } from '../../../types';
import type { CreateTriggerType, State } from './types';

export const isCreateTriggerActiveForItem = (
	state: State,
	{ id, type }: { id: ItemId; type: CreateTriggerType },
) => {
	const activeCreateTrigger = state.activeCreateTrigger;
	if (activeCreateTrigger) {
		return activeCreateTrigger.rowId === id && activeCreateTrigger.type === type;
	}
};
