import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

const EVENT_TYPES = {
	UI: 'UI',
	TRACK: 'TRACK',
	SCREEN: 'SCREEN',
	OPERATIONAL: 'OPERATIONAL',
} as const;

export type EventTypes = keyof typeof EVENT_TYPES;

const createFireAnalytics = (analyticsType: EventTypes) => (event: UIAnalyticsEvent) => {
	const clonedEvent = event.clone();
	if (clonedEvent) {
		clonedEvent
			.update({
				...event.payload,
				analyticsType,
			})
			.fire('productAnalytics');
	}
};

export const fireOperationalAnalyticsEvent = createFireAnalytics(EVENT_TYPES.OPERATIONAL);

export const fireUIAnalyticsEvent = createFireAnalytics(EVENT_TYPES.UI);
