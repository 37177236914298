import has from 'lodash/has';
import type { AccountId } from '@atlassian/jira-shared-types';
import type { User, UserHash } from '@atlassian/jira-software-roadmap-model';
import {
	createVersionedEntityHashDiffSelector,
	createPropertyHashSelector,
} from '../../../common/utils/reselect';
import type { PropertyHashSelector, State } from '../../types';

export const getFullUserHash = (state: State): UserHash => state.entities.users.hash;

export const getUser = (state: State, accountId: AccountId): User | undefined =>
	getFullUserHash(state)[accountId];

// ============== //
// === HASHES === //
// ============== //

const entityUpdateSelector = createVersionedEntityHashDiffSelector(getFullUserHash);

export const getUserDisplayNameHash: PropertyHashSelector<string> = createPropertyHashSelector(
	entityUpdateSelector,
	(user) => user.displayName,
);

export const getUserAvatar16x16Hash: PropertyHashSelector<string> = createPropertyHashSelector(
	entityUpdateSelector,
	(user) => user.avatarUrls && user.avatarUrls['16x16'],
);

export const hasUser = (state: State, accountId: AccountId): boolean =>
	has(getFullUserHash(state), accountId);
