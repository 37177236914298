import { connect } from '@atlassian/jira-react-redux';
import type { Interval } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/types';
import { getSprintIntervals } from '../../../../../state/selectors/sprint';
import type { State } from '../../../../../state/types';
import Sprints from './view';

type StateProps = {
	intervals: ReadonlyArray<Interval>;
};

const mapStateToProps = (state: State): StateProps => ({
	intervals: getSprintIntervals(state),
});

export default connect(mapStateToProps, {})(Sprints);
