import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'roadmap.standard-roadmap.flags.general-error.title',
		defaultMessage: 'Something went wrong',
		description: 'Flag title. Informs a user of a general error.',
	},
	flagDescription: {
		id: 'roadmap.standard-roadmap.flags.general-error.flag-description',
		defaultMessage: 'Reload the timeline to continue.',
		description: 'Flag description. Informs a user of a general error.',
	},
	reloadPageButton: {
		id: 'roadmap.standard-roadmap.flags.general-error.reload-page-button',
		defaultMessage: 'Reload page',
		description:
			'Flag action button description. Indication reload page action to clear the error.',
	},
});
