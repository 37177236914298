import { defineMessages } from 'react-intl-next';

const messages = defineMessages({
	selected: {
		id: 'roadmap.timeline-table.main.content.list.list-item.meatballs-button.meatball-popup.multi-select-options.selected',
		description: 'Label to indicate the number of selected item on a meatballs menu',
		defaultMessage: '<badge>{count}</badge> selected',
	},
});

export default messages;
