/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { useIntl } from 'react-intl-next';
import { N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { inQuarterOrder } from '../messages';

const headerStylesLegacy = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	color: token('color.text.subtlest', N300),
	textTransform: 'uppercase',
	marginTop: 0,
});

const headerStyles = css({
	font: token('font.body.UNSAFE_small'),
	color: token('color.text.subtlest', N300),
	textTransform: 'uppercase',
	marginTop: 0,
});

const higlightedColumnStyles = css({
	fontWeight: token('font.weight.bold', 'bold'),
});

type Props = {
	quarterNumber: number;
	// Set to required on FG cleanup jsw_roadmaps_timeline-bold-gridcell-header
	isHighlighted?: boolean;
	yearCaption?: string | undefined | null;
};

const QuarterCell = ({ quarterNumber, yearCaption, isHighlighted }: Props) => {
	const { formatMessage } = useIntl();

	const { startMonth, endMonth } = inQuarterOrder[quarterNumber];

	return (
		<small
			css={[
				fg('replace-typography-css-with-tokens-for-chronos') && headerStyles,
				!fg('replace-typography-css-with-tokens-for-chronos') && headerStylesLegacy,
				isHighlighted && fg('jsw_roadmaps_timeline-bold-gridcell-header') && higlightedColumnStyles,
			]}
		>
			{`${formatMessage(startMonth)} – ${formatMessage(endMonth)}`} {yearCaption}
		</small>
	);
};

QuarterCell.defaultProps = {
	yearCaption: null,
};

export default QuarterCell;
export type { Props as APIProps };
