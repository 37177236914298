import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { DependencyDragHandler } from '@atlassian/jira-software-roadmap-timeline-table-kit';

type Props = {
	top: number;
	left: number;
	color: string;
};

const DependencyIcon = ({ top, left, color }: Props) => (
	<Box xcss={wrapperStyles} style={{ top, left }}>
		<DependencyDragHandler color={color} />
	</Box>
);

export default DependencyIcon;

const wrapperStyles = xcss({
	position: 'absolute',
	display: 'flex',
	width: token('space.250', '20px'),
	height: token('space.250', '20px'),
	transform: 'translate(-10px, -10px)',
});
