import type { MiddlewareAPI, Action } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/zip';
import type { BatchAction } from 'redux-batched-actions';
import intersection from 'lodash/intersection';
import { Observable } from 'rxjs/Observable';
import type { IssueId } from '@atlassian/jira-shared-types';
import {
	filterItemsByJQLFilters,
	getItems,
	getIssueIdsFromServer,
	type IssuesAndUsers,
} from '@atlassian/jira-software-roadmap-services';
import { getSourceARI } from '../../../state/app/selectors';
import { getFullIssueTypeHash } from '../../../state/configuration/selectors';
import {
	updateIssuesSequence,
	type RemoveIssuesAction,
} from '../../../state/entities/issues/actions';
import { getIssueIds, getIssueRank } from '../../../state/entities/issues/selectors';
import { getQuickFilters, getCustomFilters } from '../../../state/router/selectors';
import type { State } from '../../../state/types';
import { jqlFiltersSuccess, type JQLFiltersSuccessAction } from '../../../state/ui/filters/actions';
import {
	issueTypesReloadRequired,
	criticalDataReload,
	tryGetIssueFromIssuesAndUsers,
} from './reload';
import { updateIssuesAndUsers } from './update';

type reloadWithSequenceObservables =
	| Observable<IssuesAndUsers>
	| Observable<JQLFiltersSuccessAction>
	| Observable<BatchAction>
	| Observable<Action>
	| Observable<RemoveIssuesAction>;

export const reloadIssuesWithSequence = (
	store: MiddlewareAPI<State>,
	issueIds: IssueId[],
): Observable<reloadWithSequenceObservables> => {
	if (issueIds.length === 0) {
		return Observable.empty<never>();
	}

	const state = store.getState();
	const sourceAri = getSourceARI(state);
	const quickFilterIds = getQuickFilters(store.getState());
	const customFilterIds = getCustomFilters(store.getState());
	const isJQLFiltersApplied = quickFilterIds.length > 0 || customFilterIds.length > 0;

	return Observable.zip<[IssuesAndUsers, string[] | null]>(
		getItems(sourceAri, issueIds),
		isJQLFiltersApplied
			? filterItemsByJQLFilters(getSourceARI(state), quickFilterIds, customFilterIds)
			: Observable.of(null),
	).mergeMap(([issuesAndUsers, filterIssueIds]) => {
		const reloadWithSequenceObservables: Array<reloadWithSequenceObservables> = [];

		if (filterIssueIds !== null) {
			reloadWithSequenceObservables.push(Observable.of(jqlFiltersSuccess(filterIssueIds)));
		}

		if (issueIds.length === 0 && issuesAndUsers.issues.sequence.length === 0) {
			return Observable.empty<never>();
		}

		const issueTypes = getFullIssueTypeHash(store.getState());
		if (issueTypesReloadRequired(issuesAndUsers, issueTypes)) {
			reloadWithSequenceObservables.push(criticalDataReload(store));
			return Observable.merge(...reloadWithSequenceObservables);
		}

		// when creating issues in between, we also need to update the rank
		const isAnyRankNewOrDifferent = issueIds.some((issueId: IssueId) => {
			const oldRank = getIssueRank(state, issueId);
			// it's possible that the issue was deleted by the time this realtime event reached the client
			// in this rare edge case, the fetch would not return the issue
			// and trying to search for it by id would result in undefined
			// the rank will be updated when handling the delete realtime event
			const newIssue = issuesAndUsers.issues.hash[issueId];
			return newIssue !== undefined && (oldRank === undefined || oldRank !== newIssue.rank);
		});

		if (isAnyRankNewOrDifferent) {
			return getIssueIdsFromServer(sourceAri).mergeMap((newSequence: IssueId[]) => {
				const oldSequence = getIssueIds(state);
				const batched = updateIssuesAndUsers(store, {
					issues: {
						hash: issuesAndUsers.issues.hash,
						sequence: issuesAndUsers.issues.sequence,
					},
					users: issuesAndUsers.users,
				});
				batched.payload.unshift(
					// To prevent race condition, we ignore issues that do not exist in the store.
					updateIssuesSequence(
						intersection(newSequence, oldSequence.concat(issuesAndUsers.issues.sequence)),
					),
				);
				reloadWithSequenceObservables.push(Observable.of(batched));
				return Observable.merge(...reloadWithSequenceObservables);
			});
		}

		let issueIdsForRemoval: string[] = [];
		if (issueIds.length !== issuesAndUsers.issues.sequence.length) {
			issueIdsForRemoval = issueIds.filter(
				(issueId) => !tryGetIssueFromIssuesAndUsers(issuesAndUsers, issueId),
			);
		}

		reloadWithSequenceObservables.push(
			Observable.of(updateIssuesAndUsers(store, issuesAndUsers, issueIdsForRemoval)),
		);
		return Observable.merge(...reloadWithSequenceObservables);
	});
};
