import memoizeOne from 'memoize-one';
// eslint-disable-next-line jira/restricted/moment
import moment from 'moment';
import {
	MONTHS,
	QUARTERS,
} from '@atlassian/jira-software-roadmap-timeline-table/src/common/constants.tsx';
import type {
	TimelineMode,
	TimelineDuration,
} from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/timeline.tsx';
import { getTimelineWidth } from '@atlassian/jira-software-roadmap-timeline-table/src/common/utils/timeline.tsx';

/* Get the timeline duration from a specific date input.
 * The duration is +-N years, rounded based on the specific timeline mode (weeks, months, quarters).
 */
export const getTimelineDurationFromDate = memoizeOne(
	(timelineMode: TimelineMode, date: number): TimelineDuration => {
		let startMoment = moment.utc(date).subtract(1, 'year');
		let endMoment = moment.utc(date).add(2, 'year');

		if (timelineMode === QUARTERS) {
			startMoment = startMoment.startOf('quarter');
			endMoment = endMoment.endOf('quarter');
		} else if (timelineMode === MONTHS) {
			startMoment = startMoment.startOf('month');
			endMoment = endMoment.endOf('month');
		} else {
			/* We are using ISO week to match column calculations.
			 * See getColumnDurations below for additional info.
			 */
			startMoment = startMoment.startOf('isoWeek');
			endMoment = endMoment.endOf('isoWeek');
		}

		return {
			startMilliseconds: startMoment.valueOf(),
			endMilliseconds: endMoment.valueOf(),
			totalMilliseconds: endMoment.diff(startMoment, 'milliseconds'),
		};
	},
);

/* Gets the date that the timeline viewport should be set on page load, as well
 * as when changing the timeline mode. Rather than using "today" as this date, we
 * aim to provide more context at a glance by starting half a column in the past.
 */
export const getTimelineOriginForToday = memoizeOne(
	(today: number, timelineMode: TimelineMode): number => {
		const todayMoment = moment.utc(today);

		if (timelineMode === QUARTERS) {
			return todayMoment.startOf('quarter').subtract(45, 'days').valueOf();
		}
		if (timelineMode === MONTHS) {
			return todayMoment.startOf('month').subtract(15, 'days').valueOf();
		}
		return todayMoment.startOf('isoWeek').subtract(4, 'days').valueOf();
	},
);

/* Get the position in pixels that the timeline should be scrolled to.
 * This position is set on page load, and when changing timeline mode.
 */
export const getDefaultTimelinePositionFromDate = memoizeOne(
	(date: number, timelineMode: TimelineMode, timelineDuration: TimelineDuration): number => {
		const millisecondPosition = getTimelineOriginForToday(date, timelineMode);
		const relativePosition =
			(millisecondPosition - timelineDuration.startMilliseconds) /
			timelineDuration.totalMilliseconds;

		return relativePosition * getTimelineWidth(timelineMode, timelineDuration.totalMilliseconds);
	},
);
