const SCROLL_SCALE_WITH_OFFSET = 2;

const getWeightedScrollAmountWithOffset = (scrollAmount: number): number =>
	Math.round(scrollAmount ** SCROLL_SCALE_WITH_OFFSET);

const SCROLL_MAX = 150;
const getThrottledScrollAmount = (scrollAmount: number): number =>
	Math.min(SCROLL_MAX, scrollAmount);

export const createCalculateScrollOnDragWithOffset =
	(scrollThreshold: number) =>
	(
		height: number,
		top: number,
		bottom: number,
		cursorOffset: number,
		cursorOffsetDiff: number,
		scrollPosition: number,
		scrollSize: number,
	): number => {
		// Fallback to current scrollPosition
		let newScrollPosition = scrollPosition;
		// small tables need a fallback threshold derived from its' size
		const threshold = Math.min(height / 2, scrollThreshold);

		if (cursorOffset > top && cursorOffset <= top + threshold && cursorOffsetDiff < 0) {
			// inversely relative to distance to list top
			const scrollAmount = getWeightedScrollAmountWithOffset(
				1 / ((cursorOffset - top) / threshold),
			);
			const throttledScrollAmount = getThrottledScrollAmount(scrollAmount);

			// don't scroll beyond the list start
			newScrollPosition = Math.max(0, scrollPosition - throttledScrollAmount);
		} else if (cursorOffset < bottom && cursorOffset >= bottom - threshold && cursorOffset > 0) {
			// inversely relative to distance to list bottom
			const scrollAmount = getWeightedScrollAmountWithOffset(
				1 / ((bottom - cursorOffset) / threshold),
			);
			const throttledScrollAmount = getThrottledScrollAmount(scrollAmount);

			// don't scroll beyond the list end
			newScrollPosition = Math.min(scrollSize, scrollPosition + throttledScrollAmount);
		}

		return newScrollPosition;
	};
