import { useEffect } from 'react';
import 'rxjs/add/operator/filter';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { CacheInvalidationType } from '../../common/types';
import ResourcesCacheRefreshAnalytics from '../resources-cache-refresh-analytics-subject';

/*
 * This hook is for firing an analytics event to track whether the cache is stale
 * It will be fired when we load a cache but the cache is stale and later gets updated
 */
const useCacheRefreshAnalytics = (origin: CacheInvalidationType) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		const subscription = ResourcesCacheRefreshAnalytics.filter((val) => val === origin).subscribe(
			() => {
				fireTrackAnalytics(createAnalyticsEvent({}), 'resourcesCacheInvalidator updatedStaleData', {
					origin,
				});
			},
		);
		return () => {
			subscription.unsubscribe();
		};
	}, [createAnalyticsEvent, origin]);

	return [ResourcesCacheRefreshAnalytics] as const;
};

export default useCacheRefreshAnalytics;
