import type { IdentifiableHash } from '../common';

export type SprintId = string;

export const CLOSED = 'CLOSED' as const;
export const ACTIVE = 'ACTIVE' as const;
export const FUTURE = 'FUTURE' as const;

export type SprintState = typeof CLOSED | typeof ACTIVE | typeof FUTURE;

export type Sprint = {
	id: SprintId;
	name: string;
	state: SprintState;
	startDate: number;
	endDate: number;
};

export type SprintsHash = IdentifiableHash<SprintId, Sprint>;
