import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	releases: {
		id: 'roadmap.timeline-table-kit.chart-header-item.key-dates.tooltip.releases',
		defaultMessage:
			'{noOfReleases} {noOfReleases, plural, one {release} other {releases}}: {releaseNamesAndDates}',
		description:
			'Aria label text when focus is switched to releases Lozenge button of releases section',
	},
});
