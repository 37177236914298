import { memo, useEffect, useRef } from 'react';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import { useSideEffectMarshal } from '../../common/context/side-effect-marshal';
import type { ItemId } from '../../common/types/item';

type Props = {
	selectedItemIds: ItemId[];
};

/* Item selection styles are orchestrated via the style marshal since it has to
 * be synchronised across a row. As such, there is no need for an item component
 * to even know about whether it is selected, allowing us to maintain the selection
 * state without going deep into the tree. This also has performance benefits.
 */
const ItemsSelector = ({ selectedItemIds }: Props) => {
	const { onRowSelect, onRowDeselect } = useSideEffectMarshal();
	const previousSelectedItemIds = useRef(selectedItemIds);

	useEffect(() => {
		const unselectedItemIds = difference(previousSelectedItemIds.current, selectedItemIds);
		onRowDeselect(unselectedItemIds);
		onRowSelect(selectedItemIds);
	}, [previousSelectedItemIds, selectedItemIds, onRowSelect, onRowDeselect]);

	useEffect(() => {
		previousSelectedItemIds.current = selectedItemIds;
	}, [selectedItemIds]);

	return null;
};

/* Having this update unnecessarily contributes to an unexpected lifecycle in the side-effect-marshal.
 * The simplest remedy for now is to deep compare the selected item ids. In future, we may want to integrate
 * the selection into the side-effect-marshal itself.
 */
export default memo<Props>(ItemsSelector, isEqual);
