import { fg } from '@atlassian/jira-feature-gating';
import { listItemMenu } from '@atlassian/jira-software-roadmap-timeline-table';
import type { ListItemMenuOptions } from '@atlassian/jira-software-roadmap-timeline-table/src/types';
import {
	BULK_SECTION,
	SCHEDULING_SECTION,
	REMOVE_DATES,
	EDIT_SPRINT,
	EDIT_DATES,
	BULK_EDIT_DATES,
	EDIT_DEPENDENCY,
	CHANGE_PARENT_ISSUE,
	CHANGE_ISSUE_COLOR,
} from '../../../../constants';
import messages from './messages';

export const getListItemMenuOptionsPure = (
	canUserEdit: boolean,
	isSprintsFeatureEnabled: boolean,
	isChildIssuePlanningEnabled: boolean,
): ListItemMenuOptions => {
	const {
		ITEMS_SECTION,
		MOVE_ITEM,
		CREATE_ITEM,
		CREATE_CHILD_ITEM,
		CREATE_ITEM_ABOVE,
		CREATE_ITEM_BELOW,
		MOVE_ITEM_UP,
		MOVE_ITEM_DOWN,
		MOVE_ITEM_TO_TOP,
		MOVE_ITEM_TO_BOTTOM,
		SELECTED_ITEM_COUNTER,
	} = listItemMenu;
	const overrideLabels = {
		[ITEMS_SECTION]: messages[ITEMS_SECTION],
		[CREATE_ITEM]: messages[CREATE_ITEM],
		[MOVE_ITEM]: messages[MOVE_ITEM],
		[CREATE_CHILD_ITEM]: messages[CREATE_CHILD_ITEM],
		[CREATE_ITEM_ABOVE]: messages[CREATE_ITEM_ABOVE],
		[CREATE_ITEM_BELOW]: messages[CREATE_ITEM_BELOW],
		[MOVE_ITEM_UP]: messages[MOVE_ITEM_UP],
		[MOVE_ITEM_DOWN]: messages[MOVE_ITEM_DOWN],
		[MOVE_ITEM_TO_TOP]: messages[MOVE_ITEM_TO_TOP],
		[MOVE_ITEM_TO_BOTTOM]: messages[MOVE_ITEM_TO_BOTTOM],
		[SELECTED_ITEM_COUNTER]: messages[SELECTED_ITEM_COUNTER],
	};

	if (fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y')) {
		if (canUserEdit) {
			const schedulingOptions = [
				...(isSprintsFeatureEnabled &&
				isChildIssuePlanningEnabled &&
				fg('jsw_roadmaps_timeline_sprint_scheduling_a11y')
					? [{ id: EDIT_SPRINT }]
					: []),
				...(fg('jsw_roadmaps_timeline-meatballs-date-update-modal')
					? [{ id: EDIT_DATES }, { id: REMOVE_DATES }]
					: []),
				...(fg('jsw_roadmaps_timeline-meatball-dependency-modal') ? [{ id: EDIT_DEPENDENCY }] : []),
			];

			return {
				overrideLabels,
				customSections: [
					{
						id: ITEMS_SECTION,
						title: messages[ITEMS_SECTION],
						options: [
							...(fg('jsw_roadmaps_timeline-meatball-reparent-menu')
								? [{ id: CHANGE_PARENT_ISSUE }]
								: []),
							...(fg('jsw_roadmaps_timeline-meatballs-change-issue-color')
								? [{ id: CHANGE_ISSUE_COLOR }]
								: []),
						],
					},
					{
						id: SCHEDULING_SECTION,
						title: messages[SCHEDULING_SECTION],
						options: schedulingOptions,
					},
				],
				customSectionsBulk: [
					{
						id: BULK_SECTION,
						options: [
							...(fg('jsw_roadmap_timeline_meatball_selected_item_option')
								? [{ id: BULK_EDIT_DATES }]
								: []),
						],
					},
				],
			};
		}
	}

	return {
		overrideLabels,
		customSections: [],
		customSectionsBulk: [],
	};
};
