import React, {
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	memo,
	type ComponentType,
	useState,
	useCallback,
} from 'react';
import Button from '@atlaskit/button/standard-button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ExportIcon from '@atlaskit/icon/glyph/export';
import ShareIcon from '@atlaskit/icon/glyph/share';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ModalTransition } from '@atlaskit/modal-dialog';
import type { TriggerProps } from '@atlaskit/popup';
import { Inline } from '@atlaskit/primitives';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ResponsiveButton } from '@atlassian/jira-responsive-button';
import { useRouter } from '@atlassian/jira-router';
import ShareButton, { type CustomTriggerButtonProps } from '@atlassian/jira-share-button';
import { integrationTypes } from '@atlassian/jira-share-button/src/constants';
import { ExportButton } from '@atlassian/jira-software-roadmap-timeline-table-kit';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import type { ExportCsvActionPayload } from '../../../state/ui/share/actions';
import messages from './messages';
import type ShareModal from './share-modal-with-analytics/share-modal/async';
import { getShareUrl } from './utils';

type Props = {
	ShareModalWithAnalytics: ComponentType<ComponentProps<typeof ShareModal>>;
	roadmapAri: string;
	onExportCsv: (payload: ExportCsvActionPayload) => void;
};

const ShareCustomTriggerButton = (
	{ isDisabled, isSelected, onClick }: CustomTriggerButtonProps,
	triggerProps: TriggerProps,
) => {
	const { formatMessage } = useIntl();

	return (
		<ResponsiveButton
			label={formatMessage(messages.share)}
			// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
			icon={ShareIcon}
			onClick={onClick}
			isDisabled={isDisabled}
			isSelected={isSelected}
			{...triggerProps}
		/>
	);
};

const Share = ({ ShareModalWithAnalytics, roadmapAri, onExportCsv }: Props) => {
	const { formatMessage } = useIntl();
	const [routerState] = useRouter();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onModalOpen = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({});
		fireUIAnalytics(analyticsEvent, 'exportImage clicked');
		setIsModalOpen(true);
	}, [createAnalyticsEvent]);

	const onModalClose = useCallback(() => {
		setIsModalOpen(false);
	}, []);

	const onExportCsvCallback = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({});
		fireUIAnalytics(analyticsEvent, 'exportCsv clicked');
		onExportCsv({
			inferredStartDateHeader: formatMessage(messages.exportSpreadsheetInferredStartDate),
			inferredDueDateHeader: formatMessage(messages.exportSpreadsheetInferredDueDate),
		});
	}, [createAnalyticsEvent, onExportCsv, formatMessage]);

	const exportButton =
		getWillShowNav4() && ff('timeline-export-as-spreadsheet_1mdsg') ? (
			<ExportButton onExportCsv={onExportCsv} onExportAsImageClick={onModalOpen} />
		) : (
			<DropdownMenu
				// eslint-disable-next-line jira/react-no-inline-function-prop
				trigger={({ triggerRef, ...props }) =>
					getWillShowNav4() ? (
						<ResponsiveButton
							label={formatMessage(messages.exportButton)}
							// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
							icon={ExportIcon}
							ref={triggerRef}
							{...props}
						/>
					) : (
						<Button
							{...props}
							appearance="subtle"
							iconBefore={<ExportIcon label={formatMessage(messages.exportButtonIcon)} />}
							ref={triggerRef}
						>
							{formatMessage(messages.exportButton)}
						</Button>
					)
				}
			>
				<DropdownItemGroup>
					<DropdownItem onClick={onModalOpen}>
						{formatMessage(messages.exportImagePng)}
					</DropdownItem>
					<DropdownItem onClick={onExportCsvCallback}>
						{formatMessage(messages.exportSpreadsheetCsv)}
					</DropdownItem>
				</DropdownItemGroup>
			</DropdownMenu>
		);

	const shareButton =
		!fg('common_project_actions_menu') &&
		!isVisualRefreshEnabled() &&
		(getWillShowNav4() ? (
			<ShareButton
				integrationType={integrationTypes.ROADMAP}
				productId="jira"
				subProductId="jira-software"
				shareLink={getShareUrl(routerState.location.pathname, routerState.location.search)}
				shareTitle={formatMessage(messages.timeline)}
				shareContentType="roadmap"
				objectAri={roadmapAri}
				renderCustomTriggerButton={ShareCustomTriggerButton}
			/>
		) : (
			<ShareButton
				integrationType={integrationTypes.ROADMAP}
				triggerButtonAppearance="subtle"
				triggerButtonStyle="icon-with-text"
				productId="jira"
				subProductId="jira-software"
				shareLink={getShareUrl(routerState.location.pathname, routerState.location.search)}
				shareTitle={formatMessage(messages.timeline)}
				shareContentType="roadmap"
				objectAri={roadmapAri}
			/>
		));

	return (
		<>
			<Inline space="space.100">
				{shareButton}
				{exportButton}
			</Inline>
			<ModalTransition>
				{isModalOpen && <ShareModalWithAnalytics onClose={onModalClose} />}
			</ModalTransition>
		</>
	);
};

export default memo<Props>(Share);
