import type { QuickFilterId, QuickFiltersHash } from '@atlassian/jira-software-roadmap-model';

export const getFilteredQuickFilterQueriesPure = (
	filteredQuickFilters: QuickFilterId[],
	quickFilterHash: QuickFiltersHash,
): ReadonlyArray<string> =>
	filteredQuickFilters.reduce((queries: string[], quickFilterId: QuickFilterId): string[] => {
		const quickFilter = quickFilterHash[quickFilterId];
		if (quickFilter !== undefined) {
			queries.push(quickFilter.query);
		}
		return queries;
	}, []);
