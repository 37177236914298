import memoizeOne from 'memoize-one';
import { defaultRenderers, type Renderers } from '../../renderers';

/* If the consumer does not provide a custom renderer, the table provides a default.
 * This allows us to streamline internal logic and types, since no renderer is ever absent.
 */
export const getRenderersWithDefaults = memoizeOne((customRenderers: Renderers) => {
	const safeRenderers = { ...defaultRenderers, ...customRenderers };

	return {
		listRenderers: {
			renderListEmptyContent: safeRenderers.renderListEmptyContent,
			renderListItemContent: safeRenderers.renderListItemContent,
			renderListItemSecondaryContent: safeRenderers.renderListItemSecondaryContent,
			renderListItemCreate: safeRenderers.renderListItemCreate,
			renderListHeaderItem: safeRenderers.renderListHeaderItem,
			/**
			 * TODO: This is a temporary change until the create button is deprecated.
			 * If the consumer doesn’t provide renderListItemMenu, it will render the create child button instead of the meatballs button.
			 * Revert this to the safeRenderers after the create button is deprecated.
			 */
			renderListItemMenuOption: customRenderers?.renderListItemMenuOption,
			renderListItemModal: customRenderers?.renderListItemModal,
		},
		chartRenderers: {
			renderChartOverlay: safeRenderers.renderChartOverlay,
			renderChartItemContent: safeRenderers.renderChartItemContent,
			renderChartTimelineOverlay: safeRenderers.renderChartTimelineOverlay,
			renderChartHeaderItem: safeRenderers.renderChartHeaderItem,
		},
		renderProvider: safeRenderers.renderProvider,
	};
});
