import {
	type StoreActionApi,
	createStore,
	createHook,
	createContainer,
} from '@atlassian/react-sweet-state';
import { DEFAULT_ITEM_HEIGHT } from '../../constants';
import { virtualiserActions, DEFAULT_VALUE } from './actions';
import type { State, ContainerProps, Actions } from './common/types';
import { getVirtualisedItems } from './selectors';

const store = createStore<State, Actions>({
	initialState: DEFAULT_VALUE,
	actions: virtualiserActions,
	name: 'timeline-table.virtualise',
});

export const useVirtualisedActions = createHook(store, {
	selector: null,
});

export const useVirtualisedItems = createHook(store, {
	selector: getVirtualisedItems,
});

// @Export for testing
export const onContainerInit =
	() =>
	({ dispatch }: StoreActionApi<State>, { itemHeights, isEnabled }: ContainerProps) => {
		if (isEnabled) {
			// Set a reasonable default value for items components before useVirtual hook produces meta data
			const fallbackItemsCount = Math.round(window.innerHeight / DEFAULT_ITEM_HEIGHT);
			const fallbackEnd = Math.min(itemHeights.length - 1, fallbackItemsCount);
			dispatch(virtualiserActions.generateVirtualMeta(0, fallbackEnd));
		} else {
			dispatch(virtualiserActions.generateVirtualMeta(0, itemHeights.length - 1));
		}
	};

// @Export for testing
export const onContainerUpdate =
	() =>
	({ dispatch }: StoreActionApi<State>, { itemHeights, isEnabled }: ContainerProps) => {
		/**
		 * Update the virtualised meta data When the items are updated when virtualisation is disabled.
		 * This step is not required when virtualisation is enabled because useVirtual handles that.
		 */
		if (!isEnabled) {
			dispatch(virtualiserActions.generateVirtualMeta(0, itemHeights.length - 1));
		}
	};

export const VirtualiseProviderSweetState = createContainer<State, Actions, ContainerProps>(store, {
	onInit: onContainerInit,
	onUpdate: onContainerUpdate,
});
