// eslint-disable-next-line jira/restricted/react-component-props
import React, { memo, useCallback, type ComponentProps } from 'react';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl';
import { RoadmapChartItemContent } from '@atlassian/jira-software-roadmap-timeline-table-kit';
import type { OnChartItemUpdate } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/types';
import { END_DATE_LATER_RELEASE_DATE_MESSAGE_ID } from '../../../../constants';
import warningMessages from '../../../../state/selectors/table/bar-warnings/messages';

type Props = JSX.LibraryManagedAttributes<
	typeof RoadmapChartItemContent,
	ComponentProps<typeof RoadmapChartItemContent>
>;

const ChartItemContent = (props: Props) => {
	const { onUpdate, warnings } = props;

	const hasOverdueReleaseDate = warnings
		? warnings.some(
				(warning: MessageDescriptor) =>
					warning.id === warningMessages[`${END_DATE_LATER_RELEASE_DATE_MESSAGE_ID}`].id,
			)
		: undefined;
	const onUpdateChartItem = useCallback<OnChartItemUpdate>(
		(id, { dates, meta, intervalId }, event) => {
			onUpdate(id, { dates, meta, intervalId }, event, hasOverdueReleaseDate);
		},
		[onUpdate, hasOverdueReleaseDate],
	);
	return <RoadmapChartItemContent {...props} onUpdate={onUpdateChartItem} />;
};

ChartItemContent.whyDidYouRender = true;
export default memo<Props>(ChartItemContent);
