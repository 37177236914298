import isNil from 'lodash/isNil';
import { connect } from '@atlassian/jira-react-redux';
import type { IssueId } from '@atlassian/jira-shared-types';
import { getIssueKeyById } from '../../state/entities/issues/selectors';
import { getIssuesFilter } from '../../state/selectors/filters';
import type { State } from '../../state/types';
import { clearFilter } from '../../state/ui/filters/actions';
import { setSelectedIssue } from '../../state/ui/panel/actions';
import IssueHiddenFlag, { type Props } from './view';

type OwnProps = {
	id: string | number;
	ids: IssueId[];
	onDismissed?: (arg1: string | number) => void;
};

type StateProps = {
	keys: string[];
	isFilterApplied: boolean;
};

type DispatchProps = {
	onClearFilter: () => void;
	onViewIssue: (key: string) => void;
};

const mapStateToProps = (state: State, { ids }: OwnProps): StateProps => ({
	keys: ids.reduce<string[]>((acc, id) => {
		const key = getIssueKeyById(state, id);
		!isNil(key) && acc.push(key);
		return acc;
	}, []),
	isFilterApplied: Object.values(getIssuesFilter(state)).some((filter) => filter.length),
});

const mapDispatchToProps = {
	onViewIssue: setSelectedIssue,
	onClearFilter: clearFilter,
} as const;

export const mergeProps = (
	stateProps: StateProps,
	dispatchProps: DispatchProps,
	{ id, onDismissed }: OwnProps,
): Props => ({
	id,
	onDismissed,
	...stateProps,
	...dispatchProps,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(IssueHiddenFlag);
