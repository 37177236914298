/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import Heading from '@atlaskit/heading';

type Props = {
	title: string;
};

const RoadmapListHeaderRow = ({ title }: Props) => (
	<Heading size="xsmall" as="div">
		<p css={rowTitleStyles}>{title}</p>
	</Heading>
);

export { RoadmapListHeaderRow };

const rowTitleStyles = css({
	fontWeight: 500,
	margin: 0,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
