import React from 'react';
import SprintIcon from '@atlaskit/icon/glyph/sprint';
import { N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import LegendComponent, {
	LegendItem,
	LegendSection,
	DependenciesIcon,
	RollupIcon,
	IssueBarIcon,
	ReleaseStatusIcon,
} from '@atlassian/jira-aais-timeline-legend';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

const Legend = () => {
	const { formatMessage } = useIntl();

	const sprintsMessage = formatMessage(messages.sprints);
	const dependencyMessage = formatMessage(messages.dependency);
	const rollupMessage = formatMessage(messages.rollup);

	const noEndDateMessage = formatMessage(messages.noEndDate);
	const noStartDateMessage = formatMessage(messages.noStartDate);

	const releasedMessage = formatMessage(messages.released);
	const unreleasedMessage = formatMessage(messages.unreleased);
	const overdueMessage = formatMessage(messages.overdue);

	return (
		<LegendComponent>
			<LegendSection title={formatMessage(messages.general)}>
				<LegendItem
					symbol={
						<SprintIcon
							size="medium"
							label={sprintsMessage}
							primaryColor={token('color.text.subtle', N300)}
						/>
					}
					label={sprintsMessage}
				/>
				<LegendItem
					symbol={<DependenciesIcon label={dependencyMessage} />}
					label={dependencyMessage}
				/>
				<LegendItem symbol={<RollupIcon label={rollupMessage} />} label={rollupMessage} />
			</LegendSection>

			<LegendSection title={formatMessage(messages.issueBars)}>
				<LegendItem symbol={<IssueBarIcon type="noEndDate" />} label={noEndDateMessage} />
				<LegendItem symbol={<IssueBarIcon type="noStartDate" />} label={noStartDateMessage} />
			</LegendSection>

			<LegendSection title={formatMessage(messages.releases)}>
				<LegendItem symbol={<ReleaseStatusIcon type="released" />} label={releasedMessage} />
				<LegendItem symbol={<ReleaseStatusIcon type="unreleased" />} label={unreleasedMessage} />
				<LegendItem symbol={<ReleaseStatusIcon type="overdue" />} label={overdueMessage} />
			</LegendSection>
		</LegendComponent>
	);
};

export default Legend;
