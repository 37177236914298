import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	removeDates: {
		id: 'roadmap.standard-roadmap.common.table.list-item-menu-option.remove-dates-option.remove-dates',
		defaultMessage: 'Remove dates',
		description: 'Label for remove dates option',
	},
	removeStartDate: {
		id: 'roadmap.standard-roadmap.common.table.list-item-menu-option.remove-dates-option.remove-start-date',
		defaultMessage: 'Remove start date',
		description: 'Label for remove start date option',
	},
	removeDueDate: {
		id: 'roadmap.standard-roadmap.common.table.list-item-menu-option.remove-dates-option.remove-due-date',
		defaultMessage: 'Remove due date',
		description: 'Label for remove due date option',
	},
	removeBothDates: {
		id: 'roadmap.standard-roadmap.common.table.list-item-menu-option.remove-dates-option.remove-both-dates',
		defaultMessage: 'Remove both dates',
		description: 'Label for remove both dates option',
	},
	rolledUpDates: {
		id: 'roadmap.standard-roadmap.common.table.list-item-menu-option.remove-dates-option.rolled-up-dates',
		defaultMessage: 'This issue uses dates rolled up from child issues.',
		description: 'Tooltip for rolled up dates',
	},
	requiredDates: {
		id: 'roadmap.standard-roadmap.common.table.list-item-menu-option.remove-dates-option.required-dates',
		defaultMessage: 'Dates are required for this issue',
		description: 'Tooltip for required dates',
	},
});

export default messages;
