import React, { type PropsWithChildren, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import { type TriggerProps, Popup } from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import LegendButton from './legend-button';
import ShrinkToFit from './shrink-to-fit';

type Props = {
	/**
	 * Whether the legend trigger should show the label
	 */
	showLabel?: boolean;
	/**
	 * Whether the legend should be open by default
	 */
	defaultIsOpen?: boolean;
};

const Legend = ({
	showLabel = false,
	children,
	defaultIsOpen = false,
}: PropsWithChildren<Props>) => {
	const [isOpen, setIsOpen] = useState<boolean>(defaultIsOpen);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const toggleLegend = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});

		fireTrackAnalytics(analyticsEvent, `legend ${isOpen ? 'closed' : 'opened'}`);

		setIsOpen(!isOpen);
	}, [createAnalyticsEvent, isOpen, setIsOpen]);

	const onClose = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});

		fireTrackAnalytics(analyticsEvent, 'legend closed');

		setIsOpen(false);
	}, [createAnalyticsEvent, setIsOpen]);

	const renderTrigger = useCallback(
		(triggerProps: TriggerProps) => (
			<div {...triggerProps}>
				<LegendButton isSelected={isOpen} onClick={toggleLegend} showLabel={showLabel} />
			</div>
		),
		[isOpen, showLabel, toggleLegend],
	);

	const renderContent = useCallback(
		() => (
			<ShrinkToFit>
				<LegendRoot>{children}</LegendRoot>
			</ShrinkToFit>
		),
		[children],
	);

	return (
		<Popup
			placement="top-end"
			isOpen={isOpen}
			onClose={onClose}
			trigger={renderTrigger}
			content={renderContent}
			shouldUseCaptureOnOutsideClick
		/>
	);
};

type PropsLegacy = {
	showLabel?: boolean;
	defaultIsOpen?: boolean;
};

const LegendLegacy = ({ showLabel = false, children }: PropsWithChildren<PropsLegacy>) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const toggleLegend = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});

		fireTrackAnalytics(analyticsEvent, `legend ${isOpen ? 'closed' : 'opened'}`);

		setIsOpen(!isOpen);
	}, [createAnalyticsEvent, isOpen, setIsOpen]);

	const onClose = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});

		fireTrackAnalytics(analyticsEvent, 'legend closed');

		setIsOpen(false);
	}, [createAnalyticsEvent, setIsOpen]);

	const renderTrigger = useCallback(
		(triggerProps: TriggerProps) => (
			<LegendButton
				{...triggerProps}
				isSelected={isOpen}
				onClick={toggleLegend}
				showLabel={showLabel}
			/>
		),
		[isOpen, showLabel, toggleLegend],
	);

	const renderContent = useCallback(
		() => (
			<ShrinkToFit>
				<LegendRoot>{children}</LegendRoot>
			</ShrinkToFit>
		),
		[children],
	);

	return (
		<Popup
			placement="top-end"
			isOpen={isOpen}
			onClose={onClose}
			trigger={renderTrigger}
			content={renderContent}
			shouldUseCaptureOnOutsideClick
		/>
	);
};

export { default as LegendButton } from './legend-button';

export default componentWithFF('timeline-legend-token-update_7pgll', Legend, LegendLegacy);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LegendRoot = styled.div({
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
	overflow: 'hidden',
	padding: `${token('space.250', '20px')} ${token('space.200', '16px')}`,
	gap: token('space.250', '20px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: gridSize * 35,
});
