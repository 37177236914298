import { connect } from '@atlassian/jira-react-redux';
import { isDependenciesVisible } from '../../state/selectors/filters';
import type { State } from '../../state/types';
import { setDependenciesViewVisibility } from '../../state/ui/views/actions';
import Flag from './view';

export default connect(
	(state: State) => ({
		isDependenciesVisible: isDependenciesVisible(state),
	}),
	{
		setShowDependencies: () => setDependenciesViewVisibility(true),
	},
)(Flag);
