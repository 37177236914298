import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/zip';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import type { RouterDataContext } from '@atlassian/jira-router';
import { goalsFetcher } from './goals';
import { filterItemsByJQLFetcher } from './jql-filters';
import type { EagerLoadedData, EagerLoadingDataFetcher } from './types';

const eagerDataFetchers: EagerLoadingDataFetcher[] = [goalsFetcher, filterItemsByJQLFetcher];

const mergeAdditionalData = (dataFragments: EagerLoadedData[]): EagerLoadedData => {
	const result: EagerLoadedData = {};
	dataFragments.forEach((object) => {
		Object.assign(result, object);
	});
	return result;
};

export const fetchEagerLoadedData = (
	sourceAri: string,
	context: RouterDataContext,
): Observable<EagerLoadedData> => {
	const matchingFetchers = eagerDataFetchers.filter((fetcher) => fetcher.match(context));

	if (matchingFetchers.length === 0) {
		return Observable.of({});
	}

	return Observable.zip(...matchingFetchers.map((fetcher) => fetcher.fetch(sourceAri, context))) // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		.map((dataFragments) => mergeAdditionalData(dataFragments as EagerLoadedData[]));
};
