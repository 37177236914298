import React, { memo, useEffect } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { ChangeColorMenuAsync, ChangeColorMenuLazy } from './change-color-menu/async';
import messages from './messages';
import type { Props } from './types';

const ChangeColorOption = ({ id, isColorEditable, color, Option, setCloseMenu }: Props) => {
	const { formatMessage } = useIntl();

	useEffect(() => {
		ChangeColorMenuLazy.preload();
	}, []);

	return (
		<Option isDisabled={!isColorEditable} label={formatMessage(messages.changeIssueColor)}>
			{({ setInitialFocusRef }) => (
				<ChangeColorMenuAsync
					id={id}
					color={color}
					setInitialFocusRef={setInitialFocusRef}
					setCloseMenu={setCloseMenu}
				/>
			)}
		</Option>
	);
};

export default memo<Props>(ChangeColorOption);
