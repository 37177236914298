import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'roadmap.standard-roadmap.flags.agg-error.title',
		defaultMessage: 'Something went wrong',
		description: 'Flag title. Informs a user of a general error.',
	},
	description: {
		id: 'roadmap.standard-roadmap.flags.agg-error.description',
		defaultMessage: 'Please try again later.',
		description: 'Flag description. Informs a user to try again later.',
	},
	jqlValidationErrorDescription: {
		id: 'roadmap.standard-roadmap.flags.agg-error.jql-validation-error-description',
		defaultMessage:
			'One or more fields associated with your JQL query is no longer valid. Edit your query, or adjust your settings then try again.',
		description: 'Flag description. Informs a user of the issue create error.',
	},
});
