import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/map';
import type { ActionsObservable } from 'redux-observable';
import type { State } from '../../state/types';
import {
	SET_WARNINGS_VISIBILITY,
	type SetWarningsVisibilityAction as Action,
} from '../../state/ui/views/actions';
import type { StateEpic } from '../common/types';
import updateQuery from '../common/update-query';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
	{ router: { push } },
) =>
	action$.ofType(SET_WARNINGS_VISIBILITY).map((action: Action) => {
		const hideWarnings = action.payload ? undefined : true;

		return updateQuery(push, store.getState(), (query) => ({
			...query,
			hideWarnings,
		}));
	})) as StateEpic;
