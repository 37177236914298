import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tooltip: {
		id: 'roadmap.timeline-table-kit.common.progress-bar.tooltip',
		defaultMessage: '{name}: {count} of {total}',
		description:
			'Tooltip text. Appears when hovering the progress bar. {name} is the human readable name of the entity that contributes to the progress. {count} is proportion of the {total} progress.',
	},
});
