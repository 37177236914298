import type { MiddlewareAPI, Action as ActionType } from 'redux';
import { batchActions } from 'redux-batched-actions';
import difference from 'lodash/difference';
import forEach from 'lodash/forEach';
import omit from 'lodash/omit';
import size from 'lodash/size';
import type { Color } from '@atlassian/jira-issue-epic-color';
import type { IssueId } from '@atlassian/jira-shared-types';
import {
	type AnalyticsUpdatedItems,
	type IssueTypeHash,
	type Modifiable,
	START_DATE,
	DUE_DATE,
	SPRINT_IDS,
	type Issue,
} from '@atlassian/jira-software-roadmap-model';
import type { IssuesAndUsers } from '@atlassian/jira-software-roadmap-services';
import type { IssueFieldModification } from '../../../model/issue';
import { getFullIssueTypeHash } from '../../../state/configuration/selectors';
import { extendIssues, removeIssues } from '../../../state/entities/issues/actions';
import { addUsers } from '../../../state/entities/users/actions';
import type { State } from '../../../state/types';

const isInvalid = (issue: Issue, issueTypes: IssueTypeHash): boolean =>
	issueTypes[issue.issueTypeId] === undefined;

export const updateIssuesAndUsers = (
	store: MiddlewareAPI<State>,
	issues: IssuesAndUsers,
	invalidIssueIds: Array<IssueId> = [],
) => {
	const issueTypeHash = getFullIssueTypeHash(store.getState());
	forEach(issues.issues.hash, (issue: Issue, issueId: IssueId) => {
		if (isInvalid(issue, issueTypeHash)) {
			invalidIssueIds.push(issueId);
		}
	});
	const validIssues = {
		sequence: difference(issues.issues.sequence, invalidIssueIds),
		hash: omit(issues.issues.hash, invalidIssueIds),
	};

	const actions: ActionType[] = [addUsers(issues.users)];
	if (size(validIssues.hash) > 0) {
		actions.push(extendIssues(validIssues));
	}
	if (invalidIssueIds.length > 0) {
		actions.push(removeIssues(invalidIssueIds));
	}

	return batchActions(actions);
};

export const updateProperty = <T,>(
	oldProp: Modifiable<T>,
	newProp: T,
	transitionId: string,
): Modifiable<T> => {
	if ('old' in oldProp) {
		return {
			value: newProp,
			old: oldProp.old,
			transitionId,
		};
	}

	return {
		value: newProp,
		old: oldProp.value,
		transitionId,
	};
};

export const createTransitionIssueWithUpdatedItems = (
	issue: Issue,
	updateProps: IssueFieldModification,
	transitionId: string,
): [Issue, AnalyticsUpdatedItems] => {
	const transitionIssue: Issue = { ...issue };
	const updatedItems: AnalyticsUpdatedItems = [];

	// NOTE: `in` and `hasOwnProperty` syntax check won't remove the optional marker from IssueFieldModification type
	if (updateProps.summary !== undefined) {
		updatedItems.push({
			name: 'summary',
		});
		transitionIssue.summary = updateProperty(issue.summary, updateProps.summary, transitionId);
	}

	if ('startDate' in updateProps) {
		updatedItems.push({
			name: 'startDate',
		});
		transitionIssue.startDate = updateProperty(
			issue.startDate,
			updateProps.startDate,
			transitionId,
		);
	}

	if ('dueDate' in updateProps) {
		updatedItems.push({
			name: 'dueDate',
		});
		transitionIssue.dueDate = updateProperty(issue.dueDate, updateProps.dueDate, transitionId);
	}

	// NOTE: `in` and `hasOwnProperty` syntax check won't remove the optional marker from IssueFieldModification type
	if (updateProps.sprintId !== undefined) {
		updatedItems.push({
			name: 'sprintId',
		});
		transitionIssue.sprintIds = updateProperty(
			issue.sprintIds,
			[updateProps.sprintId],
			transitionId,
		);
	}

	if (
		'clearFields' in updateProps &&
		updateProps.clearFields &&
		updateProps.clearFields.length > 0
	) {
		if (updateProps.clearFields.indexOf(START_DATE) > -1) {
			updatedItems.push({
				name: 'startDate',
			});
			transitionIssue.startDate = updateProperty(issue.startDate, undefined, transitionId);
		}

		if (updateProps.clearFields.indexOf(DUE_DATE) > -1) {
			updatedItems.push({
				name: 'dueDate',
			});
			transitionIssue.dueDate = updateProperty(issue.dueDate, undefined, transitionId);
		}

		if (updateProps.clearFields.indexOf(SPRINT_IDS) > -1) {
			updatedItems.push({
				name: 'sprintId',
			});
			transitionIssue.sprintIds = updateProperty(issue.sprintIds, [], transitionId);
		}
	}

	if ('color' in updateProps) {
		updatedItems.push({
			name: 'color',
		});
		transitionIssue.color = updateProperty(
			issue.color,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			updateProps.color as Color, // NOTE: enum types genrated from AGG has "%future added value" fallback
			transitionId,
		);
	}

	if ('parentId' in updateProps) {
		updatedItems.push({
			name: 'parentId',
		});
		transitionIssue.parentId = updateProperty(issue.parentId, updateProps.parentId, transitionId);
	}

	return [transitionIssue, updatedItems];
};
