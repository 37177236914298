// DEPRECATED: Add feature flags inline instead of in this file

import { getFeatureFlagValue, ff } from '@atlassian/jira-feature-flagging';

/* Add new FFs under your own "section" to minimise merge conflicts. */
/* Use the prefix "jsw.roadmap*" in your new FF key for it to be picked up in our techops dashboards. */

/* IF your new FF is a boolean type, then define your FF key as a const here. */
// ahuang

// cautexier

// dannesley

// hsu

// jtran

// nsudhir

// wloo

// mkilbane

const UNSUBSCRIBABLE_QUERY = 'jsw.roadmap.unsubscribable-query' as const;

const UNSUBSCRIBABLE_MUTATION = 'jsw.roadmap.unsubscribable-mutation' as const;

const STATE_CHANGE_BASED_ISSUE_HIDDEN_FLAGS =
	'jsw.roadmap.state-change-based-issue-hidden-flags' as const;

// rmulvey

// slozier

// jyeung

// myadamreddy

// magma

// ddhanda

/* Add the FF key to this list to be enabled in the integration tests. */
export const FFS_TO_TEST: string[] = [
	// ahuang

	// cautexier

	// dannesley

	// hsu

	// jtran

	// kzhang

	// nsudhir

	// wloo

	// mkilbane

	UNSUBSCRIBABLE_QUERY,

	UNSUBSCRIBABLE_MUTATION,

	STATE_CHANGE_BASED_ISSUE_HIDDEN_FLAGS,

	// slozier

	// jyeung

	// myadamreddy

	// magma

	// ddhanda
];

// ahuang

// cautexier

// dannesley

// jtran

// nsudhir

// hsu

// wloo

export const isCustomerTemplatesMuseumEnabled = () => ff('jsw.onboarding.rollout.kuro-2250');

// mkilbane
export const isStateChangeBasedIssueHiddenFlagsEnabled = () =>
	ff('jsw.roadmap.state-change-based-issue-hidden-flags');

// kye

// rmulvey

// slozier

// myadamreddy

// magma

export const getIssueLimit = () =>
	getFeatureFlagValue<number>('jsw.roadmap.issue-overall-limit', 3000);

export const getEpicLimit = () => getFeatureFlagValue<number>('jsw.roadmap.issue-epic-limit', 300);

export const isEcClientEventListenerEnabled = () => ff('odin.ec.client.integration');
