import React, { useEffect, useCallback, useState, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ChevronLeftCircleIcon from '@atlaskit/icon/glyph/chevron-left-circle';
import ChevronRightCircleIcon from '@atlaskit/icon/glyph/chevron-right-circle';
import { N40, N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';
import type { Props, ToolbarItem } from './types';
import WidthExpander from './width-expander';

const renderItem = (key: string, component: ReactNode, showDivider: boolean) => (
	<React.Fragment key={key}>
		{showDivider && <Divider />}
		{component}
	</React.Fragment>
);

const Toolbar = ({ items, isCollapsible = true }: Props) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(true);

	const toggleIsExpanded = useCallback(() => {
		if (isCollapsible) setIsExpanded(!isExpanded);
	}, [isExpanded, isCollapsible, setIsExpanded]);

	useEffect(() => {
		if (!isExpanded && !isCollapsible) setIsExpanded(true);
	}, [isExpanded, isCollapsible, setIsExpanded]);

	const renderContent = () =>
		items
			.filter(({ isVisible = true }) => isVisible)
			.map(({ id, element }, index) => renderItem(id, element, isCollapsible || index !== 0));

	const { formatMessage } = useIntl();
	return (
		<TimelineToolbarWrapper>
			<WidthExpander isExpanded={isExpanded} collapsedWidth="32px">
				<TimelineToolbarContainer>
					{isCollapsible && (
						<Button
							testId="aais-timeline-toolbar.ui.container.expand-button"
							appearance="subtle"
							onClick={toggleIsExpanded}
							iconBefore={
								isExpanded ? (
									<ChevronRightCircleIcon label={formatMessage(messages.expandButtonLabel)} />
								) : (
									<ChevronLeftCircleIcon label={formatMessage(messages.collapseButtonLabel)} />
								)
							}
							aria-expanded={isExpanded}
						/>
					)}
					{renderContent()}
				</TimelineToolbarContainer>
			</WidthExpander>
		</TimelineToolbarWrapper>
	);
};

export default Toolbar;
export type { ToolbarItem };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Divider = styled.div({
	backgroundColor: `${token('color.border', N40)}`,
	height: '24px',
	width: '1px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TimelineToolbarWrapper = styled.div({
	backgroundColor: `${token('elevation.surface.raised', N0)}`,
	padding: token('space.050', '4px'),
	display: 'flex',
	border: `1px solid ${token('color.border', N40)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
	boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TimelineToolbarContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.050', '4px'),
	flexShrink: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'>:is(div, button)': {
		flexShrink: 0,
	},
});
