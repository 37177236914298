import type { MiddlewareAPI } from 'redux';
import type { ActionsObservable } from 'redux-observable';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/defer';
import 'rxjs/add/operator/first';
import type { ExtendIssuesAction } from '../../../state/entities/issues/actions';
import {
	type IssueDisappearsFromDisplayRangeAction,
	type IssueHiddenAction,
	issueDisappearsFromDisplayRange,
	issueHidden,
} from '../../../state/flags/actions';
import { getFilteredIssueIds } from '../../../state/selectors/issues';
import type { State } from '../../../state/types';
import { awaitReapplyJQLFilters } from '../../common/filter';

const getHiddenUpdatedIssueIds = (before: string[], after: string[], updated: string[]) => {
	const hidden = difference(before, after);
	return intersection(hidden, updated);
};

export const handleHiddenIssueUpdated = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	action$: ActionsObservable<any>,
	store: MiddlewareAPI<State>,
	before: string[],
	after: string[],
	actions: ExtendIssuesAction[],
	isJQLFilterApplied: boolean,
): Observable<IssueDisappearsFromDisplayRangeAction | IssueHiddenAction> => {
	const updated = actions.flatMap((action) => Object.keys(action.payload.hash));
	const clientHiddenUpdated = getHiddenUpdatedIssueIds(before, after, updated);

	if (clientHiddenUpdated.length > 0)
		return Observable.of(
			issueDisappearsFromDisplayRange({
				issueId: clientHiddenUpdated[0],
			}),
		);

	const asyncCheck = (): Observable<IssueHiddenAction> => {
		const state = store.getState();
		const final = getFilteredIssueIds(state);

		const serverHiddenUpdated = getHiddenUpdatedIssueIds(before, final, updated);

		if (serverHiddenUpdated.length > 0)
			return Observable.of(
				issueHidden({
					ids: serverHiddenUpdated,
				}),
			);

		return Observable.empty();
	};

	if (isJQLFilterApplied)
		return Observable.concat(awaitReapplyJQLFilters(action$), Observable.defer(asyncCheck));

	return Observable.empty();
};
