import React, { useState, useRef, useLayoutEffect, type PropsWithChildren } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { mediumDurationMs, useIsReducedMotion, easeInOut } from '@atlaskit/motion';

type Props = {
	isExpanded: boolean;
	collapsedWidth: string;
};

type ExpanderState = 'expanded' | 'transitioning' | 'collapsed';

const WidthExpander = ({ isExpanded, collapsedWidth, children }: PropsWithChildren<Props>) => {
	const [width, setWidth] = useState<string>(isExpanded ? 'auto' : collapsedWidth);

	const prefersReducedMotion = useIsReducedMotion();
	const duration = prefersReducedMotion ? 0 : mediumDurationMs;

	const isFirstRender = useRef<boolean>(true);
	const state = useRef<ExpanderState>(isExpanded ? 'expanded' : 'collapsed');
	const ref = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		if (ref.current === null) return noop;

		if (isFirstRender.current) {
			isFirstRender.current = false;
			return noop;
		}

		if (prefersReducedMotion) {
			setWidth(isExpanded ? 'auto' : collapsedWidth);
			return noop;
		}

		const expandedWidth = `${ref.current.offsetWidth}px`;

		if (state.current === 'transitioning') setWidth(isExpanded ? expandedWidth : collapsedWidth);
		else setWidth(expandedWidth);

		const immediate = setTimeout(() => {
			if (!isExpanded) setWidth(collapsedWidth);

			state.current = 'transitioning';
		}, 1);

		const afterDuration = setTimeout(() => {
			if (isExpanded) setWidth('auto');

			state.current = isExpanded ? 'expanded' : 'collapsed';
		}, mediumDurationMs);

		return () => {
			clearTimeout(immediate);
			clearTimeout(afterDuration);
		};
	}, [isExpanded, collapsedWidth, duration, prefersReducedMotion]);

	return (
		<OuterWrapper
			width={width}
			duration={duration}
			data-testid="aais-timeline-toolbar.ui.container.width-expander"
		>
			<InnerWrapper ref={ref}>{children}</InnerWrapper>
		</OuterWrapper>
	);
};

export default WidthExpander;

type OuterWrapperProps = {
	width: string;
	duration: number;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OuterWrapper = styled.div<OuterWrapperProps>({
	display: 'flex',
	contain: 'layout',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width }) => width,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transition: ({ duration }) => `width ${duration}ms ${easeInOut}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InnerWrapper = styled.div({
	flexShrink: 0,
});
