import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	changeParentIssue: {
		id: 'roadmap.standard-roadmap.common.table.list-item-menu-option.reparent-menu-option.change-parent-issue',
		defaultMessage: 'Change parent issue',
		description:
			'Label for Change parent issue menu option which is displayed in the meatball menu attached to every issue on the project timeline. This menu option opens a secondary menu that allows the user to change the parent of the issue.',
	},
});

export default messages;
