import type { Color } from '@atlassian/jira-issue-epic-color';
import type { IssueId } from '@atlassian/jira-shared-types';
import { PARENT_LEVEL, type Hash } from '@atlassian/jira-software-roadmap-model';
import { getParentLevelDates } from '../../common';
import { getChartDateData } from '../../common/chart-dates';
import type { RolledUpDates } from '../../dates';
import type { ChartData, DateError } from '../common/types';

export const getParentLevelChartData = (
	issueColorHash: Hash<Color>,
	issueStartDateHash: Hash<number | undefined>,
	issueDueDateHash: Hash<number | undefined>,
	parentRolledUpDatesHash: Hash<RolledUpDates>,
	issueDateErrorsHash: Hash<DateError[]>,
	issueId: IssueId,
	isChildIssuePlanningEnabled: boolean,
): ChartData => {
	const explicitStartDate = issueStartDateHash[issueId];
	const explicitDueDate = issueDueDateHash[issueId];

	const {
		startDate: parentLevelStartDate,
		dueDate: parentLevelDueDate,
		startDateType,
		dueDateType,
		placeholder,
	} = getParentLevelDates(
		explicitStartDate,
		explicitDueDate,
		parentRolledUpDatesHash[issueId],
		isChildIssuePlanningEnabled,
	);
	const color = issueColorHash[issueId];

	const { startDate, dueDate } = getChartDateData(
		parentLevelStartDate,
		parentLevelDueDate,
		PARENT_LEVEL,
		isChildIssuePlanningEnabled,
	);

	return {
		startDateType,
		dueDateType,
		placeholder,
		color,
		startDate,
		dueDate,
		warnings: issueDateErrorsHash[issueId],
		level: PARENT_LEVEL,
	};
};
