import type { Observable as ObservableType } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import roadmapCriticalDataConcreteQuery, {
	type roadmapCriticalDataQuery,
} from '@atlassian/jira-relay/src/__generated__/roadmapCriticalDataQuery.graphql';
import { generateCriticalDataQueryVariables } from '@atlassian/jira-software-roadmap-data-queries/src/queries/roadmap-critical-data/index.tsx';
import {
	AggError,
	MISSING_DATA_ERROR,
} from '@atlassian/jira-software-roadmap-services/src/common/agg/agg-error.tsx';
import { runQueryWithMetadata } from '@atlassian/jira-software-roadmap-services/src/common/agg/run-query.tsx';
import { CRITICAL_DATA } from '@atlassian/jira-software-roadmap-utils/src/utils/metrics/common/constants.tsx';
import { queryMetrics } from '../performance-analytics';
import {
	transformCriticalData,
	transformCurrentUser,
	transformHealthcheck,
	transformRoadmapConfiguration,
} from './transformers';
import type { GraphQLItemNode, CriticalDataResponse } from './types';

const concurrentMetricDefinition = queryMetrics[CRITICAL_DATA];
const analyticKey = 'jira.frontend.fe.software.roadmap.critical-data';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (sourceAri: string, locationAri?: string): ObservableType<CriticalDataResponse> =>
	runQueryWithMetadata<roadmapCriticalDataQuery>(
		roadmapCriticalDataConcreteQuery,
		generateCriticalDataQueryVariables(sourceAri, locationAri),
		analyticKey,
		concurrentMetricDefinition,
	)
		.map(({ data, metadata: { traceIds, operationName, extensions } }) => {
			if (
				!data?.roadmaps?.roadmapForSource ||
				!data?.me?.user ||
				(data?.roadmaps?.roadmapForSource.healthcheck === null &&
					data?.roadmaps?.roadmapForSource.content === null)
			) {
				throw new AggError(
					`Failed to retrieve data via ${operationName}`,
					[],
					traceIds,
					MISSING_DATA_ERROR,
				);
			}

			const { content, healthcheck } = data.roadmaps.roadmapForSource;

			const items: ReadonlyArray<GraphQLItemNode | null | undefined> = content?.items?.edges || [];
			return {
				currentUser: transformCurrentUser(data?.me?.user),
				roadmapConfiguration: content?.configuration
					? transformRoadmapConfiguration(content.configuration)
					: undefined,
				items: items
					.filter(Boolean) // TODO: "filter" to be removed after null/undefined is removed from edges in AGG schema
					.map(({ node: roadmapItem }: GraphQLItemNode) => roadmapItem),
				metadata: { traceIds, extensions },
				healthcheck: transformHealthcheck(healthcheck),
			};
		})
		.map(transformCriticalData);
