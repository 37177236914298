import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'roadmap.standard-roadmap.flags.irrelevant-item-created.title',
		defaultMessage: 'Issue created',
		description: 'Flag title. Informs a user that a single issues has been created.',
	},
	titleMultipleIssues: {
		id: 'roadmap.standard-roadmap.flags.irrelevant-item-created.title-multiple-issues',
		defaultMessage: '{issueCount} issues created',
		description: 'Flag title. Informs a user that multiple issues have been created.',
	},
});
