// eslint-disable-next-line jira/restricted/react-component-props
import type { ComponentProps } from 'react';
import { connect } from '@atlassian/jira-react-redux';
import { getLevelOneName } from '../../../../state/configuration/selectors';
import { getLevelOneSetting, isFilterActive } from '../../../../state/selectors/filters';
import { getCanUserCreate } from '../../../../state/selectors/permission';
import type { State } from '../../../../state/types';
import { clearFilter } from '../../../../state/ui/filters/actions';
import { clearLevelOneSetting } from '../../../../state/ui/views/actions';
import { StandardRoadmapListEmptyContent } from './view';

const mapDispatchToProps = {
	onClearFilter: clearFilter,
	onClearLevelOneSetting: clearLevelOneSetting,
} as const;

type Props = JSX.LibraryManagedAttributes<
	typeof StandardRoadmapListEmptyContent,
	ComponentProps<typeof StandardRoadmapListEmptyContent>
>;

type DispatchProps = typeof mapDispatchToProps;

// @ts-expect-error - TS2344 - Type 'Props' does not satisfy the constraint '{ readonly onClearFilter: () => ClearFilterAction; readonly onClearLevelOneSetting: () => SetLevelOneSettingAction; }'.
type StateProps = Flow.Diff<Props, DispatchProps>;

export default connect((state: State): StateProps => {
	const { showCompleted, period } = getLevelOneSetting(state);
	return {
		levelOneName: getLevelOneName(state),
		isFilterActive: isFilterActive(state),
		isLevelOneSettingActive: !(showCompleted && period === 12),
		canUserCreate: getCanUserCreate(state),
	};
}, mapDispatchToProps)(StandardRoadmapListEmptyContent);
