// THIS FILE WAS AUTO-GENERATED. MODIFY WITH CAUTION. See README.md

import { createIllustration, type SVGViewBox } from '@atlassian/jira-illustration';
import srcDark from '../../assets/magnifying-glass.svg'; // eslint-disable-line import/no-duplicates, jira/import/no-duplicates
import srcLight from '../../assets/magnifying-glass.svg'; // eslint-disable-line import/no-duplicates

const Illustration = createIllustration({
	srcLight,
	srcDark,
});

export default Illustration;

// for advanced use only
const viewBox: SVGViewBox = [0, 0, 110, 84];
export { srcLight, srcDark, viewBox };
