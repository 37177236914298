import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { IssueId } from '@atlassian/jira-shared-types';
import type { CreateItemAnchor } from '@atlassian/jira-software-roadmap-timeline-table/src/types';
import type { TooltipParams } from '../../../model/table';

// set/reset create item anchor

export const SET_CREATE_ITEM_ANCHOR = 'state.ui.table.SET_CREATE_ITEM_ANCHOR' as const;

export type SetCreateItemAnchorAction = {
	type: typeof SET_CREATE_ITEM_ANCHOR;
	payload: CreateItemAnchor;
};

export const setCreateItemAnchor = (payload: CreateItemAnchor): SetCreateItemAnchorAction => ({
	type: SET_CREATE_ITEM_ANCHOR,
	payload,
});

export const RESET_CREATE_ITEM_ANCHOR = 'state.ui.table.RESET_CREATE_ITEM_ANCHOR' as const;

export type ResetCreateItemAnchorAction = {
	type: typeof RESET_CREATE_ITEM_ANCHOR;
};

export const resetCreateItemAnchor = (): ResetCreateItemAnchorAction => ({
	type: RESET_CREATE_ITEM_ANCHOR,
});

// change expansion state

export const CHANGE_EXPANSION_STATE = 'state.ui.table.CHANGE_EXPANSION_STATE' as const;

export type ChangeExpansionStatePayload = {
	id: IssueId;
	isExpanded: boolean;
};

export type ChangeExpansionStateAction = {
	type: typeof CHANGE_EXPANSION_STATE;
	payload: ChangeExpansionStatePayload;
};

export const changeExpansionState = (
	payload: ChangeExpansionStatePayload,
): ChangeExpansionStateAction => ({
	type: CHANGE_EXPANSION_STATE,
	payload,
});

export const BULK_CHANGE_EXPANSION_STATE = 'state.ui.table.BULK_CHANGE_EXPANSION_STATE';

export type BulkChangeExpansionStatePayload = {
	ids: IssueId[];
	isExpanded: boolean;
};

export type BulkChangeExpansionStateAction = {
	type: typeof BULK_CHANGE_EXPANSION_STATE;
	payload: BulkChangeExpansionStatePayload;
};

export const bulkChangeExpansionState = (
	payload: BulkChangeExpansionStatePayload,
): BulkChangeExpansionStateAction => ({
	type: BULK_CHANGE_EXPANSION_STATE,
	payload,
});

export const EXPAND_ALL_ISSUES = 'state.ui.table.EXPAND_ALL_ISSUES';

export type ExpandAllIssuesAction = {
	type: typeof EXPAND_ALL_ISSUES;
};

export const expandAllIssues = (): ExpandAllIssuesAction => ({
	type: EXPAND_ALL_ISSUES,
});

export const COLLAPSE_ALL_ISSUES = 'state.ui.table.COLLAPSE_ALL_ISSUES';

export type CollapseAllIssuesAction = {
	type: typeof COLLAPSE_ALL_ISSUES;
};

export const collapseAllIssues = (): CollapseAllIssuesAction => ({
	type: COLLAPSE_ALL_ISSUES,
});

// show/hide table tooltip
export const SHOW_TOOLTIP = 'state.ui.table.SHOW_TOOLTIP';

export type ShowToolTipAction = {
	type: typeof SHOW_TOOLTIP;
	payload: TooltipParams;
};

export const showToolTip = (payload: TooltipParams): ShowToolTipAction => ({
	type: SHOW_TOOLTIP,
	payload,
});

export const HIDE_TOOLTIP = 'state.ui.table.HIDE_TOOLTIP';

export type HideTooltipAction = {
	type: typeof HIDE_TOOLTIP;
};

export const hideToolTip = (): HideTooltipAction => ({
	type: HIDE_TOOLTIP,
});

// select items
export const SELECT_ITEMS = 'state.ui.table.SELECT_ITEMS';

type SelectItemsParams = {
	id: IssueId;
	level: number;
	withCmd: boolean;
	withShift: boolean;
};

export type SelectItemsAction = {
	type: typeof SELECT_ITEMS;
	payload: SelectItemsParams;
	meta: { analyticsEvent: UIAnalyticsEvent };
};

export const selectItems = (
	payload: SelectItemsParams,
	analyticsEvent: UIAnalyticsEvent,
): SelectItemsAction => ({
	type: SELECT_ITEMS,
	payload,
	meta: { analyticsEvent },
});

export const SET_SELECTED_ITEMS = 'state.ui.table.SET_SELECTED_ITEMS';

export type SetSelectedItemIdsAction = {
	type: typeof SET_SELECTED_ITEMS;
	payload: IssueId[];
};

export const setSelectedItemIds = (payload: IssueId[]): SetSelectedItemIdsAction => ({
	type: SET_SELECTED_ITEMS,
	payload,
});

export const SET_SELECTION_ANCHORS = 'state.ui.table.SET_SELECTION_ANCHORS';

export type SetSelectionAnchorsAction = {
	type: typeof SET_SELECTION_ANCHORS;
	payload: IssueId[];
};

export const setSelectionAnchors = (payload: IssueId[]): SetSelectionAnchorsAction => ({
	type: SET_SELECTION_ANCHORS,
	payload,
});
