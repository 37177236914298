import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	duplicatedTitle: {
		id: 'roadmap.standard-roadmap.flags.dependencies-invalid.duplicated-title',
		defaultMessage: 'Dependency already exists',
		description: 'Flag title. Informs a user that a dependency cannot be created when duplicated.',
	},
	duplicatedDescription: {
		id: 'roadmap.standard-roadmap.flags.dependencies-invalid.duplicated-description',
		defaultMessage: '<b>{dependency}</b> and <b>{dependee}</b> are already linked.',
		description:
			'Flag description. Informs a user why a dependency cannot be created when duplicated.',
	},
	duplicatedDescriptionHidden: {
		id: 'roadmap.standard-roadmap.flags.dependencies-invalid.duplicated-description-hidden',
		defaultMessage:
			'<b>{dependency}</b> and <b>{dependee}</b> are already linked, but dependencies are hidden on your timeline.',
		description:
			'Flag description. When dependencies are hidden, informs a user why a dependency cannot be created when dependencies are duplicated.',
	},
	cycleTitle: {
		id: 'roadmap.standard-roadmap.flags.dependencies-invalid.cycle-title',
		defaultMessage: "Couldn't create dependency",
		description:
			'Flag title. Informs a user that a dependency cannot be created when a cycle is created.',
	},
	cycleDescription: {
		id: 'roadmap.standard-roadmap.flags.dependencies-invalid.cycle-description',
		defaultMessage:
			'Remove the existing dependency between <b>{dependency}</b> and <b>{dependee}</b>, and try again.',
		description:
			'Flag description. Informs a user why a dependency cannot be created when a cycle is created.',
	},
	cycleDescriptionHidden: {
		id: 'roadmap.standard-roadmap.flags.dependencies-invalid.cycle-description-hidden',
		defaultMessage:
			'Remove the existing dependency between <b>{dependency}</b> and <b>{dependee}</b> and try again. This dependency is currently hidden from your timeline.',
		description:
			'Flag description. When dependencies are hidden, informs a user why a dependency cannot be created when a cycle is created.',
	},
	showDependencies: {
		id: 'roadmap.standard-roadmap.flags.dependencies-invalid.show-dependencies',
		defaultMessage: 'Change Dependency settings',
		description: 'Flag call to action. Allows user to update view settings.',
	},
	confirm: {
		id: 'roadmap.standard-roadmap.flags.dependencies-invalid.confirm',
		defaultMessage: 'Got it',
		description: 'Flag call to action. Allows user to confirm they understand the event.',
	},
});
