import React from 'react';
import { styled } from '@compiled/react';
import { N0, B400, G400, R400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

type ReleaseStatusType = 'released' | 'unreleased' | 'overdue' | 'overdue-arj';

interface Props {
	type: ReleaseStatusType;
}

const getIconColor = (type: ReleaseStatusType) => {
	switch (type) {
		case 'released':
			return token('color.icon.success', G400);
		case 'unreleased':
			return token('color.icon.information', B400);
		case 'overdue':
		case 'overdue-arj':
		default:
			return token('color.icon.danger', R400);
	}
};

const getIconContents = (type: ReleaseStatusType) => {
	switch (type) {
		case 'released':
			return (
				<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M11.707 6.707a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4Z"
						fill={token('color.text.inverse', N0)}
					/>
				</svg>
			);
		case 'overdue':
			return (
				<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M7 4a1 1 0 0 1 2 0v4a1 1 0 0 1-2 0V4Zm1 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
						fill={token('color.text.inverse', N0)}
					/>
				</svg>
			);
		case 'overdue-arj':
			return (
				<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="4" y="7" width="8" height="2" rx="1" fill={token('color.text.inverse', N0)} />
				</svg>
			);
		case 'unreleased':
		default:
			return undefined;
	}
};

const ReleaseStatusIcon = ({ type }: Props) => (
	<Container backgroundColor={getIconColor(type)}>{getIconContents(type)}</Container>
);

export default ReleaseStatusIcon;

interface ContainerProps {
	backgroundColor: string;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.span<ContainerProps>({
	color: token('color.text.inverse', N0),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props: any) => props.backgroundColor,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: gridSize * 2,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: gridSize * 2,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '100%',
	boxSizing: 'border-box',
	overflow: 'hidden',
});
