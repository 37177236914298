import type { IssueId } from '@atlassian/jira-shared-types';
import type {
	EditDatesOptionParams,
	BulkEditDatesOptionParams,
} from '../../../../model/list-item-menu-option';

export const getBulkEditDatesOptionParamsPure = (
	selectedIssueIds: IssueId[],
	{
		isDateEditable,
		startDate,
		dueDate,
		editDatesModalParams: { issueKey, issueSummary, level, warnings },
	}: EditDatesOptionParams,
): BulkEditDatesOptionParams => ({
	isDateEditable,
	issueKey,
	issueSummary,
	startDate,
	dueDate,
	level,
	selectedIssueCount: selectedIssueIds.length,
	warnings,
});
