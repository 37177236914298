import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	todo: {
		id: 'software-roadmap-services.version.todo',
		defaultMessage: 'To Do',
		description: 'The progress bar label for to do status in tooltips',
	},
	inProgress: {
		id: 'software-roadmap-services.version.in-progress',
		defaultMessage: 'In Progress',
		description: 'The progress bar label for in progress status used in tooltips',
	},
	done: {
		id: 'software-roadmap-services.version.done',
		defaultMessage: 'Done',
		description: 'The progress bar label for done status used in tooltips',
	},
});
