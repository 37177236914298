import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import {
	type AttemptRemoveIssueAction,
	ATTEMPT_REMOVE_ISSUE,
	removeIssue,
	type RemoveIssueAction,
} from '../../state/entities/issues/actions';
import { removeSubtasks, type RemoveSubtasksAction } from '../../state/entities/subtasks/actions';
import { getSubtaskParentHash } from '../../state/selectors/subtasks';
import type { State } from '../../state/types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action$: ActionsObservable<AttemptRemoveIssueAction>,
	store: MiddlewareAPI<State>,
) =>
	action$
		.ofType(ATTEMPT_REMOVE_ISSUE)
		.mergeMap(
			(action: AttemptRemoveIssueAction): Observable<RemoveIssueAction | RemoveSubtasksAction> => {
				const state = store.getState();

				const issueId = action.payload;
				const subtaskParent = getSubtaskParentHash(state)[issueId];

				if (subtaskParent !== undefined) {
					return Observable.of(removeSubtasks([{ id: issueId, parentId: subtaskParent }]));
				}

				return Observable.of(removeIssue(issueId));
			},
		);
