import React, { useCallback, type MouseEvent, type KeyboardEvent } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import messages from './messages';
import type { Props } from './types';

const BulkEditDatesOption = ({
	isDateEditable,
	Option,
	setOpenModal,
	setInitialFocusRef,
}: Props) => {
	const { formatMessage } = useIntl();

	const onClick = useCallback(
		(event: MouseEvent | KeyboardEvent) => {
			event.preventDefault();
			traceUFOPress('timeline-bulk-edit-dates-option-clicked', event.timeStamp);
			setOpenModal(event);
		},
		[setOpenModal],
	);

	return (
		<Option
			setInitialFocusRef={setInitialFocusRef}
			isDisabled={!isDateEditable}
			label={formatMessage(messages.bulkEditDates)}
			onClick={onClick}
		/>
	);
};

export default BulkEditDatesOption;
