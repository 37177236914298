import React from 'react';
import { lazy } from 'react-loosely-lazy';
import { fg } from '@atlassian/jira-feature-gating';
import { ErrorBoundaryWithChunkErrorLogging } from '@atlassian/jira-onboarding-core/src/ui/error-boundary-with-chunk-error-logging';
import Placeholder from '@atlassian/jira-placeholder';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { useShouldShowSelectInitialViewVariation } from './atlas-67241/utils';
import type { Props } from './types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyWelcomeModal = lazy(() =>
	import(/* webpackChunkName: "async-software-onboarding-modals-welcome-modal" */ './index').then(
		({ WelcomeModal }) => WelcomeModal,
	),
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyWelcomeModalVariation = lazy(() =>
	import(
		/* webpackChunkName: "async-software-onboarding-modals-welcome-modal-variation" */ './atlas-67241/index'
	).then(({ WelcomeModal }) => WelcomeModal),
);

export const AsyncWelcomeModal = ({ source }: Props) => {
	const shouldShowSelectInitialViewVariation = fg('jfind-select-initial-view-enabled-gate')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useShouldShowSelectInitialViewVariation(source)
		: false;

	if (shouldShowSelectInitialViewVariation) {
		return (
			<UFOSegment name="async-onboarding-welcome-modal">
				<ErrorBoundaryWithChunkErrorLogging
					id="asyncWelcomeModalComponent"
					fallback="unmount"
					packageName="jiraSoftwareOnboardingModals"
					teamName="jlove-makkuro"
				>
					<Placeholder name="software-onboarding-modals-welcome-modal" fallback={<></>}>
						<LazyWelcomeModalVariation source={source} />
					</Placeholder>
				</ErrorBoundaryWithChunkErrorLogging>
			</UFOSegment>
		);
	}

	return (
		<UFOSegment name="async-onboarding-welcome-modal">
			<ErrorBoundaryWithChunkErrorLogging
				id="asyncWelcomeModalComponent"
				fallback="unmount"
				packageName="jiraSoftwareOnboardingModals"
				teamName="jlove-makkuro"
			>
				<Placeholder name="software-onboarding-modals-welcome-modal" fallback={<></>}>
					<LazyWelcomeModal source={source} />
				</Placeholder>
			</ErrorBoundaryWithChunkErrorLogging>
		</UFOSegment>
	);
};
