import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { MEMORY } from '@atlassian/jira-cache/src/common/constants.tsx';
import createEntry from '@atlassian/jira-cache/src/common/utils/api/index.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { VersionDetailsResponse } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-header-item/version-flyout/content/types.tsx';
import { fetchVersionDetails } from './fetch-version-details';
import { transformVersionDetails } from './transformers';
import type { GraphQLVersionDetailsResponse } from './types';

const CACHE_SIZE = 50;

// @Export for testing
export const versionDetailsCache = createEntry<VersionDetailsResponse>({
	size: CACHE_SIZE,
	cacheKey: 'roadmap-version-details-cache',
	storageType: MEMORY,
});

export const removeVersionDetailsCache = (versionAri: Ari): Promise<void> => {
	const cacheKey = versionAri.toString();

	return versionDetailsCache.remove(cacheKey);
};

export const getVersionDetailsFromServer = (
	versionAri: Ari,
	intl: IntlShape,
): Promise<VersionDetailsResponse> => {
	const cacheKey = versionAri.toString();

	return versionDetailsCache.get(cacheKey).then((data?: VersionDetailsResponse) => {
		if (data !== undefined) {
			return data;
		}

		return fetchVersionDetails(versionAri)
			.toPromise()
			.then((response: GraphQLVersionDetailsResponse) => {
				const versionDetails = transformVersionDetails(response, intl);
				versionDetailsCache.set(cacheKey, versionDetails);
				return versionDetails;
			});
	});
};
