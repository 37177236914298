import {
	createStore,
	createContainer,
	createHook,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import { BASE_HEADER_HEIGHT } from '../../constants';
import { actions, type Actions } from './actions';
import type { Props, State } from './types';
import { getDerivedHeaderState } from './utils';

const initialState: State = {
	headerItems: [],
	headerHeight: BASE_HEADER_HEIGHT,
	headerItemsOffsetTop: {},
	isRowCountKnown: true,
};

export const headerStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'timeline-table.header',
});

export const useHeaderState = createHook(headerStore);

// Used as a lower bound for the body row aria indexes
export const useAriaRowOffset = createHook(headerStore, {
	selector: ({ isRowCountKnown, headerItems }) =>
		isRowCountKnown ? headerItems.length : undefined,
});

export const useHeaderItemIndexes = createHook(headerStore, {
	selector: ({ headerItems }) =>
		headerItems.reduce<Record<string, number>>((acc, item, index) => {
			acc[item.id] = index;
			return acc;
		}, {}),
});

const handleState =
	() =>
	({ setState }: StoreActionApi<State>, { headerItems, isRowCountKnown }: Props) => {
		const derivedHeaderState = getDerivedHeaderState(headerItems);
		setState({ ...derivedHeaderState, isRowCountKnown });
	};

const HeaderProvider = createContainer<State, Actions, Props>(headerStore, {
	onInit: handleState,
	onUpdate: handleState,
});

export default HeaderProvider;
