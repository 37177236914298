import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'roadmap.standard-roadmap.flags.item-disappears-from-display-range.title',
		defaultMessage: '<b>{issueKey}</b> is hidden from your plan',
		description: 'Flag title. Informs a user that the issue has been hidden.',
	},
	description: {
		id: 'roadmap.standard-roadmap.flags.item-disappears-from-display-range.description',
		defaultMessage:
			'<b>{issueKey}</b> is outside the visible date range of your plan. To view it, clear your issue display range settings.',
		description:
			'Flag description. Informs a user that the issue rescheduled is hidden due to their display range settings.',
	},
	clearDisplayOption: {
		id: 'roadmap.standard-roadmap.flags.item-disappears-from-display-range.clear-display-option',
		defaultMessage: 'Clear issue display range settings',
		description: 'Clear setting action caption',
	},
});
