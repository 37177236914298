import {
	DEFAULT_BORDER_RADIUS,
	LEFT_PLACEHOLDER_BORDER_RADIUS,
	RIGHT_PLACEHOLDER_BORDER_RADIUS,
	LEFT_AND_RIGHT_PLACEHOLDER_BORDER_RADIUS,
	START_DATE_PLACEHOLDER,
	DUE_DATE_PLACEHOLDER,
	START_AND_DUE_DATE_PLACEHOLDER,
} from '../../constants';
import type { Placeholder } from '../../types';

export const generateBarBorderRadius = (placeholder: Placeholder) => {
	if (placeholder === START_AND_DUE_DATE_PLACEHOLDER) {
		return LEFT_AND_RIGHT_PLACEHOLDER_BORDER_RADIUS;
	}
	if (placeholder === START_DATE_PLACEHOLDER) {
		return LEFT_PLACEHOLDER_BORDER_RADIUS;
	}
	if (placeholder === DUE_DATE_PLACEHOLDER) {
		return RIGHT_PLACEHOLDER_BORDER_RADIUS;
	}
	return DEFAULT_BORDER_RADIUS;
};
