import { createSelector } from 'reselect';
import { getTimelineWidth } from '@atlassian/jira-software-roadmap-timeline-table';
import { MINIMUM_BAR_LENGTH } from '@atlassian/jira-software-roadmap-timeline-table-kit';
import type {
	TimelineMode,
	TimelineDuration,
} from '@atlassian/jira-software-roadmap-timeline-table/src/types';
import {
	getTimelineDurationFromDate,
	getTimelineOriginForToday,
} from '@atlassian/jira-software-roadmap-utils/src/utils/timeline';
import { getToday } from '../../configuration/selectors';
import { getTimelineMode } from '../filters';

export const getTimelineDuration = createSelector(
	getToday,
	getTimelineMode,
	(today: number, timelineMode: TimelineMode) => getTimelineDurationFromDate(timelineMode, today),
);

export const getTimelineOrigin = createSelector(
	getToday,
	getTimelineMode,
	(today: number, timelineMode: TimelineMode) => getTimelineOriginForToday(today, timelineMode),
);

export const getMinTimelineDateRange = createSelector(
	getTimelineMode,
	getTimelineDuration,
	(timelineMode: TimelineMode, { totalMilliseconds }: TimelineDuration): number => {
		const timelineWidth = getTimelineWidth(timelineMode, totalMilliseconds);

		return Math.ceil((MINIMUM_BAR_LENGTH / timelineWidth) * totalMilliseconds);
	},
);
