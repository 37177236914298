import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sprintsCountLabel: {
		id: 'roadmap.timeline-table-kit.chart-header-item.intervals.marker.sprints-count-label',
		defaultMessage: '{sprintsCount} {sprintsCount, plural, one {sprint} other {sprints}}.',
		description:
			'Aria label text when focus is switched to Sprint markers, where {sprintsCount} is the total number of interavals',
	},
});
