/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@compiled/react';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { zIndex } from '../../../common/constants';
import { useListWidth } from '../../../common/context';

// NOTE: The scrollable overlay for content will extend to be outside the content container when items are filtered
// The styling here covers the scrollable overlay (e.g. columns overlay, today marker, etc.) when it goes beneath the list part (e.g. scrolling or virtualisation)
const containerStyles = css({
	position: 'sticky',
	flex: '0 0 auto',
	left: 0,
	background: token('elevation.surface', N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.LIST_COVER,
});

const ListCover = () => {
	const [listWidth] = useListWidth();

	return <div css={containerStyles} style={{ width: `${listWidth}px` }} />;
};

export default memo<{}>(ListCover);
