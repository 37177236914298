import { combineReducers } from 'redux';
import filter from './filters/reducer';
import healthcheck from './healthcheck/reducer';
import panel from './panel/reducer';
import table from './table/reducer';
import views from './views/reducer';

export default combineReducers({
	healthcheck,
	table,
	views,
	panel,
	filter,
});
