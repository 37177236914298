/**
 * @generated SignedSource<<1f21e995a2eb1be71e4e3df94f3c81a7>>
 * @relayHash 255e226237216096e8a600bbdb20cfcf
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5128cd59d6a48d3cf837c81e4baf8846c37dc31eba1fd40ee0fb65591718c84b

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type RoadmapToggleDependencyInput = {
  dependee: string;
  dependency: string;
};
export type addRoadmapDependencyMutation$variables = {
  input: RoadmapToggleDependencyInput;
  sourceARI: string;
};
export type addRoadmapDependencyMutation$data = {
  readonly roadmaps: {
    readonly addRoadmapDependency: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly errorType?: string | null | undefined;
          readonly statusCode?: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly output: {
        readonly dependee: string;
        readonly dependency: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type addRoadmapDependencyMutation = {
  response: addRoadmapDependencyMutation$data;
  variables: addRoadmapDependencyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceARI"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  },
  {
    "kind": "Variable",
    "name": "sourceARI",
    "variableName": "sourceARI"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "success"
},
v4 = {
  "kind": "ScalarField",
  "name": "message"
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "statusCode"
    },
    {
      "kind": "ScalarField",
      "name": "errorType"
    }
  ],
  "type": "RoadmapMutationErrorExtension"
},
v6 = {
  "concreteType": "RoadmapToggleDependencyResponse",
  "kind": "LinkedField",
  "name": "output",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "dependee"
    },
    {
      "kind": "ScalarField",
      "name": "dependency"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "addRoadmapDependencyMutation",
    "selections": [
      {
        "concreteType": "RoadmapsMutation",
        "kind": "LinkedField",
        "name": "roadmaps",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "RoadmapToggleDependencyPayload",
            "kind": "LinkedField",
            "name": "addRoadmapDependency",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ]
                  }
                ]
              },
              (v6/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "addRoadmapDependencyMutation",
    "selections": [
      {
        "concreteType": "RoadmapsMutation",
        "kind": "LinkedField",
        "name": "roadmaps",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "RoadmapToggleDependencyPayload",
            "kind": "LinkedField",
            "name": "addRoadmapDependency",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v5/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "id"
                          }
                        ],
                        "type": "BulkMutationErrorExtension"
                      }
                    ]
                  }
                ]
              },
              (v6/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "5128cd59d6a48d3cf837c81e4baf8846c37dc31eba1fd40ee0fb65591718c84b",
    "metadata": {},
    "name": "addRoadmapDependencyMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "32cf0e6cedae9ac26e7413c9b16a5074";

export default node;
