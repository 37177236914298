import memoizeOne from 'memoize-one';
// eslint-disable-next-line jira/restricted/moment
import moment from 'moment';
import { WEEKS, MONTHS, QUARTERS } from '../constants';
import type { TimelineMode, TimelineDuration } from '../types';

// Size in pixels a column should ideally represent for any timeline mode
const COLUMN_BASELINE = 255;

/* Size in pixels a millisecond should ideally represent for a particular timeline mode
 * The averages days per month round to 30 on non leap-years
 * The average days per quarter round to 91 on non leap-years
 */
type TimelineModeBaselines = Record<TimelineMode, number>;

export const TIMELINE_MODE_BASELINES: TimelineModeBaselines = {
	[WEEKS]: COLUMN_BASELINE / moment.duration(7, 'day').asMilliseconds(),
	[MONTHS]: COLUMN_BASELINE / moment.duration(30, 'day').asMilliseconds(),
	[QUARTERS]: COLUMN_BASELINE / moment.duration(91, 'day').asMilliseconds(),
};

/**
 * Given the totalDuration (milliseconds) of the timeline, calculate its width in pixels.
 *
 * The value needs to be rounded up in order to resolve a performance issue on Chrome:
 * Issue occurs when apply CSS linear-gradient to an element that has a decimal value width.
 */
export const getTimelineWidth = memoizeOne(
	(timelineMode: TimelineMode, totalDuration: number): number =>
		Math.round(totalDuration * TIMELINE_MODE_BASELINES[timelineMode]),
);

export const getTimelinePositionFromDate = (
	{ startMilliseconds, totalMilliseconds }: TimelineDuration,
	timelineWidth: number,
	date: number,
) => timelineWidth * ((date - startMilliseconds) / totalMilliseconds);

export const getDateFromTimelinePosition = (
	{ startMilliseconds, totalMilliseconds }: TimelineDuration,
	timelineWidth: number,
	position: number,
): number => Math.round(startMilliseconds + (position / timelineWidth) * totalMilliseconds);
