import 'rxjs/add/operator/concatMap';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/filter';
import type { MiddlewareAPI } from 'redux';
import type { ActionsObservable } from 'redux-observable';
import omit from 'lodash/omit';
import { Observable } from 'rxjs/Observable';
import { addUFOCustomData } from '@atlassian/react-ufo/custom-data';
import { type CreateIssueAction, CREATE_ISSUE } from '../../state/entities/issues/actions';
import { getInteractionPerformanceExtraData } from '../../state/selectors/metrics';
import type { State } from '../../state/types';
import {
	type SetTimelineModeAction,
	type SetStatusCategoryFilterAction,
	type SetIssueTypeFilterAction,
	type SetAssigneeFilterAction,
	type SetTextFilterAction,
	type SetLabelFilterAction,
	type SetIssueParentFilterAction,
	type SetVersionFilterAction,
	type SetComponentFilterAction,
	SET_TIMELINE_MODE,
	SET_STATUS_CATEGORY_FILTER,
	SET_ISSUE_TYPE_FILTER,
	SET_ASSIGNEE_FILTER,
	SET_TEXT_FILTER,
	SET_LABEL_FILTER,
	SET_ISSUE_PARENT_FILTER,
	SET_VERSION_FILTER,
	SET_COMPONENT_FILTER,
} from '../../state/ui/filters/actions';
import { SHOW_PANEL, SET_SELECTED_ISSUE } from '../../state/ui/panel/actions';
import {
	CHANGE_EXPANSION_STATE,
	type ChangeExpansionStateAction,
	SET_CREATE_ITEM_ANCHOR,
	type SetCreateItemAnchorAction,
	SHOW_TOOLTIP,
} from '../../state/ui/table/actions';
import {
	type SetProgressVisibilityAction,
	type SetReleasesVisibilityAction,
	type SetLevelOneSettingAction,
	SET_PROGRESS_VISIBILITY,
	SET_RELEASES_VISIBILITY,
	SET_LEVEL_ONE_SETTING,
	SET_DEPENDENCIES_VIEW_VISIBILITY,
} from '../../state/ui/views/actions';
import type { StateEpic } from '../common/types';

type Actions =
	| CreateIssueAction
	| SetTimelineModeAction
	| SetStatusCategoryFilterAction
	| SetIssueTypeFilterAction
	| SetAssigneeFilterAction
	| SetTextFilterAction
	| SetLabelFilterAction
	| SetIssueParentFilterAction
	| SetVersionFilterAction
	| SetComponentFilterAction
	| ChangeExpansionStateAction
	| SetCreateItemAnchorAction
	| SetProgressVisibilityAction
	| SetReleasesVisibilityAction
	| SetLevelOneSettingAction;

/**
 * This ops adds the custom data attributes to the UFO events.
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<Actions>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(
			CREATE_ISSUE,
			SET_TIMELINE_MODE,
			SET_STATUS_CATEGORY_FILTER,
			SET_ISSUE_TYPE_FILTER,
			SET_ASSIGNEE_FILTER,
			SET_TEXT_FILTER,
			SET_LABEL_FILTER,
			SET_ISSUE_PARENT_FILTER,
			SET_VERSION_FILTER,
			SET_COMPONENT_FILTER,
			CHANGE_EXPANSION_STATE,
			SET_CREATE_ITEM_ANCHOR,
			SET_PROGRESS_VISIBILITY,
			SET_RELEASES_VISIBILITY,
			SET_LEVEL_ONE_SETTING,
			SET_DEPENDENCIES_VIEW_VISIBILITY,
			SHOW_TOOLTIP,
			SHOW_PANEL,
			SET_SELECTED_ISSUE,
		)
		.concatMap(() => {
			const interactionPerformanceExtraData = getInteractionPerformanceExtraData(store.getState());
			const customData = omit(interactionPerformanceExtraData, ['isGetIssueLimit5kEnabled']);
			addUFOCustomData(customData);

			return Observable.empty<never>();
		})
		.ignoreElements()) as StateEpic;
