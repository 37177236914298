import { defineMessages } from 'react-intl-next';

const messages = defineMessages({
	moveItem: {
		id: 'roadmap.timeline-table.main.content.list.list-item.meatballs-button.meatball-popup.item-section-options.move-item-menu.move-item',
		defaultMessage: 'Move',
		description: 'Label for the move option in the list item menu',
	},
	moveItemUp: {
		id: 'roadmap.timeline-table.main.content.list.list-item.meatballs-button.meatball-popup.item-section-options.move-item-menu.move-item-up',
		defaultMessage: 'Move up',
		description: 'Label for the move up option in the list item menu',
	},
	moveItemDown: {
		id: 'roadmap.timeline-table.main.content.list.list-item.meatballs-button.meatball-popup.item-section-options.move-item-menu.move-item-down',
		defaultMessage: 'Move down',
		description: 'Label for the move down option in the list item menu',
	},
	moveItemToTop: {
		id: 'roadmap.timeline-table.main.content.list.list-item.meatballs-button.meatball-popup.item-section-options.move-item-menu.move-item-to-top',
		defaultMessage: 'Move to top',
		description: 'Label for the move to top option in the list item menu',
	},
	moveItemToBottom: {
		id: 'roadmap.timeline-table.main.content.list.list-item.meatballs-button.meatball-popup.item-section-options.move-item-menu.move-item-to-bottom',
		defaultMessage: 'Move to bottom',
		description: 'Label for the move to bottom option in the list item menu',
	},
});
export default messages;
