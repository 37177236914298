import {
	transformColorToTheme,
	type ColorSchema,
	type Color,
} from '@atlassian/jira-issue-epic-color';
import type { BarTheme } from '../../types';

const colorToBarTheme = (color: ColorSchema): BarTheme => ({
	background: color.primary,
	gradient: color.primaryGradient,
	handle: color.secondary,
	hasContrastSecondaryGradient: color.hasContrastSecondaryGradient,
});

const barThemes: Record<Color, BarTheme> = transformColorToTheme(colorToBarTheme);

export const getBarTheme = (color: Color): BarTheme => barThemes[color] || barThemes.PURPLE;
