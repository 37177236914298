import type { ProjectId, IssueTypeId, VersionId } from '@atlassian/jira-shared-types';
import type { CreationPreferences } from '@atlassian/jira-software-roadmap-model';
import type { UserSettingsState } from './types';

export const SET_SETTINGS = 'state.settings.SET_SETTINGS' as const;

export type SetSettingAction = {
	type: typeof SET_SETTINGS;
	payload: UserSettingsState;
};

export const setSettings = (payload: UserSettingsState): SetSettingAction => ({
	type: SET_SETTINGS,
	payload,
});

export const SET_LIST_WIDTH = 'state.settings.SET_LIST_WIDTH' as const;

export type SetListWidthAction = {
	type: typeof SET_LIST_WIDTH;
	payload: number;
};

export const setListWidth = (payload: number): SetListWidthAction => ({
	type: SET_LIST_WIDTH,
	payload,
});

export const SET_CREATION_PREFERENCES = 'state.settings.SET_CREATION_PREFERENCES' as const;

export type SetCreationPreferencesAction = {
	type: typeof SET_CREATION_PREFERENCES;
	payload: CreationPreferences;
};

export const setCreationPreferences = (
	payload: CreationPreferences,
): SetCreationPreferencesAction => ({
	type: SET_CREATION_PREFERENCES,
	payload,
});

export const UPDATE_CREATION_PREFERENCES = 'state.settings.UPDATE_CREATION_PREFERENCES' as const;

export type UpdateCreationPreferencesPayload = {
	level: number;
	itemTypeId: IssueTypeId;
	projectId: ProjectId;
};

export type UpdateCreationPreferencesAction = {
	type: typeof UPDATE_CREATION_PREFERENCES;
	payload: UpdateCreationPreferencesPayload;
};

export const updateCreationPreferences = (
	payload: UpdateCreationPreferencesPayload,
): UpdateCreationPreferencesAction => ({
	type: UPDATE_CREATION_PREFERENCES,
	payload,
});

export const SET_HIGHLIGHTED_VERSIONS = 'state.settings.SET_HIGHLIGHTED_VERSIONS' as const;

export type SetHighlightedVersionsAction = {
	type: typeof SET_HIGHLIGHTED_VERSIONS;
	payload: VersionId[];
};

export const setHighlightedVersions = (payload: VersionId[]): SetHighlightedVersionsAction => ({
	type: SET_HIGHLIGHTED_VERSIONS,
	payload,
});

export const SET_ISSUE_PANEL_RATIO = 'state.settings.SET_ISSUE_PANEL_RATIO' as const;

export type SetIssuePanelRatioAction = {
	type: typeof SET_ISSUE_PANEL_RATIO;
	payload: number;
};

export const setIssuePanelRatio = (payload: number): SetIssuePanelRatioAction => ({
	type: SET_ISSUE_PANEL_RATIO,
	payload,
});
