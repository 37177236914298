/**
 * @generated SignedSource<<4636b2692d5ceff90b984c30654913bf>>
 * @relayHash ae19ed1ed7424eb78d30531d31c19de9
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 01c1afb1671bea65374e5122e7f6071bed18381cc77a55f2fdf2be94c6806bd5

import type { ConcreteRequest, Query } from 'relay-runtime';
export type roadmapDeriveFieldsQuery$variables = {
  customFilterIds?: ReadonlyArray<string> | null | undefined;
  jqlContexts?: ReadonlyArray<string> | null | undefined;
  sourceAri: string;
};
export type roadmapDeriveFieldsQuery$data = {
  readonly roadmaps: {
    readonly roadmapDeriveFields: ReadonlyArray<{
      readonly id: string;
      readonly values: ReadonlyArray<string>;
    } | null | undefined>;
  } | null | undefined;
};
export type roadmapDeriveFieldsQuery = {
  response: roadmapDeriveFieldsQuery$data;
  variables: roadmapDeriveFieldsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customFilterIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jqlContexts"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceAri"
},
v3 = [
  {
    "concreteType": "RoadmapsQuery",
    "kind": "LinkedField",
    "name": "roadmaps",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "customFilterIds",
            "variableName": "customFilterIds"
          },
          {
            "kind": "Variable",
            "name": "jqlContexts",
            "variableName": "jqlContexts"
          },
          {
            "kind": "Variable",
            "name": "sourceARI",
            "variableName": "sourceAri"
          }
        ],
        "concreteType": "RoadmapField",
        "kind": "LinkedField",
        "name": "roadmapDeriveFields",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "id"
          },
          {
            "kind": "ScalarField",
            "name": "values"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "roadmapDeriveFieldsQuery",
    "selections": (v3/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "roadmapDeriveFieldsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "01c1afb1671bea65374e5122e7f6071bed18381cc77a55f2fdf2be94c6806bd5",
    "metadata": {},
    "name": "roadmapDeriveFieldsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "5b8a766134f80f9e9cd3532c1bf9cfbd";

export default node;
