import {
	EXTEND_SUBTASKS,
	type ExtendSubtasksAction,
	UPDATE_SUBTASK_STATUS,
	type UpdateSubtaskStatusAction,
	ADD_SUBTASK,
	type AddSubtaskAction,
	REMOVE_SUBTASKS,
	type RemoveSubtasksAction,
	CHANGE_SUBTASK_PARENT,
	type ChangeSubtaskParentAction,
} from './actions';
import type { SubtaskState } from './types';

export type Action =
	| ExtendSubtasksAction
	| UpdateSubtaskStatusAction
	| AddSubtaskAction
	| RemoveSubtasksAction
	| ChangeSubtaskParentAction;

const initialState: SubtaskState = {
	hash: {},
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: SubtaskState = initialState, action: Action): SubtaskState => {
	switch (action.type) {
		case EXTEND_SUBTASKS: {
			return {
				hash: {
					...state.hash,
					...action.payload,
				},
			};
		}
		case UPDATE_SUBTASK_STATUS: {
			const { id, parentId, statusCategoryId } = action.payload;
			const subtaskIndex = state.hash[parentId].findIndex(({ id: subtaskId }) => id === subtaskId);
			if (subtaskIndex >= 0) {
				const subtask = {
					...state.hash[parentId][subtaskIndex],
					statusCategoryId,
				};
				const subtaskList = [...state.hash[parentId]];
				subtaskList.splice(subtaskIndex, 1, subtask);

				return {
					hash: {
						...state.hash,
						[`${parentId}`]: subtaskList,
					},
				};
			}

			// ignore if target subtask does not exists
			return state;
		}
		case ADD_SUBTASK: {
			const { id, subtask } = action.payload;
			const subtaskList = [...state.hash[id]];

			subtaskList.push(subtask);
			return {
				hash: {
					...state.hash,
					[`${id}`]: subtaskList,
				},
			};
		}
		case REMOVE_SUBTASKS: {
			const items = action.payload;

			const updatedHash = { ...state.hash };
			items.forEach(({ parentId, id }) => {
				updatedHash[parentId] = updatedHash[parentId].filter((subtask) => !id.includes(subtask.id));
			});

			return {
				hash: updatedHash,
			};
		}

		case CHANGE_SUBTASK_PARENT: {
			const { id, newParentId, oldParentId } = action.payload;

			const subtask = state.hash[oldParentId].find(({ id: subtaskId }) => id === subtaskId);

			if (subtask !== undefined) {
				const newParentList = [...state.hash[newParentId]];
				newParentList.push(subtask);

				return {
					hash: {
						...state.hash,
						[`${oldParentId}`]: state.hash[oldParentId].filter(
							({ id: subtaskId }) => id !== subtaskId,
						),
						[`${newParentId}`]: newParentList,
					},
				};
			}

			// ignore if target subtask does not exists
			return state;
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
