/** @jsx jsx */
import React, { forwardRef, type Ref, type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import Tooltip, { type TriggerProps as TooltipProps } from '@atlaskit/tooltip';
import { HEADER_CONTENT_HEIGHT } from '@atlassian/jira-software-roadmap-timeline-table';
import { getDateFromTimestamp } from '@atlassian/jira-software-roadmap-utils/src/utils/dates';
import type { Interval } from '../../../../../common/types';
import {
	ContentName,
	ContentDateLabel,
	TooltipInterval,
} from '../../../../../common/ui/marker-tooltip';
import { SprintIcon } from '../../../../../common/ui/sprint-icon';

const tagStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${HEADER_CONTENT_HEIGHT}px`,
});

type Props = {
	isRichContentEnabled?: boolean;
	intervals: ReadonlyArray<Interval>;
	children: (tooltipProps?: TooltipProps) => ReactNode;
};

const TooltipTag = forwardRef<HTMLElement>((props, ref) => (
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	<div css={tagStyles} ref={ref as Ref<HTMLDivElement>} {...props} />
));

const IntervalsTooltip = ({ intervals, children }: Props) => {
	const renderTooltipContent = () => (
		<>
			{intervals.map(({ name, startDate, endDate }: Interval, index: number) => (
				<TooltipInterval key={index}>
					<SprintIcon />
					<ContentName>{name}</ContentName>
					<ContentDateLabel>{`
                        (${getDateFromTimestamp(startDate)} - ${getDateFromTimestamp(endDate)})
                    `}</ContentDateLabel>
				</TooltipInterval>
			))}
		</>
	);

	return (
		<Tooltip hideTooltipOnClick tag={TooltipTag} content={renderTooltipContent()}>
			{typeof children === 'function'
				? ({ 'aria-describedby': ariaDescribedby, ...tooltipProps }) => children(tooltipProps)
				: children}
		</Tooltip>
	);
};

export { IntervalsTooltip };
