import type { Dispatch } from 'redux';
import { connect } from '@atlassian/jira-react-redux';
import type { IssueId } from '@atlassian/jira-shared-types';
import { START_DATE, DUE_DATE } from '@atlassian/jira-software-roadmap-model';
import type { OnUpdateDate } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/types';
import type { ListItemMenuOptionProps } from '@atlassian/jira-software-roadmap-timeline-table/src/types';
import type { EditDatesOptionParams } from '../../../../../model/list-item-menu-option';
import {
	updateAndPersistIssue,
	type UpdateIssueAction,
} from '../../../../../state/entities/issues/actions';
import { getEditDatesOptionParams } from '../../../../../state/selectors/list-item-menu-option';
import type { State } from '../../../../../state/types';
import RemoveDatesOption from './view';

type OwnProps = {
	id: IssueId;
	Option: ListItemMenuOptionProps['Option'];
};

type StateProps = Pick<
	EditDatesOptionParams,
	'isDateEditable' | 'isStartDateRequired' | 'isDueDateRequired'
> &
	EditDatesOptionParams['removeDatesOptionParams'];

type Action = UpdateIssueAction;
type DispatchProps = {
	onClear: OnUpdateDate;
};

const mapStateToProps = (state: State, { id }: OwnProps): StateProps => {
	const { isDateEditable, isStartDateRequired, isDueDateRequired, removeDatesOptionParams } =
		getEditDatesOptionParams(state, id);

	return {
		isDateEditable,
		isStartDateRequired,
		isDueDateRequired,
		...removeDatesOptionParams,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<Action>, { id }: OwnProps): DispatchProps => ({
	onClear: ({ isClearStart, isClearDue }, analyticsEvent) => {
		dispatch(
			updateAndPersistIssue(
				id,
				{ clearFields: [...(isClearStart ? [START_DATE] : []), ...(isClearDue ? [DUE_DATE] : [])] },
				analyticsEvent,
			),
		);
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveDatesOption);
