import type { StoreActionApi, Action } from '@atlassian/react-sweet-state';
import { DEFAULT_STATE } from '../../common/constants';
import type { State, ContainerProps } from '../../common/types';

export type OnDragEndAction = () => Action<State, ContainerProps>;

export const onDragEnd =
	() =>
	({ setState }: StoreActionApi<State>, { sideEffectMarshal }: ContainerProps) => {
		sideEffectMarshal.custom.onBarInteractionEnd();

		/* Don't reset activeItem, the mouse remains on the item after drag finishes so it is still "active".
		 * Don't reset hasLingered, the user may still intend to interact again. Without it, we won't show any target intervals.
		 */
		const { activeItem, hasLingered, ...defaultState } = DEFAULT_STATE;
		setState(defaultState);
	};
