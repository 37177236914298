import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	releases: {
		id: 'roadmap.standard-roadmap.common.table.list-header-item.releases',
		defaultMessage: 'Releases',
		description:
			'Table header title. Informs a user that the row contains Releases content in the header.',
	},
	sprints: {
		id: 'roadmap.standard-roadmap.common.table.list-header-item.sprints',
		defaultMessage: 'Sprints',
		description:
			'Table header title. Informs a user that the row contains Releases content in the header.',
	},
});
