import React, { memo, useEffect } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { PARENT_LEVEL } from '@atlassian/jira-software-roadmap-model';
import messages from './messages';
import { ReparentMenuWrapperAsync, ReparentMenuWrapperLazy } from './reparent-menu-wrapper/async';
import type { Props } from './types';

const ReparentMenuOption = memo(({ id, issueLevel, Option: MenuOption, setCloseMenu }: Props) => {
	const { formatMessage } = useIntl();

	useEffect(() => {
		ReparentMenuWrapperLazy.preload();
	}, []);

	return (
		<MenuOption
			label={formatMessage(messages.changeParentIssue)}
			isDisabled={issueLevel === PARENT_LEVEL}
		>
			{({ setInitialFocusRef }) => (
				<ReparentMenuWrapperAsync
					id={id}
					setInitialFocusRef={setInitialFocusRef}
					setCloseMenu={setCloseMenu}
				/>
			)}
		</MenuOption>
	);
});

export default ReparentMenuOption;
