import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	moreSettingsMenuLabel: {
		id: 'roadmap.standard-roadmap.header.more-settings.more-settings-menu-label',
		defaultMessage: 'More',
		description: 'Dropdown menu to access more settings',
	},

	moreSettingsButtonLabel: {
		id: 'roadmap.standard-roadmap.header.more-settings.more-settings-button-label',
		defaultMessage: 'Configure Timeline',
		description: 'Dropdown item to configure the timeline',
	},
	feedbackButtonLabel: {
		defaultMessage: 'Give feedback',
		id: 'roadmap.standard-roadmap.header.more-settings.feedback-button-label',
		description: 'Text of the button that allows users to give feedback',
	},
	feedbackCollectorTitle: {
		id: 'roadmap.standard-roadmap.header.more-settings.feedback-collector-title',
		defaultMessage: 'Give feedback to Atlassian',
		description: 'Title for a feedback dialog',
	},
	feedbackCollectorDescription: {
		id: 'roadmap.standard-roadmap.header.more-settings.feedback-collector-description',
		defaultMessage: 'Select Jira Timeline feedback type',
		description: 'Description for a feedback dialog',
	},
});
