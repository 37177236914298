import { combineEpics } from 'redux-observable';
import type { StateEpic } from '../common/types';
import bulkChangeExpansion from './bulk-change-expansion';
import changeExpansion from './change-expansion';
import setHighlightedVersions from './set-highlighted-versions';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default combineEpics(
	setHighlightedVersions,
	changeExpansion,
	bulkChangeExpansion,
) as StateEpic;
