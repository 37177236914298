import {
	EXTEND_ISSUE_TYPE_COUNT,
	type ExtendIssueTypeCountAction,
	EXTEND_ISSUE_TYPES,
	type ExtendIssueTypesAction,
	SET_RESOLVING,
	type SetResolvingAction,
	type UpdateHealthcheckAction,
	UPDATE_HEALTHCHECK,
} from './actions';
import type { HealthcheckState } from './types';

type Action =
	| ExtendIssueTypesAction
	| ExtendIssueTypeCountAction
	| SetResolvingAction
	| UpdateHealthcheckAction;

const defaultState = {
	isResolving: false,
	issueTypeCounts: {},
	current: undefined,
	issueTypes: undefined,
} as const;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: HealthcheckState = defaultState, action: Action): HealthcheckState => {
	switch (action.type) {
		case EXTEND_ISSUE_TYPE_COUNT:
			return {
				...state,
				issueTypeCounts: {
					...state.issueTypeCounts,
					...action.payload,
				},
			};
		case EXTEND_ISSUE_TYPES:
			return {
				...state,
				issueTypes: action.payload,
			};
		case SET_RESOLVING: {
			return {
				...state,
				isResolving: action.payload,
			};
		}
		case UPDATE_HEALTHCHECK: {
			return {
				...state,
				current: action.payload,
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
