import type { LevelOneSetting } from '@atlassian/jira-software-roadmap-model';
import type { State } from '../../types';

export const isDependenciesVisibleInView = (state: State): boolean =>
	state.ui.views.isDependenciesVisible;

export const isProgressVisibleInView = (state: State): boolean => state.ui.views.isProgressVisible;

export const isWarningsVisibleInView = (state: State): boolean => state.ui.views.isWarningsVisible;

export const isReleasesVisibleInView = (state: State): boolean => state.ui.views.isReleasesVisible;

export const getLevelOneSettingInView = (state: State): LevelOneSetting =>
	state.ui.views.levelOneViewSettings;
