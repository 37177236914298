import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/map';
import type { ActionsObservable } from 'redux-observable';
import { getIssueParentIds } from '../../state/entities/issues/selectors';
import type { State } from '../../state/types';
import {
	bulkChangeExpansionState,
	COLLAPSE_ALL_ISSUES,
	type CollapseAllIssuesAction,
	EXPAND_ALL_ISSUES,
	type ExpandAllIssuesAction,
} from '../../state/ui/table/actions';
import type { StateEpic } from '../common/types';

type Action = ExpandAllIssuesAction | CollapseAllIssuesAction;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(EXPAND_ALL_ISSUES, COLLAPSE_ALL_ISSUES).map((action: Action) => {
		const { type } = action;

		const state = store.getState();

		const issueParentIds = getIssueParentIds(state);
		const isExpanded = type === EXPAND_ALL_ISSUES;

		return bulkChangeExpansionState({ ids: issueParentIds, isExpanded });
	})) as StateEpic;
