import type { MiddlewareAPI } from 'redux';
import type { OutputSelector } from 'reselect';
import type { Observable } from 'rxjs/Observable';
import type { State } from '../../state/types';

export const observeSelector = <T,>(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	action$: Observable<any>,
	store: MiddlewareAPI<State>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	selector: OutputSelector<any, T, any>,
): Observable<T> => {
	let recomputations = selector.recomputations();

	return action$
		.filter(() => selector.recomputations() !== recomputations)
		.map(() => {
			const state = store.getState();
			recomputations = selector.recomputations();

			return selector(state);
		});
};
