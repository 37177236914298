const EPIC = 'Epic' as const;
const STORY = 'Story' as const;
const BUG = 'Bug' as const;
const TASK = 'Task' as const;

// Can represent any issue type name that is not in the standard set (for PII purposes)
export const CUSTOM = 'Custom' as const;

export const standardIssueTypes: {
	[issueTypeName: string]: true;
} = {
	[EPIC]: true,
	[STORY]: true,
	[BUG]: true,
	[TASK]: true,
};

// Hardcoded possible translations of "Task"
// to be able to identify the Task issue type.
// Ideal way would be to have untranslatedName coming from the BE, but it's not the case yet.
// Cloned from "src/packages/software/backlog/src/view/card-list/inline-card-create/constants.tsx", and added the Italian translation (Attività).
export const translatedTaskNames = [
	'Task',
	'Aufgabe',
	'Tarea',
	'Tâche',
	'タスク',
	'작업',
	'Tarefa',
	'Задача',
	'Úkol',
	'Ülesanne',
	'Tehtävä',
	'Feladat',
	'Verk',
	'Taak',
	'Opgave',
	'Oppgave',
	'Zadanie',
	'Activitate',
	'Задача',
	'Úloha',
	'Uppgift',
	'Görev',
	'Nhiệm vụ',
	'任务',
	'任務',
	'Attività',
];
