import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	description: {
		id: 'roadmap.standard-roadmap.flags.irrelevant-item-created.board-description.description',
		defaultMessage:
			'Your issue {issueUrls} has been created but is not currently visible because it does not match your board filter.',
		description:
			'Flag description. Informs a user why a newly created issue is not visible. {issueUrls} is the url to the view issue page for the created issue.',
	},
	descriptionMultipleIssues: {
		id: 'roadmap.standard-roadmap.flags.irrelevant-item-created.board-description.description-multiple-issues',
		defaultMessage:
			'Your issues {issueUrls} have been created but are not currently visible because they do not match your board filter.',
		description:
			'Flag description. Informs a user why a newly created issue is not visible. {issueUrls} is the url to the view issue page for each of created issue.',
	},
});
