const GRID_SIZE = 8;
const DEFAULT_LIST_CONTENT_PADDING = GRID_SIZE * 3;
const NON_BASE_LEVEL_LIST_CONTENT_PADDING = 0;
const DEFAULT_LIST_CREATE_OFFSET = -12;

export const getListContentPaddingLeft = ({
	isCreate = false,
	depth,
	isParent,
}: {
	isCreate?: boolean;
	depth: number;
	isParent: boolean;
}) => {
	let result = depth * DEFAULT_LIST_CONTENT_PADDING;

	if (isParent) {
		result += NON_BASE_LEVEL_LIST_CONTENT_PADDING;
	} else {
		result += DEFAULT_LIST_CONTENT_PADDING;
	}

	if (isCreate) {
		result += DEFAULT_LIST_CREATE_OFFSET;
	}

	return result;
};
