import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { PAGE_LAYOUT_OFFSET_TOP } from '@atlassian/jira-common-constants/src/page-layout';

export default function RoadmapSkeleton() {
	return (
		<Box xcss={containerStyles}>
			<Spinner size="large" />
		</Box>
	);
}

const containerStyles = xcss({
	display: 'flex',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `calc(100vh - ${PAGE_LAYOUT_OFFSET_TOP})`,
	alignItems: 'center',
	justifyContent: 'center',
});
