import type { FilterConfiguration } from '@atlassian/jira-software-roadmap-model/src/configuration/index.tsx';
import type { CustomFiltersHash } from '@atlassian/jira-software-roadmap-model/src/custom-filter/index.tsx';
import type { QuickFiltersHash } from '@atlassian/jira-software-roadmap-model/src/quick-filter/index.tsx';
import type { GraphQLQuickFilter, GraphQLCustomFilter, GraphQLFilterConfiguration } from './types';

const transformQuickFilters = (quickFilters: ReadonlyArray<GraphQLQuickFilter>): QuickFiltersHash =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	quickFilters.reduce<Record<string, any>>((acc, quickFilter, position) => {
		acc[quickFilter.id] = {
			id: quickFilter.id,
			name: quickFilter.name,
			query: quickFilter.query,
			position,
		};
		return acc;
	}, {});

const transformCustomFilters = (
	customFilters: ReadonlyArray<GraphQLCustomFilter>,
): CustomFiltersHash =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	customFilters.reduce<Record<string, any>>((acc, customFilter) => {
		acc[customFilter.id] = {
			id: customFilter.id,
			name: customFilter.name,
		};
		return acc;
	}, {});

export const transformFilterConfiguration = (
	filterConfig: GraphQLFilterConfiguration,
): FilterConfiguration => ({
	quickFilters: transformQuickFilters(filterConfig.quickFilters),
	customFilters: transformCustomFilters(filterConfig.customFilters),
});
