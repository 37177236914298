import { interactionMetrics } from '@atlassian/jira-software-roadmap-services';
import type { InteractionMetricType } from '@atlassian/jira-software-roadmap-utils/src/utils/metrics';

export type InteractionPerformanceMetrics<ExtraData> = {
	setExtraData: (extraData: ExtraData | undefined) => void;
	start: (type: InteractionMetricType) => void;
	stop: (type: InteractionMetricType) => void;
};

export { useDragAndDropLongTaskMetric, DragAndDropLongTaskMetric } from './long-task-drag-and-drop';

const interactionPerformanceMetricFactory = <
	ExtraData,
>(): InteractionPerformanceMetrics<ExtraData> => {
	let lastExtraData: ExtraData | undefined;

	return {
		setExtraData: (extraData?: ExtraData) => {
			lastExtraData = extraData;
		},

		start: (type: InteractionMetricType) => {
			const performanceMetrics = interactionMetrics[type];
			performanceMetrics?.cancel();
			performanceMetrics?.start();
		},

		stop: (type: InteractionMetricType) => {
			const performanceMetrics = interactionMetrics[type];
			performanceMetrics?.stop({ customData: { ...(lastExtraData ?? undefined) } });
		},
	};
};

export default interactionPerformanceMetricFactory;
