import { createSelector } from 'reselect';
import type { MessageDescriptorV2 } from '@atlassian/jira-intl';
import type { Hash } from '@atlassian/jira-software-roadmap-model';
import { createSelectorWithDefaultEqual } from '../../../../common/utils/reselect';
import {
	getIsReleasesFeatureEnabled,
	getIsSprintsPlanningEnabled,
	getIsChildIssuePlanningEnabled,
} from '../../../configuration/selectors';
import {
	getIssueStartDateHash,
	getIssueDueDateHash,
	getIssueStatusHash,
	getIssueParentIdHash,
} from '../../../entities/issues/selectors';
import { getDoneStatusCategoryId } from '../../../entities/status-categories/selectors';
import type { State } from '../../../types';
import { isWarningsVisible } from '../../filters';
import { getIssueEdgeReleaseDatesHash } from '../../issues';
import { getSanitisedIssueSprintsHash } from '../../sprint';
import { getParentRolledUpDatesHash, getExplicitBoundaryDatesHash } from '../dates';
import { createGetIssueDateWarningsHash as createGetIssueDateWarningsHashPure } from './pure';

/**
 * Creates a hash of the date errors for each issue
 */
export const getIssueDateErrorsHash = createSelector(
	getDoneStatusCategoryId,
	getIssueStatusHash,
	getIssueStartDateHash,
	getIssueDueDateHash,
	getIssueParentIdHash,
	getParentRolledUpDatesHash,
	getExplicitBoundaryDatesHash,
	getIsSprintsPlanningEnabled,
	getIsChildIssuePlanningEnabled,
	getIssueEdgeReleaseDatesHash,
	getSanitisedIssueSprintsHash,
	getIsReleasesFeatureEnabled,
	createGetIssueDateWarningsHashPure,
);

/*
 * Fetch getIssueDateErrorsHash if warnings are visible
 */
export const getIssueDateErrorsHashWithWarnings = (state: State) =>
	isWarningsVisible(state) ? getIssueDateErrorsHash(state) : {};

/**
 * Returns a hash of the date errors with a deep equality check as dateErrors is an array of objects.
 */
export const getIssueDateErrorsHashWithDefaultEqual = createSelectorWithDefaultEqual(
	getIssueDateErrorsHashWithWarnings,
	(dateErrors: Hash<MessageDescriptorV2[]>): Hash<MessageDescriptorV2[]> => dateErrors,
);
