import React, { forwardRef, type RefObject } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ChildIssuesIcon from '@atlaskit/icon/glyph/child-issues';
import { N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import ToolTip from '@atlaskit/tooltip';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

type ProgressMetadata = {
	total: number;
	completed: number;
};

type Props = ProgressMetadata & {
	isActive: boolean;
};

const Indicator = ({ label }: { label: string }) => (
	<ChildIssuesIcon
		testId="roadmap.timeline-table-kit.ui.list-item-content.summary.progress-indicator"
		primaryColor={token('color.icon.subtle', N300)}
		size="small"
		label={label}
	/>
);

const TooltipTag = forwardRef<HTMLElement>((props, ref) => (
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	<Container ref={ref as RefObject<HTMLDivElement>} {...props} />
));

const ProgressIndicator = (props: Props) => {
	const { total, completed, isActive } = props;
	const { formatMessage } = useIntl();

	const onTooltipShow = (analyticEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticEvent, 'childIssuesIcon', { childIssuesNum: total });
	};
	const message = formatMessage(messages.progressMetadataTooltip, {
		completed,
		total,
	});
	if (isActive) {
		return (
			<ToolTip content={message} tag={TooltipTag} onShow={onTooltipShow}>
				<Indicator label={message} />
			</ToolTip>
		);
	}
	return (
		<Container>
			<Indicator label={message} />
		</Container>
	);
};

export default ProgressIndicator;
export type { ProgressMetadata };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	flex: `0 0 ${gridSize * 2}px`,
	overflow: 'hidden',
	display: 'flex',
});
