import React, { type ComponentType } from 'react';
import { connect } from '@atlassian/jira-react-redux';
import {
	DEPENDENCY_DRAG_AND_DROP,
	type InteractionMetricType,
} from '@atlassian/jira-software-roadmap-utils/src/utils/metrics';
import { DragAndDropLongTaskMetric } from '../../../../metrics';
import { getCanUserEdit } from '../../../../state/selectors/permission';
import type { State } from '../../../../state/types';
import ChartOverlay from './view';

type StateProps = {
	isEditEnabled: boolean;
	DndLongTaskMetric: ComponentType<{
		isDragging: boolean;
	}>;
};

const DndLongTaskMetric = (props: { isDragging: boolean }) => (
	<DragAndDropLongTaskMetric<InteractionMetricType>
		{...props}
		metricType={DEPENDENCY_DRAG_AND_DROP}
	/>
);

const mapStateToProps = (state: State): StateProps => ({
	isEditEnabled: getCanUserEdit(state),
	DndLongTaskMetric,
});

export default connect(mapStateToProps, {})(ChartOverlay);
