import React from 'react';
import { DocumentTitle as GlobalDocumentTitle } from '@atlassian/jira-global-document-title/src';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

export type Props = {
	projectName: string;
};

const DocumentTitle = ({ projectName }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<GlobalDocumentTitle title={formatMessage(messages.timelineDocumentTitle, { projectName })} />
	);
};
export default DocumentTitle;
