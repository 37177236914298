/** @jsx jsx */
import React, {
	useMemo,
	forwardRef,
	useCallback,
	type MouseEvent,
	type KeyboardEvent,
	type ForwardedRef,
} from 'react';
import { css, jsx } from '@compiled/react';
import { mergeRefs } from 'use-callback-ref';
import LinkIcon from '@atlaskit/icon/glyph/link';
import { N0 } from '@atlaskit/theme/colors';
import { token, useThemeObserver } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { isEnterOrSpaceKey } from '@atlassian/jira-software-roadmap-timeline-table';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import { CHART_BAR_CONTENT_Z_INDEX } from '../../../../../../common/constants';
import type { Position } from '../../../../../../common/types';
import messages from './messages';

type Props = {
	level: number;
	onClick: (position: Position) => void;
};

type HandleEventArgs = {
	timeStamp: number;
	target: HTMLElement;
};

const RoadmapBarIcon = forwardRef(
	({ level, onClick }: Props, ref: ForwardedRef<HTMLDivElement>) => {
		const { formatMessage } = useIntl();
		const { colorMode } = useThemeObserver();

		const { createAnalyticsEvent } = useAnalyticsEvents();

		const handleEvent = useCallback(
			({ timeStamp, target }: HandleEventArgs) => {
				traceUFOPress('timeline-dependency-clicked', timeStamp);

				const analyticsEvent = createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'icon',
				});
				fireUIAnalytics(analyticsEvent, 'dependencyIcon', { level });

				const { top, left, width } = target.getBoundingClientRect();
				const position = { y: top + width, x: left };

				onClick(position);
			},
			[onClick, level, createAnalyticsEvent],
		);

		const onClickCallback = useCallback(
			(event: MouseEvent<HTMLElement>) => {
				handleEvent({
					timeStamp: event.timeStamp,
					target: event.currentTarget,
				});
			},
			[handleEvent],
		);

		const onKeyDownCallback = useCallback(
			(event: KeyboardEvent<HTMLDivElement>) => {
				if (isEnterOrSpaceKey(event)) {
					event.preventDefault();
					handleEvent({
						timeStamp: event.timeStamp,
						target: event.currentTarget,
					});
				}
			},
			[handleEvent],
		);

		const CustomToolTipTag = useMemo(
			() =>
				// FIXME: Chronos should fix this by extracting the forwarded component

				forwardRef<HTMLElement>((props, tooltipRef) => (
					<div
						css={iconWrapperStyles}
						{...props}
						ref={
							fg('jsw_roadmaps_timeline-dependency-flyout-refocus')
								? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									mergeRefs([ref, tooltipRef as unknown as React.ForwardedRef<HTMLDivElement>])
								: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									(tooltipRef as unknown as React.ForwardedRef<HTMLDivElement>)
						}
						onClick={onClickCallback}
						role="button"
						onKeyDown={onKeyDownCallback}
						tabIndex={0}
						aria-label={formatMessage(messages.viewDetail)}
					/>
				)),
			[ref, onClickCallback, onKeyDownCallback, formatMessage],
		);

		return (
			<Tooltip tag={CustomToolTipTag} position="top" content={formatMessage(messages.viewDetail)}>
				{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
				<LinkIcon
					size="small"
					label=""
					primaryColor={colorMode ? 'rgba(255, 255, 255, 0.8)' : N0}
					testId="roadmap.timeline-table-kit.ui.chart-item-content.date-content.bar.bar-content.bar-icon.link-icon"
				/>
			</Tooltip>
		);
	},
);

export { RoadmapBarIcon };

// Child selector is for the nested tooltip wrapper
const iconWrapperStyles = css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: token('space.250', '20px'),
	height: token('space.250', '20px'),
	marginRight: token('space.050', '4px'),
	borderRadius: '50%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: CHART_BAR_CONTENT_Z_INDEX,
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: token('color.background.inverse.subtle.hovered', 'rgba(0, 0, 0, 0.1)'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		display: 'flex',
	},
});
