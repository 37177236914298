/** @jsx jsx */
import React, { memo, useCallback, useRef } from 'react';
import { css, jsx } from '@compiled/react';
import noop from 'lodash/noop';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import UFOSegment from '@atlassian/jira-ufo-segment';
import {
	ItemsRenderer,
	type RenderItem,
	type RenderCreateItem,
	type RenderItemContainer,
} from '../../common/components/items-renderer';
import { useEnablements, useCreateItemAnchor, useItemHeight } from '../../common/context';
import type {
	ItemDropPayload,
	ItemSelectPayload,
	DndLongTaskMetricComponent,
	OnItemDrop,
	OnItemSelect,
	OnItemExpandChanged,
	OnCreateClick,
	OnSetListWidth,
	ItemId,
} from '../../common/types';
import { fireUIAnalyticsEvent } from '../../common/utils/analytics';
import {
	useResizeObserver,
	type ResizeObserverCallback,
} from '../../common/utils/use-resize-observer';
import type {
	RenderListEmptyContent,
	RenderListItemContent,
	RenderListItemSecondaryContent,
	RenderListItemCreate,
	RenderChartItemContent,
	RenderChartTimelineOverlay,
	RenderListItemMenuOption,
	RenderListItemModal,
} from '../../renderers';
import ScrollableOverlay from '../scrollable-overlay';
import { ChartItem, ChartItemLite } from './chart';
import { ItemsBackground } from './items-background';
import {
	CreateItem,
	DropTarget,
	Footer,
	ListItem,
	ListItemLite,
	ListResizer,
	canItemCreateSibling,
	canItemCreateChild,
} from './list';

const contentContainerStyles = css({
	position: 'relative',
});

export type Props = {
	isResizeEnabled: boolean;
	DndLongTaskMetric: DndLongTaskMetricComponent | undefined;
	onItemDrop: OnItemDrop;
	onItemSelect: OnItemSelect;
	onItemExpandChanged: OnItemExpandChanged;
	onCreateClick: OnCreateClick;
	onSetListWidth: OnSetListWidth;
	onContentResize: ResizeObserverCallback;
	renderListEmptyContent: RenderListEmptyContent;
	renderListItemContent: RenderListItemContent;
	renderListItemSecondaryContent: RenderListItemSecondaryContent;
	renderListItemCreate: RenderListItemCreate;
	renderChartItemContent: RenderChartItemContent;
	renderChartTimelineOverlay: RenderChartTimelineOverlay;
	// TODO: Remove the undefined type once the create child button deprecated
	renderListItemMenuOption: RenderListItemMenuOption | undefined;
	renderListItemModal: RenderListItemModal | undefined;
};

const Content = ({
	isResizeEnabled,
	DndLongTaskMetric,
	onItemDrop,
	onItemSelect,
	onItemExpandChanged,
	onCreateClick,
	onSetListWidth,
	onContentResize,
	renderListEmptyContent,
	renderListItemContent,
	renderListItemSecondaryContent,
	renderListItemCreate,
	renderChartItemContent,
	renderChartTimelineOverlay,
	renderListItemMenuOption,
	renderListItemModal,
}: Props) => {
	const contentContainerRef = useRef<HTMLDivElement | null>(null);
	const [
		{
			isDragEnabled,
			isSelectEnabled,
			isCreateSiblingEnabled,
			isCreateChildEnabled,
			isMeatballsButtonEnabled,
		},
	] = useEnablements();
	const [createItemAnchor] = useCreateItemAnchor();
	const [itemHeight] = useItemHeight();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onSelect = useCallback(
		(id: ItemId, payload: ItemSelectPayload) => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'listItem',
				actionSubjectId: 'issueListItem',
				attributes: payload,
			});

			fireUIAnalyticsEvent(analyticsEvent);
			onItemSelect(id, payload, analyticsEvent);
		},
		[onItemSelect, createAnalyticsEvent],
	);

	const onExpandChanged = useCallback(
		(id: ItemId, isItemExpanded: boolean) => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'expansionStateChanged',
				actionSubject: 'listItem',
				actionSubjectId: 'issueListItem',
				attributes: {
					type: isItemExpanded ? 'collapsed' : 'expanded',
				},
			});

			fireUIAnalyticsEvent(analyticsEvent);
			onItemExpandChanged(id, isItemExpanded);
		},
		[onItemExpandChanged, createAnalyticsEvent],
	);

	const onDrop = useCallback(
		(id: ItemId, payload: ItemDropPayload) => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'dragEnded',
				actionSubject: 'listItem',
				actionSubjectId: 'issueListItem',
				attributes: {
					level: payload.level,
					isReparent: Boolean(payload.parentId),
					...(fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y')
						? { isMeatballsAction: payload.isMeatballsAction }
						: {}),
				},
			});

			fireUIAnalyticsEvent(analyticsEvent);
			onItemDrop(id, payload, analyticsEvent);
		},
		[onItemDrop, createAnalyticsEvent],
	);

	const renderCreateItem: RenderCreateItem = (
		id,
		anchorItem,
		{ level, depth = 0, backgroundColor, isActive, ariaRowIndex, topOffset },
	) => (
		<CreateItem
			key={id}
			id={id}
			level={level}
			canCreateChildOverride={Boolean(anchorItem?.canCreateChildOverride)}
			depth={depth}
			anchorId={anchorItem?.id}
			itemHeight={itemHeight}
			backgroundColor={backgroundColor}
			isActive={isActive}
			ariaRowIndex={ariaRowIndex}
			topOffset={topOffset}
			onCreateClick={onCreateClick}
			renderListItemCreate={renderListItemCreate}
		/>
	);

	const renderItem: RenderItem = (
		{ id, level, isInTransition, canCreateChildOverride = true, depth = 0 },
		{ parentId, backgroundColor, isParent, isExpanded, ariaControls },
		index,
		isVirtual = false,
	) => {
		if (isVirtual) {
			return (
				<>
					<ListItemLite
						key={`list-item-lite-${id}`}
						id={id}
						depth={depth}
						itemHeight={itemHeight}
						backgroundColor={backgroundColor}
						renderListItemContent={renderListItemContent}
						renderListItemSecondaryContent={renderListItemSecondaryContent}
					/>
					<ChartItemLite
						id={id}
						key={`chart-item-lite-${id}`}
						depth={depth}
						itemHeight={itemHeight}
						backgroundColor={backgroundColor}
						renderChartItemContent={renderChartItemContent}
					/>
				</>
			);
		}

		const canCreateChild = canItemCreateChild({
			id,
			createItemAnchor,
			isInTransition,
			isCreateChildEnabled,
			canCreateChildOverride,
		});

		const canCreateSibling = canItemCreateSibling({
			id,
			index,
			depth,
			createItemAnchor,
			isInTransition,
			isCreateChildEnabled,
			isCreateSiblingEnabled,
		});

		return (
			<>
				<ListItem
					ariaControls={ariaControls}
					key={`list-item-${id}`}
					id={id}
					parentId={parentId}
					level={level}
					depth={depth}
					itemHeight={itemHeight}
					backgroundColor={backgroundColor}
					isParent={isParent}
					isExpanded={isExpanded}
					isDragEnabled={isDragEnabled}
					isInTransition={isInTransition}
					isSelectEnabled={isSelectEnabled}
					isCreateChildEnabled={canCreateChild}
					isCreateSiblingEnabled={canCreateSibling}
					isMeatballsButtonEnabled={isMeatballsButtonEnabled}
					onSelect={onSelect}
					onDrop={onDrop}
					onExpandChanged={onExpandChanged}
					onInlineCreateClicked={onCreateClick}
					renderListItemContent={renderListItemContent}
					renderListItemSecondaryContent={renderListItemSecondaryContent}
					renderListItemMenuOption={renderListItemMenuOption}
					renderListItemModal={renderListItemModal}
				/>
				<ChartItem
					id={id}
					key={`chart-item-${id}`}
					depth={depth}
					parentId={parentId}
					itemHeight={itemHeight}
					backgroundColor={backgroundColor}
					renderChartItemContent={renderChartItemContent}
				/>
			</>
		);
	};

	const renderItemContainer: RenderItemContainer = (
		{
			parentId,
			isExpanded,
			isParent,
			id,
			level,
			depth = 0,
			topOffset,
			ariaRowIndex,
			childItemsHeight,
			isInTransition,
		},
		_,
		children,
	) => (
		<DropTarget
			id={id}
			key={`drop-target-${id}`}
			ariaRowIndex={ariaRowIndex}
			topOffset={topOffset}
			level={level}
			depth={depth}
			parentId={parentId}
			isExpanded={isExpanded}
			isParent={isParent}
			itemHeight={itemHeight}
			childItemsHeight={childItemsHeight}
			isInTransition={isInTransition}
			onDrop={onDrop}
			onExpandChanged={onExpandChanged}
		>
			{children}
		</DropTarget>
	);

	useResizeObserver(contentContainerRef.current, onContentResize);

	return (
		<UFOSegment name="timeline-table-content">
			<div role="rowgroup" ref={contentContainerRef} css={contentContainerStyles}>
				<ItemsBackground />
				<ItemsRenderer
					renderItem={renderItem}
					renderItemContainer={renderItemContainer}
					renderCreateItem={renderCreateItem}
				/>
			</div>
			<Footer renderListEmptyContent={renderListEmptyContent} />
			<ScrollableOverlay renderChartTimelineOverlay={renderChartTimelineOverlay} />
			{isResizeEnabled && (
				<ListResizer DndLongTaskMetric={DndLongTaskMetric} onSetListWidth={onSetListWidth} />
			)}
		</UFOSegment>
	);
};

Content.defaultProps = {
	isResizeEnabled: true,
	onItemDrop: noop,
	onItemSelect: noop,
	onItemExpandChanged: noop,
	onCreateClick: noop,
	onSetListWidth: noop,
	onContentResize: noop,
};

Content.displayName = 'Content';
Content.whyDidYouRender = true;

export default memo<Props>(Content);
