import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/catch';
import type { Epic, ActionsObservable } from 'redux-observable';
import type { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log';
import { captureException } from './sentry';

/*
 * Catches errors within the epic middleware and makes them available to monitoring.
 *
 * Takes an optional handler as a parameter that can be executed when an error is encountered.
 * This handler returns an observable.
 * This could be used to show an error flag, for example, but will replace attempting to continue
 * the current observable stream.
 *
 * Example usage, which could be applied to the root epic:
 * errorHandlerEpicMiddleware('your-app-name', 'root', combineEpics(epicOne, epicTwo));
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default <
		S,
		A extends {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			type: any;
		},
		D,
	>(
		appId: string,
		location: string,

		epic: Epic<A, S>,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		errorHandler?: (error: any) => Observable<A>,
	): Epic<A, S> =>
	(action$: ActionsObservable<A>, store: MiddlewareAPI<S>, deps: D) =>
		epic(action$, store, deps).catch((error, stream) => {
			const fullLocation = `common.epic-error.${appId}.${location}`;

			log.safeErrorWithoutCustomerData(fullLocation, 'Unhandled error in epic', error);
			captureException(fullLocation, error);

			return (errorHandler && errorHandler(error)) || stream;
		});
