/** @jsx jsx */
import React, {
	memo,
	useCallback,
	useEffect,
	useRef,
	useState,
	type FocusEvent,
	type MouseEvent,
	type KeyboardEvent,
} from 'react';
import { css, jsx } from '@compiled/react';
import { useIntl } from 'react-intl-next';
import { Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { B100 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import {
	BASE_LEVEL_ITEM_HEIGHT,
	LIST_COLUMN_COORDINATE,
	zIndex,
} from '../../../../common/constants';
import { useSideEffectMarshal, useListWidth, useIsItemSelected } from '../../../../common/context';
import { getRowClassName } from '../../../../common/context/side-effect-marshal';
import { useGridCell } from '../../../../common/context/side-effect-marshal/focus-marshal/use-grid-cell';
import { getListContentPaddingLeft } from '../../../../common/styled';
import type {
	ItemId,
	ItemDropPayload,
	ItemSelectPayload,
	OnCreateClick,
	OnItemExpandChanged,
} from '../../../../common/types';
import { isEnterOrSpaceKey, isBubbledEvent } from '../../../../common/utils';
import type {
	RenderListItemContent,
	RenderListItemSecondaryContent,
	RenderListItemMenuOption,
	RenderListItemModal,
} from '../../../../renderers';
import CreateChildButton from './create-child-button';
import CreateSiblingButton from './create-sibling-button';
import ExpandButton from './expand-button';
import MeatBallsButton from './meatballs-button';
import messages from './messages';
import useListItemDrag from './use-drag';

const secondaryContentStyles = css({
	display: 'inline-flex',
	alignItems: 'center',
	marginLeft: token('space.050', '4px'),
});

const listItemContainerStyles = css({
	position: 'sticky',
	left: 0,
	display: 'flex',
	alignItems: 'center',
	boxSizing: 'border-box',
	paddingRight: token('space.100', '8px'),
	transition: 'background-color 100ms linear',
	'&:focus': {
		outline: 'none',
	},
	'&:focus-visible': {
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused', B100)}`,
	},
});

const getCursor = (isInTransition: boolean, isSelectEnabled: boolean) => {
	if (isInTransition) {
		return 'not-allowed';
	}

	if (isSelectEnabled) {
		return 'pointer';
	}

	return 'default';
};

export type Props = {
	isParent: boolean;
	isExpanded: boolean;
	isDragEnabled: boolean;
	isInTransition: boolean;
	isSelectEnabled: boolean;
	isCreateChildEnabled: boolean;
	isCreateSiblingEnabled: boolean;
	isMeatballsButtonEnabled: boolean;
	id: ItemId;
	parentId: ItemId | undefined;
	level: number;
	depth: number;
	itemHeight: number;
	backgroundColor: string;
	ariaControls?: string;
	renderListItemContent: RenderListItemContent;
	renderListItemSecondaryContent: RenderListItemSecondaryContent;
	// TODO: Remove the undefined type once the create child button deprecated
	renderListItemMenuOption: RenderListItemMenuOption | undefined;
	renderListItemModal: RenderListItemModal | undefined;
	onSelect: (id: ItemId, meta: ItemSelectPayload) => unknown;
	onExpandChanged: OnItemExpandChanged;
	onInlineCreateClicked: OnCreateClick;
	onDrop: (id: ItemId, payload: ItemDropPayload) => void;
};

const ListItem = ({
	id,
	parentId,
	level,
	depth,
	itemHeight,
	backgroundColor,
	ariaControls,
	isParent,
	isExpanded,
	isDragEnabled,
	isInTransition,
	isSelectEnabled,
	isCreateChildEnabled,
	isCreateSiblingEnabled,
	isMeatballsButtonEnabled,
	onSelect,
	onDrop,
	onExpandChanged,
	onInlineCreateClicked,
	renderListItemContent,
	renderListItemSecondaryContent,
	renderListItemMenuOption,
	renderListItemModal,
}: Props) => {
	const { formatMessage } = useIntl();
	const sideEffectMarshal = useSideEffectMarshal();
	const [listWidth] = useListWidth();
	const [isHoveredOrFocused, setIsHoveredOrFocused] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const isContainerInFocus = useRef<boolean>(false);
	const element = useRef<HTMLElement | null>(null);
	const cellRef = useGridCell(id, LIST_COLUMN_COORDINATE);

	const [isItemSelected] = useIsItemSelected(id);

	const { onRowMouseEnter, onRowMouseLeave } = sideEffectMarshal;
	const canDrag = isDragEnabled && !isInTransition;
	const [makeDraggable, cleanupDraggable] = useListItemDrag({
		id,
		level,
		depth,
		parentId,
		canDrag,
		sideEffectMarshal,
	});

	// Effects //

	useEffect(() => {
		makeDraggable(element.current);
		return () => {
			// drag connections will be unsubscribed if NULL was passed to drag handler.
			cleanupDraggable();
		};
	}, [makeDraggable, cleanupDraggable]);

	// Callbacks //

	const onHoverOrFocus = useCallback(() => {
		onRowMouseEnter(id);
		setIsHoveredOrFocused(true);
	}, [id, onRowMouseEnter]);

	const onStopHoverOrFocus = useCallback(() => {
		onRowMouseLeave(id);
		setIsMenuOpen(false);
		setIsHoveredOrFocused(false);
	}, [id, onRowMouseLeave]);

	const onContainerBlurOld = useCallback(
		({ relatedTarget }: FocusEvent<HTMLDivElement>) => {
			if (!element.current?.contains(relatedTarget) && !isContainerInFocus.current) {
				onStopHoverOrFocus();
			}

			isContainerInFocus.current = false;
		},
		[onStopHoverOrFocus],
	);

	const onContainerBlur = useCallback(
		({ relatedTarget }: FocusEvent<HTMLDivElement>) => {
			if (!element.current?.contains(relatedTarget)) {
				onStopHoverOrFocus();
			}
		},
		[onStopHoverOrFocus],
	);

	const onContainerClick = useCallback(
		(event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
			const { timeStamp, metaKey, ctrlKey, shiftKey } = event;
			traceUFOPress('timeline-item-clicked', timeStamp);

			const withCmd = ctrlKey || metaKey;
			const withShift = shiftKey;

			onSelect(id, {
				level,
				withCmd,
				withShift,
				depth,
			});
		},
		[id, level, depth, onSelect],
	);

	const onContainerMouseLeave = useCallback(() => {
		if (!element.current?.contains(document.activeElement)) {
			onStopHoverOrFocus();
		}
	}, [onStopHoverOrFocus]);

	const onContainerMouseDown = useCallback(() => {
		isContainerInFocus.current = true;
	}, []);

	const onContainerKeyDown = useCallback(
		(event: KeyboardEvent<HTMLDivElement>) => {
			if (isEnterOrSpaceKey(event) && !isBubbledEvent(event)) {
				// Stops default behaviour of scrolling the parent container
				event.preventDefault();
				onContainerClick(event);
			}
		},
		[onContainerClick],
	);

	const setItemRef = useCallback(
		(ref: HTMLElement | null) => {
			element.current = ref;
			cellRef(ref);
		},
		[cellRef],
	);

	// Render //

	const rendererProps = { id };
	const rendererState = { isVirtual: false, isHoveredOrFocused };

	const renderSecondaryContent = () => {
		if (isInTransition) {
			return (
				<div css={secondaryContentStyles}>
					<Spinner
						size="small"
						testId={`roadmap.timeline-table.components.list-item.spinner.${id}`}
					/>
				</div>
			);
		}

		return (
			<div css={secondaryContentStyles}>
				{renderListItemSecondaryContent(rendererProps, rendererState)}
				{renderListItemMenuOption && isMeatballsButtonEnabled && (
					<MeatBallsButton
						isTriggerVisible={isHoveredOrFocused}
						isMenuOpen={isMenuOpen}
						id={id}
						level={level}
						depth={depth}
						parentId={parentId}
						isCreateChildEnabled={isCreateChildEnabled}
						isCreateSiblingEnabled={isCreateSiblingEnabled}
						renderListItemMenuOption={renderListItemMenuOption}
						renderListItemModal={renderListItemModal}
						onStopHoverOrFocus={onStopHoverOrFocus}
						onInlineCreateClicked={onInlineCreateClicked}
						onDrop={onDrop}
						setIsMenuOpen={setIsMenuOpen}
						cellRef={element}
					/>
				)}
			</div>
		);
	};

	const renderSecondaryContentOld = () => {
		let innerElement;

		if (isInTransition) {
			innerElement = (
				<Spinner
					size="small"
					testId={`roadmap.timeline-table.components.list-item.spinner.${id}`}
				/>
			);
		} else if (isCreateChildEnabled) {
			innerElement = isHoveredOrFocused ? (
				<CreateChildButton
					parentId={id}
					label={formatMessage(messages.createChild)}
					onInlineCreateClicked={onInlineCreateClicked}
				/>
			) : null;
		}
		// Consumer secondary content should only be visible when internal secondary content is not
		if (innerElement) {
			return <div css={secondaryContentStyles}>{innerElement}</div>;
		}

		return renderListItemSecondaryContent(rendererProps, rendererState);
	};

	const height = depth > 0 ? BASE_LEVEL_ITEM_HEIGHT : itemHeight;
	return (
		<div
			id={id}
			data-testid={`roadmap.timeline-table.components.list-item.container-${id}`}
			role="gridcell"
			tabIndex={-1}
			ref={setItemRef}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={getRowClassName(id, parentId).className}
			css={listItemContainerStyles}
			style={{
				width: `${listWidth}px`,
				height: `${height}px`,
				backgroundColor,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				paddingLeft: `${getListContentPaddingLeft({ isParent, depth })}px`,
				zIndex:
					isMenuOpen && fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y')
						? zIndex.PORTAL // eslint-disable-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						: zIndex.LIST, // eslint-disable-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				cursor: getCursor(isInTransition, isSelectEnabled),
			}}
			onClick={isSelectEnabled && !isInTransition ? onContainerClick : undefined}
			onKeyDown={isSelectEnabled && !isInTransition ? onContainerKeyDown : undefined}
			onMouseEnter={onHoverOrFocus}
			onMouseLeave={
				fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y')
					? onContainerMouseLeave
					: onStopHoverOrFocus
			}
			{...(!fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y') && {
				onMouseDown: onContainerMouseDown,
			})}
			onFocus={onHoverOrFocus}
			onBlur={
				fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y') ? onContainerBlur : onContainerBlurOld
			}
		>
			{isItemSelected && isMenuOpen && fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y') ? (
				// Applies a border overlay to the currently selected item if the meatballs menu is open.
				// This is required as we increase the z-index of this list item to the PORTAL layer to
				// fix overlapping issues in the timeline.
				<Box xcss={borderOverlayStyles} />
			) : null}
			{isCreateSiblingEnabled && (
				<CreateSiblingButton
					id={id}
					level={level}
					depth={depth}
					parentId={parentId}
					label={formatMessage(messages.createSibling)}
					onInlineCreateClicked={onInlineCreateClicked}
				/>
			)}
			{isParent && (
				<ExpandButton
					id={id}
					label={isExpanded ? formatMessage(messages.collapse) : formatMessage(messages.expand)}
					isExpanded={isExpanded}
					isRichContentEnabled={isHoveredOrFocused}
					onExpandChanged={onExpandChanged}
					ariaControls={ariaControls}
				/>
			)}
			{renderListItemContent(rendererProps, rendererState)}
			{fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y')
				? renderSecondaryContent()
				: renderSecondaryContentOld()}
		</div>
	);
};

const borderOverlayStyles = xcss({
	width: '100%',
	height: '100%',
	position: 'absolute',
	top: '0',
	left: '0',
	pointerEvents: 'none',
	borderLeft: `1px solid ${token('color.border')}`,
	borderRight: `2px solid ${token('color.border')}`,
});

ListItem.defaultProps = {
	isInTransition: false,
};

ListItem.displayName = 'ListItem';
ListItem.whyDidYouRender = true;

export default memo<Props>(ListItem);
