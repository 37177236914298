import { defineSimpleStorage } from '@atlassian/jira-common-storage-redux/src';
import { isDependenciesVisible } from '../../../state/selectors/filters';
import type { State } from '../../../state/types';
import { setDependenciesViewVisibility } from '../../../state/ui/views/actions';

export default defineSimpleStorage<State, boolean | null>(
	() => 'isDependenciesVisible',
	(isVisible: boolean | null) =>
		isVisible === null ? [] : [setDependenciesViewVisibility(isVisible)],
	(state) => isDependenciesVisible(state),
);
