import { combineReducers } from 'redux';
import app from './app/reducer';
import configuration from './configuration/reducer';
import entities from './entities/reducer';
import metadata from './metadata/reducer';
import router from './router/reducer';
import settings from './settings/reducer';
import ui from './ui/reducer';

export default combineReducers({
	app,
	configuration,
	entities,
	ui,
	router,
	settings,
	metadata,
});
