import { WEEKS, MONTHS, QUARTERS } from '@atlassian/jira-software-roadmap-timeline-table';
import type { Action } from '@atlassian/react-sweet-state';
import { INTERVAL } from '../../../../../common/constants';
import type { State, ContainerProps } from '../../common/types';
import { getTargetIntervalId, getGridSize, snapToGrid } from '../../common/utils';

const TIMELINE_MODE_OFFSET = {
	[WEEKS]: 7,
	[MONTHS]: 14,
	[QUARTERS]: 30,
} as const;

export type OnPreviewAction = (previewX: number) => Action<State, ContainerProps>;

/* Previewing a chart-item takes place when they have no dates, and the user intends to create them.
 * Based upon the users cursor position, we determine matching left and right positions to display for the item.
 */
export const onPreview: OnPreviewAction =
	(previewX) =>
	({ getState, setState }, { timelineMode, timelineDuration, timelineWidth, simpleIntervals }) => {
		const { activeItem, dragType } = getState();

		// Dragging interactions have precedence (interactions should be mutually exclusive)
		if (!activeItem || (activeItem && dragType)) {
			return;
		}
		const isInterval = activeItem.startDateType === INTERVAL && activeItem.dueDateType === INTERVAL;
		let leftPosition;
		let rightPosition;
		let targetIntervalId;
		const gridSize = getGridSize(timelineDuration, timelineWidth);
		const leftPositionSnapToGrid = snapToGrid(0, gridSize, previewX);

		if (!isInterval) {
			const rightOffset = TIMELINE_MODE_OFFSET[timelineMode] * gridSize;

			leftPosition = leftPositionSnapToGrid;
			rightPosition = timelineWidth - (leftPosition + rightOffset);
		} else {
			/**
			 * An item will not have mixed date types when one side is INTERVAL.
			 * When startDateType is INTERVAL, it's dueDateType will be INTERVAL, too.
			 */
			targetIntervalId = getTargetIntervalId(simpleIntervals, timelineWidth, previewX);
		}

		setState({
			itemPositions: {
				[`${activeItem.id}`]: {
					leftPosition,
					rightPosition,
				},
			},
			targetIntervalId,
		});
	};
