import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	bulkEditDates: {
		id: 'roadmap.standard-roadmap.common.table.list-item-menu-option.bulk-edit-dates-option.bulk-edit-dates',
		defaultMessage: 'Edit dates',
		description: 'Label for bulk edit dates option',
	},
});

export default messages;
