import { Component } from 'react';
import type { IssueId } from '@atlassian/jira-shared-types';
import type { DataProvider } from '../../../model';
import { WithDataProvider } from '../../context';

export type Props = {
	issueIds: IssueId[];
	issueKeys?: string[];
};

type OwnProps = Props & {
	dataProvider: DataProvider;
};

// eslint-disable-next-line jira/react/no-class-components
class Prefetch extends Component<OwnProps> {
	constructor(props: OwnProps) {
		super(props);
		this.fetchIssues(props.issueIds, props.issueKeys);
	}

	componentDidUpdate({ issueIds: prevIssueIds, issueKeys: prevIssueKeys }: OwnProps) {
		const { issueIds, issueKeys } = this.props;
		if (prevIssueIds !== issueIds) {
			const newIds = issueIds.filter((issueId) => {
				const isTemporaryId = Number(issueId) < 0;
				return !prevIssueIds.includes(issueId) && !isTemporaryId;
			});
			this.fetchIssues(newIds);
		}

		if (issueKeys && prevIssueKeys && prevIssueKeys !== issueKeys) {
			this.fetchIssues([], issueKeys);
		}
	}

	fetchIssues(issueIds: IssueId[], issueKeys?: string[]) {
		if (issueIds.length > 0) {
			this.props.dataProvider.loadIssuesById(issueIds.map((id) => String(id)));
		}
		if (issueKeys && issueKeys.length > 0) {
			this.props.dataProvider.loadIssues(issueKeys);
		}
	}

	render() {
		return null;
	}
}

// @ts-expect-error - TS2559 - Type 'Props' has no properties in common with type '{ readonly dataProvider?: DataProvider | undefined; }'.
const PrefetchWithDataProvider = WithDataProvider<Props>(Prefetch);

export { PrefetchWithDataProvider as Prefetch };
