export const isDependencyDatesOverlapping = (
	dependencyStartDate = -1,
	dependencyDueDate = -1,
	dependeeStartDate = -1,
	dependeeDueDate = -1,
): boolean => {
	// happy case where dates are defined
	if (dependencyDueDate > -1 && dependeeStartDate > -1) {
		return dependencyDueDate > dependeeStartDate;
	}

	// no dates on either dependency or dependee
	if (
		(dependencyStartDate === -1 && dependencyDueDate === -1) ||
		(dependeeStartDate === -1 && dependeeDueDate === -1)
	) {
		return false;
	}

	// only start date on dependency and due date on dependee
	if (dependencyDueDate === -1 && dependeeStartDate === -1) {
		return dependencyStartDate > dependeeDueDate;
	}

	// due date of dependency and due date of dependee
	if (dependencyDueDate !== -1) {
		return dependencyDueDate > dependeeDueDate;
	}

	// start date of dependency and start date of dependee
	if (dependencyStartDate !== -1) {
		return dependencyStartDate > dependeeStartDate;
	}

	// impossible case
	return false;
};
