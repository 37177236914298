import { defineMessages } from 'react-intl-next';

const messages = defineMessages({
	createItem: {
		id: 'roadmap.timeline-table.main.content.list.list-item.meatballs-button.meatball-popup.item-section-options.create-item-menu.create-item',
		defaultMessage: 'Create',
		description: 'Label for the create option in the list item menu',
	},
	createChildItem: {
		id: 'roadmap.timeline-table.main.content.list.list-item.meatballs-button.meatball-popup.item-section-options.create-item-menu.create-child-item',
		defaultMessage: 'Create child',
		description: 'Label for the create child option in the list item menu',
	},
	createItemAbove: {
		id: 'roadmap.timeline-table.main.content.list.list-item.meatballs-button.meatball-popup.item-section-options.create-item-menu.create-item-above',
		defaultMessage: 'Create above',
		description: 'Label for the create above option in the list item menu',
	},
	createItemBelow: {
		id: 'roadmap.timeline-table.main.content.list.list-item.meatballs-button.meatball-popup.item-section-options.create-item-menu.create-item-below',
		defaultMessage: 'Create below',
		description: 'Label for the create below option in the list item menu',
	},
});

export default messages;
