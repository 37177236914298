import {
	type LevelOneSetting,
	DEFAULT_LEVEL_ONE_VIEW_SETTINGS,
} from '@atlassian/jira-software-roadmap-model';
// set dependencies view visibility
export const SET_DEPENDENCIES_VIEW_VISIBILITY =
	'state.ui.views.SET_DEPENDENCIES_VIEW_VISIBILITY' as const;

export type SetDependenciesViewVisibilityAction = {
	type: typeof SET_DEPENDENCIES_VIEW_VISIBILITY;
	payload: boolean;
};

export const setDependenciesViewVisibility = (
	payload: boolean,
): SetDependenciesViewVisibilityAction => ({
	type: SET_DEPENDENCIES_VIEW_VISIBILITY,
	payload,
});

// set progress visibility
export const SET_PROGRESS_VISIBILITY = 'state.ui.views.SET_PROGRESS_VISIBILITY' as const;

export type SetProgressVisibilityAction = {
	type: typeof SET_PROGRESS_VISIBILITY;
	payload: boolean;
};

export const setProgressVisibility = (payload: boolean): SetProgressVisibilityAction => ({
	type: SET_PROGRESS_VISIBILITY,
	payload,
});

// set warnings visibility
export const SET_WARNINGS_VISIBILITY = 'state.ui.views.SET_WARNINGS_VISIBILITY' as const;

export type SetWarningsVisibilityAction = {
	type: typeof SET_WARNINGS_VISIBILITY;
	payload: boolean;
};

export const setWarningsVisibility = (payload: boolean): SetWarningsVisibilityAction => ({
	type: SET_WARNINGS_VISIBILITY,
	payload,
});

// set releases visibility
export const SET_RELEASES_VISIBILITY = 'state.ui.views.SET_RELEASES_VISIBILITY' as const;

export type SetReleasesVisibilityAction = {
	type: typeof SET_RELEASES_VISIBILITY;
	payload: boolean;
};

export const setReleasesVisibility = (payload: boolean): SetReleasesVisibilityAction => ({
	type: SET_RELEASES_VISIBILITY,
	payload,
});

// set level one setting
export const SET_LEVEL_ONE_SETTING = 'state.ui.views.SET_LEVEL_ONE_SETTING' as const;

export type SetLevelOneSettingAction = {
	type: typeof SET_LEVEL_ONE_SETTING;
	payload: LevelOneSetting;
};

export const setLevelOneSetting = (payload: LevelOneSetting): SetLevelOneSettingAction => ({
	type: SET_LEVEL_ONE_SETTING,
	payload,
});

// clear level one setting (a convenience wrapper around set level one setting)

export const clearLevelOneSetting = (): SetLevelOneSettingAction => ({
	type: SET_LEVEL_ONE_SETTING,
	payload: DEFAULT_LEVEL_ONE_VIEW_SETTINGS,
});

export type ClearLevelOneSettingAction = SetLevelOneSettingAction;
