import React, { useCallback, type MouseEvent, type KeyboardEvent } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import messages from './messages';
import type { Props } from './types';

const EditDatesOption = ({ isDateEditable, Option, setOpenModal }: Props) => {
	const { formatMessage } = useIntl();

	const onClick = useCallback(
		(event: MouseEvent | KeyboardEvent) => {
			event.preventDefault();
			traceUFOPress('timeline-edit-dates-option-clicked', event.timeStamp);
			setOpenModal(event);
		},
		[setOpenModal],
	);

	return (
		<Option
			isDisabled={!isDateEditable}
			label={formatMessage(messages.editDates)}
			onClick={onClick}
		/>
	);
};

export default EditDatesOption;
