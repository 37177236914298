import {
	BULK_CHANGE_EXPANSION_STATE,
	type BulkChangeExpansionStateAction,
	CHANGE_EXPANSION_STATE,
	type ChangeExpansionStateAction,
	HIDE_TOOLTIP,
	type HideTooltipAction,
	RESET_CREATE_ITEM_ANCHOR,
	type ResetCreateItemAnchorAction,
	SET_CREATE_ITEM_ANCHOR,
	SET_SELECTED_ITEMS,
	SET_SELECTION_ANCHORS,
	type SetCreateItemAnchorAction,
	type SetSelectedItemIdsAction,
	type SetSelectionAnchorsAction,
	SHOW_TOOLTIP,
	type ShowToolTipAction,
} from './actions';
import type { TableState } from './types';

type Action =
	| SetCreateItemAnchorAction
	| ResetCreateItemAnchorAction
	| ChangeExpansionStateAction
	| BulkChangeExpansionStateAction
	| ShowToolTipAction
	| HideTooltipAction
	| SetSelectedItemIdsAction
	| SetSelectionAnchorsAction;

const defaultState: TableState = {
	createItemAnchor: undefined,
	expandedItems: {},
	selectedItemIds: [],
	selectionAnchors: [],
	tooltip: undefined,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: TableState = defaultState, action: Action): TableState => {
	switch (action.type) {
		case SET_CREATE_ITEM_ANCHOR:
			return {
				...state,
				expandedItems: {
					...state.expandedItems,
					...(action.payload && 'parentId' in action.payload && action.payload.parentId
						? { [action.payload.parentId]: true }
						: {}),
				},
				createItemAnchor: action.payload,
			};

		case RESET_CREATE_ITEM_ANCHOR:
			return {
				...state,
				createItemAnchor: undefined,
			};

		case CHANGE_EXPANSION_STATE:
			return {
				...state,
				expandedItems: {
					...state.expandedItems,
					[action.payload.id]: action.payload.isExpanded,
				},
			};

		case BULK_CHANGE_EXPANSION_STATE:
			return {
				...state,
				expandedItems: {
					...state.expandedItems,
					...Object.fromEntries(action.payload.ids.map((id) => [id, action.payload.isExpanded])),
				},
			};

		case SHOW_TOOLTIP:
			return {
				...state,
				tooltip: action.payload,
			};

		case HIDE_TOOLTIP:
			return {
				...state,
				tooltip: undefined,
			};

		case SET_SELECTED_ITEMS:
			return {
				...state,
				selectedItemIds: action.payload,
			};

		case SET_SELECTION_ANCHORS:
			return {
				...state,
				selectionAnchors: action.payload,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
