/**
 * @generated SignedSource<<505aa4f805ebe71f3f28cccdd8184b4b>>
 * @relayHash 78dd425f98792f38e9f6e8424a58c087
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3758129448547311f08b77e2bed6324551c5cffddb448486c6a3fe20ba22e4cb

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type RoadmapPaletteColor = "BLUE" | "DARK_BLUE" | "DARK_GREEN" | "DARK_GREY" | "DARK_ORANGE" | "DARK_PURPLE" | "DARK_TEAL" | "DARK_YELLOW" | "GREEN" | "GREY" | "ORANGE" | "PURPLE" | "TEAL" | "YELLOW" | "%future added value";
export type RoadmapAddItemInput = {
  assignee?: string | null | undefined;
  color?: RoadmapPaletteColor | null | undefined;
  componentIds?: ReadonlyArray<string> | null | undefined;
  dueDate?: AGG$Date | null | undefined;
  itemTypeId: string;
  jql?: string | null | undefined;
  jqlContexts?: ReadonlyArray<string> | null | undefined;
  labels?: ReadonlyArray<string> | null | undefined;
  parentId?: string | null | undefined;
  projectId: string;
  rank?: RoadmapAddItemRank | null | undefined;
  startDate?: AGG$Date | null | undefined;
  summary: string;
  versionIds?: ReadonlyArray<string> | null | undefined;
};
export type RoadmapAddItemRank = {
  beforeId?: string | null | undefined;
};
export type addRoadmapItemMutation$variables = {
  input: RoadmapAddItemInput;
  isRoadmapsCriticalDataItemFlaggedEnabled: boolean;
  sourceARI: string;
};
export type addRoadmapItemMutation$data = {
  readonly roadmaps: {
    readonly addRoadmapItem: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly errorType?: string | null | undefined;
          readonly statusCode?: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly output: {
        readonly id: string;
        readonly item: {
          readonly assignee: {
            readonly accountId: string;
            readonly name: string;
            readonly picture: AGG$URL;
          } | null | undefined;
          readonly color: RoadmapPaletteColor | null | undefined;
          readonly componentIds: ReadonlyArray<string> | null | undefined;
          readonly dependencies: ReadonlyArray<string> | null | undefined;
          readonly dueDateRFC3339: AGG$Date | null | undefined;
          readonly flagged?: boolean | null | undefined;
          readonly id: string;
          readonly itemTypeId: AGG$Long;
          readonly key: string;
          readonly labels: ReadonlyArray<string> | null | undefined;
          readonly parentId: string | null | undefined;
          readonly rank: string | null | undefined;
          readonly resolved: boolean | null | undefined;
          readonly sprintIds: ReadonlyArray<string> | null | undefined;
          readonly startDateRFC3339: AGG$Date | null | undefined;
          readonly status: {
            readonly id: string;
            readonly name: string | null | undefined;
            readonly statusCategory: {
              readonly id: string;
            } | null | undefined;
          } | null | undefined;
          readonly summary: string | null | undefined;
          readonly versionIds: ReadonlyArray<string> | null | undefined;
        } | null | undefined;
        readonly key: string;
        readonly matchesJqlFilters: boolean;
        readonly matchesSource: boolean;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type addRoadmapItemMutation = {
  response: addRoadmapItemMutation$data;
  variables: addRoadmapItemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isRoadmapsCriticalDataItemFlaggedEnabled"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceARI"
},
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  },
  {
    "kind": "Variable",
    "name": "sourceARI",
    "variableName": "sourceARI"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "success"
},
v5 = {
  "kind": "ScalarField",
  "name": "message"
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "statusCode"
    },
    {
      "kind": "ScalarField",
      "name": "errorType"
    }
  ],
  "type": "RoadmapMutationErrorExtension"
},
v7 = {
  "kind": "ScalarField",
  "name": "id"
},
v8 = {
  "kind": "ScalarField",
  "name": "key"
},
v9 = {
  "kind": "ScalarField",
  "name": "matchesSource"
},
v10 = {
  "kind": "ScalarField",
  "name": "matchesJqlFilters"
},
v11 = {
  "kind": "ScalarField",
  "name": "parentId"
},
v12 = {
  "kind": "ScalarField",
  "name": "summary"
},
v13 = {
  "kind": "ScalarField",
  "name": "startDateRFC3339"
},
v14 = {
  "kind": "ScalarField",
  "name": "dueDateRFC3339"
},
v15 = {
  "kind": "ScalarField",
  "name": "color"
},
v16 = {
  "kind": "ScalarField",
  "name": "dependencies"
},
v17 = {
  "kind": "ScalarField",
  "name": "itemTypeId"
},
v18 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v19 = {
  "kind": "ScalarField",
  "name": "name"
},
v20 = {
  "kind": "ScalarField",
  "name": "picture"
},
v21 = [
  (v7/*: any*/)
],
v22 = {
  "concreteType": "RoadmapItemStatus",
  "kind": "LinkedField",
  "name": "status",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v19/*: any*/),
    {
      "concreteType": "RoadmapItemStatusCategory",
      "kind": "LinkedField",
      "name": "statusCategory",
      "plural": false,
      "selections": (v21/*: any*/)
    }
  ]
},
v23 = {
  "kind": "ScalarField",
  "name": "rank"
},
v24 = {
  "kind": "ScalarField",
  "name": "labels"
},
v25 = {
  "kind": "ScalarField",
  "name": "sprintIds"
},
v26 = {
  "kind": "ScalarField",
  "name": "versionIds"
},
v27 = {
  "kind": "ScalarField",
  "name": "componentIds"
},
v28 = {
  "kind": "ScalarField",
  "name": "resolved"
},
v29 = {
  "condition": "isRoadmapsCriticalDataItemFlaggedEnabled",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "flagged"
    }
  ]
},
v30 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "addRoadmapItemMutation",
    "selections": [
      {
        "concreteType": "RoadmapsMutation",
        "kind": "LinkedField",
        "name": "roadmaps",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "RoadmapAddItemPayload",
            "kind": "LinkedField",
            "name": "addRoadmapItem",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ]
                  }
                ]
              },
              {
                "concreteType": "RoadmapAddItemResponse",
                "kind": "LinkedField",
                "name": "output",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "concreteType": "RoadmapItem",
                    "kind": "LinkedField",
                    "name": "item",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "assignee",
                        "plural": false,
                        "selections": [
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/)
                        ]
                      },
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/),
                      (v25/*: any*/),
                      (v26/*: any*/),
                      (v27/*: any*/),
                      (v28/*: any*/),
                      (v29/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "addRoadmapItemMutation",
    "selections": [
      {
        "concreteType": "RoadmapsMutation",
        "kind": "LinkedField",
        "name": "roadmaps",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "RoadmapAddItemPayload",
            "kind": "LinkedField",
            "name": "addRoadmapItem",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v30/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v21/*: any*/),
                        "type": "BulkMutationErrorExtension"
                      }
                    ]
                  }
                ]
              },
              {
                "concreteType": "RoadmapAddItemResponse",
                "kind": "LinkedField",
                "name": "output",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "concreteType": "RoadmapItem",
                    "kind": "LinkedField",
                    "name": "item",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "assignee",
                        "plural": false,
                        "selections": [
                          (v30/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v7/*: any*/)
                        ]
                      },
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/),
                      (v25/*: any*/),
                      (v26/*: any*/),
                      (v27/*: any*/),
                      (v28/*: any*/),
                      (v29/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "3758129448547311f08b77e2bed6324551c5cffddb448486c6a3fe20ba22e4cb",
    "metadata": {},
    "name": "addRoadmapItemMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "5d55eaa73059fae775c21e07aebd6bbe";

export default node;
