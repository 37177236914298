/**
 * @generated SignedSource<<3c810cae4890c69f4255fd4ce5926752>>
 * @relayHash a71a4c37e44c87ca3d5208d8e4f39467
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 86d3d5d0c58fd2a26d1fd2f392bc3902793bfe94272758d1f721f1b99392b72b

import type { ConcreteRequest, Query } from 'relay-runtime';
export type getIssueIdsQuery$variables = {
  sourceAri: string;
};
export type getIssueIdsQuery$data = {
  readonly roadmaps: {
    readonly roadmapForSource: {
      readonly content: {
        readonly items: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
            };
          } | null | undefined>;
        };
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type getIssueIdsQuery = {
  response: getIssueIdsQuery$data;
  variables: getIssueIdsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sourceAri"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "sourceARI",
    "variableName": "sourceAri"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "getIssueIdsQuery",
    "selections": [
      {
        "concreteType": "RoadmapsQuery",
        "kind": "LinkedField",
        "name": "roadmaps",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "RoadmapDetails",
            "kind": "LinkedField",
            "name": "roadmapForSource",
            "plural": false,
            "selections": [
              {
                "concreteType": "RoadmapContent",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "RoadmapItemConnection",
                    "kind": "LinkedField",
                    "name": "items",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": {
                          "concreteType": "RoadmapItemEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": {
                                "concreteType": "RoadmapItem",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": (v2/*: any*/),
                                    "action": "THROW",
                                    "path": "roadmaps.roadmapForSource.content.items.edges.node.id"
                                  }
                                ]
                              },
                              "action": "THROW",
                              "path": "roadmaps.roadmapForSource.content.items.edges.node"
                            }
                          ]
                        },
                        "action": "THROW",
                        "path": "roadmaps.roadmapForSource.content.items.edges"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getIssueIdsQuery",
    "selections": [
      {
        "concreteType": "RoadmapsQuery",
        "kind": "LinkedField",
        "name": "roadmaps",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "RoadmapDetails",
            "kind": "LinkedField",
            "name": "roadmapForSource",
            "plural": false,
            "selections": [
              {
                "concreteType": "RoadmapContent",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "RoadmapItemConnection",
                    "kind": "LinkedField",
                    "name": "items",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "RoadmapItemEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "RoadmapItem",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "86d3d5d0c58fd2a26d1fd2f392bc3902793bfe94272758d1f721f1b99392b72b",
    "metadata": {},
    "name": "getIssueIdsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ef97f4c0b11c66b5a686daf59be5fe4f";

export default node;
