import { combineEpics } from 'redux-observable';
import 'rxjs/add/observable/of';
import type { StateEpic } from '../common/types';
import initializeCriticalData from './critical-data';
import initializeDeriveFields from './derive-fields';
import initializeNonCriticalData from './non-critical-data';
import updateData from './update-data';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default combineEpics(
	initializeCriticalData,
	initializeNonCriticalData,
	initializeDeriveFields,
	updateData,
) as StateEpic;
