import type { SelectedDependency } from '@atlassian/jira-aais-dependency-lines-overlay/src/types';
import { fg } from '@atlassian/jira-feature-gating';
import type { MessageDescriptorV2 } from '@atlassian/jira-intl';
import type { IssueId, IssueKey, IssueTypeId } from '@atlassian/jira-shared-types';
import type { Hash, IssueTypeHash, Sprint } from '@atlassian/jira-software-roadmap-model';
import { BASE_LEVEL_ITEM_HEIGHT } from '@atlassian/jira-software-roadmap-timeline-table';
import {
	START_AND_DUE_DATE_PLACEHOLDER,
	START_DATE_PLACEHOLDER,
	DUE_DATE_PLACEHOLDER,
	DEPENDENCIES_FLYOUT_TYPE,
} from '@atlassian/jira-software-roadmap-timeline-table-kit';
import type {
	OverlayItem,
	DependencyItem,
	ImplicitDependencyItem,
	DateType,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/types';
import type { ExpandedItems } from '@atlassian/jira-software-roadmap-timeline-table/src/types';
import type { DependencyTooltipParams } from '../../../../model/dependencies';
import type { DependencyTooltip, TooltipParams } from '../../../../model/table';
import { getSprintNameForBaseLevelIssue } from '../../sprints';
import { getParentLevelDates, getBaseLevelDates, type ChartDataHash } from '../../table';
import type { RolledUpDates } from '../../table/dates';
import messages from '../messages';

const getWarningMessage = (isHiddenByView: boolean, isHidden: boolean) => {
	const warningMessages: Array<MessageDescriptorV2> = [];
	if (isHiddenByView) {
		warningMessages.push(messages.hiddenByViewsConfigurationWarning);
		return warningMessages;
	}
	if (isHidden) {
		warningMessages.push(messages.hiddenByFilterWarning);
		return warningMessages;
	}
	return warningMessages;
};

type DateData = {
	startDate: number | undefined;
	dueDate: number | undefined;
	startDateType: DateType;
	dueDateType: DateType;
};
// Exclude inferred placeholder dates
const getDateData = ({
	startDate,
	dueDate,
	startDateType,
	dueDateType,
	placeholder,
}: ReturnType<typeof getParentLevelDates>): DateData => ({
	startDate:
		placeholder === START_DATE_PLACEHOLDER || placeholder === START_AND_DUE_DATE_PLACEHOLDER
			? undefined
			: startDate,
	dueDate:
		placeholder === DUE_DATE_PLACEHOLDER || placeholder === START_AND_DUE_DATE_PLACEHOLDER
			? undefined
			: dueDate,
	startDateType,
	dueDateType,
});

export const getIssueAttributesHashPure = (
	issueIds: IssueId[],
	filteredIssueIdHash: Hash<boolean>,
	issueSummaries: Hash<string>,
	issueTypes: Hash<IssueTypeId>,
	fullIssueTypes: IssueTypeHash,
	issueDueDates: Hash<number | undefined>,
	issueStartDates: Hash<number | undefined>,
	issueKeyHash: Hash<IssueKey | undefined>,
	parentIdHash: Hash<IssueId | undefined>,
	issueSprintsHash: Hash<Sprint[]>,
	isSprintsPlanning: boolean,
	isChildIssuePlanningEnabled: boolean,
	parentRolledUpDatesHash: Hash<RolledUpDates | undefined>,
	isDependenciesVisible: boolean,
): Hash<OverlayItem> => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const attributesHash: Record<string, any> = {};

	issueIds.forEach((issueId) => {
		const isVisible = filteredIssueIdHash[issueId] ?? false;
		const issueType = fullIssueTypes[issueTypes[issueId]];
		const parentId = parentIdHash[issueId];
		const isChildIssue = parentId !== undefined;

		const startDate = issueStartDates[issueId];
		const dueDate = issueDueDates[issueId];

		if (!isChildIssue) {
			// parent item
			const parentDateData = getDateData(
				getParentLevelDates(
					startDate,
					dueDate,
					parentRolledUpDatesHash[issueId],
					isChildIssuePlanningEnabled,
				),
			);

			attributesHash[`${issueId}`] = {
				id: issueId,
				itemKey: issueKeyHash[issueId],
				title: issueSummaries[issueId],
				typeName: issueType ? issueType.name : '',
				iconUrl: issueType ? issueType.iconUrl : '',
				isHidden: !isVisible,
				warningMessages: getWarningMessage(!isDependenciesVisible, !isVisible),
				parent: undefined,
				intervalName: undefined,
				startDate: parentDateData.startDate,
				dueDate: parentDateData.dueDate,
				startDateType: parentDateData.startDateType,
				dueDateType: parentDateData.dueDateType,
			};
		} else {
			// child item
			const childDateData = getDateData(
				getBaseLevelDates(
					startDate,
					dueDate,
					issueSprintsHash[issueId],
					isSprintsPlanning,
					isChildIssuePlanningEnabled,
				),
			);

			const parent =
				parentId && issueTypes[parentId]
					? {
							iconUrl: fullIssueTypes[issueTypes[parentId]].iconUrl,
							title: issueSummaries[parentId],
						}
					: undefined;
			const sprintName =
				parentId !== undefined && isSprintsPlanning
					? getSprintNameForBaseLevelIssue(issueId, issueSprintsHash)
					: undefined;

			attributesHash[`${issueId}`] = {
				id: issueId,
				itemKey: issueKeyHash[issueId],
				title: issueSummaries[issueId],
				typeName: issueType ? issueType.name : '',
				iconUrl: issueType ? issueType.iconUrl : '',
				isHidden: !isVisible,
				warningMessages: getWarningMessage(!isDependenciesVisible, !isVisible),
				parent,
				intervalName: sprintName,
				startDate: childDateData.startDate,
				dueDate: childDateData.dueDate,
				startDateType: childDateData.startDateType,
				dueDateType: childDateData.dueDateType,
			};
		}
	});
	return attributesHash;
};

export const getSelectedDependencyPure = (
	tooltipParams: TooltipParams,
	dependeesHash: Hash<IssueId[]>,
): SelectedDependency | undefined => {
	if (tooltipParams && tooltipParams.type === DEPENDENCIES_FLYOUT_TYPE) {
		const {
			id,
			links: { toIds },
		}: DependencyTooltip = tooltipParams;
		const toId = toIds.find(
			(dependee: IssueId) => dependeesHash[id] && dependeesHash[id].includes(dependee),
		);

		if (toId !== undefined) {
			return {
				fromId: id,
				toId,
			};
		}
	}
	return undefined;
};

export const getDependenciesTooltipParamsPure = (
	tooltipParams: TooltipParams,
	dependenciesHash: Hash<IssueId[]>,
	dependeesHash: Hash<IssueId[]>,
): DependencyTooltipParams | undefined => {
	if (tooltipParams && tooltipParams.type === DEPENDENCIES_FLYOUT_TYPE) {
		const {
			id,
			position,
			links: { fromIds, toIds },
			showImplicitDependency,
		}: DependencyTooltip = tooltipParams;

		const filteredDependencies = fromIds.filter(
			(dependency: IssueId) => dependenciesHash[id] && dependenciesHash[id].includes(dependency),
		);

		const filteredDependees = toIds.filter(
			(dependee: IssueId) => dependeesHash[id] && dependeesHash[id].includes(dependee),
		);

		return {
			position,
			issueId: id,
			type: tooltipParams.type,
			dependencies: filteredDependencies,
			dependees: filteredDependees,
			showImplicitDependency,
			...(fg('jsw_roadmaps_timeline-dependency-flyout-refocus')
				? { triggerRef: tooltipParams.triggerRef }
				: {}),
		};
	}

	return undefined;
};

export const getImplicitDependencyItemsHashPure = (
	issueIds: IssueId[],
	issueParentIdHash: Hash<IssueId | undefined>,
	expandedItems: ExpandedItems,
	filteredIssueDependencies: Hash<IssueId[] | undefined>,
	filteredIssueDependees: Hash<IssueId[] | undefined>,
	attributesHash: Hash<OverlayItem>,
	isChildIssuePlanningEnabled: boolean,
): Hash<ImplicitDependencyItem[]> => {
	const implicitDependencyItems: Record<string, ImplicitDependencyItem[]> = {};

	isChildIssuePlanningEnabled &&
		issueIds.forEach((issueId: IssueId) => {
			const parentId = issueParentIdHash[issueId];
			const isChildIssue = parentId !== undefined;
			const isParentIssueCollapsed = Boolean(parentId && !expandedItems[parentId]);

			if (isChildIssue && isParentIssueCollapsed) {
				const dependencies =
					filteredIssueDependencies[issueId]?.map((id: IssueId) => attributesHash[id]) ?? [];
				const dependees =
					filteredIssueDependees[issueId]?.map((id: IssueId) => attributesHash[id]) ?? [];

				if (dependencies.length > 0 || dependees.length > 0) {
					const item = attributesHash[issueId];

					if (parentId) {
						if (implicitDependencyItems[parentId] === undefined) {
							implicitDependencyItems[parentId] = [];
						}

						implicitDependencyItems[parentId].push({
							item,
							dependencies,
							dependees,
						});
					}
				}
			}
		});

	return implicitDependencyItems;
};

export const getDependenciesItemsPure = (
	positionsHash: Hash<number>,
	itemsHash: ChartDataHash,
	dependenciesHash: Hash<IssueId[]>,
	epicIssueTypeIds: IssueTypeId[],
	issueTypeIdHash: Hash<IssueTypeId>,
	defaultItemHeight: number,
) => {
	const dependencies: DependencyItem[] = [];

	Object.keys(dependenciesHash).forEach((issueId: IssueId) => {
		const blockedIssueId: IssueId = issueId;
		const issueDependencies = dependenciesHash[blockedIssueId];

		if (itemsHash[blockedIssueId] && positionsHash[blockedIssueId] !== undefined) {
			const blockedItem = itemsHash[blockedIssueId];

			const blockedItemStartDate = blockedItem.startDate;
			if (blockedItemStartDate !== undefined) {
				const to = {
					id: blockedIssueId,
					startDate: blockedItemStartDate,
					y: positionsHash[blockedIssueId],
					color: blockedItem.color,
				};
				issueDependencies.forEach((blockerIssueId) => {
					if (itemsHash[blockerIssueId] && positionsHash[blockerIssueId] !== undefined) {
						const blockerItem = itemsHash[blockerIssueId];
						const blockerItemDueDate = blockerItem.dueDate;
						if (blockerItemDueDate !== undefined) {
							const itemHeight =
								!epicIssueTypeIds.includes(issueTypeIdHash[blockedIssueId]) ||
								!epicIssueTypeIds.includes(issueTypeIdHash[blockerIssueId])
									? BASE_LEVEL_ITEM_HEIGHT
									: defaultItemHeight;

							dependencies.push({
								itemHeight,
								from: {
									id: blockerIssueId,
									dueDate: blockerItemDueDate,
									y: positionsHash[blockerIssueId],
									color: blockerItem.color,
								},
								to,
							});
						}
					}
				});
			}
		}
	});

	return dependencies;
};
