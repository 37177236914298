import React, { memo, useEffect } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { EditSprintMenuAsync, EditSprintMenuLazy } from './edit-sprint-menu/async';
import messages from './messages';
import type { Props } from './types';

const EditSprintOption = ({
	id,
	isSprintEditable,
	isSprintClearable,
	sprints,
	Option,
	setCloseMenu,
}: Props) => {
	const { formatMessage } = useIntl();

	useEffect(() => {
		EditSprintMenuLazy.preload();
	}, []);

	return (
		<Option isDisabled={!isSprintEditable} label={formatMessage(messages.editSprint)}>
			{({ setInitialFocusRef }) => (
				<EditSprintMenuAsync
					id={id}
					isSprintClearable={isSprintClearable}
					sprints={sprints}
					setInitialFocusRef={setInitialFocusRef}
					setCloseMenu={setCloseMenu}
				/>
			)}
		</Option>
	);
};

export default memo<Props>(EditSprintOption);
