import type { IssueId, Hash } from '@atlassian/jira-shared-types';
import type { Parent } from '@atlassian/jira-software-filters';
import { createSelectorWithDefaultEqual } from '../../../../../common/utils/reselect';
import { getIssueParentIds, getIssueSummaryHash } from '../../../../entities/issues/selectors';

export const getIssueParents = createSelectorWithDefaultEqual(
	getIssueParentIds,
	getIssueSummaryHash,
	(uniqueIssueParentIds: IssueId[], issueSummaryHash: Hash<string>): Parent[] =>
		uniqueIssueParentIds.map((parentId) => ({
			id: parentId,
			name: issueSummaryHash[parentId],
		})),
);
