import { fetchQuery } from 'react-relay';
import type { VariablesOf, OperationType, ConcreteRequest } from 'relay-runtime';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators/catchError';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { tap } from 'rxjs/operators/tap';
import uuid from 'uuid';
import getRelayEnvironment from '@atlassian/jira-relay-environment';
import { startCaptureGraphQlErrors } from '@atlassian/jira-relay-errors';
import {
	startCapturingExtensions,
	stopCapturingExtensions,
	getExtensions,
} from '@atlassian/relay-capture-extensions';
import {
	startCapturingTraceIds,
	stopCapturingTraceIds,
	getTraceIds,
} from '@atlassian/relay-traceid';
import { isUnsubscribableQueryEnabled } from '../../feature-flags';
import type { AggErrors, ResponseWithMetadata, GraphqlExtensions } from '../types';
import {
	AggError,
	GRAPHQL_ERROR,
	NETWORK_ERROR,
	MISSING_DATA_ERROR,
	INVALID_PAYLOAD_ERROR,
} from './agg-error';
import type { Metric, ExecutionOptions } from './types';
import 'rxjs/add/observable/fromPromise';
import {
	sendOperationalEventV2,
	getGraphQlErrorsForOperation,
	fetchQueryObservable,
	transformGenericError,
	sendOperationalEventV2OnError,
} from './utils';

const runQueryWithMetadataInternal = <TQuery extends OperationType>(
	compiledQuery: ConcreteRequest,
	variables: VariablesOf<TQuery>,
	executionOptions: ExecutionOptions = {},
): Observable<ResponseWithMetadata<TQuery['response']>> => {
	const operationName = compiledQuery.params.name;
	startCapturingTraceIds(operationName);
	startCapturingExtensions(operationName);

	const errorCollector = startCaptureGraphQlErrors();

	if (isUnsubscribableQueryEnabled()) {
		return fetchQueryObservable<TQuery>(getRelayEnvironment(), compiledQuery, variables)
			.catch((error: Error) => {
				const traceIds = getTraceIds(operationName);
				stopCapturingTraceIds(operationName);
				throw transformGenericError(error, traceIds, operationName);
			})
			.map((data) => {
				const traceIds = getTraceIds(operationName);
				stopCapturingTraceIds(operationName);
				const [extensions] = getExtensions(operationName);
				stopCapturingExtensions(operationName);

				const relevantErrors = getGraphQlErrorsForOperation(
					errorCollector,
					operationName,
					executionOptions,
				);

				if (relevantErrors.length > 0)
					throw new AggError(
						`Query ${operationName} had graphql errors`,
						relevantErrors,
						traceIds,
						GRAPHQL_ERROR,
					);

				return {
					metadata: {
						operationName,
						traceIds,
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						extensions: extensions as GraphqlExtensions,
					},
					data,
				};
			});
	}

	return Observable.fromPromise(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		new Promise((resolve, reject: (error?: any) => void) => {
			fetchQuery<TQuery>(getRelayEnvironment(), compiledQuery, variables).subscribe({
				error: (error: Error) => {
					const traceIds = getTraceIds(operationName);
					stopCapturingTraceIds(operationName);
					stopCapturingExtensions(operationName);

					const transformedError = transformGenericError(error, traceIds, operationName);
					reject(transformedError);
				},
				next: (data) => {
					const traceIds = getTraceIds(operationName);
					stopCapturingTraceIds(operationName);
					const [extensions] = getExtensions(operationName);
					stopCapturingExtensions(operationName);

					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					const relevantErrors = getGraphQlErrorsForOperation(
						errorCollector,
						operationName,
						executionOptions,
					) as AggErrors;
					if (relevantErrors.length > 0) {
						reject(
							new AggError(
								`Query ${operationName} had graphql errors`,
								relevantErrors,
								traceIds,
								GRAPHQL_ERROR,
							),
						);
						return;
					}
					resolve({
						metadata: {
							operationName,
							traceIds,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							extensions: extensions as GraphqlExtensions,
						},
						data,
					});
				},
			});
		}),
	);
};

export const runQueryWithMetadata = <TQuery extends OperationType>(
	compiledQuery: ConcreteRequest,
	variables: VariablesOf<TQuery>,
	analyticKey: string,
	concurrentMetricDefinition: (concurrentId: string) => Metric,
	executionOptions: ExecutionOptions = {},
): Observable<ResponseWithMetadata<TQuery['response']>> => {
	const concurrentId = uuid.v4();
	concurrentMetricDefinition(concurrentId).start();
	let isRetried = false;

	return runQueryWithMetadataInternal<TQuery>(compiledQuery, variables, executionOptions).pipe(
		catchError((error) => {
			if (error instanceof AggError) {
				if (
					error.errorType === INVALID_PAYLOAD_ERROR ||
					error.errorType === MISSING_DATA_ERROR ||
					((error.errorType === NETWORK_ERROR || error.errorType === GRAPHQL_ERROR) &&
						(error.errors.length === 0 ||
							(error.errors[0].extensions != null &&
								// @ts-expect-error - TS2533 - Object is possibly 'null' or 'undefined'. | TS2365 - Operator '>=' cannot be applied to types 'number' and 'string'.
								error.errors[0].extensions.statusCode >= '500' &&
								// @ts-expect-error - TS2533 - Object is possibly 'null' or 'undefined'. | TS2365 - Operator '<=' cannot be applied to types 'number' and 'string'.
								error.errors[0].extensions.statusCode <= '600')))
				) {
					isRetried = true;
					return runQueryWithMetadataInternal<TQuery>(compiledQuery, variables, executionOptions);
				}
			}
			throw error;
		}),
		tap({
			next: ({ metadata: { traceIds } }) => {
				sendOperationalEventV2(analyticKey, '200', traceIds, isRetried);
				concurrentMetricDefinition(concurrentId).stop({
					customData: {
						traceId: traceIds[0],
						statusCode: '200',
						isRetried,
					},
				});
			},
			error: (error) => {
				const { traceIds, statusCode } = sendOperationalEventV2OnError(
					error,
					analyticKey,
					isRetried,
				);
				concurrentMetricDefinition(concurrentId).stop({
					customData: {
						traceId: traceIds[0],
						statusCode,
						isRetried,
					},
				});
			},
		}),
	);
};

export const runQueryWithMetadataAndSelector = <TQuery extends OperationType, TField>(
	compiledQuery: ConcreteRequest,
	variables: VariablesOf<TQuery>,
	analyticKey: string,
	concurrentMetricDefinition: (concurrentId: string) => Metric,
	selector: (response: TQuery['response']) => TField | undefined | null,
	executionOptions: ExecutionOptions = {},
): Observable<ResponseWithMetadata<TField>> =>
	runQueryWithMetadata<TQuery>(
		compiledQuery,
		variables,
		analyticKey,
		concurrentMetricDefinition,
		executionOptions,
	).map(({ data, metadata }) => {
		const field = selector(data);
		if (field == null) {
			throw new AggError(
				`Failed to retrieve data via ${metadata.operationName}`,
				[],
				metadata.traceIds,
				MISSING_DATA_ERROR,
			);
		}
		return { data: field, metadata };
	});
