import React from 'react';
import type { TimelineMode, TimelineDuration } from '../../../common/types';
import { getTimelineWidth } from '../../../common/utils';
import { getTodayPosition } from './utils';
import TodayMarkerLine from './view';

type Props = {
	today: number;
	timelineMode: TimelineMode;
	timelineDuration: TimelineDuration;
};

const TodayMarker = ({ today, timelineMode, timelineDuration }: Props) => (
	<TodayMarkerLine
		todayPosition={getTodayPosition(
			today,
			timelineDuration,
			getTimelineWidth(timelineMode, timelineDuration.totalMilliseconds),
		)}
	/>
);

export default TodayMarker;
export type { Props as APIProps };
