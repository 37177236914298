import { useRef, useCallback } from 'react';
import { useViewport } from '@atlassian/jira-software-roadmap-timeline-table';
import type {
	SetViewportScroll,
	ScrollBounding,
	ClientBounding,
	RequestScrollBounding,
} from '@atlassian/jira-software-roadmap-timeline-table/src/types';
import type { Position } from '../types';
import { createCalculateScrollOnDragWithOffset } from './calculate-scroll-on-drag';

const calculateScrollOnDragLeft = createCalculateScrollOnDragWithOffset(120);
const calculateScrollOnDragTop = createCalculateScrollOnDragWithOffset(40);

const DEFAULT_VIEWPORT_CACHE: {
	scrollBoundingRect: ScrollBounding | undefined;
	viewportBoundingRect: ClientBounding | undefined;
} = {
	scrollBoundingRect: undefined,
	viewportBoundingRect: undefined,
} as const;

export const adjustScrollPosition = (
	from: Position,
	to: Position,
	setViewportScroll: SetViewportScroll,
	initialScrollBoundingRect: ScrollBounding | undefined,
	requestViewportScrollBounding: RequestScrollBounding,
	viewportBoundingRect: ClientBounding | undefined,
	// Use current viewport scrollbounding if this is not defined
	newScrollLeft: number | undefined = undefined,
	// Use current viewport scrollbounding if this is not defined
	newScrollTop: number | undefined = undefined,
): {
	scrollLeft: number;
	scrollTop: number;
	updatedScrollLeft: number | undefined;
	updatedScrollTop: number | undefined;
} => {
	const initialScrollLeft = initialScrollBoundingRect ? initialScrollBoundingRect.scrollLeft : 0;
	const initialScrollTop = initialScrollBoundingRect ? initialScrollBoundingRect.scrollTop : 0;
	const { scrollLeft, scrollWidth, scrollTop, scrollHeight } = requestViewportScrollBounding();

	let topScroll;
	let leftScroll;

	if (viewportBoundingRect) {
		const { width, left, right, top, height, bottom } = viewportBoundingRect;

		const newScrollTopValue = calculateScrollOnDragTop(
			height,
			top,
			bottom,
			to.y,
			to.y - from.y,
			scrollTop,
			scrollHeight - height,
		);

		if (newScrollTopValue !== scrollTop && newScrollTopValue > 0) {
			setViewportScroll({ scrollTop: newScrollTopValue });
			topScroll = newScrollTopValue;
		}

		const newScrollLeftValue = calculateScrollOnDragLeft(
			width,
			left,
			right,
			to.x,
			to.x - from.x,
			scrollLeft,
			scrollWidth - width,
		);

		if (newScrollLeftValue !== scrollLeft && newScrollLeftValue > 0) {
			setViewportScroll({ scrollLeft: newScrollLeftValue });
			leftScroll = newScrollLeftValue;
		}
	}

	return {
		scrollLeft: initialScrollLeft - (newScrollLeft !== undefined ? newScrollLeft : scrollLeft),
		scrollTop: initialScrollTop - (newScrollTop !== undefined ? newScrollTop : scrollTop),
		updatedScrollLeft: leftScroll,
		updatedScrollTop: topScroll,
	};
};

/* Provides the ability to adjust the viewport scroll position while dragging.
 * This caters to the common use case of providing more room to drag while moving towards the edge of the viewport.
 * !! The viewport cache is a performance optimisation and needs to be set to adjust the position.
 * !! The ideal time to set it is at the start of a drag operation, since these parameters are unexpected to change for the duration of the drag.
 */
export const useAdjustScrollPosition = () => {
	const viewportContext = useViewport();
	const viewportCache = useRef(DEFAULT_VIEWPORT_CACHE);
	const baselineScrollLeft = useRef(0);
	const baselineScrollTop = useRef(0);

	const { requestViewportBoundingClient, requestViewportScrollBounding, setViewportScroll } =
		viewportContext;

	const updateViewportCache = useCallback(() => {
		const scrollBoundingRect = requestViewportScrollBounding();
		const viewportBoundingRect = requestViewportBoundingClient();

		viewportCache.current = { viewportBoundingRect, scrollBoundingRect };
		baselineScrollLeft.current = scrollBoundingRect.scrollLeft;
		baselineScrollTop.current = scrollBoundingRect.scrollTop;
	}, [requestViewportBoundingClient, requestViewportScrollBounding]);

	// The offset between the old and new scroll positions is returned.
	// This can be used to help keep a drag element up to date with the mouse position.
	const adjustScrollPositionAndGetOffset = useCallback(
		(from: Position, to: Position) => {
			const { scrollBoundingRect, viewportBoundingRect } = viewportCache.current;

			const { scrollLeft, updatedScrollLeft } = adjustScrollPosition(
				from,
				to,
				setViewportScroll,
				scrollBoundingRect,
				requestViewportScrollBounding,
				viewportBoundingRect,
				baselineScrollLeft.current,
				baselineScrollTop.current,
			);

			if (updatedScrollLeft !== undefined) {
				baselineScrollLeft.current = updatedScrollLeft;
			}

			return scrollLeft;
		},
		[setViewportScroll, requestViewportScrollBounding],
	);

	return { updateViewportCache, adjustScrollPositionAndGetOffset };
};
