/** @jsx jsx */
import React, { memo, useCallback, type ComponentType } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { CustomFilter } from '@atlassian/jira-filters';
import { useIntl } from '@atlassian/jira-intl';
import {
	useAnalyticsEvents,
	fireUIAnalytics,
	type AnalyticsAttributes,
} from '@atlassian/jira-product-analytics-bridge';
import type { Goal } from '@atlassian/jira-shared-types';
import {
	type Filters,
	type FilterType,
	type IssueType,
	type Person,
	type Label,
	type Version,
	type Component,
	type StatusCategory,
	type CustomFilterType,
	type QuickFilterType,
	type Parent,
	FilterBarStateless,
	ASSIGNEE,
	VERSION,
	LABEL,
	COMPONENT,
	CLEAR,
	UNASSIGNED_USER_ID,
	UNASSIGNED_VERSION_ID,
	UNASSIGNED_LABEL_ID,
	UNASSIGNED_COMPONENT_ID,
} from '@atlassian/jira-software-filters';
import type { CustomFilterProps } from '@atlassian/jira-software-filters/src/ui/stateless/types';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import { useCollator } from '../../common/use-collator';
import messages from './messages';

type APIProps = {
	issueTypes: IssueType[];
	isAddPeopleButtonEnabled: boolean;
	isCustomFilterManageLinkEnabled: boolean;
	isVersionFilterSpotlightEnabled: boolean;
	isCustomFiltersLoading: boolean;
	issueParents: Parent[];
	issueParentsLabel: string;
	projectId: number;
	projectKey: string;
	projectName: string;
	boardId: number;
	AddPeopleModal:
		| ComponentType<{
				onClose: () => void;
				isOpen: boolean;
		  }>
		| undefined;
	assignees: Person[];
	labels: Label[];
	versions: Version[];
	components: Component[];
	statusCategories: StatusCategory[];
	customFilters: CustomFilterType[];
	quickFilters: QuickFilterType[];
	filter: Filters;
	isCMP: boolean;
	goals: Goal[];
};

export type Props = {
	isMacroView: boolean;
} & APIProps & {
		onClearAllButtonClicked: () => void;
		onChange: (filters: Filters, filterType: FilterType | null) => void;
	};

const RoadmapCustomFilter = (props: CustomFilterProps) => (
	<CustomFilter filters={props.filters} {...props} />
);

const Filter = ({ isMacroView, isCMP, goals, ...filterProps }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const {
		isCustomFiltersLoading,
		versions,
		labels,
		components,
		customFilters,
		quickFilters,
		onChange,
		onClearAllButtonClicked,
	} = filterProps;
	const { formatMessage } = useIntl();
	const jqlFilters = isCMP ? quickFilters : customFilters;

	const { collator } = useCollator();

	const onFilterChanged = useCallback(
		(
			filters: Filters,
			filterType: FilterType | null,
			extraAttributes: AnalyticsAttributes | undefined,
		) => {
			traceUFOPress('timeline-filter');

			onChange(filters, filterType);
			const analyticsEvent = createAnalyticsEvent({
				action: 'changed',
				actionSubject: 'filterBar',
			});

			const hasUnassignedAssignee = Boolean(filterType === ASSIGNEE && filters[ASSIGNEE]);
			let hasUnassignedOption = hasUnassignedAssignee;

			const isAssigneeUnassigned = filters[ASSIGNEE]?.includes(UNASSIGNED_USER_ID);
			let isUnassigned = isAssigneeUnassigned;

			const hasUnassignedVersion = Boolean(filterType === VERSION && filters[VERSION]);
			const hasUnassignedLabel = Boolean(filterType === LABEL && filters[LABEL]);
			const hasUnassignedComponent = Boolean(filterType === COMPONENT && filters[COMPONENT]);
			hasUnassignedOption =
				hasUnassignedAssignee ||
				hasUnassignedVersion ||
				hasUnassignedLabel ||
				hasUnassignedComponent;

			const isVersionUnassigned = filters[VERSION]?.includes(UNASSIGNED_VERSION_ID);
			const isLabelUnassigned = filters[LABEL]?.includes(UNASSIGNED_LABEL_ID);
			const isComponentUnassigned = filters[COMPONENT]?.includes(UNASSIGNED_COMPONENT_ID);
			isUnassigned =
				isAssigneeUnassigned || isVersionUnassigned || isLabelUnassigned || isComponentUnassigned;

			const attributes = hasUnassignedOption
				? {
						filterType,
						isUnassigned,
						...(extraAttributes || {}),
					}
				: { filterType, ...(extraAttributes || {}) };

			fireUIAnalytics(analyticsEvent, 'changeFilterBar', attributes);
		},
		[createAnalyticsEvent, onChange],
	);

	const onClear = useCallback(() => {
		onClearAllButtonClicked();
		const clickedAnalyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});
		const clearedAnalyticsEvent = createAnalyticsEvent({
			action: 'changed',
			actionSubject: 'filterBar',
		});

		fireUIAnalytics(clickedAnalyticsEvent, 'clearFiltersButton');
		fireUIAnalytics(clearedAnalyticsEvent, 'changeFilterBar', { filterType: CLEAR });
	}, [createAnalyticsEvent, onClearAllButtonClicked]);

	const FilterBar = FilterBarStateless;

	const sortedGoals = [...goals].sort((a, b) => collator.compare(a.name, b.name));

	return (
		<div css={[isMacroView ? macroViewStyles : nonMacroViewStyles]}>
			<FilterBar
				{...filterProps}
				goals={sortedGoals}
				externalId="software.roadmap"
				screenName="roadmap"
				onChange={onFilterChanged}
				onClearAllButtonClicked={onClear}
				isVersionsFeatureEnabled={versions.length > 0}
				isLabelsFeatureEnabled={labels.length > 0}
				isComponentsFeatureEnabled={components.length > 0}
				isGoalsFeatureOptionEnabled={goals.length > 0}
				showPanelToggle={false}
				showSearchFilterChangeboarding={!isMacroView}
				hijackBrowserDefaultSearch={!isMacroView}
				isUnassignedAssigneeOptionEnabled
				isUnassignedVersionOptionEnabled
				isUnassignedLabelOptionEnabled
				isUnassignedComponentOptionEnabled
				jqlFilters={jqlFilters}
				CustomFilter={RoadmapCustomFilter}
				showCustomFilters={!isCMP}
				placeholder={formatMessage(messages.filterPlaceholder)}
				isCustomFiltersLoading={isCustomFiltersLoading}
			/>
		</div>
	);
};

Filter.whyDidYouRender = true;

export default memo<Props>(Filter);
export type { APIProps };

const macroViewStyles = css({
	paddingLeft: token('space.300', '24px'),
});
const nonMacroViewStyles = css({
	paddingLeft: 0,
});
