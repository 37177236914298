import type { UseBreakpointCSSVariablesOptions } from '../../common/utils/responsive';

export const headerBreakpointOptions: UseBreakpointCSSVariablesOptions = {
	breakpoint: 1140,
	above: {
		largeButtonVisibility: 'block',
		smallButtonVisibility: 'none',
	},
	below: {
		largeButtonVisibility: 'none',
		smallButtonVisibility: 'block',
	},
};
