import React from 'react';
import '@atlaskit/css-reset';
import last from 'lodash/last';
import memoize from 'lodash/memoize';
import noop from 'lodash/noop';
import { ArchivedProjectRedirect } from '@atlassian/jira-classic-software/src/ui/archived-project-redirect/index.tsx';
import {
	ExperienceFailureTracker as ViewExperienceFailureTracker,
	ExperienceSuccessTracker as ViewExperienceSuccessTracker,
} from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/result-declared/index.tsx';
import { getNormalisedPerformanceFFs } from '@atlassian/jira-common-long-task-metrics/src/common/util/collectors';
import logs from '@atlassian/jira-common-util-logging/src/log';
import { ErrorFlagRenderer } from '@atlassian/jira-error-boundary-flag-renderer';
import { ff, getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { MetaConsumerData } from '@atlassian/jira-providers-meta/src/types.tsx';
import {
	appBundleReadyMark,
	prefetchApiReadyMark,
} from '@atlassian/jira-providers-spa-apdex-analytics/src/marks';
import SubmitApdex from '@atlassian/jira-providers-spa-apdex-analytics/src/submit-apdex-mark/index.tsx';
import { Redirect, type RouteResourceResponse } from '@atlassian/jira-router';
import {
	PROJECT_NOT_AVAILABLE_ERROR,
	NETWORK_ERROR,
} from '@atlassian/jira-router-resources-roadmap/src/services/common/constants.tsx';
import type { RoadmapPrefetchData } from '@atlassian/jira-router-resources-roadmap/src/services/common/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { TenantContext } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { RoadmapSkeleton } from '@atlassian/jira-skeletons/src/ui/roadmap/index.tsx';
import {
	type RoadmapType,
	NEXTGEN,
} from '@atlassian/jira-software-roadmap-model/src/app/index.tsx';
import type { CriticalData } from '@atlassian/jira-software-roadmap-services/src/initialize/types.tsx';
import SoftwareRoadmapApp from '@atlassian/jira-software-roadmap-standard';
import {
	ROADMAP_CRITICAL_DATA_FAILED,
	ROADMAP_CRITICAL_DATA_SUCCEEDED,
	ROADMAP_RESOURCE_DATA_FAILED,
	TTRSLO,
	TTISLO,
} from '@atlassian/jira-software-roadmap-standard/src/constants.tsx';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import UFOCustomData from '@atlassian/react-ufo/custom-data';
import type { CustomData } from '@atlassian/react-ufo/interaction-metrics';
import { toRoadmapSpaAppId } from '../../../common/utils';
import { roadmapPageLoad } from '../../../utils/performance-analytics';

// permanent FF
const getIssueLimit = () => getFeatureFlagValue<number>('jsw.roadmap.issue-overall-limit', 3000);

const logError = memoize((type, error) =>
	logs.safeErrorWithoutCustomerData(type, 'Prefetch data error', error),
);

const getIsReleasesVisible = (criticalData?: CriticalData): boolean =>
	Boolean(
		criticalData?.configuration?.projectConfiguration?.isReleasesFeatureEnabled &&
			criticalData?.settings?.isReleasesVisible,
	);

const getCriticalDataTraceId = (
	criticalData?: CriticalData,
): {
	criticalDataTraceId: string;
} => ({
	criticalDataTraceId: last(criticalData?.metadata?.traceIds || []) || '',
});

const isNativeGraphQL = (criticalData?: CriticalData): boolean =>
	criticalData?.metadata?.extensions?.roadmaps?.isNativeGraphQL || false;

export const getRoadmapSizeMetrics = (
	criticalData?: CriticalData,
): {
	issuesCount: number;
	epicsCount: number;
} => {
	let issuesCount = 0;
	let epicsCount = 0;
	if (criticalData && criticalData.issues) {
		const { hash, sequence } = criticalData.issues;

		sequence.forEach((id) => {
			issuesCount += 1;
			if (!hash[id].parentId.value) {
				epicsCount += 1;
			}
		});
	}

	return { issuesCount, epicsCount };
};

const renderApp = (
	type: RoadmapType,
	rapidViewId: number,
	containerName: string,
	sourceAri: Ari,
	metaConsumerData: MetaConsumerData,
	{
		tenantContext,
	}: {
		tenantContext: TenantContext;
	},
	{ error: prefetchError, loading, data }: RouteResourceResponse<RoadmapPrefetchData>,
) => {
	if (prefetchError && !loading) {
		logError(toRoadmapSpaAppId(type), prefetchError);
		return (
			<>
				{/**
				 * Send taskSuccess because resource error shouldn't affect the app reliability.
				 * Use ROADMAP_RESOURCE_DATA_FAILED as task's reason so we can still keep track
				 * of this occurrence.
				 */}
				<ViewExperienceSuccessTracker location={ROADMAP_RESOURCE_DATA_FAILED} />
				{/* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */}
				<ErrorFlagRenderer id="roadmap" error={prefetchError as Error} />
			</>
		);
	}

	if (!data) {
		return <RoadmapSkeleton />;
	}

	/**
	 *  Redirect users to projects page when project is not available.
	 */
	if (data.roadmapErrorContext) {
		const errorType = data.roadmapErrorContext.type;
		if (errorType === PROJECT_NOT_AVAILABLE_ERROR) {
			return <Redirect to="/jira/projects?showFlag=jsw.project-not-found" />;
		}

		if (errorType === NETWORK_ERROR) {
			return (
				<>
					<ViewExperienceSuccessTracker location={ROADMAP_CRITICAL_DATA_SUCCEEDED} />
					<ErrorFlagRenderer id="roadmap" error={null} />
				</>
			);
		}

		return (
			<>
				<ViewExperienceFailureTracker
					location={ROADMAP_CRITICAL_DATA_FAILED}
					failureEventAttributes={data.roadmapErrorContext.context}
				/>
				<ErrorFlagRenderer id="roadmap" error={null} />
			</>
		);
	}

	// Custom data added to UFO and BM3 events.
	const analyticsCustomData: CustomData = {
		...getNormalisedPerformanceFFs(),
		...getRoadmapSizeMetrics(data.roadmapData),
		...getCriticalDataTraceId(data.roadmapData),
	};

	const { roadmapData, resourcesCacheSource } = data;

	return (
		<UFOSegment name="roadmap">
			<ArchivedProjectRedirect />
			{roadmapData && (
				<>
					{/* @ts-expect-error - TS2739 - Type '{ type: RoadmapType; boardId: number; containerName: string; sourceAri: string; locale: Locale; loggedInAccountId: string; cloudId: string; activationId: string; criticalData: CriticalData; isServer: boolean; isSiteAdmin: boolean; onUnmount: (...args: any[]) => void; }' is missing the following properties from type 'AppProps': isReadOnly, macroConfig */}
					<SoftwareRoadmapApp
						type={type}
						boardId={rapidViewId}
						containerName={containerName}
						sourceAri={sourceAri}
						locale={tenantContext.locale}
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						loggedInAccountId={tenantContext.atlassianAccountId || ('' as AccountId)}
						cloudId={tenantContext.cloudId}
						activationId={tenantContext.activationId}
						criticalData={{
							...roadmapData,
							resourcesCacheSource,
						}}
						isServer={__SERVER__}
						isSiteAdmin={tenantContext.isSiteAdmin}
						onUnmount={noop}
					/>
				</>
			)}
			{/* TODO: Types for JFE BM3Metric and @atlassian/browser-metrics' PageLoadMetric are not compatible */}
			{/* @ts-expect-error - TS2769 - No overload matches this call. */}
			<SubmitApdex
				appName="software-roadmap"
				isExpectedToHaveSsr
				withMarks={[appBundleReadyMark, prefetchApiReadyMark]}
				metric={roadmapPageLoad}
				extra={{
					...analyticsCustomData,
					isNativeGraphQL: isNativeGraphQL(data.roadmapData),
					isSimplified: type === NEXTGEN,
					isIssueDetailsOpen: window.location.href.includes('?selectedIssue='),
					hasAppliedJQLFilters:
						window.location.href.includes('customFilter=') ||
						window.location.href.includes('quickFilter='),
					hasAppliedGoalFilters: window.location.href.includes('goal='),
					isIssueLimit5kEnabled: getIssueLimit() === 5000,
					isReleasesVisible: getIsReleasesVisible(data.roadmapData),
					isCustomScrollbarPdnd: fg('jsw_roadmaps_timeline_table_custom_scroll_pdnd'),
				}}
				ttrSlo={TTRSLO}
				ttiSlo={TTISLO}
				emitOnAnimationFrame={ff('bm3.emit-on-raf.top-experiences')}
			/>
			<UFOCustomData data={analyticsCustomData} />
			<SpaStatePageReady />
		</UFOSegment>
	);
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	type: RoadmapType,
	rapidViewId: number,
	containerName: string,
	sourceAri: Ari,
	metaConsumerData: MetaConsumerData,
	tenantContext: TenantContext,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	prefetchData: any,
) => (
	<>
		<SubProductUpdater subProduct="software" />
		{renderApp(
			type,
			rapidViewId,
			containerName,
			sourceAri,
			metaConsumerData,
			{ tenantContext },
			prefetchData,
		)}
	</>
);
