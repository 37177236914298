import type { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';

const getJqlForCount = (projectId: string, issueTypeId: string) =>
	`project = ${projectId} AND issuetype = ${issueTypeId}`;

// This function returns the number of issues of a particular type
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (projectId: string, issueTypeId: string): Observable<number> => {
	const options = {
		method: 'POST',
		body: JSON.stringify({
			jql: getJqlForCount(projectId, issueTypeId),
			fields: [],
			expand: [],
		}),
	};

	return fetchJson$<{ total: number }>('/rest/api/2/search', options).map(
		(result): number => result.total,
	);
};
