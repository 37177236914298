import React from 'react';
import { styled } from '@compiled/react';
import { N0, N700 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

const DependenciesIcon = () => <Container>1</Container>;

export default DependenciesIcon;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.span({
	color: token('color.text.inverse', N0),
	backgroundColor: token('color.background.accent.gray.bolder', N700),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: gridSize * 2,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: gridSize * 2,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '100%',
	boxSizing: 'border-box',
	overflow: 'hidden',
});
