import type { Action } from '@atlassian/react-sweet-state';
import type { ItemId } from '../../../types';
import { LARGE_STEP } from './constants';
import type {
	State,
	ContainerProps,
	GetData,
	AcceptedInputs,
	Modifiers,
	CreateTriggerType,
} from './types';

// ======================= //
// === PRIVATE ACTIONS === //
// ======================= //

// Moves the roving tab index, updating the DOM and relevant state
const rove =
	(current: HTMLElement | undefined, next: HTMLElement): Action<State, ContainerProps> =>
	({ setState }) => {
		current?.setAttribute('tabindex', '-1');
		next.setAttribute('tabindex', '0');
		setState({ rovingCell: next });
	};

/* The grid must have a focusable cell to navigate. So when a cell is removed, we check
 * whether doing so would invalidate this requirement and find a suitable replacement.
 */
const reconcile =
	(previous: HTMLElement): Action<State, ContainerProps> =>
	({ getState, dispatch }) => {
		const { gridCells, rovingCell, focusedCell } = getState();

		// Does the cell we're removing contain the current roving tab index
		if (previous === rovingCell) {
			const nextCell = gridCells.getStart(rovingCell, true);

			if (nextCell) {
				// Make the new roving point the origin for simplicity
				dispatch(rove(undefined, nextCell));
				// Only focus new cell if the previous was focused too
				previous === focusedCell && nextCell.focus();
			}
		}
	};

// ====================== //
// === PUBLIC ACTIONS === //
// ====================== //

const initialiseRovingCell =
	(elem: HTMLElement): Action<State, ContainerProps> =>
	({ getState, setState }) => {
		if (!getState().rovingCell) {
			setState({ rovingCell: elem });
		}
	};

const createGetCoordinates =
	(getData: GetData, { getCoordinates }: ContainerProps) =>
	(): [number, number] =>
		getCoordinates(getData);

const trackCell =
	(elem: HTMLElement, getData: GetData): Action<State, ContainerProps> =>
	({ getState }, containerProps) => {
		const getCoordinates = createGetCoordinates(getData, containerProps);
		getState().gridCells.set(elem, { getCoordinates });
	};

const untrackCell =
	(elem: HTMLElement): Action<State, ContainerProps> =>
	({ getState, dispatch }) => {
		const { gridCells } = getState();
		gridCells.delete(elem);
		dispatch(reconcile(elem));
	};

const focusCell =
	(elem: HTMLElement): Action<State, ContainerProps> =>
	({ getState, setState, dispatch }) => {
		const { gridCells, rovingCell } = getState();

		if (gridCells.has(elem)) {
			setState({ focusedCell: elem });

			/* Cell can be focused for reasons outside our direct control
			 * which means we have to be resilient to moving the roving tab index
			 */
			if (elem !== rovingCell) {
				dispatch(rove(rovingCell, elem));
			}
		}
	};

const blurCell =
	(elem: HTMLElement): Action<State, ContainerProps> =>
	({ getState, setState }) => {
		const { gridCells, focusedCell } = getState();

		if (gridCells.has(elem) && focusedCell === elem) {
			setState({ focusedCell: undefined });
		}
	};

const navigateCell =
	(type: AcceptedInputs, { ctrlKey }: Modifiers): Action<State, ContainerProps> =>
	({ getState, dispatch }) => {
		const { gridCells, focusedCell } = getState();

		if (!focusedCell) return;
		let nextCell;

		switch (type) {
			case 'ArrowLeft':
				nextCell = gridCells.getBefore(focusedCell);
				break;
			case 'ArrowRight':
				nextCell = gridCells.getAfter(focusedCell);
				break;
			case 'ArrowUp':
				nextCell = gridCells.getAbove(focusedCell);
				break;
			case 'ArrowDown':
				nextCell = gridCells.getBelow(focusedCell);
				break;
			case 'PageUp':
				nextCell = gridCells.getAbove(focusedCell, LARGE_STEP);
				break;
			case 'PageDown':
				nextCell = gridCells.getBelow(focusedCell, LARGE_STEP);
				break;
			case 'Home':
				nextCell = gridCells.getStart(focusedCell, ctrlKey);
				break;
			case 'End':
				nextCell = gridCells.getEnd(focusedCell, ctrlKey);
				break;
			default:
				break;
		}

		if (nextCell && nextCell !== focusedCell) {
			dispatch(rove(focusedCell, nextCell));
			nextCell.focus();
		}
	};

const setActiveCreateTrigger =
	(rowId: ItemId, type: CreateTriggerType, element: HTMLElement): Action<State, ContainerProps> =>
	({ setState }) => {
		setState({
			activeCreateTrigger: { rowId, type, element },
		});
	};

// ====================== //
// === CONSUMER ACTIONS === //
// ====================== //

const removeActiveCreateTrigger =
	(): Action<State, ContainerProps> =>
	({ setState }) => {
		setState({
			activeCreateTrigger: undefined,
		});
	};

const focusCreateTrigger =
	(): Action<State, ContainerProps> =>
	({ getState, setState }) => {
		const activeCreateTrigger = getState().activeCreateTrigger;

		if (activeCreateTrigger) {
			setState({ activeCreateTrigger: undefined });
			activeCreateTrigger.element?.focus();
		}
	};

export const actions = {
	initialiseRovingCell,
	trackCell,
	untrackCell,
	focusCell,
	blurCell,
	navigateCell,
	setActiveCreateTrigger,
	focusCreateTrigger,
	removeActiveCreateTrigger,
};
