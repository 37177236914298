import type { Color } from '@atlassian/jira-issue-epic-color';
import type { IssueId } from '@atlassian/jira-shared-types';
import type { Hash, IdentifiableHash, Sprint } from '@atlassian/jira-software-roadmap-model';
import type { RolledUpDates } from '../dates';
import { getBaseLevelChartData } from './base-level';
import type { ChartData, DateError } from './common/types';
import { getParentLevelChartData } from './parent-level';

export type ChartDataHash = IdentifiableHash<IssueId, ChartData>;
export type { ChartData };

export const getChartDataHash = (
	parentIds: IssueId[],
	childIdsHash: Hash<IssueId[]>,
	issueStartDateHash: Hash<number | undefined>,
	issueDueDateHash: Hash<number | undefined>,
	issueSprintsHash: Hash<Sprint[]>,
	issueColorHash: Hash<Color>,
	issueDateErrorsHash: Hash<DateError[]>,
	parentRolledUpDatesHash: Hash<RolledUpDates>,
	issueParentIdHash: Hash<IssueId | undefined>,
	isSprintsPlanning: boolean,
	isChildIssuePlanningEnabled: boolean,
): ChartDataHash => {
	const chartDataHash: ChartDataHash = {};

	parentIds.forEach((parentId) => {
		chartDataHash[parentId] = getParentLevelChartData(
			issueColorHash,
			issueStartDateHash,
			issueDueDateHash,
			parentRolledUpDatesHash,
			issueDateErrorsHash,
			parentId,
			isChildIssuePlanningEnabled,
		);

		const childIds = childIdsHash[parentId] || [];
		childIds.forEach((childId) => {
			chartDataHash[childId] = getBaseLevelChartData(
				isSprintsPlanning,
				isChildIssuePlanningEnabled,
				issueParentIdHash,
				issueColorHash,
				issueStartDateHash,
				issueDueDateHash,
				issueSprintsHash,
				issueDateErrorsHash,
				childId,
			);
		});
	});

	return chartDataHash;
};
