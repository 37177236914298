/** @jsx jsx */
import React, { useRef, useEffect } from 'react';
import { css, jsx } from '@compiled/react';
import { zIndex } from '../../../constants';
import { usePortalScope } from '../context';

const containerStyles = css({
	position: 'absolute',
	pointerEvents: 'none',
	left: 0,
	top: 0,
	bottom: 0,
	right: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.PORTAL,
	/* clip property is deprecated, but still supported by all target browsers
	 * clip-path added as a fallback in case clip property support will be dropped
	 */
	clip: 'rect(0, auto, auto, 0)',
	clipPath: 'inset(0 0 0 0)',
});

const PortalContainer = () => {
	const { setPortalContainerElement } = usePortalScope();
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setPortalContainerElement(containerRef.current);
		return () => {
			setPortalContainerElement(null);
		};
	}, [setPortalContainerElement]);

	return <div css={containerStyles} ref={containerRef} />;
};

export default PortalContainer;
