export const SET_SHARED = 'state.ui.share.SET_SHARED' as const;

export type SetSharedAction = {
	type: typeof SET_SHARED;
	payload: boolean;
};

export const setShared = (payload: boolean): SetSharedAction => ({
	type: SET_SHARED,
	payload,
});

export const EXPORT_CSV = 'state.ui.share.EXPORT_CSV' as const;

export type ExportCsvActionPayload = {
	inferredStartDateHeader: string;
	inferredDueDateHeader: string;
};

export type ExportCsvAction = {
	type: typeof EXPORT_CSV;
	payload: ExportCsvActionPayload;
};

export const exportCsv = (payload: ExportCsvActionPayload): ExportCsvAction => ({
	type: EXPORT_CSV,
	payload,
});
