import React from 'react';
import { lazy } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import Placeholder from '@atlassian/jira-placeholder';
import { ROADMAP_PACKAGE_NAME } from '../../../constants';
import type { Props } from './view';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const JiraGlobalIssueCreateDialogTrigger = lazy(
	() => import(/* webpackChunkName: "async-standard-roadmap-global-issue-create" */ './view'),
);

const LazyJiraGlobalIssueCreateDialogTrigger = (props: Props) => {
	const { payloadGIC } = props;

	if (payloadGIC !== null) {
		return (
			<JSErrorBoundary
				id="async-standard-roadmap-global-issue-create"
				packageName={ROADMAP_PACKAGE_NAME}
				fallback="flag"
			>
				<Placeholder name="standard-roadmap-global-issue-create" fallback={null}>
					<JiraGlobalIssueCreateDialogTrigger {...props} />
				</Placeholder>
			</JSErrorBoundary>
		);
	}

	return null;
};

export default LazyJiraGlobalIssueCreateDialogTrigger;
