import type { RolledUpDates } from '../../dates';

export const fallbackToInferredDate = (
	explicitDates: {
		explicitStartDate: number | undefined;
		explicitDueDate: number | undefined;
	},
	rolledUpDates: RolledUpDates,
): {
	startDate: number | undefined;
	dueDate: number | undefined;
	isStartDatePlaceholder: boolean;
	isDueDatePlaceholder: boolean;
} => {
	const rollUpStartDate = rolledUpDates.startDate;
	const rollUpDueDate = rolledUpDates.dueDate;
	const { explicitStartDate, explicitDueDate } = explicitDates;
	const { isStartDatePlaceholder, isDueDatePlaceholder } = rolledUpDates;

	if (explicitStartDate === undefined && explicitDueDate === undefined) {
		return {
			startDate: rollUpStartDate,
			dueDate: rollUpDueDate,
			isStartDatePlaceholder,
			isDueDatePlaceholder,
		};
	}

	if (rollUpStartDate !== undefined && rollUpDueDate !== undefined) {
		if (explicitStartDate !== undefined && explicitDueDate === undefined) {
			return {
				startDate: explicitStartDate,
				dueDate: explicitStartDate >= rollUpDueDate ? rollUpStartDate : rollUpDueDate,
				isStartDatePlaceholder,
				isDueDatePlaceholder,
			};
		}

		if (explicitStartDate === undefined && explicitDueDate !== undefined) {
			return {
				startDate: explicitDueDate <= rollUpStartDate ? rollUpDueDate : rollUpStartDate,
				dueDate: explicitDueDate,
				isStartDatePlaceholder,
				isDueDatePlaceholder,
			};
		}
	}

	return {
		startDate: explicitStartDate,
		dueDate: explicitDueDate,
		isStartDatePlaceholder: false,
		isDueDatePlaceholder: false,
	};
};
