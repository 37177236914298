import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	explanationFilter: {
		id: 'roadmap.standard-roadmap.common.table.list-empty-content.explanation-filter',
		defaultMessage: 'There are no issues that match your current filter.',
		description: 'Text explaining why there are no epics to display in the table',
	},
	clearFilter: {
		id: 'roadmap.standard-roadmap.common.table.list-empty-content.clear-filter',
		defaultMessage: 'Clear filter',
		description: 'Clear filter button caption',
	},
	explanationLevelOneSetting: {
		id: 'roadmap.standard-roadmap.common.table.list-empty-content.explanation-level-one-setting',
		defaultMessage: "There isn't a match for your current {levelOneName} display option.",
		description: 'Text explaining why there are no epics to display in the table',
	},
	clearLevelOneSetting: {
		id: 'roadmap.standard-roadmap.common.table.list-empty-content.clear-level-one-setting',
		defaultMessage: 'Clear {levelOneName} display option',
		description: 'Clear display option button caption',
	},
	titleNoAccess: {
		id: 'roadmap.standard-roadmap.common.table.list-empty-content.title-no-access',
		defaultMessage: "There's nothing here!",
		description: 'Title on why there are no epics to display in the table',
	},
	explanationNoAccess: {
		id: 'roadmap.standard-roadmap.common.table.list-empty-content.explanation-no-access',
		defaultMessage:
			'To add issues to the timeline, your Jira administrator needs to give you access to this project.',
		description: 'Text explaining why there are no epics to display in the table',
	},
});
