export const START = 'START' as const;
export const DUE = 'DUE' as const;
export const BOTH = 'BOTH' as const;

type OutsideBoundary = typeof START | typeof DUE | typeof BOTH;

/**
 * Checks whether a start and due date lies outside of a boundary
 */
export const getErrorForDatesOutsideBoundary = (
	startDate?: number,
	dueDate?: number,
	boundaryStartDate?: number,
	boundaryDueDate?: number,
): OutsideBoundary | undefined => {
	// Short circuit when either both boundary dates or both explicit dates are not defined
	if (
		(boundaryStartDate === undefined && boundaryDueDate === undefined) ||
		(startDate === undefined && dueDate === undefined)
	) {
		return undefined;
	}

	const validBoundaryStartDate = boundaryStartDate;
	const validBoundaryDueDate = boundaryDueDate;
	let validStartDate = startDate;
	let validDueDate = dueDate;

	if (startDate !== undefined && dueDate !== undefined && startDate > dueDate) {
		validStartDate = dueDate;
		validDueDate = startDate;
	}

	const isStartDateBeforeBoundaryStartDate =
		validBoundaryStartDate !== undefined && validStartDate !== undefined
			? validStartDate < validBoundaryStartDate
			: false;

	const isDueDateLaterBoundaryDueDate =
		validBoundaryDueDate !== undefined && validDueDate !== undefined
			? validDueDate > validBoundaryDueDate
			: false;

	const isStartDateLaterBoundaryDueDate =
		validBoundaryDueDate !== undefined && validStartDate !== undefined
			? validStartDate > validBoundaryDueDate
			: false;

	const isDueDateBeforeBoundaryStartDate =
		validBoundaryStartDate !== undefined && validDueDate !== undefined
			? validDueDate < validBoundaryStartDate
			: false;

	const isOutsideBoundaryDates =
		(isStartDateBeforeBoundaryStartDate && isDueDateLaterBoundaryDueDate) ||
		(isStartDateLaterBoundaryDueDate && isDueDateLaterBoundaryDueDate) ||
		(isStartDateBeforeBoundaryStartDate && isDueDateBeforeBoundaryStartDate);

	if (isOutsideBoundaryDates) {
		return BOTH;
	}

	if (isStartDateBeforeBoundaryStartDate) {
		return START;
	}

	if (isDueDateLaterBoundaryDueDate) {
		return DUE;
	}

	return undefined;
};

export const isDateAfter = (date?: number, comparisonDate?: number): boolean =>
	date !== undefined && comparisonDate !== undefined ? date > comparisonDate : false;
