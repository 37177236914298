import type { StatusCategoryHash } from '@atlassian/jira-software-roadmap-model';

export const MERGE_STATUS_CATEGORIES =
	'state.entities.status-categories.MERGE_STATUS_CATEGORIES' as const;

export type MergeStatusCategoriesAction = {
	type: typeof MERGE_STATUS_CATEGORIES;
	payload: StatusCategoryHash;
};

export const mergeStatusCategories = (
	payload: StatusCategoryHash,
): MergeStatusCategoriesAction => ({
	type: MERGE_STATUS_CATEGORIES,
	payload,
});
