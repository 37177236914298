import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/empty';
import type { MiddlewareAPI } from 'redux';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { getIssueParentIdHash } from '../../../state/entities/issues/selectors';
import {
	type RouterLocationChangeAction as Action,
	ROUTER_LOCATION_CHANGE,
} from '../../../state/router/actions';
import { getSelectedIssueId } from '../../../state/selectors/issues';
import type { State } from '../../../state/types';
import { changeExpansionState } from '../../../state/ui/table/actions';
import { getExpandedItems } from '../../../state/ui/table/selectors';
import type { StateEpic } from '../../common/types';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(ROUTER_LOCATION_CHANGE).mergeMap(() => {
		const state = store.getState();
		const issueId = getSelectedIssueId(state);

		if (issueId) {
			const parentId = getIssueParentIdHash(state)[issueId];
			if (parentId && !getExpandedItems(state)[parentId]) {
				return Observable.of(changeExpansionState({ id: parentId, isExpanded: true }));
			}
		}

		return Observable.empty<never>();
	})) as StateEpic;
