import { combineEpics } from 'redux-observable';
import type { StateEpic } from '../common/types';
import clear from './clear';
import clearFilter from './clear-filter';
import customFiltersRequest from './custom-filters-request';
import jqlFiltersReapply from './jql-filters-reapply';
import quickFiltersRequest from './quick-filters-request';
import setAssignee from './set-assignee';
import setComponent from './set-component';
import setCustomFilter from './set-custom-filter';
import setDependenciesView from './set-dependencies-view';
import setGoal from './set-goal';
import setIssueParent from './set-issue-parent';
import setIssueType from './set-issue-type';
import setLabel from './set-label';
import setLevelOneSettingView from './set-level-one-setting-view';
import setProgressView from './set-progress-view';
import setQuickFilter from './set-quick-filter';
import setReleasesView from './set-releases-view';
import setShared from './set-shared';
import setStatusCategory from './set-status-category';
import setText from './set-text';
import setTimelineMode from './set-timeline-mode';
import setVersion from './set-version';
import setWarningsView from './set-warnings-view';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default combineEpics(
	clear,
	clearFilter,
	setShared,
	setStatusCategory,
	setIssueType,
	setIssueParent,
	setTimelineMode,
	setDependenciesView,
	setProgressView,
	setWarningsView,
	setReleasesView,
	setLevelOneSettingView,
	setText,
	setLabel,
	setAssignee,
	setVersion,
	setComponent,
	setQuickFilter,
	quickFiltersRequest,
	jqlFiltersReapply,
	setCustomFilter,
	customFiltersRequest,
	setGoal,
) as StateEpic;
